import { apiLinks } from 'config/api-config';
import { checkFieldAndReturnState } from 'globals/utils/fieldValidations';
import { hidePricesDueToPayerType } from 'globals/utils/filtering';
import { fixCommaInWeight, formatDate, getDateFromDateTimeString } from 'globals/utils/formatting';
import { validateGiroNumber } from 'globals/utils/giroNumberValidations';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE_CODE,
  IMPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE_CODE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  SERVICE_POINT_PRODUCT_CODE,
} from '../../../globals/constants';
import { config, CONST } from '../../data-config';
import { internationalLTCDeliveryTime } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { payerCodeValidation } from '../EnterPickupLocation/Validations/AccountNumber-validations';
import { getAdditionalServices } from '../Form-helpers/AdditionalServices-helpers';
import { cherryPickTerminalPostalCode, formPartyData } from '../Form-helpers/Party-helpers';
import { formPieces } from '../Form-helpers/Pieces-helpers';
import { setLoadersState } from '../ServiceDetails/ServiceDetails-helpers/ServiceDetails-General-helpers';
import { isDomesticReturnProduct } from '../ServiceDetails/ServiceDetails-helpers/ServiceDetails-helpers';
import { additionalServicesOptionsValidations } from 'globals/validators/AdditionalServices-validations';
import { getQuoteForPriceAPICall } from '../../../globals/helpers/delivery-options';
import { store } from '../../../store/store';
import { hemleveransDependentVasCheck } from '../../../globals/helpers/additionalServices';
import { GLOBAL_CONST } from '../../../globals/data-config-global';

export const deliveryOptionsReset = () => {
  return {
    selectedProduct: { code: '' },
    desiredPickupDates: [],
    additionalServices: [],
    dangerousGoodsLinkedIfNeeded: true,
    servicePointUseResidentialAddress: { value: false, disabled: false, isProductServicePointDelivery: false },
    chargeableWeight: '',
    additionalServicesError: false,
    additionalServicesGeneralError: false,
    deliveryMonitoringExpanded: false,
    returnProdStatus: false,
    selectProductError: false,

    notificationsError: false,
    notificationEmail: {
      value: '',
      error: false,
    },
    isb2cProduct: false,
    notificationsForReceiver: [
      {
        type: 'sms',
        value: false,
        input: '',
        name: 'Notify by SMS',
      },
      {
        type: 'email',
        value: false,
        input: '',
        name: 'Notify by Email',
      },
    ],
    servicePointId: '',
    selectedServicePoint: {
      id: '',
      street: '',
      name: '',
      cityName: '',
      postalCode: '',
      countryCode: null,
      distance: 0,
      routeDistance: 0,
      featureCodes: [],
      lat: 0,
      lng: 0,
    },
    servicePointsError: false,
    markedServicePoint: {},

    loaders: {
      products: { value: false },
      additionalServices: { value: false },
      shipmentDetails: { value: false },
    },

    servicePoints: [],
  };
};

export const postTimeTable = async (ctxState, dateTime) => {
  const state = getPropperAPIData(ctxState, ctxState.selectedServicePoint && ctxState.selectedServicePoint.cityName);
  const deliveryPostalCode = cherryPickTerminalPostalCode(ctxState, config, state.deliveryPostalCode.value); // CR81

  const body = {
    dateTime: dateTime,
    countryCode: state.pickupCountry.value,
    postalCode: state.pickupPostalCode.value,
    deliveryCountryCode: state.deliveryCountry.value,
    deliveryPostalCode: deliveryPostalCode,
    earliestSent: true,
  };
  let resultMap = {};

  await apiRequest(apiLinks.postTimeTable, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        if (!(result.data.status && result.data.status === 'error')) resultMap = result;
        else resultMap.status = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const getResidentialServicePoint = state => {
  const properState = getPropperAPIData(state);

  return {
    id: RECEIVER_PAYER,
    street: properState.deliveryStreet.value,
    name: properState.deliveryReceiver.value,
    postalCode: properState.deliveryPostalCode.value,
    cityName: properState.deliveryCity.value,
    countryCode: properState.deliveryCountry.value,
  };
};

export const resetPriceSummary = (quotePriceMap, modifiedVas) => {
  // reset service pt related vas price in quote summary
  const modifiedQuotePriceMap = JSON.parse(JSON.stringify(quotePriceMap));
  const unSelectedVas = modifiedVas.filter(vas => !vas.value).map(filteredVas => filteredVas.code);

  unSelectedVas.forEach(vas => {
    delete modifiedQuotePriceMap[vas];
  });

  return modifiedQuotePriceMap;
};

export const disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound = (
  servicePoint,
  additionalServices,
  callFromServicePoint = false,
) => {
  if (
    servicePoint &&
    servicePoint.featureCodes &&
    !servicePoint.featureCodes.some(code => config.servicePointFeatureCode.indexOf(code) !== -1) &&
    additionalServices.some(VAS => config.servicePointDependentVas.indexOf(VAS.code) !== -1)
  ) {
    return resetVAS(additionalServices, config.servicePointDependentVas);
  }

  if (callFromServicePoint) {
    return resetVAS(additionalServices);
  }

  return additionalServices;
};

export const setDisabledAndResetVas = (additionalServices, serviceCodeList, disabled = true) => {
  if (!additionalServices) return additionalServices;
  const modifiedVas = additionalServices.map(vas => {
    if (serviceCodeList.includes(vas.code)) {
      return resetVAS([vas], disabled ? [vas.code] : false)[0];
    }
    return vas;
  });
  return modifiedVas;
};

export const getServicePoints = async (params, context, preSelectedLocationId, updateServicePt = false) => {
  const servicePoints = [];
  const payload = fixCommaInWeight(params);
  let stateToSet = {};

  context.updateState({ hereLoading: true });

  await apiRequest(apiLinks.getServicePoints, 'POST', {
    body: { ...payload },
  })
    .then(res => {
      if (res.status === CONST.STATUS_OK && res.data.servicePoints.length > 0) {
        res.data.servicePoints.forEach(async point => {
          servicePoints.push({
            ...point,
            // routeDistance: point.routeDistance || Math.round(point.distance / 1000),
          });

          stateToSet = {
            servicePoints: servicePoints,
            hereLoading: false,
            servicePointsError: false,
          };

          if (preSelectedLocationId) {
            switch (preSelectedLocationId) {
              // use-residential is pre-selected
              case RECEIVER_PAYER:
                stateToSet.selectedServicePoint = getResidentialServicePoint(context.state);
                stateToSet.markedServicePoint = servicePoints[0];
                break;

              default:
                const swedenPossibleID = 'SE-' + preSelectedLocationId + '00';
                const preSelectedServicePoint = servicePoints.find(
                  servicePt => servicePt.id === preSelectedLocationId || servicePt.id === swedenPossibleID,
                );
                // IF pre-selected present return same
                if (preSelectedServicePoint) {
                  stateToSet.selectedServicePoint = preSelectedServicePoint;
                  stateToSet.markedServicePoint = preSelectedServicePoint;
                } else {
                  // ELSE if does not exist service point loaded => use 1st by search
                  if (stateToSet.selectedServicePoint && !stateToSet.selectedServicePoint.id) {
                    stateToSet.selectedServicePoint = servicePoints[0];
                    stateToSet.markedServicePoint = servicePoints[0];
                  }
                  // stateToSet.servicePointsError = false;
                }
                break;
            }
          } else {
            stateToSet.selectedServicePoint = updateServicePt ? servicePoints[0] : context.state.selectedServicePoint;
            stateToSet.markedServicePoint = servicePoints[0];
          }
        });
      } else {
        stateToSet = {
          servicePointsError: true,
          hereLoading: false,
        };

        if (
          context.state.isb2cProduct &&
          !context.state.servicePointUseResidentialAddress.isProductServicePointDelivery
        ) {
          stateToSet.servicePointUseResidentialAddress = JSON.parse(
            JSON.stringify(context.state.servicePointUseResidentialAddress),
          );
          stateToSet.servicePointUseResidentialAddress.disabled = true;
          stateToSet.selectedServicePoint = {};
          const isResidential = getResidentialFlag(context.state);

          if (isResidential) {
            stateToSet.selectedServicePoint = getResidentialServicePoint(context.state);
          }
        }
      }
    })
    .catch(() => {
      console.error('Error in promise');

      stateToSet = {
        servicePointsError: true,
        hereLoading: false,
      };
    });

  const { additionalServices } = context.state;

  if (additionalServices && additionalServices.length > 0) {
    let modifiedVas = disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound(
      stateToSet.selectedServicePoint,
      additionalServices,
    );

    if (stateToSet?.selectedServicePoint?.locationType === 'locker') {
      modifiedVas = setDisabledAndResetVas(modifiedVas, config.servicePointLockerDisabledVas);
    }

    if (modifiedVas) {
      stateToSet.additionalServices = modifiedVas;
    }
  }

  return stateToSet;
};

const getResidentialFlag = state => {
  let isResidential = false;

  const countrySpecificRules = state.deliveryTypesForParcelConnect[state.deliveryCountry.value];
  if (Array.isArray(countrySpecificRules)) {
    isResidential = countrySpecificRules.some(rule => rule.code.toLowerCase() === 'residential');
  }

  return isResidential;
};

export const updateLTCDates = (matchedProducts, state, pickupDate, isDomestic) => {
  const additionalServices = JSON.parse(JSON.stringify(state.additionalServices));

  const newSelectedProduct = matchedProducts.find(prod => prod.code === state.selectedProduct.code);

  if (newSelectedProduct) {
    // Updates all LTCDate VAS options of the selected product
    if (additionalServices && additionalServices.length > 0) {
      additionalServices.forEach((additionalService, i) => {
        if (additionalService.groups && additionalService.groups.length > 0) {
          additionalService.groups.forEach((group, groupIndex) => {
            if (group.options && group.options.length > 0) {
              group.options.forEach((optionList, parentOptionIndex) => {
                optionList.forEach((option, ind) => {
                  if (option.type === config.OptionFieldTypes.LtcDate && !isDomestic) {
                    additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = '';
                    additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].values =
                      newSelectedProduct.LTCDates ? newSelectedProduct.LTCDates.map(itm => itm) : [];
                  }

                  // loading date
                  if (config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1) {
                    if (additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value) {
                      // some date is already set, so use it and adjust minutes

                      const adjustedDate = new Date(
                        additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value,
                      );

                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = adjustedDate;
                    } else {
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = new Date(
                        pickupDate,
                      );
                    }

                    const now = new Date();
                    if (
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value.getTime() <
                      now.getTime()
                    ) {
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value.setHours(
                        now.getHours(),
                      );
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value.setMinutes(
                        now.getMinutes(),
                      );
                    }
                  }

                  // unloading date
                  if (config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1) {
                    const unloadingDate =
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value;

                    // if new selected pickup date greater than current unloading date; set new date to unloading
                    if (new Date(pickupDate).getTime() > new Date(unloadingDate).getTime()) {
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value = new Date(
                        pickupDate,
                      );
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value.setHours(
                        new Date().getHours(),
                      );
                      additionalServices[i].groups[groupIndex].options[parentOptionIndex][ind].value.setMinutes(
                        new Date().getMinutes(),
                      );
                    }
                  }
                });
              });
            }
          });
        }
      });
    }
  }

  return [additionalServices];
};

const getLTCProd = ltcResponse => {
  const ltcProd = {};

  ltcResponse.forEach(ltc => (ltcProd[ltc.product_code] = !!ltc.delivery_date));

  return ltcProd;
};

const isEurapidAvail = (matchedProducts, state) => {
  return (
    matchedProducts.some(product => config.ltcEurapidProductCodes.includes(product.code.toString())) &&
    state.selectedProduct &&
    config.ltcEurapidProductCodes.includes(state.selectedProduct.code.toString())
  );
};

const resetProduct = () => {
  return { selectedProduct: { code: '' }, additionalServices: [], dangerousGoodsLinkedIfNeeded: true };
};

export const getTimeTable = async (
  date,
  context,
  loaderRef = false,
  isPickupDateCalledFromStep3 = false,
  updateState = true,
  setSelectedPickupDate,
) => {
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';
  const state = getPropperAPIData(
    context.state,
    context.state.selectedServicePoint && context.state.selectedServicePoint.cityName,
  );
  let productTypeName = '';
  let productDeliveryDt = null;
  const stateToSet = {};

  const isDomestic = context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
  const matchedProducts = JSON.parse(JSON.stringify(context.state.matchedProducts));

  if (loaderRef) loaderRef.classList.add('is-loading');

  context.updateState({ additionalServicesError: false });

  // DOMESTIC Sweden = postTimeTable
  if (isDomestic && isSwedenCountry) {
    productDeliveryDt = await postTimeTable(context.state, formatDate(date));
    productTypeName = 'product';
  }
  // INTERNATIONAL + Domestic global = LTC
  else {
    let formURL =
      apiLinks.ltc.commitedLeadtimes +
      '?country_from=' +
      state.pickupCountry.value?.toUpperCase() +
      '&zip_from=' +
      state.pickupPostalCode.value +
      '&country_to=' +
      state.deliveryCountry.value +
      '&zip_to=' +
      state.deliveryPostalCode.value +
      '&customer_pickup_date=' +
      formatDate(date) +
      '&_=' +
      new Date().getTime().toString();

    productDeliveryDt = await internationalLTCDeliveryTime(formURL);
    productTypeName = 'product_code';
  }

  if (productDeliveryDt && !productDeliveryDt.error && productDeliveryDt.status) {
    if (matchedProducts) {
      stateToSet.matchedProducts = addDeliveryDate(
        matchedProducts,
        productDeliveryDt.data,
        productTypeName,
        productDeliveryDt.hasOwnProperty('status') && productDeliveryDt.status,
      );

      // Update fixedDeliveryDate VAS LTCdate option on change of pickup date
      const ltcProd = getLTCProd(productDeliveryDt.data);

      if (
        context.state.selectedProduct &&
        context.state.selectedProduct.constructor === Object &&
        Object.keys(context.state.selectedProduct).length > 0
      ) {
        [stateToSet.additionalServices] = updateLTCDates(stateToSet.matchedProducts, context.state, date);
      }

      // reset vas only in 3rd step
      if (isPickupDateCalledFromStep3) {
        // euroconnect
        if (
          context.state.selectedProduct &&
          config.ltcNonEurapidProductCodes.includes(context.state.selectedProduct.toString())
        ) {
          stateToSet.additionalServices = stateToSet.additionalServices
            ? resetVASIfFixedDeliveryPresent(stateToSet.additionalServices, context.state.ltcFailed || !ltcProd['ECE'])
            : resetVASIfFixedDeliveryPresent(
                context.state.additionalServices,
                context.state.ltcFailed || !ltcProd['ECE'],
              );
        }

        //eurapid
        if (!ltcProd['ERA'] && isEurapidAvail(matchedProducts, context.state)) {
          Object.assign(stateToSet, resetProduct());
        }
      } else {
        // step 4 - LTC call
        if (config.ltcNonEurapidProductCodes.includes(context.state.selectedProduct.code.toString())) {
          //  ECE selected && no delivery date from LTC

          stateToSet.additionalServices = context.state.additionalServices.map(oldStateVas => {
            if (config.vasExcludedForFailedLTC.indexOf(oldStateVas.code) !== -1) {
              // disable if any of notCompatible is checked else if ECE is not present
              oldStateVas.disabled =
                oldStateVas.notCompatibleWith.some(deleselect =>
                  context.state.additionalServices.some(opt => opt.code === deleselect && opt.value),
                ) || !ltcProd['ECE'];
            }

            return oldStateVas;
          });
        }

        // pre selected Eurapid - LTC not avail
        if (!ltcProd['ERA'] && isEurapidAvail(matchedProducts, context.state)) {
          const eurapidProd = stateToSet.matchedProducts.find(prod =>
            config.ltcEurapidProductCodes.includes(prod.code.toString()),
          );
          // setting it to diabled false because we disable it in addDeliveryDate method
          eurapidProd.disabled = false;
        }
      }
    }

    stateToSet.ltcFailed = false;
  } else {
    // LTC failed
    stateToSet.ltcFailed = true;

    // set ltc date error
    stateToSet.matchedProducts = setDeliveryDateError(matchedProducts, isPickupDateCalledFromStep3);

    if (isPickupDateCalledFromStep3) {
      // product Eurapid is disabled
      if (isEurapidAvail(matchedProducts, context.state)) {
        Object.assign(stateToSet, resetProduct());
      } else {
        // perform reset on vas only when PU date called from step 3
        stateToSet.additionalServices = resetVASIfFixedDeliveryPresent(context.state.additionalServices, true);
      }
    } else if (
      context.state.selectedProduct.code &&
      config.ltcEurapidProductCodes.includes(context.state.selectedProduct.code.toString())
    ) {
      // LTC failed and selected prod !== Eurapid
      const eurapidProd = matchedProducts.find(prod => config.ltcEurapidProductCodes.includes(prod.code.toString()));
      eurapidProd.disabled = true;
    }
  }

  // Remove G Services if no time table
  stateToSet.priorityServiceDisabled = isDomestic && (productDeliveryDt.error || productDeliveryDt.status === false);

  // pre selected product - set dates
  if (
    !stateToSet.hasOwnProperty('selectedProduct') &&
    context.state.selectedProduct &&
    context.state.selectedProduct.code
  ) {
    stateToSet.selectedProduct = stateToSet.matchedProducts.find(
      product => product.code === context.state.selectedProduct.code,
    ) ?? { code: '' };
  }

  stateToSet.pickupDate = { value: date, error: false };
  if (setSelectedPickupDate) {
    store.dispatch(setSelectedPickupDate(new Date(date)?.toISOString()));
  }

  if (updateState) {
    context.updateState(stateToSet);
  } else {
    return stateToSet;
  }

  if (loaderRef) loaderRef.classList.remove('is-loading');
};

export const resetVAS = (additionalServices, vasesToBeGreyedOut = false) => {
  const modifiedVAS = JSON.parse(JSON.stringify(additionalServices));

  modifiedVAS.forEach(VAS => {
    VAS.value = false;
    VAS.calculatedResult = null;

    if (vasesToBeGreyedOut && vasesToBeGreyedOut.indexOf(VAS.code) !== -1) {
      VAS.disabled = true;
    } else {
      VAS.disabled = false;
    }

    if (VAS.groups && VAS.groups.length > 0) {
      VAS.groups = resetOptionsSchemaOnVasUnCheck(JSON.parse(JSON.stringify(VAS.groups)));
    }
  });

  return modifiedVAS;
};

const resetVASIfFixedDeliveryPresent = (additionalServices, disable = false) => {
  const modifiedVAS = JSON.parse(JSON.stringify(additionalServices));
  const someExcludedVas = additionalServices.some(VAS => {
    return config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1;
  });
  if (someExcludedVas) {
    modifiedVAS.forEach(VAS => {
      const isExcludedVas = config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1;
      if (isExcludedVas) {
        // Only uncheck this VAS if it is explicitly present in the config for vasExcludedForFailedLTC
        VAS.value = false;
        VAS.calculatedResult = null;
      }
      VAS.disabled = false;

      if (VAS.groups && VAS.groups.length > 0) {
        VAS.groups = resetOptionsSchemaOnVasUnCheck(JSON.parse(JSON.stringify(VAS.groups)));
      }

      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.disabled = disable;
      }
    });
  }

  return modifiedVAS;
};

const setDeliveryDateError = (matchedProducts, disableProd = false) => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(prod => {
      prod.deliveryDate = 'ltc_error';

      if (config.ltcEurapidProductCodes.includes(prod.code.toString())) {
        prod.disabled = disableProd;
        prod.value = false;
      }

      if (config.schedulePickup.indexOf(prod.code) !== -1) {
        prod.LTCDates = [];
      }
    });
  }

  return matchedProducts;
};

export const validateGeoLocation = async (state, additionalServices, productCode, getVas = false) => {
  let url = apiLinks.postGeoLocationValidator;
  const selectedServices = [];
  let resultMap = {};
  const body = {};

  const properState = getPropperAPIData(state);

  if (config.homeDeliveryMapping[productCode].deliveryType === 'Return') {
    body.cityName = properState.pickupCity ? properState.pickupCity.value : '';
    body.postalCode = properState.pickupPostalCode ? properState.pickupPostalCode.value : '';
  } else {
    body.cityName = properState.deliveryCity ? properState.deliveryCity.value : '';
    body.postalCode = properState.deliveryPostalCode ? properState.deliveryPostalCode.value : '';
  }

  body.deliveryType = config.homeDeliveryMapping[productCode].deliveryType;

  if (additionalServices && additionalServices.length > 0) {
    additionalServices.forEach(additionalService => {
      if (additionalService.value) selectedServices.push(additionalService.code);
    });
  }

  // getVas is get Home Delivery validator VAS.
  if (getVas) {
    url = apiLinks.postGeoLocation;
    body.AgreementNo = properState.payerAccount;
  } else {
    body.AgreementNo = state.thirdPartyNumber.value || state.receiverNumber.value || state.accountNumber.value;
    body.additionalServices = selectedServices;
  }

  await apiRequest(url, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK && !(result.data.status && result.data.status === 'error')) {
        resultMap = result;
        resultMap.error = false;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const updateServicePointToReceiverAddress = (params, props, servicePoint) => {
  const stateObj = {};

  stateObj.servicePointUseResidentialAddress = JSON.parse(
    JSON.stringify(props.context.state.servicePointUseResidentialAddress),
  );
  if (params.value) {
    stateObj.servicePointUseResidentialAddress.value = true;
    stateObj.selectedServicePoint = getResidentialServicePoint(props.context.state);
  } else {
    stateObj.servicePointUseResidentialAddress.value = false;
    stateObj.selectedServicePoint =
      props.context.state.servicePoints.length > 0
        ? JSON.parse(JSON.stringify(props.context.state.servicePoints[0]))
        : {};
    if (servicePoint) {
      stateObj.selectedServicePoint = servicePoint;
    }
  }

  props.context.updateState(stateObj);
};

export const resetOptionsSchemaOnVasUnCheck = groups => {
  groups.forEach(group => {
    if (group.options && group.options.length > 0) {
      group.options.forEach(optionList => {
        optionList.forEach(option => {
          const resetOptionsSchema = config.resetOptionSchemaFieldTypes.indexOf(option.type) !== -1;
          const isListCOT = option.optionCode === config.OptionFieldTypes.ListCOT;

          if (resetOptionsSchema && !isListCOT) {
            option.value = '';
            option.error = false;
          } else if (resetOptionsSchema && isListCOT) {
            // reset to default terminal
            const defaultTerminal = option.values.find(
              listCotValue => listCotValue.terminal && listCotValue.terminal.defaultTerminal,
            );

            if (defaultTerminal) {
              option.value = defaultTerminal.value;
            }
            option.error = false;
          }
        });
      });
    }
  });

  return groups;
};

export const checkForProperlySelectedDangerousGoods = (shipmentDetailsRows, additionalServices) => {
  let result = false;
  const dangerousGoodsCheckedAtLeastOnce = Boolean(
    shipmentDetailsRows.find(row => row.dangerousGoods && row.dangerousGoods.value),
  );

  if (dangerousGoodsCheckedAtLeastOnce && additionalServices && Array.isArray(additionalServices)) {
    additionalServices.forEach(additionalService => {
      if (additionalService.code.indexOf('dangerousGoods') !== -1 && additionalService.value === false) result = true;
    });
  }

  return result;
};

export const checkSubmit = async context => {
  let error = false;
  let homeDeliveryError = false;
  let stateToSet = {};
  const state = JSON.parse(JSON.stringify(context.state));

  if (context?.state?.shipmentRange?.value === INTERNATIONAL_SHIPMENT_RANGE) {
    ({ stateToSet, hasErrors: error } = payerCodeValidation(state, stateToSet, error));
  }

  if (context.state.selectedProduct.code) {
    let deliveryMonitoringExpanded = false;

    const { adError, additionalServices } = additionalServicesOptionsValidations(
      JSON.parse(JSON.stringify(context.state.additionalServices)),
    );

    const dangerousGoodsError = checkForProperlySelectedDangerousGoods(
      context.state.shipmentDetailsRows,
      additionalServices,
    );
    stateToSet.dangerousGoodsLinkedIfNeeded = !dangerousGoodsError;

    // FCPG-2348 HEMLEVERANS && DangerousGoods Limited quantity check
    const hemError = hemleveransDependentVasCheck(context.state.selectedProduct.code, context.state.additionalServices);

    error = error || adError || dangerousGoodsError || hemError;

    if (
      document.getElementById('frc_additional-services_delivery_monitoring') &&
      document.getElementById('frc_additional-services_delivery_monitoring').classList.contains('expand')
    ) {
      deliveryMonitoringExpanded = true;
    }

    if (context.state.returnProdStatus) {
      let getState = checkFieldAndReturnState({
        name: 'deliveryInstructions',
        value: context.state.deliveryInstructions.value,
        regEx: config.regEx.everything,
        lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxInstructionsLength}}$`)],
        isRequired: false,
      });

      stateToSet.deliveryInstructions = { value: getState.value, error: getState.error };

      if (getState.error) error = true;
    }

    stateToSet.additionalServices = additionalServices;
    stateToSet.selectProductError = false;
    stateToSet.deliveryMonitoringExpanded = deliveryMonitoringExpanded;

    // Perform homedelivery check to validate access point
    if (config.homeDeliveryProductCodes.indexOf(context.state.selectedProduct.code) !== -1) {
      const validation = await validateGeoLocation(
        context.state,
        additionalServices,
        context.state.selectedProduct.code,
      );

      // replyStatus = 1 means VAS combination issue
      if (validation.error || validation?.data?.replyStatus !== 'Ok') {
        homeDeliveryError = true;
        stateToSet.additionalServicesError = true;
      } else {
        stateToSet.partyData = validation.data;
      }
    } else {
      stateToSet.partyData = null;
    }

    stateToSet.additionalServicesGeneralError = error;

    if (!(error || homeDeliveryError)) {
      stateToSet.missingVASArrNames = [];
      stateToSet.preSelectedVasNotAvailable = '';
    }

    context.updateState(stateToSet);
  } else {
    error = true;

    document.getElementById('fcp-product-container').classList.add('has-pricelist-error');
    stateToSet = {
      selectProductError: true,
      additionalServicesGeneralError: error,
    };

    context.updateState(stateToSet);
  }

  return error || homeDeliveryError;
};

export const isValidBankPlusGiroNumber = context => {
  let isValid = true;
  const COD = context.state.additionalServices.filter(item => item.code === 'cashOnDelivery' && item.value === true);

  if (COD.length !== 0 && COD[0] && COD[0].groups && COD[0].groups.length > 0) {
    COD[0].groups.forEach(group => {
      group.options.forEach(option => {
        if (isValid) {
          const bankGiroOption = option.optionCode === 'bankGiroNumber';
          const plusGiroOption = option.optionCode === 'plusGiroNumber';

          if (bankGiroOption && bankGiroOption.value) {
            isValid = !validateGiroNumber(bankGiroOption.value).isError;
            bankGiroOption.error = !isValid;
          }

          if (plusGiroOption && plusGiroOption.value) {
            isValid = !validateGiroNumber(plusGiroOption.value).isError;
            plusGiroOption.error = !isValid;
          }
        }
      });
    });
  }

  return isValid;
};

export const addDeliveryDate = (matchedProducts, productsWithDeliveryDate, productTypeName, status) => {
  if (matchedProducts && matchedProducts.length > 0) {
    matchedProducts.forEach(prod => {
      if (status) {
        const item = productsWithDeliveryDate.find(productWithDeliveryDate => {
          return (
            (productWithDeliveryDate[productTypeName] && productWithDeliveryDate[productTypeName].toLowerCase()) ===
            (prod.product_code && prod.product_code.toLowerCase())
          );
        });

        // match prod found in matchedProducts
        if (item) {
          prod.deliveryDate = item.deliveryDate ? getDateFromDateTimeString(item.deliveryDate) : item.delivery_date;
          prod.product_features = item.product_features ? item.product_features : undefined;
          prod.LTCDates = item.available_fixed_delivery_dates || [];
          if (item.possible_pickups) {
            prod.possible_pickups = item.possible_pickups;
          }
          if (item.possible_pickups_holidays) {
            prod.possible_pickups_holidays = item.possible_pickups_holidays;
          }
          if (config.ltcEurapidProductCodes.includes(prod?.code?.toUpperCase()) && prod.deliveryDate) {
            prod.disabled = false;
          }

          if (item.options !== undefined) {
            prod.priorityServices = item.options;
          }
        } else {
          prod.deliveryDate = 'ltc_error';
          prod.product_features = [];
          prod.LTCDates = [];
          prod.possible_pickups = [];
          prod.possible_pickups_holidays = [];

          if (config.ltcEurapidProductCodes.includes(prod.code.toString())) {
            prod.disabled = true;
          }
        }
      } else {
        if (config.schedulePickup.indexOf(prod.code) !== -1) {
          prod.LTCDates = [];
          prod.deliveryDate = 'ltc_error';

          // CR-54 Ltc committed delivery date api failure
          if (config.ltcEurapidProductCodes.includes(prod.code.toString())) {
            prod.disabled = true;
          }
        }
      }
    });
  }

  return matchedProducts;
};

export const servicePointCallAndFormDesiredPickupDate = async (context, preSelectedLocationId = false) => {
  let stateToSet = {};
  if (context.state.servicePoints.length === 0) {
    const isB2CProduct = context.state.matchedProducts.find(prod => {
      return config.b2cProducts.indexOf(prod.code) !== -1;
    });

    if (isB2CProduct) {
      const routeState = getPropperAPIData(context.state);
      const pieces = [];

      context.state.shipmentDetailsRows.forEach(row => {
        const obj = {
          length: row.length.value || null,
          height: row.height.value || null,
          weight: row.weight.value || null,
          width: row.width.value || null,
          numberOfPieces: row.quantity.value || null,
        };

        pieces.push(obj);
      });

      const params = {
        dhlProductCode:
          context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? '109' : SERVICE_POINT_PRODUCT_CODE,
        pieces: pieces,
        parties: [
          {
            address: {
              cityName: routeState.deliveryCity.value,
              countryCode: routeState.deliveryCountry.value,
              postalCode: routeState.deliveryPostalCode.value,
              street: routeState.deliveryStreet.value,
              streetNumber: null,
              locationType: null,
              additionalAddressInfo: null,
            },
            type: CONSIGNEE_CUSTOMER_TYPE,
          },
        ],
      };

      stateToSet = await getServicePoints(params, context, preSelectedLocationId, true);
    } else {
      // but set default loading to success
      stateToSet = {
        servicePointsError: false,
      };
    }
  }

  stateToSet.servicePointUseResidentialAddress = JSON.parse(
    JSON.stringify(context.state.servicePointUseResidentialAddress),
  );

  if (!isDomesticReturnProduct(context.state.selectedProduct.code)) {
    const servicePtAPISuccess =
      stateToSet.selectedServicePoint &&
      !stateToSet.servicePointsError &&
      stateToSet.servicePoints &&
      stateToSet.servicePoints.length > 0;

    // servicePointUseResidentialAddress disable fix based on residential value
    const isResidential = getResidentialFlag(context.state);
    stateToSet.servicePointUseResidentialAddress.disabled = !isResidential;
    // selectedServicePoint !== RECEIVER_PAYER, then it means selected service pt is from api
    if (servicePtAPISuccess && stateToSet.selectedServicePoint.id !== RECEIVER_PAYER) {
      stateToSet.servicePointUseResidentialAddress.value = false;
    } else if (servicePtAPISuccess && stateToSet.selectedServicePoint.id === RECEIVER_PAYER) {
      //selectedServicePoint === RECEIVER_PAYER; then selected address is TO address
      stateToSet.servicePointUseResidentialAddress.value = true;
    } else {
      //service point api failed; servicePointUseResidentialAddress is true only if the delivery country has 'residential'
      stateToSet.servicePointUseResidentialAddress.value = isResidential;
      stateToSet.servicePointUseResidentialAddress.disabled = true;
    }
  } else {
    stateToSet.servicePointUseResidentialAddress.value = false;
  }

  return stateToSet;
};

export const getProductBasedPrice = async state => {
  const quoteForPriceData = createQuoteForPricePayloadBody(state);
  let productCode = state.selectedProduct.code;

  if (config.homeDeliveryMapping[productCode]) {
    const productCodeBasedOnVas =
      getProductCodeForHDBasedOnVAS({
        ...state,
      }) || state.products.find(item => item.code === productCode)?.code;
    if (productCodeBasedOnVas) {
      productCode = productCodeBasedOnVas;
    }
  }

  if (productCode) {
    quoteForPriceData.shipment.dhlProductCode = productCode;
  }

  const quoteForPriceResult = await getQuoteForPriceAPICall(quoteForPriceData);

  if (
    !quoteForPriceResult.error &&
    quoteForPriceResult.data &&
    quoteForPriceResult.data.prices &&
    quoteForPriceResult.data.prices[productCode] &&
    Array.isArray(quoteForPriceResult.data.prices[productCode])
  ) {
    return {
      quotePriceError: false,
      quotePriceMap: formQuoteResultMap(quoteForPriceResult.data.prices[productCode]),
    };
  } else {
    return {
      quotePriceError: true,
      quotePriceMap: {},
    };
  }
};

export const formQuoteResultMap = formQuoteResultPrices => {
  const priceMap = {};

  formQuoteResultPrices.forEach(price => {
    priceMap[price.id] = price;
    priceMap[price.id].calculatedResult = price.value
      ? price.value + ' ' + price.unit
      : GLOBAL_CONST.PRICE_NOT_AVAILABLE;
  });

  return priceMap;
};

export const createQuoteForPricePayloadBody = state => {
  const quoteDataMap = {};
  const totals = state.totals.shipmentDetailsRows;
  const { numberOfFullPallets, numberOfHalfPallets } = calculateHalfPalletAndFullPallet(state.shipmentDetailsRows);
  quoteDataMap.validatePartyAccount = state.shipmentPayer.value === SENDER_PAYER;
  quoteDataMap.shipment = {};

  quoteDataMap.shipment.totalNumberOfPieces = totals.quantity.value;
  quoteDataMap.shipment.totalWeight = totals.weight.value;
  quoteDataMap.shipment.totalVolume = totals.volume.value;
  quoteDataMap.shipment.totalLoadingMeters = totals.loadingMeter.value * 1;
  quoteDataMap.shipment.payerCode = state.payerCode;
  quoteDataMap.shipment.piece = formPieces(state);
  quoteDataMap.shipment.parties = formPartyData(state, config);

  if (state.shipmentTypesImpOrExp.value !== '' && state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
    quoteDataMap.shipment.importExport =
      state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
        ? IMPORT_SHIPMENT_TYPE_CODE
        : EXPORT_SHIPMENT_TYPE_CODE;
  }

  if (numberOfFullPallets) quoteDataMap.shipment.numberOfFullPallets = numberOfFullPallets;
  if (numberOfHalfPallets) quoteDataMap.shipment.numberOfHalfPallets = numberOfHalfPallets;

  if (state.additionalServices && state.additionalServices.some(vas => vas.value)) {
    const additionalServices = getAdditionalServices(JSON.parse(JSON.stringify(state.additionalServices)));
    quoteDataMap.shipment.additionalServices = additionalServices
      ? additionalServices.selectedAdditionalServices || null
      : null;
  }

  if (config.homeDeliveryMapping[state.selectedProduct.code]) {
    quoteDataMap.shipment.dhlProductCode = getProductCodeForHDBasedOnVAS(state);
  } else {
    quoteDataMap.shipment.dhlProductCode = state.selectedProduct.code;
  }

  return quoteDataMap;
};

export const getProductCodeForHDBasedOnVAS = ({ additionalServices, selectedProduct }) => {
  const productCodes = additionalServices.filter(vas => vas.value).map(selectedVAS => selectedVAS.productCode);
  const filteredProductCodes = productCodes.filter((val, i, arr) => arr.indexOf(val) === i).sort();
  // possible chances -> [401, 501] , [401], [501]
  const resultProductCode = filteredProductCodes[0] || selectedProduct.code;
  return resultProductCode;
};

const calculateHalfPalletAndFullPallet = shipmentDetailsRows => {
  let numberOfFullPallets = 0;
  let numberOfHalfPallets = 0;

  shipmentDetailsRows.forEach(row => {
    switch (row.shipmentType.value) {
      case 'full pallet':
        numberOfFullPallets += row.quantity.value * 1;
        break;
      case 'half pallet':
        numberOfHalfPallets += row.quantity.value * 1;
        break;
      default:
    }
  });

  return {
    numberOfFullPallets,
    numberOfHalfPallets,
  };
};

export const getQuoteForPriceWithAd = async (context, additionalServiceRow, countryConfig) => {
  let adError = false;
  let stateObj = {};
  let additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));
  let newAdditionalServices = [];

  if (additionalServiceRow && !additionalServiceRow.canMakeQuotePriceCall) {
    const response = additionalServicesOptionsValidations(additionalServices);

    adError = response.adError;
    additionalServices = response.additionalServices;

    context.updateState({ additionalServices: additionalServices, additionalServicesGeneralError: adError });
  }

  if ((!adError || additionalServiceRow.canMakeQuotePriceCall) && !hidePricesDueToPayerType(context.state)) {
    context.updateState(setLoadersState(context.state, true), async () => {
      stateObj = await getProductBasedPrice(context.state);

      if (!stateObj.quotePriceError) {
        newAdditionalServices = additionalServices;
        mapPriceQuoteToAdditionalService(
          stateObj,
          newAdditionalServices,
          countryConfig,
          context.state.selectedProduct.code,
        );

        context.updateState({
          additionalServices: newAdditionalServices,
          ...stateObj,
          ...setLoadersState(context.state, false),
        });
      } else {
        context.updateState({ ...stateObj, ...setLoadersState(context.state, false) });
      }
    });
  }
};

export const isFreeVas = ({ freeVas, vasCode, selectedProductCode }) => {
  let isFree = false;
  for (const [productCode, vasCodes] of Object.entries(freeVas)) {
    if (selectedProductCode === productCode && vasCodes.indexOf(vasCode) !== -1) {
      isFree = true;
      break;
    }
  }
  return isFree;
};

export const mapPriceQuoteToAdditionalService = (
  stateObj,
  newAdditionalServices,
  countryConfig,
  selectedProductCode,
) => {
  if (selectedProductCode) {
    newAdditionalServices.forEach(AD => {
      if (AD.value) {
        if (stateObj.quotePriceMap[AD.code]) {
          AD.calculatedResult = stateObj.quotePriceMap[AD.code].calculatedResult;
        } else {
          const freeVas = isFreeVas({ freeVas: countryConfig?.freeVass, vasCode: AD.code, selectedProductCode });
          if (freeVas) {
            AD.calculatedResult = GLOBAL_CONST.PRICE_FREE_OF_CHARGE;
          } else {
            AD.calculatedResult = GLOBAL_CONST.PRICE_NOT_AVAILABLE;
          }
          AD.translationWhenPriceNotAvailable = true;
        }
      }
    });
  }
};
