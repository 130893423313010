import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Paper,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddressType, useLazySearchAddressesQuery } from 'store/api/fcpAddressBook';
import { Info } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';

type FormValues = {
  searchTerm: string;
};

type AddressSearchDialogProps = {
  open: boolean;
  onClose: () => void;
  onTileClick: (address: AddressType) => void;
};

export const AddressSearchDialog: React.FC<AddressSearchDialogProps> = ({ open, onClose, onTileClick }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<FormValues>();
  const [triggerSearch, { data: response, isLoading, isError }] = useLazySearchAddressesQuery();
  const accountCountryCode = useAppSelector(selectUserAccountCountryCode);

  const defaultPayload = {
    countryCriteria: 'INCLUDE',
    criteriaCountries: [accountCountryCode?.toUpperCase() ?? ''],
    page: 1,
    pageSize: 15,
    searchTerm: null,
    countryCode: accountCountryCode?.toUpperCase() ?? '',
  };

  useEffect(() => {
    if (open) {
      triggerSearch(defaultPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, triggerSearch]);

  const onSubmit = (data: FormValues) => {
    const payload = {
      ...defaultPayload,
      searchTerm: data.searchTerm,
    };
    triggerSearch(payload);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t('general|Search Address Book')}

        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 26, top: 26 }}>
          <CloseIcon
            sx={{
              fontSize: '25px',
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display="flex"
            alignItems="flex-start"
            mb={2}
            sx={{
              mt: '8px',
            }}
          >
            <Controller
              name="searchTerm"
              control={control}
              defaultValue=""
              rules={{ required: t('general|labels|inputs|required') }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={
                    <Stack direction="row" gap="8px">
                      {t('general|labels|Search')}
                      <Tooltip title={t('general|labels|Search Company Name, Contact Name, Address or Account Number')}>
                        <Info color="primary" sx={{ fontSize: '20px' }} />
                      </Tooltip>
                    </Stack>
                  }
                  placeholder={t('general|labels|Search by ID, Company Name, Name, Street, City, Note')}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ ml: 2, px: '42px', height: '48px' }}>
              {t('general|labels|Search')}
            </Button>
          </Box>
        </form>

        {isLoading ? (
          <Box mt={2} textAlign="center">
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Typography color="error">{t('general|errors|General error')}</Typography>
        ) : response?.addressBookList?.length ? (
          <>
            <Typography variant="body1">
              {t('general|labels|Search results')} {t('general|filtered by')}{' '}
              <strong>{response.addressBookList[0]?.name}</strong>
            </Typography>
            <Typography variant="body1">
              {response.total} {response.total === 1 ? t('portalOrder|result found') : t('general|results found')}
            </Typography>
            <Grid
              container
              spacing={2}
              mt={2}
              sx={{
                bgcolor: 'grey.200',
                pb: 2,
              }}
            >
              {response.addressBookList.map((address: AddressType) => (
                <Grid item xs={12} sm={6} key={address.id}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 2,
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: theme => `0 0 10px ${theme.palette.primary.main}`,
                      },
                    }}
                    onClick={() => onTileClick(address)}
                  >
                    <Typography variant="h6">{address.companyName}</Typography>
                    <Typography>{address.name}</Typography>
                    <Typography>{address.street}</Typography>
                    <Typography>{`${address.city}, ${address.countryCode}`}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Typography mt={2}>{t('general|errors|No Results Found')}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
