import { apiLinks } from 'config/api-config';
import { allCountries } from 'globals/data-countries';
import { formatDate } from 'globals/utils/formatting';
import { getTransactionId } from 'globals/utils/generators';
import {
  apiRequest,
  eraseCookie,
  getCookie,
  getScopedCookie,
  setCookie,
  setScopedCookie,
} from 'globals/utils/requests';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  HEMLEVERANS_PRODUCT_CODE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  PRODUCTS_RANGE_ALL,
  PRODUCTS_RANGE_COOKIE,
  PRODUCTS_RANGE_ONE,
} from '../../../globals/constants';
import { CONST, config } from '../../data-config';
import { postTimeTable } from '../DeliveryOptions/DeliveryOptions-helpers';
import {
  accountAndProductValidation,
  postalCodeValidation,
  productsRangeValidation,
} from './Validations/EnterPickupLocation-validations';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import i18n from '../../../locale/i18n';
import React from 'react';

export const genericPostalCodeValidation = async state => {
  const sourceURL = apiLinks.fetchCityName
    .replace('{countryCode}', state.pickupCountry.value)
    .replace('{postalCode}', state.pickupPostalCode.value);

  const destinationUrl = apiLinks.fetchCityName
    .replace('{countryCode}', state.deliveryCountry.value)
    .replace('{postalCode}', state.deliveryPostalCode.value);

  const resultMap = {
    destinationBookable: false,
    sourceBookable: false,
    internationalSourceCityError: false,
    internationalDestinationCityError: false,
    deliveryCity: null,
    pickupCity: null,
    data: {
      destination: null,
      source: null,
    },
    status: null,
    error: false,
  };

  const doApiCall = (type, url) =>
    apiRequest(url, 'GET')
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          const data = result.data;

          if (data && data.validated && data.bookable === undefined) {
            data.bookable = true;
          }

          if (data && type === 'destination') {
            // if data.postalCode is empty, it is a valid case for countries like Ireland
            resultMap.destinationBookable = data.bookable ? true : false;
            resultMap.deliveryCity = data.city;
            resultMap.data.destination = data;
          }

          if (data && type === 'source') {
            // if data.postalCode is empty, it is a valid case for countries like Ireland
            resultMap.sourceBookable = data.bookable ? true : false;
            resultMap.pickupCity = data.city;
            resultMap.data.source = data;
          }
        } else {
          resultMap.error = true;
        }
        return result;
      })
      .catch(() => {
        resultMap.error = true;
      });

  const [sourceValidation, destinationValidation] = await Promise.all([
    doApiCall('source', sourceURL),
    doApiCall('destination', destinationUrl),
  ]);

  if (
    sourceValidation &&
    sourceValidation.status === CONST.STATUS_OK &&
    destinationValidation &&
    destinationValidation.status === CONST.STATUS_OK
  ) {
    resultMap.status = CONST.STATUS_OK;
  }

  return resultMap;
};

export const internationalLTCDeliveryTime = async url => {
  const products = {};

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        products.status = true;
        products.data = result.data;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        products.status = false;
      } else {
        products.error = true;
      }
    })
    .catch(() => {
      products.error = true;
    });

  return products;
};

export const checkInternationalPostalCodeWithLtcZipValidator = async (url, resultMap) => {
  await apiRequest(url.replace('url', apiLinks.ltc.zipvalidator), 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      if (!Array.isArray(result.data)) {
        resultMap.status = true;
        resultMap.data = result.data;
      }
    } else if (result.status === CONST.BAD_REQUEST_ERROR) {
      if (result.data.length > 0) {
        result.data.forEach(data => {
          switch (data.code) {
            case -1:
              resultMap.sourcePostalCode = true;
              break;
            case -2:
              resultMap.destinationPostalCode = true;
              break;
            default:
              return;
          }
        });
      } else {
        resultMap.error = true;
      }
    } else {
      resultMap.error = true;
    }
  });
};

export const updatePayerCodeAndResetProducts = async (routeState, context) => {
  const accountCountryCode = localStorage.getItem('country')?.toUpperCase();
  const originCountry = routeState.pickupCountry.value;
  const deliveryCountry = routeState.deliveryCountry.value;
  const isDomestic = originCountry === deliveryCountry && originCountry === accountCountryCode;
  const shipmentTypesImpOrExp = routeState.shipmentTypesImpOrExp.value;
  let payerCode = '';

  if (accountCountryCode === 'SE') {
    // Sweden terms of delivery
    if (isDomestic) {
      payerCode = '1';
    } else {
      payerCode = shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE ? 'EXW' : 'DAP';
    }
  } else {
    // global terms of delivery
    if (isDomestic) {
      payerCode = 'DAP';
    } else {
      payerCode = shipmentTypesImpOrExp === IMPORT_SHIPMENT_TYPE ? 'EXW' : 'DAP';
    }
  }

  context.updateState({
    payerCode,
    products: [],
  });
};

export const checkSubmit = context => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  // Clone state object
  let state = JSON.parse(JSON.stringify(context.state));

  // Products range check
  validate = productsRangeValidation(state, validate.stateToSet, validate.hasErrors);

  // Postal codes check
  validate = postalCodeValidation(state, validate.stateToSet, validate.hasErrors, 'pickup');
  validate = postalCodeValidation(state, validate.stateToSet, validate.hasErrors, 'delivery');

  // Validate account number and product (if selected)
  validate = accountAndProductValidation(state, validate.stateToSet, validate.hasErrors, 'pickup');

  context.extendedSetState(validate.stateToSet);
  return validate.hasErrors;
};

export const resetProductSelectData = (stateToSet = {}, context, performUpdate) => {
  stateToSet = JSON.parse(JSON.stringify(stateToSet));
  stateToSet.productsPerAccount = [];
  stateToSet.selectedProduct = JSON.parse(JSON.stringify(context.state.selectedProduct));
  stateToSet.selectedProduct.value = '';
  stateToSet.selectedProduct.error = false;

  // Forced update if needed
  performUpdate && context && context.updateState(stateToSet);

  return stateToSet;
};

const filterOutProductsNotAllowed = products => {
  let filteredList;

  filteredList = products.filter(product => {
    return config.allowedProducts.find(p => p.code === product.productCode);
  });

  return filteredList;
};

export const prepareAPIDataForAccountSelect = (result, enabledAccounts) => {
  let accounts = [];

  if (result.data.accounts && result.data.accounts.length > 0) {
    accounts = result.data.accounts.map((account, index) => {
      return {
        name: `${account.accountNumber} (${account.accountReference})`,
        code: account.accountNumber,
        products: filterOutProductsNotAllowed(account.products),
      };
    });
  }

  // Only use accounts that are enabled for quote
  accounts = accounts.filter(
    item => enabledAccounts.findIndex(enabledAcc => enabledAcc.accountNumber === item.code) !== -1,
  );

  return accounts;
};

export const prepareAPIDataForProductSpecificationsAndCircumferrences = result => {
  const productSpecifications = {};
  const productsCircumferrence = {};

  if (result.data.productSpecifications && result.data.productSpecifications.length > 0) {
    result.data.productSpecifications.forEach(product => {
      const key = Object.keys(product)[0];

      // Adds product specifications
      productSpecifications[key] = product[key];

      // Add circumferrences for predefined list of products
      if (config.circumferrenceProducts.indexOf(key) !== -1 && product[key].piece) {
        productsCircumferrence[key] = product[key].piece.circumferenceMax;
      }
    });
  }

  return { productSpecifications, productsCircumferrence };
};

const checkCountry = (country, listOfAllowedCountries) => {
  return listOfAllowedCountries?.find(c => c.country.countryCode.toUpperCase() === country.value);
};

const disableServiceByProductSpecificationsData = (state, product) => {
  const specification = state.productSpecifications[product.productCode];
  const allowedRange = specification.isDomestic ? DOMESTIC_SHIPMENT_RANGE : INTERNATIONAL_SHIPMENT_RANGE;

  // Check country availability
  if (
    !checkCountry(state.pickupCountry, specification.fromCountries) ||
    !checkCountry(state.deliveryCountry, specification.toCountries)
  )
    return true;

  // Check for Domestic / International availability
  if (state.shipmentRange.value !== allowedRange) return true;

  // Check for Market Availability
  return !specification.marketAvailability.find(m => m.name === String(state.marketAvailability.value));
};

export const populateProductsPerAccountSelect = (context, params) => {
  const accounts = context.state.accounts;
  let stateToSet = {};
  let productsData;
  // Populate if data available
  if (accounts && accounts.length > 0 && params.accountNumber.value) {
    productsData = accounts.find(account => {
      return params.accountNumber.value === account.code;
    });
    if (productsData && productsData.products && productsData.products.length > 0) {
      productsData = productsData.products.map(product => {
        return {
          name: product.productName,
          code: product.productCode,
          disabled: disableServiceByProductSpecificationsData(context.state, product),
        };
      });

      stateToSet.productsPerAccount = productsData;

      // Small reset
      stateToSet.selectedProduct = JSON.parse(JSON.stringify(context.state.selectedProduct));
      stateToSet.selectedProduct.value = '';
    }
  }
  // Reset the product select (on prompt option)
  else {
    stateToSet = resetProductSelectData(stateToSet, context);
  }
  context.updateState(stateToSet);
};

export const clearAccountAndProductSelects = (context, stateToSet = {}, forceUpdate = true) => {
  // Prepare to reset account Select
  stateToSet.accountNumber = JSON.parse(JSON.stringify(context.state.accountNumber));
  stateToSet.accountNumber.error = false;

  // Prepares product select resetting and execute context update
  return resetProductSelectData(stateToSet, context, forceUpdate);
};

export const afterUpdateMarketingAvailAbilityAndSelectReset = context => {
  let marketAvailability;

  // Market availability
  if (context.state.pickupAddressResidential.value) {
    marketAvailability = context.state.deliveryAddressResidential.value ? 'C2C' : 'C2B';
  } else {
    marketAvailability = context.state.deliveryAddressResidential.value ? 'B2C' : 'B2B';
  }
  const ctx = Object.assign({}, context, {
    state: Object.assign({}, context.state, {
      marketAvailability: Object.assign({}, context.state.marketAvailability, {
        value: marketAvailability,
      }),
    }),
  });

  // Clear Selects
  clearAccountAndProductSelects(context, { marketAvailability: { value: marketAvailability } }, true);
  populateProductsPerAccountSelect(ctx, context.state);
};

export const getPostalCodePlaceholder = countryCode => {
  const postalCode = allCountries.find(country => country.code.toLowerCase() === countryCode?.toLowerCase());

  const resultCode = postalCode && postalCode.example ? postalCode.example : config.defaultPostalCode;
  return resultCode;
};

export const checkShipmentRange = (
  context,
  pickupCountryCode = null,
  deliveryCountryCode = null,
  userAccountCountryCode,
) => {
  const shipmentRangeObject = {};
  const pickupCountry = pickupCountryCode || context.state.pickupCountry.value;
  const deliveryCountry = deliveryCountryCode || context.state.deliveryCountry.value;
  const userAccountCountry = userAccountCountryCode || context.state.userAccountCountry;

  const fromCountryDomestic = userAccountCountry?.toUpperCase() === pickupCountry?.toUpperCase();
  const toCountryDomestic = userAccountCountry?.toUpperCase() === deliveryCountry?.toUpperCase();
  const isInternational = !(fromCountryDomestic && toCountryDomestic);

  // Shipment range and Import / Export management
  shipmentRangeObject.shipmentRange = JSON.parse(JSON.stringify(context.state.shipmentRange));
  shipmentRangeObject.shipmentRange.value = isInternational ? INTERNATIONAL_SHIPMENT_RANGE : DOMESTIC_SHIPMENT_RANGE;
  shipmentRangeObject.shipmentTypesImpOrExp = JSON.parse(JSON.stringify(context.state.shipmentTypesImpOrExp));
  shipmentRangeObject.shipmentTypesImpOrExp.value = isInternational
    ? fromCountryDomestic
      ? EXPORT_SHIPMENT_TYPE
      : IMPORT_SHIPMENT_TYPE
    : '';

  return shipmentRangeObject;
};

export const afterUpdateForSelectCountry = (context, params) => {
  const stateObj = {};
  let resetSelects = {};

  // Get shipment range (domestic/international) to set
  Object.assign(stateObj, checkShipmentRange(context));
  //Reset account and product selects on change
  if (context.state.accounts?.length > 1) {
    resetSelects = clearAccountAndProductSelects(context, {}, false);
  } else {
    resetSelects = resetProductSelectData(stateObj, context, true);
  }

  Object.assign(stateObj, resetSelects);

  // Update placeholder for the related postalcode
  stateObj[params.relatedField] = JSON.parse(JSON.stringify(context.state[params.relatedField]));
  stateObj[params.relatedField].placeholder = getPostalCodePlaceholder(params.value);

  context.updateState(stateObj);
  populateProductsPerAccountSelect(context, context.state);
};

export const resetProductRange = (context, params) => {
  const stateToSet = {};

  // Remove products range Error
  stateToSet.productsRangeError = { value: false, error: false };

  // Reset Ranges
  if (params.name === PRODUCTS_RANGE_ALL) {
    stateToSet.accountNumber = { value: '', error: false, isRequired: true };
    stateToSet.productsPerAccount = [];
    stateToSet.selectedProduct = { value: '', error: false, isRequired: true };
    stateToSet.productsRangeOne = { value: false };
  } else {
    stateToSet.productsRangeAll = {
      value: false,
      disabled: params.productsRangeAll ? params.productsRangeAll.disabled : false,
    };
  }

  return stateToSet;
};

export const checkForProductsInMultipleAccounts = (contextState, context) => {
  const productsRangeOne = JSON.parse(JSON.stringify(context.state.productsRangeOne));
  const productsRangeAll = JSON.parse(JSON.stringify(context.state.productsRangeAll));
  const accounts = contextState.accounts;
  const searchResult = {};
  let productIndex = 0;
  let accountIndex = 0;
  let multipleFound = false;
  let product;
  let products;

  while (accountIndex < accounts.length && !multipleFound) {
    productIndex = 0;
    products = accounts[accountIndex].products;
    while (productIndex < products.length && !multipleFound) {
      product = products[productIndex];
      if (!searchResult[product.productCode]) {
        searchResult[product.productCode] = 0;
      }
      searchResult[product.productCode]++;
      if (searchResult[product.productCode] > 1) {
        multipleFound = true;
      }
      productIndex++;
    }
    accountIndex++;
  }

  if (multipleFound) {
    productsRangeAll.disabled = true;
    productsRangeOne.value = true;
  }

  return { productsRangeOne, productsRangeAll };
};

export const getCookieCountryAndPostalCode = async (context, userAccountCountry) => {
  const ctxStateToSet = {};
  const cookieData = getCookie('quotePostalCode');
  const deliveryAddressCookie = getCookie(config.bookingCookieName);
  let pickupCountryCode = null;
  let deliveryCountryCode = null;

  if (cookieData || deliveryAddressCookie) {
    // sender side
    if (cookieData) {
      try {
        const cookie = JSON.parse(cookieData);
        for (const [key, value] of Object.entries(cookie)) {
          if (key?.toLowerCase() === userAccountCountry?.toLowerCase()) {
            ctxStateToSet.pickupCountry = {
              comparisonError: false,
              error: false,
              isRequired: true,
              value: value.pickupCountry,
            };

            pickupCountryCode = value.pickupCountry;

            ctxStateToSet.pickupPostalCode = {
              error: false,
              isRequired: true,
              placeholder: getPostalCodePlaceholder(value.pickupCountry),
              value: value.pickupPostalCode,
            };
          }
        }
      } catch {
        return;
      }
    }

    // delivery side
    if (deliveryAddressCookie) {
      const deliveryAddress = await fillDeliveryAddress();

      if (!deliveryAddress.error) {
        ctxStateToSet.deliveryCountry = {
          comparisonError: false,
          error: false,
          isRequired: true,
          value: deliveryAddress.countryCode,
        };

        deliveryCountryCode = deliveryAddress.countryCode;

        ctxStateToSet.deliveryPostalCode = {
          error: false,
          isRequired: true,
          placeholder: getPostalCodePlaceholder(deliveryAddress.countryCode),
          value: deliveryAddress.postalCode,
        };

        ctxStateToSet.deliveryAddressResidential = {
          value: deliveryAddress.residentialAddress,
        };
      }
    }

    // Set proper shipment range
    Object.assign(
      ctxStateToSet,
      checkShipmentRange(context, pickupCountryCode, deliveryCountryCode, userAccountCountry),
    );

    context.updateState(ctxStateToSet);
  }
};

export const fillDeliveryAddress = async () => {
  try {
    let resultMap = {};
    const url = apiLinks.searchUpdateAddress.replace('{id}', getCookie(config.bookingCookieName));

    await apiRequest(url, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap = result.data;
      } else {
        resultMap.error = true;
      }
    });

    eraseCookie(config.bookingCookieName);

    return resultMap;
  } catch {
    return;
  }
};

export const setCookieCountryAndPostalCode = context => {
  setCookie(
    'quotePostalCode',
    JSON.stringify({
      ...JSON.parse(getCookie('quotePostalCode') ?? '{}'),
      [context.state.userAccountCountry]: {
        pickupCountry: context.state.pickupCountry.value,
        pickupPostalCode: context.state.pickupPostalCode.value,
      },
    }),
    CONST.ONE_MONTH,
    'h',
  );
};

export const setQuoteProductsRangeCookie = async ({ context, userEmail }) => {
  const isProductsRangeAll = context?.state?.productsRangeAll?.value === true;
  const isProductsRangeOne = context?.state?.productsRangeOne?.value === true;

  if (!isProductsRangeAll && !isProductsRangeOne) return;

  try {
    await setScopedCookie({
      cookieName: PRODUCTS_RANGE_COOKIE,
      key: userEmail,
      value: isProductsRangeAll ? PRODUCTS_RANGE_ALL : PRODUCTS_RANGE_ONE,
    });
  } catch {
    console.error('Failed to set quoteProductsRange cookie');
  }
};

export const getQuoteProductsRangeCookie = async ({ context, userEmail }) => {
  try {
    // GET COOKIE
    const value = await getScopedCookie({
      cookieName: PRODUCTS_RANGE_COOKIE,
      key: userEmail,
    });
    if (!value) return;

    const isProductsRangeAll = value === PRODUCTS_RANGE_ALL;
    const isProductsRangeOne = value === PRODUCTS_RANGE_ONE;
    if (!isProductsRangeAll && !isProductsRangeOne) return;

    // UPDATE STATE
    if (isProductsRangeAll && !context?.state?.productsRangeAll?.disabled) {
      const updatedRange = structuredClone(context?.state?.productsRangeAll);
      updatedRange.value = true;
      context.updateState({ productsRangeAll: updatedRange }, () =>
        afterUpdateProductRange(context, {
          name: PRODUCTS_RANGE_ALL,
          value: true,
          isRequired: false,
          disabled: false,
          deselect: [PRODUCTS_RANGE_ONE],
        }),
      );
    }

    if (isProductsRangeOne && !context?.state?.productsRangeOne?.disabled) {
      const updatedRange = structuredClone(context?.state?.productsRangeOne);
      updatedRange.value = true;
      context.updateState({ productsRangeOne: updatedRange }, () =>
        afterUpdateProductRange(context, {
          name: PRODUCTS_RANGE_ONE,
          value: true,
          isRequired: false,
          disabled: false,
          deselect: [PRODUCTS_RANGE_ALL],
        }),
      );
    }
  } catch {
    console.error('Failed to read state from quoteProductsRange cookie');
  }
};

export const domesticPostalCodeValidation = async (state, stateToSet, context) => {
  const pickupCity = { value: '', error: false };
  const deliveryCity = { value: '', error: false };

  const result = await genericPostalCodeValidation(state);

  if (!result.error) {
    if (!result.sourceBookable) stateToSet.sourcePostalCodeError = true;
    if (!result.destinationBookable) stateToSet.destinationPostalCodeError = true;

    // Hemleverans is pre-selected => check availability from postal code call
    if (state.selectedProduct && state.selectedProduct.value === HEMLEVERANS_PRODUCT_CODE) {
      if (result.data.destination && result.data.destination.homeDeliveryParcel === false) {
        stateToSet.validationError = true;
        stateToSet.destinationPostalCodeError = true;
        toast(
          <Alert severity="error" variant="standard">
            {i18n.t('general|errors|The product you have selected is not available at the destination address.')}
          </Alert>,
        );
      }
    }

    // Set Domestic City if below condition satisfies
    if (!stateToSet.sourcePostalCodeError && !stateToSet.destinationPostalCodeError) {
      pickupCity.value = result.pickupCity;
      deliveryCity.value = result.deliveryCity;
    } else {
      pickupCity.value = '';
      deliveryCity.value = '';
    }

    context.updateState({
      pickupCity,
      deliveryCity,
    });
  } else {
    stateToSet.apiError = true;
  }
};

export const internationalPostalCodeValidation = async (state, stateToSet) => {
  const resultMap = await genericPostalCodeValidation(state);

  stateToSet.sourcePostalCodeError = !resultMap.sourceBookable;
  stateToSet.destinationPostalCodeError = !resultMap.destinationBookable;
  stateToSet.apiError = resultMap.error;
};

export const getPickupAndDeliveryDateForProducts = async (state, stateToSet, context) => {
  const isDomestic = state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';
  const dateTime = formatDate(new Date());
  let productsDateAPI = null;
  let products = [];
  let ltcFailed = false;

  // Domestic only for SE
  if (isDomestic && isSwedenCountry) {
    productsDateAPI = await postTimeTable(state, dateTime);
  }

  if (!productsDateAPI?.error && productsDateAPI?.status) {
    products = productsDateAPI.data ? productsDateAPI.data : [];
    stateToSet.products = products;
  } else {
    stateToSet.goNextError = isDomestic && isSwedenCountry;
    stateToSet.apiError = isDomestic && isSwedenCountry;
    ltcFailed = isDomestic && isSwedenCountry;
  }

  stateToSet.eurapidLtcError =
    context.state.productsRangeOne.value &&
    config.ltcEurapidProductCodes.includes(context.state.selectedProduct.value.toString()) &&
    products.some(ltcProduct => ltcProduct.product === 'ERA' && !ltcProduct.delivery_date);

  context.updateState({
    products,
    ltcFailed,
  });
};

export const getUsersAccountsAndProductsData = async (context, setLocalState, enabledAccounts) => {
  if (!context.state.accounts || context.state.accounts.length === 0) {
    const url = apiLinks.getUsersAccountsAndProductsData;

    // Gets the transaction ID, if not, generates it
    const transactionId = context.state.transactionId
      ? context.state.transactionId
      : getTransactionId(CONST.QUOTE_TOOL_PREFIX);

    setLocalState({ userDataIsLoading: true });
    // API call to get and format the data
    const stateToSet = {};

    await apiRequest(url, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        const contextState = {
          accounts: prepareAPIDataForAccountSelect(result, enabledAccounts),
          transactionId: transactionId,
        };

        // Implementig product specifications and circumferrence logics
        Object.assign(contextState, prepareAPIDataForProductSpecificationsAndCircumferrences(result));

        // Check for products in multiple accounts and disable "all" option if necessary
        Object.assign(contextState, checkForProductsInMultipleAccounts(contextState, context));
        context.updateState(contextState);
        stateToSet.userAPIError = false;
      } else {
        stateToSet.userAPIError = true;
      }
    });

    // Removes loader
    setLocalState({ userDataIsLoading: false, ...stateToSet });
  }
};

export const afterUpdateProductRange = (context, params) => {
  const stateToSet = {};
  // Reset the specific data
  Object.assign(stateToSet, resetProductRange(context, params));
  if (!!context.state.productsRangeOne.value && context.state.accounts?.length === 1) {
    stateToSet.accountNumber = {
      value: context.state.accounts[0].code,
      error: false,
      isRequired: true,
      comparisonError: false,
    };
    populateProductsPerAccountSelect(context, stateToSet);
  }
  context.updateState(stateToSet);
};

export const setAccountWithProductsIfOnlyOne = (context, params) => {
  const stateToSet = {};
  // Reset the specific data
  if (!!context.state.productsRangeOne.value && context.state.accounts?.length === 1) {
    stateToSet.accountNumber = {
      value: context.state.accounts[0].code,
      error: false,
      isRequired: true,
      comparisonError: false,
    };
    populateProductsPerAccountSelect(context, stateToSet);
  }
  context.updateState(stateToSet);
};
