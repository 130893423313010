import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { Input } from 'components';
import { Error } from 'components';
import { Checkbox } from 'components';
import { loginFormValidation } from './Login-validation';
import {
  credentialsBackendValidation,
  createOrDeleteRememberMeCookie,
  checkForSavedRememberMe,
  createAccessAndRefreshToken,
} from './Login-helpers';
import './Login.css';
import { withRouter, ROUTES } from 'router';
import AuthService from 'globals/utils/auth';
import i18next from 'i18next';
import { SHOW_PLANNED_MAINTENANCE_KEY } from '../../../components/fcp-components/PlannedMaintenance';
import { jwtDecode } from 'jwt-decode';
import { isCountryEnabled } from '../../../locale/config';
import { rootApi } from '../../../store/api/apiSlice';
import { TagTypes } from 'store/api/apiSlice';
import RandomString from 'randomstring';
import { PasswordInput } from 'components/fcp-components/PasswordInput';
import { isValidPilRequest } from '../../../globals/helpers/pil';

const ENV_DEV = process.env.NODE_ENV === 'development';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationError: false,
      loginError: false,
      loading: false,
      login: false,
    };

    this.csrfToken = RandomString.generate(128);
  }

  onSubmit = async (e, t) => {
    e.preventDefault();

    const { context } = this.props;
    const state = JSON.parse(JSON.stringify(context.state));
    let formValidation = {
      stateToSet: {},
      hasErrors: false,
    };

    // validate form
    formValidation = loginFormValidation(state, formValidation.stateToSet, formValidation.hasErrors);

    if (formValidation.hasErrors) {
      this.setState({
        validationError: true,
      });

      // update context state on form errors
      context.updateState(formValidation.stateToSet);
    } else {
      this.setState({ loading: true }, async () => {
        const search = window.location.search;
        const queryParams = new URLSearchParams(search);
        const isPil = isValidPilRequest(queryParams);

        // make backend call to validate credentails
        const credentialsValidation = await credentialsBackendValidation(state, this.csrfToken, isPil);

        // success
        if (credentialsValidation && !credentialsValidation.error) {
          const token = jwtDecode(credentialsValidation.access_token);
          // not enabled country -> do not allow to login
          if (token.accountCountryCode && !isCountryEnabled(token.accountCountryCode.toLowerCase())) {
            if (process.env.REACT_APP_VERBOSE_LOGS?.toLowerCase() === 'true' && ENV_DEV) {
              console.log('User country %s not enabled', token.accountCountryCode);
            }
            this.setState({
              loginError: true,
              login: false,
              loading: false,
            });
          } else {
            // set login status to true
            this.setState({ login: true, loading: false, loginError: false }, async () => {
              // handle authorization tokens
              createAccessAndRefreshToken(credentialsValidation);

              this.props.dispatch(
                rootApi.util.invalidateTags([
                  TagTypes.USER_API_TAG,
                  TagTypes.PRODUCT_API_TAG,
                  TagTypes.BOOKING_API_TAG,
                ]),
              );

              // Handle remember me cookie
              await createOrDeleteRememberMeCookie(state);

              if (this.props.authContext) {
                this.props.authContext.authenticate(true, () => {
                  ENV_DEV && console.log('user authenticated successfully');
                  sessionStorage.setItem(SHOW_PLANNED_MAINTENANCE_KEY, true);
                  ENV_DEV && console.log('Request from PIL: ', isPil);
                  if (isPil) {
                    this.props.navigateRouter(`/${i18next.language}/${ROUTES.pilDashboard}?${queryParams.toString()}`, {
                      replace: true,
                      state: { redirectDisabled: true },
                    });
                  } else {
                    this.props.navigateRouter(`/${i18next.language}/${ROUTES.dashboard}`, { replace: true });
                  }
                });
              }
            });
          }
        } else {
          // Failure
          this.setState({
            loginError: true,
            login: false,
            loading: false,
          });
        }
      });
    }
  };

  componentDidMount = async () => {
    await checkForSavedRememberMe(this.props);
    const isRefreshTokenExpired = AuthService.isRefreshTokenExpired();
    const authToken = AuthService.getAuthToken();
    const search = window.location.search;
    const queryParams = new URLSearchParams(search);
    const isPil = isValidPilRequest(queryParams);

    if (Boolean(localStorage.getItem('authenticated')) && Boolean(authToken) && !isRefreshTokenExpired) {
      if (isPil) {
        ENV_DEV && console.log('User is signed in, redirect to PIL dashboard ', i18next.language);
        this.props.navigateRouter(`/${i18next.language}/${ROUTES.pilDashboard}?${queryParams.toString()}`, {
          replace: true,
          state: { redirectDisabled: true },
        });
      } else {
        ENV_DEV && console.log('User is signed in, redirect to dashboard ', i18next.language);
        this.props.navigateRouter(`/${i18next.language}/${ROUTES.dashboard}`, { replace: true });
      }
    }
  };

  render() {
    const { context } = this.props;

    return (
      <Translation>
        {t => (
          <div className="frc__login-form">
            <div className="frc__login-form--container">
              <div className="l-grid--center-s l-grid--left-m ">
                <h4>{t(`portalLogin|Sign in to MyDHL Freight`)}</h4>
              </div>
              <form onSubmit={e => this.onSubmit(e, t)} autoComplete="off" noValidate>
                <div className="l-grid--left-s">
                  <div className="margin-bottom-2">
                    <Input
                      context={context}
                      config={config}
                      CONST={CONST}
                      placeholder={config.emailPlaceholder}
                      label={t(`general|E-mail`) + ':'}
                      name="email"
                      isRequired={context.state.email.isRequired}
                      value={context.state.email.value}
                    />

                    {context.state.email.error ? (
                      <Error
                        name="email"
                        className="frc__input--error"
                        message={
                          t(`portalLogin|Please enter an email-address in the format`) + ' ' + config.emailPlaceholder
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="margin-bottom-1 frc__input--wrapper frc__generic--field-wrapper">
                    <PasswordInput
                      value={context.state.password.value}
                      label={t('general|labels|inputs|Password') + ':'}
                      required={context.state.password.isRequired}
                      onChange={newValue =>
                        context.updateField({
                          name: 'password',
                          value: newValue,
                          isRequired: context.state.password.isRequired,
                        })
                      }
                      testId="passwordInp"
                    />

                    {context.state.password.error ? (
                      <Error
                        name="password"
                        className="frc__input--error"
                        message={t(`portalLogin|Please enter your password`)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className="l-grid l-grid--w-100pc-s margin-bottom-1">
                  <div className=" frc__freightcalc--item-checkbox l-grid--w-50pc-s frc__freightcalc--quotation-form-checkbox-input">
                    <Checkbox
                      label={t(`portalLogin|Remember me`)}
                      name="rememberMe"
                      isRequired={false}
                      checked={context.state.rememberMe.value}
                      context={context}
                      config={config}
                      CONST={CONST}
                    />
                  </div>
                  <div className="l-grid l-grid--right-s l-grid--w-50pc-s frc__login-form-element--padding-top">
                    <a
                      id="forgotPassword"
                      href={this.props.linkTo(ROUTES.forgotPassword)}
                      className="link link-red link-internal has-icon"
                    >
                      {t(`portalLogin|Forgot password`)}
                    </a>
                  </div>
                </div>

                <button
                  id="signInBtn"
                  className={`l-grid--w-100pc-s base-button frc__login-form-button-top  ${
                    this.state.loading ? 'is-loading--right' : ''
                  } `}
                >
                  {t(`portalLogin|Sign in`)}
                </button>
                {this.state.loginError && (
                  <p
                    id="loginInvalidLoginMsg"
                    className="l-grid--center-s frc__login-form-message frc__login-form-message--error "
                  >
                    {t(`portalLogin|Wrong eMail or password`)}
                  </p>
                )}
                {this.state.login && (
                  <p
                    id="loginSuccessMsg"
                    className="l-grid--center-s frc__login-form-message frc__login-form-message--success "
                  >
                    {t(`portalLogin|Successfully logged`)}
                    {'!'}
                  </p>
                )}
              </form>

              <button
                id="createLogin"
                onClick={() => {
                  this.props.navigate(ROUTES.registration);
                }}
                className="l-grid--w-100pc-s base-button base-button--white frc__login-form-button-down "
              >
                {t(`portalLogin|Not registered`)}
                {'? '}
                {t(`portalLogin|Create login`)}
              </button>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default withRouter(Login);
