import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { debounce } from 'globals/utils/debounce';
import getBaseUrl from 'globals/utils/getUrlBase';
import React, { useEffect, useState } from 'react';
import { ManifestReceivedIcon, PalletIcon, ParcelIcon, TruckRoadFreightIcon } from '../../../assets/icons';
import { DashboardTile } from '../../../components/fcp-components/DashboardTile';
import { useGetUser } from '../../../hooks/api/useGetUser';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { useQuote } from '../../../quote/useQuote';
import { config } from '../../data-config';
import RecentShipments from '../RecentShipments/RecentShipments';

import { useTranslation } from 'react-i18next';
import './AppDashboard.css';

import { ChevronRight } from '@mui/icons-material';
import { ShipmentsTable } from '../../../components/fcp-components/shipment-table';
import { ROUTES, useRouting } from '../../../router';
import { TrackShipment } from 'components/fcp-components/dashboard/TrackShipment';
import { useGetBulkBookStatusQuery } from 'store/api/fcpBooking';
import { GlobalLoader } from 'components';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { BulkBookingCompleteBrief } from 'components/fcp-components/bulk-booking-popups/BulkBookingCompleteBrief';

export default function Dashboard(props) {
  const { linkTo, navigate } = useRouting();
  const { context, url } = props;
  const { enableQuote } = useQuote();

  const { t } = useTranslation();
  const { language } = useCountryLanguage();
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });

  const isSweden = userAccountCountryCode?.toLowerCase() === 'se';

  const updatePrintList = debounce(shipmentsListToPrint => {
    props.context.updateState({
      shipmentsPrintList: shipmentsListToPrint,
    });
  }, config.standardDebounceTimeout);

  const [openBulkBookingCompleteBrief, setOpenBulkBookingCompleteBrief] = useState(false);

  const [bulkBookingInProgress, setBulkBookingInProgress] = useState(false);

  const { data: bulkBookStatusResponse, isLoading: bulkBookingIsLoading } = useGetBulkBookStatusQuery(undefined, {
    pollingInterval: bulkBookingInProgress ? 1000 : 0,
  });

  useEffect(() => {
    const message = t('general|quickBookingInProgress');
    const splitMessage = message.split('{{link}}');
    const finalMessage = (
      <>
        {splitMessage[0]}
        <Link
          to={linkTo(ROUTES.onholdOrders)}
          onClick={() => {
            toast.dismiss();
          }}
        >
          {t('general|onHoldOrdersLink')}
        </Link>
        {splitMessage[1]}
      </>
    );

    if (bulkBookStatusResponse?.bulkBooking) {
      if (bulkBookStatusResponse.bulkBooking.result) {
        setOpenBulkBookingCompleteBrief(true);
        setBulkBookingInProgress(false);
        toast.dismiss();
      } else if (!bulkBookingInProgress) {
        setBulkBookingInProgress(true);
        toast(
          <Alert severity="info" variant="standard">
            {finalMessage}
          </Alert>,
          {
            autoClose: false,
          },
        );
      }
    } else {
      setBulkBookingInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkBookStatusResponse?.bulkBooking]);

  return (
    <>
      {bulkBookingIsLoading ? <GlobalLoader /> : null}
      <BulkBookingCompleteBrief open={openBulkBookingCompleteBrief} />

      <Box display="flex" flexDirection="column" pt={{ xs: 0, sm: 2 }} px={{ xs: 2, sm: 4 }}>
        <Typography variant="h1">{t('general|pageTitles|dashboard')}</Typography>
        <Grid container spacing={3} mb={8} columns={15}>
          {enableQuote ? (
            <DashboardTile
              linkTo="../quote-tool"
              title={t('mainMenu|Get a Quote')}
              subtitle={t('dashboard|See price and transit times')}
              Icon={ManifestReceivedIcon}
              data-testid="calculatePriceBtn"
              dense={isSweden}
            />
          ) : null}
          <DashboardTile
            linkTo="../portal-order"
            title={t('dashboard|Order Entry')}
            subtitle={t('general|Create Shipment and Print')}
            Icon={TruckRoadFreightIcon}
            data-testid="orderEntryBtn"
            dense={isSweden}
          />
          {isSweden && (
            <>
              <DashboardTile
                linkTo="../portal-pickup"
                title={t('mainMenu|Schedule a Pickup')}
                subtitle={t('dashboard|Submit a Pickup Request')}
                Icon={ParcelIcon}
                data-testid="startPickupBtn"
                dense={isSweden}
              />
              <DashboardTile
                linkTo="../edit-profile#palletAccounts"
                title={t('dashboard|Pallet Accounts')}
                subtitle={t('dashboard|View your Pallet Account transactions')}
                Icon={PalletIcon}
                data-testid="managePalletAccount"
                dense={isSweden}
              />
            </>
          )}
          <TrackShipment dense={isSweden} />
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Typography variant="h2" mb={0}>
            {t(`dashboard|Recent Saved Shipments`)} ({context.state.savedShipments?.length || 0})
          </Typography>
          <Button
            variant="text"
            onClick={() => navigate(ROUTES.savedShipments)}
            endIcon={<ChevronRight />}
            data-testid="viewAllShipmentsBtn2"
          >
            {t(`dashboard|View All Shipments`)}
          </Button>
        </Box>
        <ShipmentsTable
          shipments={context.state.savedShipments}
          loading={context.state.isLoading}
          error={context.state.apiError}
          tableProps={{
            hideFooter: true,
          }}
          context={context}
          summaryMinWidth={250}
        />
        {context.state.savedShipments?.length ? (
          <Box textAlign="center" my="16px">
            <Button
              variant="outlined"
              onClick={() => navigate(ROUTES.savedShipments)}
              data-testid="viewAllShipmentsBtn"
            >
              {t(`dashboard|View All Shipments`)}
            </Button>
          </Box>
        ) : null}
        <div className="l-grid l-grid--w-100pc-s">
          <div className="l-grid--w-100pc-s">
            <RecentShipments
              t={t}
              url={url}
              context={context}
              languageCode={language}
              baseUrl={getBaseUrl(ROUTES.portal)}
              onPrintCheckBoxSelected={updatePrintList}
            />
          </div>
        </div>
      </Box>
    </>
  );
}
