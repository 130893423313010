import { Radio } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCardDeliveryDate } from '../../../../../components/fcp-components/ProductCardDeliveryDate';
import { INTERNATIONAL_SHIPMENT_RANGE } from '../../../../../globals/constants';
import { getDescBulletPoints, getReadMoreLink } from '../../../../../globals/helpers/product';
import { CONST, config } from '../../../../data-config';
import { afterCheck, beforeCheck } from '../../../ServiceDetails/ServiceDetails';
import './SelectService.css';
import { useSelector } from 'react-redux';
import { productPickupDatesSelector, selectedPickupDateSelector } from '../../../../../store/portalOrderSlice';
import { useGetCountryConfig } from 'hooks/api/useGetCountryConfig';

export default function SelectService({ context, product, index }) {
  const { t, i18n } = useTranslation();
  const { data: countryConfig } = useGetCountryConfig();
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);
  const { productPickupDates } = useSelector(productPickupDatesSelector);

  const selectService = (params, countryConfig) => {
    const ctxParams = {};
    const stateObj = {
      additionalServices: [],
      dangerousGoodsLinkedIfNeeded: true,
      additionalServicesError: false,
      additionalServicesAPIError: false,
      selectedProduct: { value: '' },
      selectedServicePoint: {
        id: '',
        street: '',
        name: '',
        cityName: '',
        postalCode: '',
        countryCode: null,
        distance: 0,
        routeDistance: 0,
        featureCodes: [],
        lat: 0,
        lng: 0,
      },
    };

    // Update `customs` to decide whether to display the "Upload Customs Documents" dialog on the last page
    stateObj.customs =
      config.productsWithUploadCustomsDocumentsForAllCountries.indexOf(params.name) !== -1
        ? true
        : context.state.countryBasedCustoms;

    stateObj.selectedProduct = context.state.matchedProducts.find(chosenProduct => chosenProduct.code === params.value);
    stateObj.selectedProduct.error = params.value === '';
    stateObj.additionalServicesGeneralError = stateObj.selectedProduct.error;
    stateObj.preSelectedTemplateNotAvailable = '';

    if (stateObj.selectedProduct.code) {
      ctxParams.beforeUpdate = beforeCheck;
      // ctxParams.afterUpdate = afterCheck;
      ctxParams.afterUpdate = afterCheck(context, true, stateObj.selectedProduct, countryConfig);
    }

    // If current payerCode is not available for the selected product
    if (
      stateObj?.selectedProduct?.payerCodes &&
      context.state?.shipmentRange?.value === INTERNATIONAL_SHIPMENT_RANGE &&
      !stateObj.selectedProduct.payerCodes.includes(context.state?.payerCode)
    ) {
      stateObj.payerCode = stateObj?.selectedProduct?.payerCodes[0];
    }

    context.extendedSetState(stateObj, ctxParams);

    if (document.getElementById('frc_additional-services_delivery_monitoring')) {
      document.getElementById('frc_additional-services_delivery_monitoring').classList.remove('expand');
      document.getElementById('frc__special_case').classList.add('frc__special_case');
    }
    if (document.getElementById('fcp-product-container'))
      document.getElementById('fcp-product-container').classList.remove('has-pricelist-error');
  };

  return (
    <>
      <div className={`c-product-width  c-product ${index}`} id={index}>
        <div
          className={
            'l-grid--w-100pc-s  c-freightcalc--pricelist-box ' +
            (context?.state?.selectedProduct?.code === product?.code
              ? 'fcp__is-selected'
              : 'c-swe--freightcalc--result-wrapper')
          }
        >
          <div className="l-grid--center-s c-swe-freightcalc--pricelist-top">
            <span className="color-DHL-red">{t(`products|${product.code}|name`)}</span>
          </div>

          <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-delivery-date">
            <ProductCardDeliveryDate
              productCode={product.code}
              deliveryDate={product.deliveryDate}
              additionalServices={context.state?.additionalServices}
              selectedProductCode={context.state?.selectedProduct?.code}
              selectedPickupDate={selectedPickupDate}
              productPickupDates={productPickupDates}
            />
          </div>
          <div className="l-grid--left-s c-freight-pricelist-details">
            <div className="l-grid--center-s l-grid--w-100pc-s c-freightcalc--pricelist-seedetails">
              &nbsp;{t('general|See more details')}
            </div>
            <div className="c-freightcalc--pricelist-details-display list is-left c-freightcalc--list">
              <div className="c-fcp-product-description">{t(`products|${product.code}|description`)}</div>
              <ul>
                {getDescBulletPoints(product.code, i18n, t).map((bulletPoint, index) => (
                  <li key={index}>
                    <span> {bulletPoint} </span>
                  </li>
                ))}
                {getReadMoreLink(product.code, i18n, t) ? (
                  <li>
                    <span className="c-swe-freightcalc--productinfo-link">
                      <a
                        href={getReadMoreLink(product.code, i18n, t)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="c-swe-freightcalc-price-quote-error link color-DHL-red has-icon link-external"
                      >
                        {t('general|Read More')}...
                      </a>
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
          <div
            className={`l-grid--center-s c-freightcalc--pricelist-checkbox
           ${product.disabled ? 'c-freightcalc--pricelist-checkbox--disabled' : ''}`}
          >
            <Radio
              label={t('general|labels|inputs|Select')}
              id={product.name + 'Select'}
              isBooleanValueRequired
              name={product.name}
              disabled={product.disabled}
              className={'l-grid frc__radio--wrapper frc__service-radio'}
              isRequired={false}
              checked={context?.state?.selectedProduct?.code === product?.code}
              value={product.code}
              context={context}
              config={config}
              CONST={CONST}
              updateOnParent={params => selectService(params, countryConfig)}
              checkGroupIndex={1}
            />
          </div>
        </div>
      </div>
      <br />
    </>
  );
}
