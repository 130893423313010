import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';

export const postalCodeApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getCity: builder.query<GetCityResponse, GetCityParams>({
      query: ({ countryCode, postalCode, accountCountryCode }) => ({
        url: apiLinks.fetchCityName.replace('{countryCode}', countryCode).replace('{postalCode}', postalCode),
        headers: {
          'Country-Code': accountCountryCode?.toLowerCase(),
        },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetCityQuery, useLazyGetCityQuery } = postalCodeApi;

type GetCityParams = {
  countryCode: string;
  postalCode: string;
  accountCountryCode: string;
};

type GetCityResponse = {
  countryCode?: string;
  postalCode?: string;
  city?: string;
  lineHaul?: string;
  handlingOffice?: string;
  deviating?: string;
  updatedDate?: string;
  validated: boolean;
  terminalId?: string;
  bookable: boolean;
};
