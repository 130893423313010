import { Error } from 'components';
import { apiLinks } from 'config/api-config';
import { checkFieldAndReturnState, returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { formatDate } from 'globals/utils/formatting';
import getBaseUrl from 'globals/utils/getUrlBase';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import React from 'react';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  DOMESTIC_SHIPMENT_RANGE,
  HUNGARY_CODE,
  INTERNATIONAL_SHIPMENT_RANGE,
  ROMANIA_CODE,
} from '../../../globals/constants';
import { CONST, config } from '../../data-config';
import { addDeliveryDate, postTimeTable } from '../DeliveryOptions/DeliveryOptions-helpers';
import { internationalLTCProducts } from '../EnterPickupLocation/EnterPickupLocation-helpers';
import { calculateInputs } from './ShipmentDetailsRow/ShipmentDetailsRow-helpers';
import { Validation, shipmentDetailsTotalsValidations } from './Validations/ShipmentDetails-validation';
import { validateLoadingMeter } from '../../../globals/helpers/shipment';
import { prefilterShipmentProducts } from '../../../globals/helpers/product';
import { setShipmentDimensionsForProductShowAll } from '../../../globals/helpers/farmapi-validations';
import { caseInsensitiveEquals } from 'globals/utils/strings';

export const formPayloadForMatchedProducts = (statelessObj, props) => {
  statelessObj.data = formShipmentDetailsData(props);
  statelessObj.shipmentData = prepareUserData(statelessObj.data);
  statelessObj.payload = JSON.parse(JSON.stringify(statelessObj.shipmentData.shipmentDetails));

  statelessObj.data.totals.shipmentDetailsRows.numberOfFullPallets = {
    value: statelessObj.payload.numberOfFullPallets,
  };
  statelessObj.data.totals.shipmentDetailsRows.numberOfHalfPallets = {
    value: statelessObj.payload.numberOfHalfPallets,
  };
};

export const resetShipmentType = selectedProducts => {
  let shipmentType = {};

  if (selectedProducts && selectedProducts[0] === 'dhlPall') {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['full pallet']));
  } else {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['unspecified']));
  }

  return shipmentType;
};

const formMatchedProducts = (state, statelessObj, result) => {
  statelessObj.matchedProducts = getMatchedProducts(result.data, statelessObj.shipmentData.hideDHLPallForHalfPallet);

  if (statelessObj.matchedProducts.length === 0) {
    statelessObj.noContent = true;
  } else {
    statelessObj.matchedProducts = filterProductsBasedonPayerCode(statelessObj.matchedProducts, state.payerCode);
    statelessObj.matchedProducts = filterProductsBasedonNumberOfColli(
      state.shipmentDetailsRows,
      state.products,
      statelessObj.matchedProducts,
    );
    statelessObj.payerCodeError = statelessObj.matchedProducts.length === 0;
  }
};

const getDeliveryDates = async (isDomestic, state) => {
  let productDeliveryDt = null;
  // send pickup date from calender if presented
  const dateTime = state.pickupDate.value ? formatDate(state.pickupDate.value) : formatDate(new Date());

  if (isDomestic) {
    productDeliveryDt = await postTimeTable(state, dateTime, state);
  } else {
    productDeliveryDt = await internationalLTCProducts(state);
  }

  return productDeliveryDt;
};

const formDeliveryDateIfNoPayerCodeError = async (state, statelessObj) => {
  let { matchedProducts } = statelessObj;
  let ltcFailed = false;
  const isDomestic = state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE;
  const productDeliveryDt = await getDeliveryDates(isDomestic, state);

  if (productDeliveryDt && !productDeliveryDt.error) {
    matchedProducts = addDeliveryDate(
      matchedProducts,
      productDeliveryDt.data,
      isDomestic ? 'product' : 'product_code',
      productDeliveryDt.hasOwnProperty('status') && productDeliveryDt.status,
    );
  } else if (productDeliveryDt.error) {
    statelessObj.goNextError = isDomestic ? true : false;
    if (!isDomestic) {
      ltcFailed = true;

      // check and disable eurapid
      const eurapidIndex = matchedProducts.findIndex(product => product.code === '233');
      if (eurapidIndex !== -1) {
        matchedProducts[eurapidIndex].disabled = true;
      }
    }
  } else if (!isDomestic && Object.keys(productDeliveryDt).length === 0 && productDeliveryDt.constructor === Object) {
    matchedProducts = matchedProducts.map(prod => {
      prod.product_features = [];
      return prod;
    });
  }

  if (checkIfProductsNotAvailable(matchedProducts)) {
    statelessObj.noContent = true;
  }

  // Remove G Services if no time table
  statelessObj.priorityServiceDisabled = isDomestic && (productDeliveryDt.error || productDeliveryDt.status === false);

  statelessObj.matchedProducts = matchedProducts;
  statelessObj.ltcFailed = ltcFailed;

  return statelessObj;
};

export const postMatchedProductsCall = async (props, statelessObj) => {
  const params = {
    body: statelessObj.payload,
    headers: {
      Referer: getBaseUrl(null, 1),
    },
  };
  params.body.transactionId = props.context.state.transactionId;

  return await postMatchedProducts(params);
};

export const addDeliveryDateToMatchedProducts = async (state, result, statelessObj, _isMounted) => {
  if (result.status === CONST.STATUS_OK && _isMounted) {
    // make call to matched product api
    Object.assign(statelessObj, formMatchedProducts(state, statelessObj, result));

    if (!statelessObj.payerCodeError) {
      // post time table for domestic and LTC for international
      Object.assign(statelessObj, await formDeliveryDateIfNoPayerCodeError(state, statelessObj));
    }
  } else if (result.status === CONST.STATUS_NO_CONTENT) {
    statelessObj.noContent = true;
  } else {
    statelessObj.goNextError = true;
  }
};

export const checkSubmit = context => {
  let dimensions = context.state.dimensions;
  let shipmentDetailsRows = JSON.parse(JSON.stringify(context.state.shipmentDetailsRows));
  const isRelatedToHungary =
    context.state.pickupCountry.value.toLowerCase() === HUNGARY_CODE ||
    context.state.deliveryCountry.value.toLowerCase() === HUNGARY_CODE;
  let ekaerFreeError = isRelatedToHungary && context.state.ekaerFree.value === null;
  let ekaerFree = JSON.parse(JSON.stringify(context.state.ekaerFree));
  let ekaerNumber = JSON.parse(JSON.stringify(context.state.ekaerNumber));
  let ekaerNumberError = isRelatedToHungary && !context.state.ekaerFree.value && context.state.ekaerNumber.value === '';
  const { hasTotalError } = shipmentDetailsTotalsValidations(context);

  const isRelatedToRomania =
    caseInsensitiveEquals(context.state.pickupCountry.value, ROMANIA_CODE) ||
    caseInsensitiveEquals(context.state.deliveryCountry.value, ROMANIA_CODE);

  const uitFree = JSON.parse(JSON.stringify(context.state.uitFree));
  uitFree.error = config.etransportEnabled && isRelatedToRomania && uitFree.value === null;

  const uitNumber = JSON.parse(JSON.stringify(context.state.uitNumber));
  const uitNumberValidation = checkFieldAndReturnState({
    name: 'uitNumber',
    value: uitNumber.value,
    regEx: config.regEx.uitNumber,
    lengthCheck: [RegExp(`^.{0,${config.shipmentInputs.uitNumberLength}}$`)],
    isRequired: uitNumber.required,
  });
  uitNumber.error = config.etransportEnabled && isRelatedToRomania && uitNumberValidation.error;

  // UIT free and number
  context.extendedSetState({ uitFree, uitNumber });

  shipmentDetailsRows.forEach((shipmentRow, index) => {
    if (shipmentRow.quantity && !shipmentRow.quantity.hidden && !shipmentRow.quantity.disabled) {
      shipmentRow.quantity.error = Validation(
        shipmentRow.quantity,
        dimensions.min_item_quantity,
        dimensions.max_item_quantity,
        config.regEx.float,
      );
    }

    if (shipmentRow.weight && !shipmentRow.weight.hidden && !shipmentRow.weight.disabled) {
      shipmentRow.weight.error = Validation(
        shipmentRow.weight,
        dimensions.min_item_weight,
        dimensions.max_item_weight,
        config.regEx.float,
      );
    }

    if (shipmentRow.volume && !shipmentRow.volume.hidden && !shipmentRow.volume.disabled) {
      shipmentRow.volume.error = Validation(
        shipmentRow.volume,
        dimensions.min_item_volume,
        dimensions.max_item_volume,
        config.regEx.float,
      );
    }

    if (
      (shipmentRow.loadingMeter && !shipmentRow.loadingMeter.hidden && !shipmentRow.loadingMeter.disabled) ||
      shipmentRow.loadingMeter.required
    ) {
      const { error } = validateLoadingMeter({
        loadingMeter: shipmentRow.loadingMeter.value,
        dimensions,
        required: shipmentRow.loadingMeter.required,
        nonStackable: shipmentRow.nonStackable.value,
        width: shipmentRow.width.value,
        length: shipmentRow.length.value,
        height: shipmentRow.height.value,
      });
      shipmentRow.loadingMeter.error = error;
    }

    if (shipmentRow.length && !shipmentRow.length.hidden && !shipmentRow.length.disabled) {
      shipmentRow.length.error = Validation(
        shipmentRow.length,
        dimensions.min_item_length,
        dimensions.max_item_length,
        config.regEx.float,
      );
    }

    if (shipmentRow.width && !shipmentRow.width.hidden && !shipmentRow.width.disabled) {
      shipmentRow.width.error = Validation(
        shipmentRow.width,
        dimensions.min_item_width,
        dimensions.max_item_width,
        config.regEx.float,
      );
    }

    if (shipmentRow.height && !shipmentRow.height.hidden && !shipmentRow.height.disabled) {
      shipmentRow.height.error = Validation(
        shipmentRow.height,
        dimensions.min_item_height,
        dimensions.max_item_height,
        config.regEx.float,
      );
    }

    if (shipmentRow.nonStackable && !shipmentRow.nonStackable.hidden && !shipmentRow.nonStackable.disabled) {
      shipmentRow.nonStackable.error =
        shipmentRow.nonStackable.required && !shipmentRow.nonStackable.value ? true : false;
    }

    if (shipmentRow.longShipment && !shipmentRow.longShipment.hidden && !shipmentRow.longShipment.disabled) {
      shipmentRow.longShipment.error =
        shipmentRow.longShipment.required && !shipmentRow.longShipment.value ? true : false;
    }

    if (
      shipmentRow.goodsDescription &&
      !shipmentRow.goodsDescription.hidden &&
      !shipmentRow.goodsDescription.disabled
    ) {
      let descriptionError = checkFieldAndReturnState({
        name: 'goodsDescription',
        value: shipmentRow.goodsDescription.value,
        regEx: config.regEx.everything,
        lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxDescriptionLength}}$`)],
        isRequired: shipmentRow.goodsDescription.required,
      });
      shipmentRow.goodsDescription.error = descriptionError.error;
    }

    // Check validation only if Interational
    if (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      if (shipmentRow.shippingMark && !shipmentRow.shippingMark.hidden && !shipmentRow.shippingMark.disabled) {
        let shippingMarkValidation = checkFieldAndReturnState({
          name: 'shippingMark',
          value: shipmentRow.shippingMark.value,
          regEx: config.regEx.everything,
          lengthCheck: [RegExp(`^.{0,${config.maxShippingMarkLength}}$`)],
          isRequired: shipmentRow.shippingMark.required,
        });
        shipmentRow.shippingMark.error = shippingMarkValidation.error;
      }
    }
  });

  context.extendedSetState({ shipmentDetailsRows });

  // Ekaer free
  ekaerFree.error = context.state.ekaerFree.value === null;
  context.extendedSetState({ ekaerFree });

  // Ekaer Number
  if (context.state.ekaerFree && !context.state.ekaerFree.value) {
    ekaerNumber.error = ekaerNumberError;
    context.extendedSetState({ ekaerNumber });
  }
  return (
    checkError(shipmentDetailsRows) ||
    hasTotalError ||
    ekaerFreeError ||
    ekaerNumberError ||
    uitFree.error ||
    uitNumber.error
  );
};

const checkError = shipmentDetailsRows => {
  let error = false;
  shipmentDetailsRows.forEach(shipment => {
    let keys = Object.keys(shipment);
    keys.forEach(key => {
      if (shipment[key].error) error = true;
    });
  });
  return error;
};

export const prepareUserData = data => {
  let pieces = [];
  let numberOfFullPallets = 0;
  let numberOfHalfPallets = 0;
  let marketAvailability = '';
  let isHalfPalletStyke = false;
  let isHalfPalletAlone = true;

  data.shipmentDetailsRows.forEach(row => {
    const obj = {
      numberOfPieces: row.quantity?.value || null,
      weight: returnProperValueOrNull(row.weight.value),
      length: row.length.value || null,
      width: row.width.value || null,
      height: row.height.value || null,
      volume: returnProperValueOrNull(row.volume.value),
      loadingMeters: returnProperValueOrNull(row.loadingMeter.value),
      loadingMetres: returnProperValueOrNull(row.loadingMeter.value),
      stackable: !row.nonStackable.value,
      packageType: config.packageType[row.shipmentType.value] || null,
    };

    switch (row.shipmentType.value) {
      case 'full pallet':
        numberOfFullPallets += row.quantity.value * 1;
        isHalfPalletAlone = false;
        break;
      case 'half pallet':
        numberOfHalfPallets += row.quantity.value * 1;
        if (row.height.value && isHalfPalletAlone && !isHalfPalletStyke) {
          isHalfPalletStyke = parseInt(row.height.value) > 150;
        }
        break;
      default:
        isHalfPalletAlone = false;
    }

    pieces.push(obj);
  });

  marketAvailability =
    (data.pickupAddressResidential.value ? 'C' : 'B') + '2' + (data.deliveryAddressResidential.value ? 'C' : 'B');

  const requestData = {
    parties: [
      {
        type: CONSIGNOR_CUSTOMER_TYPE,
        address: {
          postalCode: data.pickupPostalCode.value || null,
          countryCode: data.pickupCountry.value || null,
        },
      },
      {
        type: CONSIGNEE_CUSTOMER_TYPE,
        address: {
          postalCode: data.deliveryPostalCode.value || null,
          countryCode: data.deliveryCountry.value || null,
        },
      },
    ],
    pieces: pieces,
    totalNumberOfPieces: data.totals.shipmentDetailsRows.quantity.value || null,
    totalWeight: data.totals.shipmentDetailsRows.weight.value || null,
    totalVolume: data.totals.shipmentDetailsRows.volume.value || null,
    totalLoadingMetres: data.totals.shipmentDetailsRows.loadingMeter.value || null,
    numberOfFullPallets: numberOfFullPallets || null,
    numberOfHalfPallets: numberOfHalfPallets || null,
    marketAvailability: marketAvailability,
  };

  return { shipmentDetails: requestData, hideDHLPallForHalfPallet: isHalfPalletAlone && isHalfPalletStyke };
};

export const filterProductsBasedonNumberOfColli = (shipmentDetailsRows, availableProducts, products) => {
  return (
    products &&
    products.filter(prod =>
      availableProducts.find(
        avProd => avProd.code === prod.code && shipmentDetailsRows.length <= avProd.shipmentRowsNumberMax,
      ),
    )
  );
};

export const formShipmentDetailsData = props => {
  const data = {};
  const routeState = getPropperAPIData(props.context.state);

  data.shipmentDetailsRows = props.context.state.shipmentDetailsRows;
  data.totals = props.context.state.totals;
  data.pickupCountry = routeState.pickupCountry;
  data.pickupPostalCode = routeState.pickupPostalCode;
  data.pickupAddressResidential = routeState.pickupAddressResidential;
  data.deliveryCountry = routeState.deliveryCountry;
  data.deliveryPostalCode = routeState.deliveryPostalCode;
  data.deliveryAddressResidential = routeState.deliveryAddressResidential;

  return JSON.parse(JSON.stringify(data));
};

export const removeShipmentDetailsRow = (index, context) => {
  const params = {};
  if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length > 1) {
    params.groupName = 'shipmentDetailsRows';
    params.index = index;
    params.afterUpdate = calculateInputs;
    context.removeGroupField(params);
  }
};

export const updateDimensions = props => {
  const context = props.context;
  const configDimensions = JSON.parse(JSON.stringify(config.dimensions)); //structure def

  const prefilteredProducts = prefilterShipmentProducts({
    productList: context.state.products,
    shipmentData: {
      deliveryResidential: context.state.deliverToDifferentAddress.value
        ? context.state.differentDeliveryAddressResidential.value
        : context.state.deliveryAddressResidential.value,
      pickupResidential: context.state.pickupFromDifferentAddress.value
        ? context.state.differentPickupAddressResidential.value
        : context.state.pickupAddressResidential.value,
      shipmentRange: context.state.shipmentRange.value,
    },
  });

  const [productsDimensions, shipmentRowsNumberMax] = setShipmentDimensionsForProductShowAll(
    context.state,
    config,
    prefilteredProducts,
  );

  return {
    dimensions: { ...configDimensions, ...productsDimensions },
    shipmentRowsNumberMax,
  };
};

export const addShipmentDetailsRow = context => {
  const params = {};
  if (
    context.state.shipmentDetailsRows &&
    context.state.shipmentDetailsRows.length < config.maxAllowedShipmentDetailsRows
  ) {
    params.groupName = 'shipmentDetailsRows';
    params.item = resetShipmentType(context.state.selectedProducts);
    params.afterUpdate = calculateInputs;

    context.addAnotherGroupField(params);
  }
};

export const filterProductsBasedonPayerCode = (products, payerCode) => {
  return products && products.filter(prod => prod.payerCodes.find(code => code === payerCode));
};

export const checkIfProductsNotAvailable = matchedProducts => {
  let notAvailable = false;

  matchedProducts &&
    matchedProducts.forEach(product => {
      if (config.serviceDescriptions[product.code] === undefined && !notAvailable) notAvailable = true;
    });

  return notAvailable;
};

const filterPayerCode = payerCodes => {
  let payerCodeArr = [];
  payerCodes.forEach(PC => {
    payerCodeArr.push(PC.code);
  });

  return payerCodeArr;
};

export const getErrorMessage = (t, state) => {
  const caseNoProductError = state.noContent ? 'noProductError' : false;
  const caseNextError = state.goNextError ? 'goNextError' : false;
  const caseGeneralError = state.generalError ? 'generalError' : false;
  const casePayerCodeError = state.payerCodeError ? 'payerCodeError' : false;
  const caseSaveForFutureError = state.saveShipmentsError || state.saveForFutureError ? 'saveForFutureError' : false;

  const error = caseNoProductError || caseNextError || caseGeneralError || casePayerCodeError || caseSaveForFutureError;
  let message = '';

  switch (error) {
    case 'noProductError':
      message =
        t('general|errors|No product matches were found') +
        ' <br> ' +
        t('general|errors|Please change criteria') +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'goNextError':
      message =
        t('general|errors|General error') +
        '. <br> ' +
        t('general|Try again or call technical support.') +
        ' ' +
        t('general|or') +
        ' ' +
        <ChatWithUs /> +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'generalError':
      message = t('general|errors|Please fill properly all necessary fields');
      break;
    case 'payerCodeError':
      message =
        t('general|errors|No product matches were found for selected payer code') +
        ' <br> ' +
        t('general|errors|Please change criteria') +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'saveForFutureError':
      message = t(`publicOrder|Shipment could not be saved`);
      break;
    default:
      return;
  }

  return <Error name="multicaseErrorMessage" message={message} />;
};

export const getMatchedProducts = (products, hideDHLPallForHalfPallet) => {
  const matchedProducts = [];

  if (products && products.length > 0) {
    products.forEach(prod => {
      let product;
      product = config.allowedProducts.find(p => p.code === prod.product.code);

      if (product && product.code) {
        if (!hideDHLPallForHalfPallet || (hideDHLPallForHalfPallet && product.code !== '210')) {
          matchedProducts.push({
            code: product.code,
            value: false,
            name: product.mapName,
            payerCodes: filterPayerCode(prod.product.payerCodes),
            isDomestic: prod.product.isDomestic,
            product: product.product,
            product_code: (prod.product.isDomestic ? product.product : product.alias) || null,
          });
        }
      }
    });
  }
  return matchedProducts;
};

export const postMatchedProducts = async params => {
  let resultMap = {};

  await apiRequest(apiLinks.postPublicOrderProductMatches, 'POST', params)
    .then(result => {
      if (!result.data || (result.data && result.data.length === 0)) {
        result.status = CONST.STATUS_NO_CONTENT;
      }
      resultMap = result;
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};
