import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Error } from 'components';
import { CONST } from '../../data-config';
import TemplateName from './TemplateName/TemplateName';
import Route from './Route/Route';
import EnterShipmentDetails from './EnterShipmentDetails/EnterShipmentDetails';
import DeliveryOptions from './DeliveryOptions/DeliveryOptions';
import SchedulePickup from './SchedulePickup/SchedulePickup';
import { performActionEvent } from './Template-helpers';
import { setTemplate } from '../TemplateList/TemplateList-helpers';
import { fetchTemplateOnSearchOrPageChangeOrReset } from '../CommonUtils';
import { rootApi } from 'store/api/apiSlice';
import { connect } from 'react-redux';

import './Template.css';

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      validationError: false,
      apiError: false,
      deleteDialog: false,
      atLeastOneFieldFilled: true,
    };
  }

  componentDidMount = async () => {
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    // id - edit -> form and set data for template edit
    if (this.props.context.state.id) {
      this.setState({ loader: true }, async () => {
        // make necessary BE calls e.g. vas
        await setTemplate(this.props.context, this.props.t, this.props.countryConfig);
        this.setState({ loader: false });
      });
    } else {
      let stateObj = JSON.parse(JSON.stringify(this.props.context.state));
      const newState = {
        pickupCountry: {
          ...stateObj.pickupCountry,
          value: this.props.context.state?.userData?.accountCountryCode.toUpperCase(),
        },
        differentPickupCountry: {
          ...stateObj.differentPickupCountry,
          value: this.props.context.state?.userData?.accountCountryCode.toUpperCase(),
        },
        deliveryCountry: {
          ...stateObj.deliveryCountry,
          value: this.props.context.state?.userData?.accountCountryCode.toUpperCase(),
        },
        differentDeliveryCountry: {
          ...stateObj.differentDeliveryCountry,
          value: this.props.context.state?.userData?.accountCountryCode.toUpperCase(),
        },
      };
      this.props.context.updateState(newState);
    }
  };

  onActionClick = async (action, t) => {
    this.doSaveDeleteAction(action, t);
  };

  doSaveDeleteAction = async (action, t) => {
    this.setState(
      {
        deleteDialog: false,
        loader: true,
      },
      async () => {
        const { validationError, apiError, atLeastOneFieldFilled, message } = await performActionEvent(
          action,
          this.props.context,
        );

        if (!apiError && !validationError && atLeastOneFieldFilled) {
          this.props.onShowSuccessToaster(message);
          await fetchTemplateOnSearchOrPageChangeOrReset(this.props.context);
          this.props.onToggleTemplate();
          window.location.reload(); // TODO: jm - Rewrite the whole Templates List and Template Detail with react router + remove context
        } else {
          this.setState({
            apiError,
            validationError,
            loader: false,
            atLeastOneFieldFilled,
          });
        }
      },
    );
  };

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteDialog: !prevState.deleteDialog,
    }));
  };

  render() {
    const { context } = this.props;
    const isNewTemplate = !context.state.id ? true : false;
    const disableAction = context.state.loaders.products.value;

    return (
      <div className="l-grid l-grid--between-s frc__push-bottom  frc__generic--field-wrapper ">
        <Translation>
          {t => (
            <>
              <div className="l-grid--w-100pc-s  margin-bottom-1">
                <h4 className="margin-bottom-1">
                  {t(`portalTemplates|${isNewTemplate ? 'Add Template' : 'Edit Template'}`)}
                </h4>
              </div>
              <div className="frc__generic-row--wrapper">
                <p className="color-dhl-red">* {t('general|Mandatory field')}</p>
              </div>

              {/* Template Name */}
              <TemplateName context={context} />

              {/* Route */}
              <Route context={context} />

              {/* Shipment Details */}
              <EnterShipmentDetails context={context} />

              {/* Delivery Option */}
              <DeliveryOptions context={context} />

              {/* Schedule Pickup */}
              <SchedulePickup context={context} />

              {/**Action button*/}
              <div className="l-grid l-grid--w-100pc-s f">
                <div className=" l-grid--w-100pc-s l-grid--w-20pc-m-s l-grid--w-20pc-m-m"></div>
                <div className="l-grid l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-80pc-m-m  ">
                  <div className="frc__edit-action-btn-left">
                    <button
                      className={`base-button l-grid--w-20pc-s  ${disableAction ? ' disabled' : ''}`}
                      id="cancelTemplateBtn"
                      onClick={disableAction ? null : this.props.onToggleTemplate}
                    >
                      <span>{t('general|labels|buttons|Cancel')}</span>
                    </button>
                    <div className="frc__button-space-divider" />

                    {!isNewTemplate && (
                      <>
                        <button
                          className={`base-button l-grid--w-20pc-s  ${disableAction ? ' disabled' : ''}`}
                          onClick={disableAction ? null : this.toggleDeleteModal}
                          id="deleteTemplateBtn"
                        >
                          <span>{t('general|labels|buttons|Delete')}</span>
                        </button>
                        <div className="frc__button-space-divider" />
                      </>
                    )}

                    <button
                      className={`base-button l-grid--w-20pc-s  ${disableAction ? ' disabled' : ''}`}
                      id="saveTemplateBtn"
                      onClick={disableAction ? null : () => this.onActionClick(CONST.SAVE, t)}
                    >
                      <span>{t('general|labels|buttons|Save')}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="l-grid--right-s l-grid--w-100pc-s frc__generic-row--wrapper margin-bottom-1">
                {!this.state.atLeastOneFieldFilled ||
                this.state.apiError ||
                context.state.postalCodeState.apiError ||
                this.state.validationError ||
                context.state.postalCodeState.submitError ? (
                  <Error
                    name="TemplateActionResultError"
                    className="frc__input--error"
                    message={
                      this.state.validationError || context.state.postalCodeState.submitError
                        ? t('general|errors|Please fill properly all necessary fields')
                        : !this.state.atLeastOneFieldFilled
                        ? t(`portalTemplates|Empty template can not be saved`)
                        : t('general|errors|General error') +
                          '. <br> ' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}" 
                                            class ="frc__chat-with-us"
                                            target="_blank" rel="noopener noreferrer">${t(
                                              `general|Chat with us`,
                                            )}</a></strong>` +
                          ' <br> ' +
                          t('general|ID') +
                          ': ' +
                          context.state.transactionId
                    }
                  />
                ) : (
                  ''
                )}
              </div>

              {/**Delete dialog*/}
              {this.state.deleteDialog && (
                <div className="frc__dialog">
                  <div className="frc__dialog-overlay" onClick={this.toggleDeleteModal}></div>
                  <div className="frc__dialog-width frc__delete-dialog-wrapper">
                    <div className="l-grid l-grid--center-s l-grid--middle-s frc__dialog-holder">
                      <div className="frc__dialog-container">
                        <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
                          <button className="frc__button--delete--darker" onClick={this.toggleDeleteModal}>
                            X
                          </button>
                        </div>

                        <h5 className="frc__modal-center">{t(`general|Are you sure you want to delete`)}</h5>
                        <div className="l-grid l-grid--center-s">
                          <button
                            className={'base-button frc_btn-divider l-grid--w-20pc-s frc__btn-margin-right '}
                            onClick={() => this.onActionClick(CONST.DELETE, t)}
                            id="deleteTemplateBtn"
                          >
                            <span>{t('general|labels|buttons|Delete')}</span>
                          </button>

                          <button
                            className={'base-button  l-grid--w-20pc-s '}
                            id="cancelTemplateBtn"
                            onClick={this.toggleDeleteModal}
                          >
                            <span>{t('general|labels|buttons|Cancel')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={`frc__generic--loader-overlay ${this.state.loader ? ' is-loading ' : ''}`} />
            </>
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const userData = rootApi.endpoints.getUserData.select()(state).data;
  return {
    countryConfig: rootApi.endpoints.getCountryConfig.select({ countryCode: userData?.user?.accountCountryCode })(state)
      .data,
  };
};

export default connect(mapStateToProps, null)(Template);
