import { Box } from '@mui/material';
import { Checkbox, Error, Input, Select } from 'components';
import { getInputsToGrayOut } from 'globals/utils/formInterface';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import { ShipmentRowControls } from '../../../../components/fcp-components/ShipmentRowControls';
import { cloneShipmentRow, getColliTypes } from '../../../../globals/helpers/shipment';
import { config, CONST } from '../../../data-config';
import {
  calculateInputs,
  calculateVolume,
  checkIfSelectedProductIsHomeOrStycke,
  dhlPallEURHalfPalletDimensions,
  resetLWHLoadingMeterAndCalculateInputs,
  resetShipmentDetailsInputOnTypeChange,
} from './ShipmentDetailsRow-helpers';
import './ShipmentDetailsRow.css';

export default class ShipmentDetailsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defocused: {
        lengthWidthHeight: false,
        volume: false,
        loadingMeter: false,
      },
    };
  }

  update = (params, context) => {
    const stateToSet = getInputsToGrayOut(params.name);

    // Only update state if stateToSet is not empty
    if (Object.keys(stateToSet).length !== 0 && stateToSet.constructor === Object)
      this.setState({ defocused: stateToSet });

    params.groupName = 'shipmentDetailsRows';
    context.updateGroupOfFields(params);
  };

  render() {
    const shipmentType = this.props.shipmentRow.shipmentType.value;
    const dimensions = this.props.context.state.dimensions;
    const userAccountCountry = localStorage.getItem('country')?.toLowerCase();
    const { maxWeight, maxHeight } = dhlPallEURHalfPalletDimensions(this.props.context.state, this.props.shipmentRow);

    const lengthMax =
      (this.props.shipmentRow.shipmentType.value === 'pallet' ||
        this.props.shipmentRow.shipmentType.value === 'unspecified') &&
      this.props.shipmentRow.weight.value >= 50;
    const lengthMin =
      this.props.shipmentRow.longShipment.value &&
      (this.props.shipmentRow.shipmentType.value === 'pallet' ||
        this.props.shipmentRow.shipmentType.value === 'unspecified');
    const isSelectedProductHomeorStycke = checkIfSelectedProductIsHomeOrStycke(this.props.context.state);

    let allowedShipmentTypes =
      this.props.context.state.allowedShipmentTypes.length > 0
        ? this.props.context.state.allowedShipmentTypes
        : config.shipmentTypes;

    // set unspecified if more products (not available for global)
    if (this.props.isMorethanTwoProductsSelectedOrMultipleReceiver && userAccountCountry === 'se') {
      allowedShipmentTypes = config.shipmentTypes.filter(type => type.dataRel === 'CLL');
    } else {
      allowedShipmentTypes = getColliTypes({
        colliTypes: allowedShipmentTypes,
        countryCode: userAccountCountry,
      });
    }

    return (
      <div>
        <Translation>
          {t => (
            <Box display="flex">
              <div className="l-grid frc__shipment-details-row l-grid--w-auto-s">
                {!this.props.shipmentRow.quantity.hidden ? (
                  <div className="frc__quantity--width-col-fix frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-10pc-m ">
                    {config.shipmentTypesFields[shipmentType]['quantity'] ? (
                      <Input
                        id={'quantity' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        regEx={config.regEx.quantity}
                        label={t('general|labels|metrics|quantity')}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        index={this.props.index}
                        name="quantity"
                        isRequired={config.shipmentTypesFields[shipmentType]['quantity'].required || false}
                        disabled={config.shipmentTypesFields[shipmentType]['quantity'].disabled || false}
                        min={dimensions.min_item_quantity}
                        max={dimensions.max_item_quantity}
                        value={this.props.shipmentRow.quantity.value}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateInputs}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['quantity'] && this.props.shipmentRow.quantity.error ? (
                      <Error
                        name={'quantity' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|quantity'),
                          min: dimensions.min_item_quantity,
                          max: dimensions.max_item_quantity,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.shipmentType.hidden ? (
                  <div className="frc__shipment-type--width-col-fix frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-15pc-m-m  ">
                    <Select
                      id={'shipmentType' + this.props.index}
                      context={this.props.context}
                      config={config}
                      CONST={{ API_DOMAIN: 'general|labels|shipmentType' }}
                      className=" frc__select--wrapper-flex frc__generic--field-wrapper--no-top "
                      label={t('general|labels|inputs|Shipment Type')}
                      name="shipmentType"
                      isRequired={true}
                      disabled={this.props.isMorethanTwoProductsSelectedOrMultipleReceiver}
                      index={this.props.index}
                      values={allowedShipmentTypes}
                      value={this.props.shipmentRow.shipmentType.value}
                      updateOnParent={(params, context) =>
                        params.value !== this.props.shipmentRow.shipmentType.value ? this.update(params, context) : null
                      }
                      beforeUpdate={resetShipmentDetailsInputOnTypeChange}
                      afterUpdate={calculateInputs}
                    />
                    {this.props.shipmentRow.shipmentType.error ? (
                      <Error
                        name={'shipmentType' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidShipmentType')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.weight.hidden ? (
                  <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['weight'] ? (
                      <Input
                        id={'weight' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.weight}
                        label={t('general|labels|metrics|Weight')}
                        labelAfter={'<small>(' + t('general|kg') + ')</small>'}
                        index={this.props.index}
                        name="weight"
                        isRequired={config.shipmentTypesFields[shipmentType]['weight'].required || false}
                        disabled={config.shipmentTypesFields[shipmentType]['weight'].disabled || false}
                        min={dimensions.min_item_weight}
                        max={maxWeight}
                        emptyDependentField={[{ param: 'longShipment', criteriaValue: 50 }]}
                        value={this.props.shipmentRow.weight.value}
                        integerCutoff={config.shipmentInputs.weight.maxIntegers}
                        decimalCutoff={config.shipmentInputs.weight.maxDecimals}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateInputs}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['weight'] && this.props.shipmentRow.weight.error ? (
                      <Error
                        name={'weight' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Weight'),
                          min: dimensions.min_item_weight,
                          max: maxWeight,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.palletPlace.hidden ? (
                  <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['palletPlace'] ? (
                      <Input
                        id={'palletPlace' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.palletPlace}
                        label={t('general|labels|metrics|Pallet Place')}
                        index={this.props.index}
                        name="palletPlace"
                        isRequired={config.shipmentTypesFields[shipmentType]['palletPlace'].required || false}
                        disabled={
                          config.shipmentTypesFields[shipmentType]['palletPlace'].disabled ||
                          this.props.shipmentRow.loadingMeter.value ||
                          false
                        }
                        min={dimensions.min_item_palletPlace}
                        max={dimensions.max_item_palletPlace}
                        lengthCheck={[RegExp(`^.{0,${config.maxPalletPlaceDigits}}$`)]}
                        integerCutoff={config.shipmentInputs.palletPlace.maxIntegers}
                        decimalCutoff={config.shipmentInputs.palletPlace.maxDecimals}
                        value={this.props.shipmentRow.palletPlace.value}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateInputs}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['palletPlace'] &&
                    this.props.shipmentRow.palletPlace.error ? (
                      <Error
                        name={'palletPlace' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Pallet Place'),
                          min: dimensions.min_item_palletPlace,
                          max: dimensions.max_item_palletPlace,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.length.hidden &&
                !this.props.isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                  <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['length'] ? (
                      <Input
                        id={'length' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.length}
                        label={t('general|labels|metrics|Length')}
                        labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                        index={this.props.index}
                        name="length"
                        isRequired={
                          config.shipmentTypesFields[shipmentType]['length'].required ||
                          ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                            this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                            this.props.shipmentRow.longShipment.value) ||
                          false
                        }
                        disabled={config.shipmentTypesFields[shipmentType]['length'].disabled || false}
                        grayedOut={this.state.defocused.lengthWidthHeight}
                        value={
                          this.props.shipmentRow.length.value ||
                          config.shipmentTypesFields[shipmentType]['length'].value ||
                          ''
                        }
                        min={
                          isSelectedProductHomeorStycke && lengthMin
                            ? config.dimensions.length_with_weight_less_than_50
                            : dimensions.min_item_length
                        }
                        max={
                          isSelectedProductHomeorStycke && lengthMax
                            ? config.dimensions.length_with_weight_less_than_50
                            : dimensions.max_item_length
                        }
                        lengthCheck={[RegExp(`^.{0,${config.maxLengthDigits}}$`)]}
                        cutTextLimit={config.maxLengthDigits}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateVolume}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['length'] && this.props.shipmentRow.length.error ? (
                      <Error
                        name={'length' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Length'),
                          min:
                            isSelectedProductHomeorStycke && lengthMin
                              ? config.dimensions.length_with_weight_less_than_50
                              : dimensions.min_item_length,
                          max:
                            isSelectedProductHomeorStycke && lengthMax
                              ? config.dimensions.length_with_weight_less_than_50
                              : dimensions.max_item_length,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.width.hidden && !this.props.isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                  <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['width'] ? (
                      <Input
                        id={'width' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.width}
                        label={t('general|labels|metrics|Width')}
                        labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                        index={this.props.index}
                        name="width"
                        isRequired={
                          config.shipmentTypesFields[shipmentType]['width'].required ||
                          ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                            this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                            this.props.shipmentRow.longShipment.value) ||
                          false
                        }
                        disabled={config.shipmentTypesFields[shipmentType]['width'].disabled || false}
                        grayedOut={this.state.defocused.lengthWidthHeight}
                        min={dimensions.min_item_width}
                        max={dimensions.max_item_width}
                        value={
                          this.props.shipmentRow.width.value ||
                          config.shipmentTypesFields[shipmentType]['width'].value ||
                          ''
                        }
                        lengthCheck={[RegExp(`^.{0,${config.maxWidthDigits}}$`)]}
                        cutTextLimit={config.maxWidthDigits}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateVolume}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['width'] && this.props.shipmentRow.width.error ? (
                      <Error
                        name={'width' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Width'),
                          min: dimensions.min_item_width,
                          max: dimensions.max_item_width,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.height.hidden &&
                !this.props.isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                  <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['height'] ? (
                      <Input
                        id={'height' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.height}
                        label={t('general|labels|metrics|Height')}
                        labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                        index={this.props.index}
                        name="height"
                        isRequired={
                          config.shipmentTypesFields[shipmentType]['height'].required ||
                          ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                            this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                            this.props.shipmentRow.longShipment.value) ||
                          false
                        }
                        disabled={config.shipmentTypesFields[shipmentType]['height'].disabled || false}
                        grayedOut={this.state.defocused.lengthWidthHeight}
                        min={dimensions.min_item_height}
                        max={maxHeight}
                        lengthCheck={[RegExp(`^.{0,${config.maxHeightDigits}}$`)]}
                        cutTextLimit={config.maxHeightDigits}
                        value={this.props.shipmentRow.height.value}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateVolume}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['height'] && this.props.shipmentRow.height.error ? (
                      <Error
                        name={'height' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Height'),
                          min: dimensions.min_item_height,
                          max: maxHeight,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.volume.hidden ? (
                  <div
                    className={`"frc__column-wrapper ${
                      this.props.shipmentRow.shipmentType.value === 'parcel' ? '' : 'frc__column-wrapper--short'
                    } l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m "`}
                  >
                    {config.shipmentTypesFields[shipmentType]['volume'] ? (
                      <Input
                        id={'volume' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.volume}
                        label={t('general|labels|metrics|Volume')}
                        labelAfter={'<small>(' + t('general|m') + '<sup>3</sup>)</small>'}
                        index={this.props.index}
                        name="volume"
                        isRequired={config.shipmentTypesFields[shipmentType]['volume'].required || false}
                        disabled={config.shipmentTypesFields[shipmentType]['volume'].disabled || false}
                        grayedOut={this.state.defocused.volume}
                        min={dimensions.min_item_volume}
                        max={dimensions.max_item_volume}
                        value={this.props.shipmentRow.volume.value}
                        integerCutoff={config.shipmentInputs.volume.maxIntegers}
                        decimalCutoff={config.shipmentInputs.volume.maxDecimals}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={resetLWHLoadingMeterAndCalculateInputs}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['volume'] && this.props.shipmentRow.volume.error ? (
                      <Error
                        name={'volume' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Volume'),
                          min: dimensions.min_item_volume,
                          max: dimensions.max_item_volume,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.loadingMeter.hidden ? (
                  <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                    {config.shipmentTypesFields[shipmentType]['loadingMeter'] ? (
                      <Input
                        id={'loadingMeter' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                        className=" frc__input--wrapper-flex frc__input--wrapper-flex--no-direction frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.loadingMeter}
                        label={t('general|LDMLoading')}
                        labelAfter={
                          '&nbsp;<span class="has-tooltip"> ' +
                          t('general|LDMMeter') +
                          '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                          t('general|loadingMeterCalculation') +
                          '</div></div>' +
                          '</span>'
                        }
                        index={this.props.index}
                        name="loadingMeter"
                        isRequired={config.shipmentTypesFields[shipmentType]['loadingMeter'].required || false}
                        disabled={
                          this.props.shipmentRow.loadingMeter.disabled ||
                          config.shipmentTypesFields[shipmentType]['loadingMeter'].disabled ||
                          this.props.shipmentRow.palletPlace.value ||
                          false
                        }
                        grayedOut={this.state.defocused.loadingMeter}
                        min={dimensions.min_item_loading_meter}
                        max={dimensions.max_item_loading_meter}
                        value={this.props.shipmentRow.loadingMeter.value}
                        integerCutoff={config.shipmentInputs.loadingMeter.maxIntegers}
                        decimalCutoff={config.shipmentInputs.loadingMeter.maxDecimals}
                        updateOnParent={(params, context) => this.update(params, context)}
                        afterUpdate={calculateInputs}
                      />
                    ) : (
                      ''
                    )}

                    {config.shipmentTypesFields[shipmentType]['loadingMeter'] &&
                    this.props.shipmentRow.loadingMeter.error ? (
                      <Error
                        name={'loadingMeter' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|invalidValue', {
                          name: t('general|labels|metrics|Loading Meter'),
                          min: dimensions.min_item_loading_meter,
                          max: dimensions.max_item_loading_meter,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.nonStackable.hidden &&
                !this.props.isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                  <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m">
                    {config.shipmentTypesFields[shipmentType]['nonStackable'] ? (
                      <Checkbox
                        id={'nonStackable' + this.props.index}
                        context={this.props.context}
                        config={this.props.config}
                        CONST={this.props.CONST}
                        className={' frc__checkbox--vertical '}
                        label={t('general|labels|inputs|Non Stackable')}
                        index={this.props.index}
                        name="nonStackable"
                        isRequired={config.shipmentTypesFields[shipmentType]['nonStackable'].required || false}
                        disabled={
                          this.props.shipmentRow.nonStackable.disabled ||
                          config.shipmentTypesFields[shipmentType]['nonStackable'].disabled ||
                          false
                        }
                        checked={this.props.shipmentRow.nonStackable.value}
                        updateOnParent={(params, context) => this.update(params, context)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}

                {!this.props.shipmentRow.longShipment.hidden &&
                !this.props.isMorethanTwoProductsSelectedOrMultipleReceiver ? (
                  isSelectedProductHomeorStycke && lengthMax ? (
                    ''
                  ) : (
                    <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m">
                      {config.shipmentTypesFields[shipmentType]['longShipment'] ? (
                        <Checkbox
                          labelAfter={
                            '&nbsp;<span class="has-tooltip has-tooltip-mobile-fix"> ' +
                            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                            t('general|Long Shipment tooltip') +
                            '</div></div>' +
                            '</span>'
                          }
                          id={'longShipment' + this.props.index}
                          context={this.props.context}
                          config={this.props.config}
                          CONST={this.props.CONST}
                          className={' frc__checkbox--vertical  '}
                          label={t('general|labels|inputs|Long Shipment')}
                          index={this.props.index}
                          name="longShipment"
                          isRequired={config.shipmentTypesFields[shipmentType]['longShipment'].required || false}
                          disabled={
                            this.props.shipmentRow.longShipment.disabled ||
                            config.shipmentTypesFields[shipmentType]['longShipment'].disabled ||
                            false
                          }
                          checked={this.props.shipmentRow.longShipment.value}
                          updateOnParent={(params, context) => this.update(params, context)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
              <ShipmentRowControls
                showDeleteButton={this.props.context.state.shipmentDetailsRows.length > 1}
                onDelete={() => this.props.removeOnParent(this.props.index)}
                showCloneButton={
                  this.props.context.state.shipmentDetailsRows &&
                  this.props.context.state.shipmentDetailsRows.length < this.props.context.state.shipmentRowsNumberMax
                }
                onClone={() =>
                  cloneShipmentRow({
                    rowIndex: this.props.index,
                    context: this.props.context,
                    maxRows: this.props.context.state.shipmentRowsNumberMax,
                    calculateInputs,
                  })
                }
              />
            </Box>
          )}
        </Translation>
      </div>
    );
  }
}
