import * as React from 'react';

import { useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import ConfigService from '../../globals/utils/configService';
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';

export const SHOW_PLANNED_MAINTENANCE_KEY = 'SHOW_PLANNED_MAINTENANCE';

export const PlannedMaintenance = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    sessionStorage.setItem(SHOW_PLANNED_MAINTENANCE_KEY, false);
    setOpen(false);
  };

  const fetchData = async () => {
    setTimeout(async () => {
      try {
        const response = await ConfigService.getScheduledMaintenance();
        const sessionValue = sessionStorage.getItem(SHOW_PLANNED_MAINTENANCE_KEY);
        const result = response?.toLowerCase() === 'true' && sessionValue?.toLowerCase() === 'true';
        setOpen(result);
      } catch (error) {
        console.error('Error while fetching scheduled maintenance');
      }
    }, 2000);
  };

  useEffect(() => {
    if (auth.authenticated) {
      fetchData();
    }
  }, [auth?.authenticated]);

  return (
    <>
      <ConfirmationDialog
        open={open}
        title={t(`general|plannedMaintenanceTitle`)}
        dialogContent={
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">{t('general|plannedMaintenanceDescription')}</Typography>
          </Box>
        }
        onConfirm={handleClose}
        confirmLabel={t('general|plannedMaintenanceContinue')}
        onClose={handleClose}
      />
    </>
  );
};
