import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { TextWithBoldPart } from '../TextWithBoldPart';
import type { GridColDef, GridRowClassNameParams } from '@mui/x-data-grid';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { StatusCell } from '../shipment-table/TableCells';
import { useLazyGetBookingReportExcelQuery } from 'store/api/fcpBooking';
import { BulkBooking, QuickBookingShipment } from 'store/api/fcpBooking.types';
import { formatElapsedTime } from './BookingProgress';
import { formatDate } from '../../../globals';
import { ROUTES, useRouting } from 'router';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { OnHoldErrorMessagesDialog } from '../onhold-orders/OnHoldErrorMessagesDialog';
import { useState } from 'react';
import { ReportProblem } from '@mui/icons-material';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';

type QuickBookReportProps = {
  open: boolean;
  bookingId: string;
  onClose: () => void;
  bulkBooking: BulkBooking;
};

export const QuickBookReport = ({ open, bulkBooking, onClose }: QuickBookReportProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { linkTo } = useRouting();

  const hasPickupOrderNumber = bulkBooking?.result?.shipments.some(shipment => shipment.pickupOrderNumber !== null);
  const hasEffectivePickupDate = bulkBooking?.result?.shipments.some(shipment => shipment.effectivePickupDate !== null);
  const hasErrors = bulkBooking?.result?.shipments.some(shipment => shipment.errors?.length);

  const [errorDialogShipmentNumberIsOpen, setErrorDialogShipmentNumberIsOpen] = useState<string | undefined>(undefined);

  const { country: accountCountryCode, language } = useCountryLanguage();
  const [excelReportQuery, { isLoading: excelReportIsFetching }] = useLazyGetBookingReportExcelQuery();
  const downloadBulkBookingReport = async (bulkBookingId: number) => {
    const response = await excelReportQuery({ bulkBookingId, accountCountryCode, language }).unwrap();
    const a = document.createElement('a');
    a.href = response.blobUrl;
    a.download = response.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(response.blobUrl);
  };

  const errorDialogOnClose = () => {
    setErrorDialogShipmentNumberIsOpen(undefined);
  };

  const errorsForErrorDialog =
    bulkBooking?.result?.shipments.find(shipment => shipment.shipmentNumber === errorDialogShipmentNumberIsOpen)
      ?.errors || [];

  const columns: GridColDef<QuickBookingShipment>[] = [
    {
      field: 'status',
      headerName: t('general|Status'),
      flex: 0.7,
      sortable: false,
      renderCell: column => <StatusCell status={column.value} loading={false} />,
    },
    ...(hasErrors
      ? [
          {
            field: 'errors',
            headerName: '',
            flex: 0.8,
            sortable: false,
            renderCell: column =>
              column.value?.length ? (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setErrorDialogShipmentNumberIsOpen(column.row.shipmentNumber);
                  }}
                  startIcon={<ReportProblem />}
                  data-testid="displayErrorsButton"
                >
                  {column.row.errors.length} {t('general|errorsLabel')}
                </Button>
              ) : null,
          } as GridColDef<QuickBookingShipment>,
        ]
      : []),
    {
      field: 'shopName',
      headerName: t('general|Sender'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'shipmentNumber',
      headerName: t('general|onholdOrders|columns|shipmentNumber'),
      flex: 1,
      sortable: false,
      renderCell: column => (
        <Button
          variant="text"
          onClick={() => {
            navigate({
              pathname: linkTo(ROUTES.savedShipments),
              search: `?${createSearchParams({ shipmentId: [`${column.row.shipmentId}`] })}`,
            });
          }}
          sx={{ p: 0, maxWidth: 'fit-content', textAlign: 'left' }}
          data-testid={`${column.value}-shipment-number`}
        >
          <Stack direction="column" gap={0}>
            <div data-testid="reference">{column.row.reference}</div>
            <div data-testid="shipmentNumber">{column.value}</div>
          </Stack>
        </Button>
      ),
    },
    ...(hasEffectivePickupDate
      ? [
          {
            field: 'effectivePickupDate',
            headerName: t('general|onholdOrders|columns|effectivePickupDate'),
            flex: 0.9,
            sortable: false,
          },
        ]
      : []),
    ...(hasPickupOrderNumber
      ? [
          {
            field: 'pickupOrderNumber',
            headerName: t('general|onholdOrders|columns|pickupOrderNumber'),
            flex: 1.3,
            sortable: false,
          },
        ]
      : []),
  ];
  const elapsedTime = bulkBooking.elapsedTimeInMillis ? formatElapsedTime(bulkBooking.elapsedTimeInMillis) : '00:00:00';

  const [timeLabel, time] = t('general|elapsedTime', { elapsedTime }).split(': ', 2);

  const renderElapsedTime = (
    <div>
      {timeLabel}: <strong>{time}</strong>
    </div>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth data-testid="quick-book-report" sx={{ p: 0 }}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle data-testid="dialog-title" sx={{ textAlign: 'left', p: 0 }}>
            {t('general|onholdOrders|quickBookReport')}
          </DialogTitle>
          <IconButton onClick={onClose} size="medium" data-testid="close-xicon">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>

        <DialogContent sx={{ pl: 0, display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Stack>
            <TextWithBoldPart
              normalText={`${t('general|date')}: `}
              boldText={formatDate(bulkBooking.createdAt)}
              variant="body1"
              id="createdA"
            />
            <Typography variant="body1">
              <Box component="span" data-testid="elapsed-time">
                {renderElapsedTime}
              </Box>
            </Typography>
            <Box display="flex" gap={1}>
              <TextWithBoldPart
                normalText={`${t('general|items')}: `}
                boldText={bulkBooking.shipmentProcessedCount}
                variant="body1"
                id="shipmentProcessedCount"
              />
              <TextWithBoldPart
                normalText={`${t('general|onholdOrders|shipmentsQuickBookCompleteBookedLabel')}: `}
                boldText={bulkBooking.result?.successCount}
                variant="body1"
                id="successCount"
              />
              <TextWithBoldPart
                normalText={`${t('general|onholdOrders|shipmentsQuickBookCompleteErrorsLabel')}: `}
                boldText={bulkBooking.result?.errorCount}
                variant="body1"
                id="errorCount"
              />
            </Box>
          </Stack>
          <Box width="100%" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography>{t('general|download')}</Typography>
              <Button
                startIcon={
                  excelReportIsFetching ? <CircularProgress color="inherit" size="20px" /> : <FileDownloadIcon />
                }
                color="primary"
                variant="outlined"
                data-testid="download-report-button"
                onClick={() => downloadBulkBookingReport(bulkBooking.bulkBookingId)}
                disabled={excelReportIsFetching}
              >
                Excel
              </Button>
            </Box>
            <DataGrid
              // autoHeight
              rows={bulkBooking?.result?.shipments ?? []}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnMenu
              getRowClassName={getRowClassName}
              getRowId={getRowId}
              // getRowHeight={getRowHeight}
              hideFooter
              density="standard"
              // loading={loading}
              sx={gridSx}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            data-testid="close-button"
            sx={{ minWidth: 200 }}
          >
            {t('general|close')}
          </Button>
        </DialogActions>
      </Dialog>
      <OnHoldErrorMessagesDialog
        open={!!errorDialogShipmentNumberIsOpen}
        onCloseDialog={errorDialogOnClose}
        errors={errorsForErrorDialog}
      />
    </>
  );
};

// Stable references to improve performance
const getRowId = (row: QuickBookingShipment) => row.shipmentId;
// const getRowHeight = () => 'auto' as GridRowHeightReturnValue;
const getRowClassName = ({ indexRelativeToCurrentPage }: GridRowClassNameParams<QuickBookingShipment>) => {
  return indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
};

const gridSx: SxProps<Theme> = {
  overflow: 'auto',
  maxHeight: 'calc(100vh - 450px)',
  minHeight: 200,
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeaders}`]: {
    bgcolor: '#333',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`& .${gridClasses.row}:nth-of-type(even):not(.Mui-selected)`]: {
    bgcolor: '#F9F9F9',
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontWeight: 'bold',
  },
  '& .MuiTablePagination-input': {
    fontSize: 14,
    alignSelf: 'flex-start',
    pt: '5px',
    mr: 2,
  },
  '& .MuiTablePagination-displayedRows': {
    pr: 2,
  },
};
