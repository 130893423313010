import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatBrowserDateTimeReadable } from 'globals/utils/formatting';
import { VolumeDisplay } from 'components/fcp-components/VolumeDisplay';

export default function PiecesCustomCell({ cellValue, type }) {
  const { t } = useTranslation();

  switch (type) {
    case 'StatusWithID':
      return (
        <div className="frc__custom-cell-line-break">
          <div>{cellValue.packageId || ''}</div>

          {cellValue.shipmentDescription && (
            <div className="">
              <br />
              <div>{t(`general|Actual Status`)}: </div>
              <div>
                <div>{t(`general|${cellValue.shipmentDescription}`)} </div>
                {cellValue.terminalCountry && cellValue.terminalName && (
                  <div>
                    <strong>
                      {cellValue.terminalCountry}-{cellValue.terminalName}
                    </strong>
                    &nbsp;
                    <i>{formatBrowserDateTimeReadable(cellValue.statusUpdated, cellValue.statusUpdatedHasTime)}</i>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );

    case 'Dimension':
      return (
        <div className="frc__custom-cell-line-break">
          <>
            {cellValue.length || cellValue.width || cellValue.height ? (
              <div>
                {cellValue.length || '-'} {' x '} {cellValue.width || '-'} {' x '}
                {cellValue.height || '-'} {' ' + t(`general|cm`)}
              </div>
            ) : (
              '---'
            )}
            {cellValue.volume && (
              <div>
                {t('general|labels|metrics|Volume')}: <VolumeDisplay volume={cellValue.volume} />
              </div>
            )}
            {cellValue.loadMeter && (
              <div>
                {t('general|labels|metrics|Loading Meter')}: {cellValue.loadMeter} m3
              </div>
            )}
          </>
        </div>
      );

    case 'Weight':
      return (
        <div className="frc__custom-cell-line-break">
          <div>
            {cellValue.weight || ''} {' ' + t(`general|kg`)}
          </div>
          {cellValue.nonStackable && <div>{t('general|labels|inputs|Non Stackable')}</div>}
        </div>
      );

    default:
      return null;
  }
}
