import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps extends DialogProps {
  title?: string;
  dialogContent: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onClose: () => void;
}

export const ConfirmationDialog = ({
  onClose,
  open,
  dialogContent,
  title,
  confirmLabel,
  cancelLabel,
  onConfirm,
  ...other
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '100%' } }} maxWidth="sm" open={open} {...other}>
      <IconButton
        aria-label="close"
        data-testid="closeModalIcon"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      {title ? <DialogTitle data-testid="modalTitle">{title}</DialogTitle> : null}
      <DialogContent data-testid="modalContent">{dialogContent}</DialogContent>
      <DialogActions
        sx={{
          border: 'none',
          gap: 2,
        }}
      >
        {cancelLabel ? (
          <Button onClick={handleCancel} variant="outlined" data-testid="closeModalBtn" sx={{ minWidth: 85 }}>
            {cancelLabel}
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={() => {
            onConfirm?.();
            handleCancel();
          }}
          data-testid="confirmModalBtn"
          sx={{ minWidth: 85 }}
        >
          {confirmLabel || t('general|labels|buttons|Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
