import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../data-config';
import './SelectServicePoint.css';
import { Error } from 'components';

const SelectServicePoint = ({ isServicePointTitle, servicePoint: originalServicePoint, state }) => {
  const { t } = useTranslation();
  const servicePoint = JSON.parse(JSON.stringify(originalServicePoint));

  const distance =
    servicePoint.distance === 0 || servicePoint.distance === '0'
      ? '--'
      : servicePoint.distance
      ? servicePoint.distance.toFixed(config.distance.maxDecimals) + ` ${t('general|km')}`
      : servicePoint.distance;

  const routeDistance =
    servicePoint.routeDistance === 0 || servicePoint.routeDistance === '0'
      ? '--'
      : servicePoint.routeDistance
      ? servicePoint.routeDistance.toFixed(config.distance.maxDecimals) + ` ${t('general|km')}`
      : servicePoint.routeDistance;

  return (
    <div className="margin-bottom-1 frc__push-bottom ">
      <h4 className="margin-bottom-0 frc__red-lined-title">
        {isServicePointTitle ? t('general|Select a Service Point') : t('general|Select Delivery Option')}
      </h4>
      <div className="l-grid l-grid--w-100pc-s frc__push-bottom">
        <div className="l-grid--w-50pc-s">{t('general|Service Point')}</div>
        <div className="l-grid--w-25pc-s">{t(`general|Distance`)}</div>
        <div className="l-grid--w-25pc-s">{t('portalOrder|Road Distance')}</div>
      </div>
      <div className="l-grid l-grid--w-100pc-s frc-service-point">
        {!servicePoint.cityName ? (
          <Error
            name="serviceCityNamePointError"
            message={t('general|errors|No Service Point Available, Try Different Address')}
          />
        ) : (
          <>
            <div className="l-grid--w-50pc-s">
              <div className="l-grid--w-100pc-s">
                <h4 className="l-grid--w-100pc-s has-no-margin-bottom">{servicePoint.name}</h4>
              </div>
              {servicePoint.partyName ? (
                <div className="l-grid--w-100pc-s frc__uppercase">{servicePoint.partyName}</div>
              ) : (
                ''
              )}
              <div className="l-grid--w-100pc-s">{servicePoint.street}</div>
              <div className="l-grid--w-100pc-s">
                {servicePoint.countryCode ? servicePoint.countryCode + '-' : ''}
                {servicePoint.postalCode} {servicePoint.cityName}
              </div>
            </div>

            <div className="l-grid--w-25pc-s">{distance}</div>

            {routeDistance && (
              <div className="l-grid--w-25pc-s">
                <h4 className="has-no-margin-bottom">{routeDistance}</h4>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectServicePoint;
