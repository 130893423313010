import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import AuthService from 'globals/utils/auth';
import { GLOBAL_CONST } from '../globals/data-config-global';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ENV_DEV = process.env.NODE_ENV === 'development';

export function PilPage() {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectDisabled = location.state?.redirectDisabled;
    if (!redirectDisabled) {
      AuthService.getUserAuthorizationCode().then(response => {
        ENV_DEV && console.log('PIL: requesting authorization code, response: ', response);
        if (response && response.data && response.data.code) {
          const pilRedirectUrl = queryParams.get(GLOBAL_CONST.QUERY_PARAM_REDIRECT_URL);
          const stateQueryParam = queryParams.get(GLOBAL_CONST.QUERY_PARAM_STATE);
          const redirectUrl = stateQueryParam
            ? `${pilRedirectUrl}?code=${response.data.code}&state=${stateQueryParam}`
            : `${pilRedirectUrl}?code=${response.data.code}`;
          ENV_DEV && console.warn('PIL: redirecting to: ', redirectUrl);
          window.location.href = redirectUrl;
        } else {
          ENV_DEV && console.warn('PIL: authorization code error: ', response);
        }
      });
    }
  }, [location]);

  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" pt={{ xs: 0, sm: 2 }} px={{ xs: 2, sm: 4 }}>
      <Typography variant="h1">{t('general|pageTitles|pil')}</Typography>
      <Box sx={{ minHeight: 400 }}></Box>
    </Box>
  );
}
