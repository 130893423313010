import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import i18next from 'i18next';
import AuthService from 'globals/utils/auth';
import { useAuth } from '../hooks/useAuth';
import { ROUTES } from '../router';
import { isValidPilRequest } from '../globals/helpers/pil';

const ENV_DEV = process.env.NODE_ENV === 'development';

export function HomePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let isPil = isValidPilRequest(queryParams);

  const isRefreshTokenExpired = AuthService.isRefreshTokenExpired();
  const { authenticate } = useAuth();
  if (!isRefreshTokenExpired) {
    ENV_DEV && console.log('Home Page: Redirecting to language spec site lang:', i18next.language);
    authenticate(true);
    if (isPil) {
      return (
        <Navigate
          to={`/${i18next.language}/${ROUTES.pilDashboard}?${queryParams.toString()}`}
          state={{ redirectDisabled: true }}
        />
      );
    } else {
      return <Navigate to={`/${i18next.language}/${ROUTES.dashboard}`} />;
    }
  } else {
    ENV_DEV && console.log('Home Page: Refresh token expired -> redirecting to login');
    const redirectUrl = isPil
      ? `/${i18next.language}/${ROUTES.login}?${queryParams.toString()}`
      : `/${i18next.language}/${ROUTES.login}`;
    return <Navigate to={redirectUrl} replace={true} />;
  }
}
