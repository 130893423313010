import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import { DOMESTIC_SHIPMENT_RANGE, HEMLEVERANS_PRODUCT_CODE } from '../../../globals/constants';
import { config, CONST } from '../../data-config';
import { genericPostalCodeValidation } from './EnterPickupLocation-helpers';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import i18n from 'i18next';

export const postalCodeAndCityValidation = async (state, prevStateToSet) => {
  const stateToSet = JSON.parse(JSON.stringify(prevStateToSet));

  if (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE) {
    Object.assign(stateToSet, await domesticPostalCodeCityValidation(state, stateToSet));
  } else {
    Object.assign(stateToSet, await internationalPostalCodeCityValidation(state, stateToSet));
  }

  return stateToSet;
};

export const internationalPostalCodeCityValidation = async (state, prevStateToSet) => {
  const stateToSet = JSON.parse(JSON.stringify(prevStateToSet));
  const resultMap = await genericPostalCodeValidation(state, true);
  if (!resultMap.error) {
    stateToSet.sourcePostalCodeError = resultMap.sourceBookable ? false : true;
    stateToSet.destinationPostalCodeError = resultMap.destinationBookable ? false : true;

    const sourceCityError =
      !stateToSet.sourcePostalCodeError &&
      config.numberPostalCodeCountries.indexOf(state.pickupCountry.value) !== -1 &&
      state.pickupCountry.value?.toLowerCase() === 'se';

    const destinationCityError =
      !stateToSet.destinationPostalCodeError &&
      config.numberPostalCodeCountries.indexOf(state.deliveryCountry.value) !== -1 &&
      state.deliveryCountry.value?.toLowerCase() === 'se';

    if (sourceCityError) {
      stateToSet.sourceCityError = !!resultMap.internationalSourceCityError;
    }

    if (destinationCityError) {
      stateToSet.destinationCityError = !!resultMap.internationalDestinationCityError;
    }
  } else {
    stateToSet.apiError = true;
  }

  return stateToSet;
};

const domesticCityValidation = async (state, stateToSet) => {
  const isSwedenDomesic =
    state.deliveryCountry.value?.toLowerCase() === 'se' && state.pickupCountry.value?.toLowerCase() === 'se';

  if (!isSwedenDomesic) {
    return;
  }

  const postalCodeValidationRequest = await postalCodeValidationAPIRequest(state);

  if (postalCodeValidationRequest.error) stateToSet.apiError = true;
  else {
    if (!postalCodeValidationRequest.source) stateToSet.sourceCityError = true;
    if (!postalCodeValidationRequest.destination) stateToSet.destinationCityError = true;
  }

  if (
    !postalCodeValidationRequest.error &&
    postalCodeValidationRequest.destination &&
    postalCodeValidationRequest.source
  ) {
    stateToSet.goNextError = false;
  }
};

export const domesticPostalCodeCityValidation = async (state, prevStateToSet) => {
  //postal code validation
  const stateToSet = JSON.parse(JSON.stringify(prevStateToSet));
  const result = await genericPostalCodeValidation(state);

  if (result.error) {
    stateToSet.apiError = true;
  } else {
    if (!result.sourceBookable) stateToSet.sourcePostalCodeError = true;
    if (!result.destinationBookable) stateToSet.destinationPostalCodeError = true;

    // Hemleverans is pre-selected => check availability from postal code call
    if (state.productRange === HEMLEVERANS_PRODUCT_CODE) {
      if (result.data.destination && result.data.destination.homeDeliveryParcel === false) {
        stateToSet.validationError = true;
        stateToSet.destinationPostalCodeError = true;
        toast(
          <Alert severity="error" variant="standard">
            {i18n.t('general|errors|The product you have selected is not available at the destination address.')}
          </Alert>,
        );
      }
    }

    if (!stateToSet.sourcePostalCodeError && !stateToSet.destinationPostalCodeError) {
      // city name validation
      await domesticCityValidation(state, stateToSet);
    }
  }

  return stateToSet;
};

export const postalCodeValidationAPIRequest = async state => {
  const urlValidation = apiLinks.postalCodeValidation;
  const body = {
    destinationAllowDeviating: true,
    destinationCity: state.deliveryCity.value.trim(),
    destinationCountryCode: state.deliveryCountry.value,
    destinationPostalCode: state.deliveryPostalCode.value,
    sourceAllowDeviating: true,
    sourceCity: state.pickupCity.value.trim(),
    sourceCountryCode: state.pickupCountry.value,
    sourcePostalCode: state.pickupPostalCode.value,
  };
  const resultMap = {
    source: false,
    destination: false,
  };

  await apiRequest(urlValidation, 'POST', {
    body: body,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.source = result.data.source;
        resultMap.destination = result.data.destination;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};
