import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { analytics } from 'globals/utils/analytics';
import { Error } from 'components';
import ShipmentDetailsRow from './ShipmentDetailsRow/ShipmentDetailsRow';
import { ShipmentRowsSummary } from '../../../components/ShipmentRowsSummary/ShipmentRowsSummary';
import { onSaveForFutureClick, saveShipmentCall } from '../../helpers/savedShipments';
import { config, CONST } from '../../data-config';
import {
  addDeliveryDateToMatchedProducts,
  addShipmentDetailsRow,
  checkSubmit,
  filterProductsByEnabledForPayingAccount,
  formPayloadForMatchedProducts,
  getErrorMessage,
  getStatelessObj,
  postMatchedProductsCall,
  postQuoteToolProductMatchesCall,
  removeShipmentDetailsRow,
  setPricesToProduct,
  updateDimensions,
} from './EnterShipmentDetails-helpers';
import { deliveryOptionsReset, getProductBasedPrice } from '../DeliveryOptions/DeliveryOptions-helpers';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import './EnterShipmentDetails.css';
import { withQueryClient } from '../../../utils/withQueryClient';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { Stack } from '@mui/material';
import SentInformation from '../../../globals/components/SentInformation';
import { HUNGARY_CODE, POLAND_CODE, ROMANIA_CODE } from '../../../globals/constants';
import EkaerInformation from '../../../globals/components/EkaerInformation';
import { checkAndCalculateLdmVolumeAllRows } from '../../../globals/helpers/shipment-details-row';
import { setProductPickupDates } from '../../../store/portalOrderSlice';
import { connect } from 'react-redux';
import { convertProductDatesToIsoStrings } from '../../../utils/products';
import { UitInformation } from 'globals/components/UitInformation';
import { caseInsensitiveEquals } from 'globals/utils/strings';

class EnterShipmentDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      nextBtnLoader: false,
      goNextError: false,
      transactionId: '',
      generalError: false,
      noContent: false,
      payerCodeError: false,
      saveForFutureError: false,
      saveForFutureLoader: false,
      globalColliError: false,
      isLoading: true,
      hideDgErrors: true,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this._isMounted = true;
    const favouriteColliTypes = this.props.queryClient.getQueryData([userQueryKey])?.colliTypes;

    // update context based on the selection on page 1
    let partialContextUpdate = { ...updateDimensions(this.props, favouriteColliTypes) };
    partialContextUpdate = {
      ...partialContextUpdate,
      shipmentDetailsRows: checkAndCalculateLdmVolumeAllRows({
        dimensions: partialContextUpdate?.dimensions,
        shipmentDetailsRows: this.props.context.state.shipmentDetailsRows,
      }),
    };
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    this.props.context.updateState(partialContextUpdate, () => {
      if (
        this.props.context.state.shipmentDetailsRows?.length &&
        this.props.context.state.shipmentDetailsRows.length > 0
      ) {
        checkSubmit(this.props.context, this.props.queryClient.getQueryData([userQueryKey])?.user?.accountCountryCode);
      } else {
        addShipmentDetailsRow(this.props.context, favouriteColliTypes);
      }
      // We must wait for state update to finish, there should be no logic after this line!
      this.setState({ isLoading: false });
    });
  };

  update = (params, context) => {
    params.groupName = 'shipmentDetailsRows';
    context.updateGroupOfFields(params);
  };

  noErrorFound = statelessObj => {
    return (
      !statelessObj.goNextError &&
      !statelessObj.noContent &&
      !statelessObj.saveShipmentsError &&
      !statelessObj.payerCodeError &&
      !statelessObj.globalColliError
    );
  };

  updateAnalyticsAndGoToNextTab = statelessObj => {
    if (this.noErrorFound(statelessObj)) {
      analytics('step 2', CONST.ANALYTICS);
      this.props.nextTab(config.tabs.tab3);
    } else {
      disableOtherTabs(this.props, false, [2], config.tabs.tab2);
    }
  };

  resetPreSelectedProduct = (selectedProduct, preSelectedProductId) => {
    const stateToSet = {};

    if (selectedProduct.code === preSelectedProductId) {
      stateToSet.preSelectedProductId = '';
    }

    return stateToSet;
  };

  adjustPreSelectedProduct = statelessObj => {
    return statelessObj.matchedProducts.find(prod => prod.code === this.props.context.state.selectedProduct.code);
  };

  updateContextAndState = statelessObj => {
    if (this._isMounted) {
      const stateToSet = {
        matchedProducts: statelessObj.matchedProducts,
        ltcFailed: statelessObj.ltcFailed,
        prevLtcFailed: this.props.context.state.ltcFailed || false,
        payingAccountNumber: statelessObj.payingAccountNumber,
        productPrices: statelessObj.productPrices,
        // selectedServicePoint: {}, //reset service point here because, change of city may occur
        servicePoints: [],
        tab4OrTab5Touched: false,
        priorityServiceDisabled: statelessObj.priorityServiceDisabled,
      };

      const preSelectedProduct = this.adjustPreSelectedProduct(statelessObj);

      if (preSelectedProduct && preSelectedProduct.code) {
        stateToSet.selectedProduct = preSelectedProduct;
      }

      if (
        this.noErrorFound(statelessObj) &&
        statelessObj.matchedProducts &&
        Array.isArray(statelessObj.matchedProducts) &&
        !statelessObj.matchedProducts.some(
          matchedPrd => matchedPrd.code === this.props.context.state.selectedProduct.code,
        )
      ) {
        Object.assign(stateToSet, this.resetDeliveryOptionsIfPrevSelectedProductNotPresent(this.props.context.state));
      }

      Object.assign(
        stateToSet,
        this.resetPreSelectedProduct(
          stateToSet.selectedProduct || this.props.context.state.selectedProduct,
          this.props.context.state.preSelectedProductId,
        ),
      );

      if (!this.props.context.state.templateData) {
        stateToSet.quotePriceError = statelessObj.quotePriceError;
        stateToSet.quotePriceMap = statelessObj.quotePriceMap;
      }

      this.props.context.updateState(stateToSet);

      this.setState({
        nextBtnLoader: false,
        goNextError: statelessObj.goNextError,
        payerCodeError: statelessObj.payerCodeError,
        generalError: false,
        noContent: statelessObj.noContent,
        saveShipmentsError: statelessObj.saveShipmentsError,
        globalColliError: false,
      });
    }
  };

  handleSaveForFutureClick = async t => {
    this.setState({ saveForFutureLoader: true }, async () => {
      const result = await onSaveForFutureClick(this.props.context, t);

      if (result.error) {
        this.setState({ saveForFutureError: true, saveForFutureLoader: false });
      }
    });
  };

  resetDeliveryOptionsIfPrevSelectedProductNotPresent = state => {
    const stateToSet = deliveryOptionsReset();

    if (state.returnProdStatus) {
      stateToSet.deliveryInstructions = { value: '', error: false };
    }

    return stateToSet;
  };

  onGoClick = async () => {
    const statelessObj = getStatelessObj();
    const { hasError, isGlobalColliError } = checkSubmit(
      this.props.context,
      this.props.queryClient.getQueryData([userQueryKey])?.user?.accountCountryCode,
    );
    if (!hasError) {
      const { state } = this.props.context;

      disableOtherTabs(this.props, true, [2], config.tabs.tab2);
      Object.assign(statelessObj, formPayloadForMatchedProducts(state));

      this.setState(
        {
          nextBtnLoader: true,
          transactionId: this.props.context.state.transactionId,
          goNextError: false,
          globalColliError: false,
        },
        async () => {
          const [postMatchedProductsResult, productMatchesWithPricesAndDates] = await Promise.all([
            postMatchedProductsCall(state, statelessObj),
            postQuoteToolProductMatchesCall(state, statelessObj),
          ]);

          this.props.setProductPickupDates(
            convertProductDatesToIsoStrings(productMatchesWithPricesAndDates?.data?.pickupDates),
          );

          if (!postMatchedProductsResult.error && !productMatchesWithPricesAndDates.error) {
            const postMatchedProducts = filterProductsByEnabledForPayingAccount(postMatchedProductsResult, state);

            // add delivery date
            Object.assign(
              statelessObj,
              await addDeliveryDateToMatchedProducts(
                state,
                postMatchedProducts,
                statelessObj,
                this._isMounted,
                productMatchesWithPricesAndDates.data,
              ),
            );

            statelessObj.productPrices = productMatchesWithPricesAndDates.data.prices;
            statelessObj.payingAccountNumber = { value: postMatchedProducts.payingAccountNumber };

            // quote price - call when not starting from templates
            if (!state.templateData && state.selectedProduct && state.selectedProduct.code) {
              Object.assign(statelessObj, await getProductBasedPrice(state));
            }

            setPricesToProduct(statelessObj);

            const savedShipmentResult = await saveShipmentCall(this.props.context);

            statelessObj.saveShipmentsError = savedShipmentResult.error || false;
          } else {
            statelessObj.goNextError = true;
          }

          this.updateContextAndState(statelessObj);
          this.updateAnalyticsAndGoToNextTab(statelessObj);
        },
      );
    } else {
      this.setState({
        generalError: true,
        globalColliError: isGlobalColliError,
        hideDgErrors: false,
      });
    }
  };

  render() {
    const { state } = this.props.context;
    const disableBtn = this.state.nextBtnLoader || this.state.saveForFutureLoader;

    if (this.state.isLoading) {
      return <div id="fcp-shipment-details-loader" className="frc__generic--loader-overlay is-loading" />;
    }

    return (
      <>
        <Translation>
          {t => (
            <>
              <div className="frc__enter-shipment-details--wrapper frc__generic-section--wrapper">
                <div className="frc__generic--field-wrapper">
                  <h4 className="margin-bottom-1 frc__red-lined-title ">
                    {t(`general|Enter Shipment Details`)}
                    <span className="has-tooltip-label">
                      <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
                        <div className="frc__tooltip-container-flyout">
                          {t(`general|Enter Shipment Details Tooltip`)} <br /> <br />
                          {t(`general|Enter Shipment Details Tooltip second line`)}
                        </div>
                      </div>
                    </span>
                  </h4>

                  <div
                    className={`frc__enter-shipment-details-rows--wrapper ${
                      state.shipmentDetailsRows.length >= 5
                        ? 'frc__enter-shipment-details-rows--wrapper-fixed-height'
                        : ''
                    }`}
                  >
                    <Stack spacing={1} id="shipmentDetailsRowsContainer">
                      {state.shipmentDetailsRows.map((shipmentRow, ind) => (
                        <ShipmentDetailsRow
                          key={ind}
                          index={ind}
                          shipmentRow={shipmentRow}
                          removeOnParent={index => removeShipmentDetailsRow(index, this.props.context)}
                          error={shipmentRow.error}
                          context={this.props.context}
                          hideDgErrors={this.state.hideDgErrors}
                        />
                      ))}
                    </Stack>
                    <div
                      className={
                        'frc__generic--loader-overlay' + (state.loaders.shipmentDetails.value ? ' is-loading ' : '')
                      }
                    />
                  </div>

                  <div
                    className={
                      'frc__generic--field-wrapper ' + (state.loaders.shipmentDetails.value ? ' l-invisible ' : '')
                    }
                  >
                    {state.shipmentDetailsRows &&
                    state.shipmentDetailsRows.length < config.maxAllowedShipmentDetailsRows ? (
                      <button
                        id="addAnotherItemOfDifferentSize"
                        onClick={() =>
                          addShipmentDetailsRow(
                            this.props.context,
                            this.props.queryClient.getQueryData([userQueryKey])?.colliTypes,
                          )
                        }
                        className={
                          'base-button base-button--white base-button--wide frc__generic-button frc__add-button ' +
                          (state.shipmentDetailsRows &&
                          state.shipmentDetailsRows.length < config.maxAllowedShipmentDetailsRows
                            ? ''
                            : ' disabled ')
                        }
                      >
                        <span className="has-icon icon-plus frc__button-icon--align">
                          {t(`general|Add another item of different size`)}
                        </span>
                      </button>
                    ) : (
                      <Error name="rowLimitReached" message={t('general|You have reached the limit of rows')} />
                    )}
                  </div>
                  {(state.pickupCountry.value.toLowerCase() === POLAND_CODE ||
                    state.deliveryCountry.value.toLowerCase() === POLAND_CODE) && (
                    <SentInformation context={this.props.context} componentConfig={config} componentConstants={CONST} />
                  )}
                  {(state.pickupCountry.value.toLowerCase() === HUNGARY_CODE ||
                    state.deliveryCountry.value.toLowerCase() === HUNGARY_CODE) && (
                    <EkaerInformation
                      context={this.props.context}
                      componentConfig={config}
                      componentConstants={CONST}
                    />
                  )}
                  {config.etransportEnabled &&
                    (caseInsensitiveEquals(state.pickupCountry.value, ROMANIA_CODE) ||
                      caseInsensitiveEquals(state.deliveryCountry.value, ROMANIA_CODE)) && (
                      <UitInformation
                        context={this.props.context}
                        componentConfig={config}
                        componentConstants={CONST}
                      />
                    )}
                  <ShipmentRowsSummary context={this.props.context} />

                  <div className="frc__generic-row--wrapper">
                    <div className="l-grid l-grid--w-100pc-s frc__spacebetween-firstleft">
                      <button
                        id="backToRouteBtn"
                        className={`base-button base-button--white l-grid--w-25pc-w  
                          ${disableBtn ? ' disabled' : ''} `}
                        onClick={disableBtn ? null : () => this.props.previousTab(config.tabs.tab1, 1)}
                        disabled={disableBtn}
                      >
                        <span>{t(`general|Back to route`)}</span>
                      </button>

                      <button
                        id="saveForFutureBtn"
                        className={
                          'base-button base-button--white l-grid--w-25pc-w frc__saveforfuturebtn ' +
                          (disableBtn ? ' disabled' : '') +
                          (this.state.saveForFutureLoader ? ' is-loading--right ' : '')
                        }
                        onClick={disableBtn ? null : () => this.handleSaveForFutureClick(t)}
                        disabled={disableBtn}
                      >
                        <span>{t(`portalOrder|Save for future`)}</span>
                      </button>
                      <button
                        id="goToDeliveryOptionsBtn"
                        data-testid="next-button"
                        className={
                          'base-button base-button--wide l-grid--w-25pc-w ' +
                          (disableBtn ? ' disabled' : '') +
                          (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                        }
                        onClick={disableBtn ? null : () => this.onGoClick(this.props.context)}
                        disabled={disableBtn}
                      >
                        <span>{t('general|labels|buttons|Next Step')}</span>
                      </button>
                    </div>

                    <div className="l-grid--right-s">
                      {this.state.goNextError ||
                      this.state.noContent ||
                      this.state.payerCodeError ||
                      this.state.generalError ||
                      this.state.saveShipmentsError ||
                      this.state.saveForFutureError ||
                      this.state.globalColliError
                        ? getErrorMessage(t, this.state)
                        : ''}
                    </div>
                  </div>
                  <div id="fcp-shipment-details-loader" className="frc__generic--loader-overlay ">
                    {' '}
                  </div>
                </div>
              </div>
            </>
          )}
        </Translation>
      </>
    );
  }
}

const QueryClientComponent = withQueryClient(EnterShipmentDetails);

const mapDispatchToProps = {
  setProductPickupDates,
};

export default connect(null, mapDispatchToProps)(QueryClientComponent);
