import { Box, Button, Grid, Skeleton, TextField, Typography } from '@mui/material';
import { useRef } from 'react';
import { Section } from '../../../components/fcp-components/Section';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../router';
import { ChevronRight } from '@mui/icons-material';
import { useGetOnHoldCountQuery } from 'store/api/fcpBooking';
import { useNavigate } from 'react-router-dom';

export const TrackShipment = ({ dense }: { dense?: boolean }) => {
  const trackRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { country, language } = useCountryLanguage();
  const navigate = useNavigate();

  const { data, isLoading } = useGetOnHoldCountQuery(
    { accountCountryCode: country },
    { pollingInterval: 1000 * 60, skipPollingIfUnfocused: true },
  );

  return (
    <Grid item xs={15} sm={dense ? 15 : 5} md={dense ? 3 : 5} display="flex" flexDirection="column" gap={2}>
      <Section display="flex" flexDirection="column" sx={{ bgcolor: '#f9f9f9', padding: 2 }} flexGrow={1} gap={1}>
        <Typography
          variant="h4"
          sx={{
            wordBreak: 'break-word',
          }}
        >
          {t('general|labels|buttons|Track Shipment')}
        </Typography>
        <Box display="flex" flexDirection="row">
          <TextField
            id="tracking-number"
            variant="outlined"
            color="primary"
            placeholder={t('dashboard|trackShipmentPlaceholder')}
            size="small"
            inputRef={trackRef}
            InputProps={{ sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
            sx={{
              flexBasis: '90px',

              flexGrow: 5,
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'text.primary',
                },
              },
            }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="medium"
            disableElevation
            data-testid="trackBtn"
            sx={{
              flexGrow: 1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={() =>
              window.open(
                encodeURI(
                  `https://www.logistics.dhl/${country}-${language}/${ROUTES.tracking}?submit=1&tracking-id=${trackRef?.current?.value}`,
                ),
                '_blank',
                'noopener, noreferrer',
              )
            }
          >
            {t('general|labels|buttons|Track')}
          </Button>
        </Box>
      </Section>
      <Section sx={{ bgcolor: '#f9f9f9', padding: 2 }} flexGrow={1}>
        <Typography
          variant="h4"
          sx={{
            wordBreak: 'break-word',
          }}
          data-testid="trackShipment-onHoldOrdersTitle"
        >
          {t('dashboard|onHoldOrdersTitle')} (
          {isLoading ? (
            <Skeleton
              variant="text"
              width={14}
              sx={{ display: 'inline-block' }}
              data-testid="trackShipment-loadingOnHoldOrders"
            />
          ) : (
            <span data-testid="trackShipment-onHoldOrdersCount">{data?.totalCount ?? ' - '}</span>
          )}
          )
        </Typography>
        <Button
          variant="text"
          endIcon={<ChevronRight />}
          sx={{ textAlign: 'left', pl: 0, wordBreak: 'break-word' }}
          onClick={() => navigate(`../${ROUTES.onholdOrders}`)}
          data-testid="trackShipment-onHoldOrdersText"
        >
          {t('dashboard|onHoldOrdersText')}
        </Button>
      </Section>
    </Grid>
  );
};
