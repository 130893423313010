import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTimeReadable } from 'globals/utils/formatting';
import { formatVasForDisplay } from './ShipmentDetails-helpers';
import { config } from '../../data-config';

import './ShipmentDetails.css';

export default function AdditionalServices({ data, languageCode, deliveryAddress }) {
  const { t } = useTranslation();
  const [additionalServices, setAdditionalServices] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    async function formatVas() {
      setAdditionalServices(await formatVasForDisplay(data, languageCode, deliveryAddress));
      setLoader(false);
    }

    formatVas();
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {loader && <div id="frc_loader" className="frc__generic--loader-overlay   is-loading" />}
      {additionalServices.map((vas, vasIndex) => (
        <div className="l-grid l-grid--w-100pc-s margin-bottom-1" key={`${vasIndex} - ${vas.vasCode}`}>
          <h5 className="l-grid--w-25pc-s  l-grid--w-100pc-s-s l-grid--w-40pc-s-l l-grid--w-40pc-s-m margin-bottom-0">
            {t(`additionalServices|${vas.vasCode}|name`)}
          </h5>

          <div className="l-grid--w-75pc-s l-grid--w-100pc-s-s l-grid--w-60pc-s-l l-grid--w-60pc-s-m">
            {vas.groups &&
              vas.groups.length > 0 &&
              vas.groups.map((group, groupIndex) =>
                group.map((optionList, optionListIndex) => (
                  <div
                    key={`${groupIndex} ${optionListIndex} ${vasIndex}-${vas.vasCode}`}
                    className="frc__vas_container"
                  >
                    {optionList.map((option, optionIndex, arrList) =>
                      option.vasField.indexOf('subHeading') === 0 ? (
                        <strong key={`${groupIndex}-${vas.vasCode}-${option.id} `}>
                          {' '}
                          {option.vasFieldValue + ' '}{' '}
                        </strong>
                      ) : (
                        <Fragment key={`${option.id}-${optionListIndex}-${optionIndex} - ${vas.vasCode} `}>
                          {option.vasField && option.vasField.indexOf('subHeading') === -1 && (
                            <i key={option.id + 'field'}>{`${t(`additionalServices|${option.vasField}`)}: `}</i>
                          )}

                          {option.vasField.indexOf('subHeading') === 0 ? (
                            <strong key={option.id + 'value'}> {option.vasFieldValue || ''}</strong>
                          ) : config.optionsSchemaRequiredAsDateTime.indexOf(option.vasField) !== -1 &&
                            option.vasFieldValue ? (
                            formatDateTimeReadable(option.vasFieldValue)
                          ) : (
                            (option.vasFieldValue || '-') + (optionIndex !== arrList.length - 1 ? ', ' : '')
                          )}
                        </Fragment>
                      ),
                    )}
                  </div>
                )),
              )}
          </div>
        </div>
      ))}
    </>
  );
}
