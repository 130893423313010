import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { CONST, config } from '../../../data-config';
import { debounce } from 'globals/utils/debounce';
import { matchSorter } from 'match-sorter';
import { Button } from 'components';
import { Input } from 'components';
import { Error } from 'components';
import { Pagination } from 'components';
import { FilterBanner } from 'components';
import SavedShipmentResultGrid from './SavedShipmentResultGrid/SavedShipmentResultGrid';
import { resetPagination, fetchTemplateList, fetchFavoriteTemplate } from './SavedTemplates-helpers';
import { setCookie } from 'globals/utils/requests';
import getBaseUrl from 'globals/utils/getUrlBase';
import { ROUTES } from 'router';

export default class SavedTemplates extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      className: props.className,
      party: '',
      showFavorites: true,
      countryList: [],
      searchResults: [],
      searchResultsMaster: [],
      pagination: {
        page: 1,
        total: 0,
        pageSize: config.paginationInitialNumberOfItems,
        lastPage: 1,
      },
      paginationMaster: {},
      manualSearch: false,
      total: 0,
      apiError: false,
      pageSize: config.paginationInitialNumberOfItems,
      loader: false,
      searchTerm: { value: '' },
      remoteSearchTerm: '',
      newestTemplates: [],
      popularTemplates: [],
      errorModal: React.createRef(),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = async () => {
    this.setState({ loader: true, isOpen: true }, async () => {
      const favoritesList = await fetchFavoriteTemplate();
      const stateToSet = {};

      if (!favoritesList.error) {
        stateToSet.newestTemplates = favoritesList.newestTemplates || [];
        stateToSet.popularTemplates = favoritesList.popularTemplates || [];
        stateToSet.apiError = false;
      } else {
        stateToSet.apiError = favoritesList.error || false;
      }

      stateToSet.loader = false;
      this.setState(stateToSet);
    });
  };

  closeModal() {
    this.setState({ isOpen: false, showFavorites: true, remoteSearchTerm: '', manualSearch: false });
    this.props.context.updateState({ templateSearch: { value: '' } });
  }

  handlePageChange = page => {
    this.searchTemplate({ pageSize: false, page: page });
  };

  handleDisplayRowChange = pageSize => {
    this.searchTemplate({ pageSize: pageSize, page: false });
  };

  resetState = () => {
    return {
      apiError: false,
    };
  };

  closeOnEsc = event => {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  };

  searchFilterPropNames = () => {
    const parentName = this.props.isReceiver ? 'receiver' : 'sender';

    return [
      'templateName',
      `${parentName}.companyName`,
      `${parentName}.name`,
      `${parentName}.street`,
      `${parentName}.city`,
      'senderAccount',
      'otherAccount',
    ];
  };

  filterMatchedTemplates = debounce(context => {
    let keys;
    const stateToSet = {};

    if (context.state.templateSearch.value.length !== 0 && this.state.searchResultsMaster.length > 0) {
      keys = this.searchFilterPropNames();

      const filteredMatches = matchSorter(this.state.searchResultsMaster, context.state.templateSearch.value, {
        keys: keys,
        threshold: matchSorter.rankings.WORD_STARTS_WITH,
      });

      stateToSet.pagination = resetPagination();
      stateToSet.searchResults = filteredMatches.length > 0 ? filteredMatches : [];
      stateToSet.manualSearch = true;
    } else {
      stateToSet.pagination = JSON.parse(JSON.stringify(this.state.paginationMaster));
      stateToSet.searchResults = this.state.searchResultsMaster.length > 0 ? this.state.searchResultsMaster : [];
      stateToSet.manualSearch = false;
    }

    this.setState(stateToSet);
  }, config.standardSearchDebounceTimeout);

  onSearchTemplate = e => {
    e.preventDefault();

    setTimeout(() => {
      this.searchTemplate();
    }, 200);
  };

  searchTemplate = async ({ pageSize = false, page = false } = false) => {
    this.setState({ loader: true, searchTerm: { value: this.props.context.state.templateSearch.value } }, async () => {
      const stateToSet = this.resetState();

      stateToSet.pagination = JSON.parse(JSON.stringify(this.state.pagination));

      if (pageSize || page) {
        stateToSet.manualSearch = false;

        if (pageSize) {
          stateToSet.pagination.pageSize = pageSize;
          stateToSet.pagination.page = 1;
        }
        if (page) stateToSet.pagination.page = page;
      }

      const searchResults = await fetchTemplateList(
        this.props.context.state.templateSearch.value,
        stateToSet.pagination,
      );

      Object.assign(stateToSet, searchResults);
      stateToSet.showFavorites = false;

      this.setState(stateToSet);
    });
  };

  onTemplateSelect = async templateId => {
    if (templateId !== null && templateId !== undefined && templateId !== '') {
      setCookie(config.portalOrderCookie, templateId, CONST.SECONDS_PER_MINUTE, 's');
      const location = `${getBaseUrl(ROUTES.portal)}/${ROUTES.portalOrder}`;
      window.location = encodeURI(location);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('keydown', this.closeOnEsc);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('keydown', this.closeOnEsc);
  }

  render() {
    const { context } = this.props;

    return (
      <>
        {this.state.isOpen ? (
          <Translation>
            {t => (
              <div className="frc__dialog">
                <div className="frc__dialog-overlay" onClick={() => this.closeModal()}></div>
                <div className="frc__dialog-wrapper l-grid--w-100pc-s l-grid--w-80pc-m">
                  <div className="frc__dialog-container l-grid--w-100pc-s l-grid--w-80pc-m">
                    <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
                      <button className="frc__button--delete--darker" onClick={() => this.closeModal()}>
                        X
                      </button>
                    </div>

                    <h5 className="frc__address-pickup-header">{t(`portalOrder|Search Templates`)}</h5>
                    <form onSubmit={e => this.onSearchTemplate(e)}>
                      <div className="l-grid">
                        <div className="l-grid--w-100pc-s l-grid--w-60pc-m">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|Search')}
                            tooltip={t('general|labels|Search Company Name, Contact Name, Address or Account Number')}
                            name="templateSearch"
                            dataTestId="templateSearchInp"
                            inputClassName="frc__addressbook-searchinput"
                            value={context.state.templateSearch.value}
                            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                            afterUpdate={!this.state.showFavorites ? this.filterMatchedTemplates : null}
                            placeholder={t('general|labels|Search by ID, Company Name, Name, Street, City, Note')}
                          />
                        </div>
                        <div className="frc__search-btn-center l-grid--w-100pc-s l-grid--w-25pc-m frc__address-search--button">
                          <Button
                            id="addressBook"
                            dataTestId="searchAddressBookBtn"
                            className="base-button frc__search-button l-grid--w-50pc-s-s l-grid--w-50pc-s-l l-grid--w-20pc-m"
                            label={t('general|labels|Search')}
                          />
                        </div>
                      </div>
                    </form>

                    <div className="frc__addresspickup-results">
                      {!this.state.showFavorites && (
                        <div className="frc__generic-row--wrapper">
                          <FilterBanner
                            CONST={CONST}
                            remoteSearchTerm={this.state.remoteSearchTerm}
                            searchTerm={context.state.templateSearch.value}
                            t={t}
                          />
                        </div>
                      )}

                      {!this.state.loader && !this.state.showFavorites && this.state.searchResults.length > 0 ? (
                        <div>
                          {this.state.searchResults.length}{' '}
                          {this.state.searchResults.length === 1
                            ? t(`portalOrder|result found`)
                            : t('general|results found')}
                        </div>
                      ) : (
                        ''
                      )}
                      {!this.state.loader &&
                      ((this.state.showFavorites &&
                        (this.state.searchResults.length > 0 || this.state.newestTemplates.length > 0)) ||
                        this.state.searchResults.length > 0) ? (
                        <>
                          <SavedShipmentResultGrid
                            isReceiver={this.props.isReceiver}
                            handleClick={this.onTemplateSelect}
                            showFavorites={this.state.showFavorites}
                            context={context}
                            searchResults={this.state.searchResults}
                            newestTemplates={this.state.newestTemplates}
                            popularTemplates={this.state.popularTemplates}
                          />
                          {!this.state.showFavorites && !this.state.manualSearch && (
                            <Pagination
                              t={t}
                              simplePagination
                              CONST={CONST}
                              onHandleDisplayRowChange={this.handleDisplayRowChange}
                              context={context}
                              pagination={this.state.pagination}
                              onHandlePageChange={this.handlePageChange}
                            />
                          )}
                        </>
                      ) : (
                        ''
                      )}

                      {!this.state.loader && !this.state.apiError ? (
                        ((this.state.showFavorites &&
                          (this.state.newestTemplates.length === 0 || this.state.popularTemplates.length === 0)) ||
                          (!this.state.showFavorites && this.state.searchResults.length === 0)) && (
                          <Error
                            className="frc__input--error"
                            message={t(`portalOrder|No saved templates were found`)}
                          />
                        )
                      ) : this.state.apiError ? (
                        <Error className="frc__input--error" message={t('general|errors|General error')} />
                      ) : (
                        ''
                      )}
                    </div>
                    {this.state.loader && (
                      <div
                        className="frc__generic--loader-overlay is-loading"
                        style={{ backgroundAttachment: 'initial' }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Translation>
        ) : (
          ''
        )}
      </>
    );
  }
}
