import { v4 as uuidv4 } from 'uuid';
import { Info, Add } from '@mui/icons-material';
import { Box, Button, FormControlLabel, Checkbox as MuiCheckbox, Tooltip } from '@mui/material';
import { Checkbox, Error, Input, Select, TextArea } from 'components';
import { getInputsToGrayOut } from 'globals/utils/formInterface';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { ShipmentRowControls } from '../../../../components/fcp-components/ShipmentRowControls';
import { DangerousGoods } from '../../../../components/fcp-components/dangerous-goods';
import { DOMESTIC_SHIPMENT_RANGE, INTERNATIONAL_SHIPMENT_RANGE } from '../../../../globals/constants';
import { contextStoreDgData, contextStoreDgErrors } from '../../../../globals/helpers/dangerous-goods';
import { cloneShipmentRow, getColliTypes } from '../../../../globals/helpers/shipment';
import { CONST, config } from '../../../data-config';
import { calculateInputs, resetShipmentDetailsInputOnTypeChange } from './ShipmentDetailsRow-helpers';
import './ShipmentDetailsRow.css';
import { dangerousGoodsExclusions } from '../../../../components/fcp-components/dangerous-goods/DangerousGoods';
import {
  afterUpdateHeight,
  afterUpdateLengthWidth,
  afterUpdateNonStackable,
  getShipmentTypesPerProductRange,
  resetLWHLoadingMeterAndCalculateTotals,
} from '../../../../globals/helpers/shipment-details-row';
import { rootApi } from '../../../../store/api/apiSlice';
import { connect } from 'react-redux';

class ShipmentDetailsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedShipmentTypes: false,
      defocused: {
        lengthWidthHeight: false,
        volume: false,
        loadingMeter: false,
      },
      domesticDangerousGoodsEnabled: null,
    };
  }

  update = params => {
    const stateToSet = getInputsToGrayOut(params.name);

    // Only update state if stateToSet is not empty
    if (Object.keys(stateToSet).length !== 0 && stateToSet.constructor === Object)
      this.setState({ defocused: stateToSet });

    params.groupName = 'shipmentDetailsRows';
    this.props.context.updateGroupOfFields(params);
  };

  componentDidMount() {
    const favouriteColliTypes = this.props.userData?.colliTypes;
    const userAccountCountry = this.props.userData?.user?.accountCountryCode;


    let allowedShipmentTypes =
      this.props.context.state.allowedShipmentTypes.length > 0
        ? this.props.context.state.allowedShipmentTypes
        : config.shipmentTypes;

    const filteredSortedColliTypes = getColliTypes({
      colliTypes: allowedShipmentTypes,
      favouriteColliTypes: favouriteColliTypes,
      countryCode: userAccountCountry,
    });

    const shipmentTypes = getShipmentTypesPerProductRange(
      this.props.context.state.productRange,
      this.props.products,
      this.props.accountNumber,
      this.props.accountsWithProducts,
      filteredSortedColliTypes,
    );

    this.setState({
      allowedShipmentTypes: shipmentTypes,
      domesticDangerousGoodsEnabled: !!this.props.countryConfig?.domesticDangerousGoodsEnabled,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.shipmentRow.width.value !== prevProps.shipmentRow.width.value &&
        !this.props.shipmentRow.width.value) ||
      (this.props.shipmentRow.length.value !== prevProps.shipmentRow.length.value &&
        !this.props.shipmentRow.length.value) ||
      (this.props.shipmentRow.height.value !== prevProps.shipmentRow.height.value &&
        !this.props.shipmentRow.height.value)
    ) {
      this.setState(prevState => ({ ...prevState, defocused: { ...prevState.defocused, loadingMeter: false } }));
    }

    const { allowedShipmentTypes } = this.state;
    const selectedShipmentType = this.props.shipmentRow.shipmentType?.value;
    if (allowedShipmentTypes && allowedShipmentTypes?.length && selectedShipmentType) {
      const shipmentTypeExists = allowedShipmentTypes
        .map(shipmentType => shipmentType.value?.toLowerCase())
        .includes(selectedShipmentType?.toLowerCase());
      if (!shipmentTypeExists) {
        const newState = structuredClone(this.props.context.state);
        newState.shipmentDetailsRows[this.props.index] = {
          ...newState.shipmentDetailsRows[this.props.index],
          shipmentType: {
            value: allowedShipmentTypes[0].value,
          },
        };
        this.props.context.updateState(newState);
      }
    }

    if (
      JSON.stringify(this.props.context.state.shipmentDetailsRows) !==
      JSON.stringify(prevProps.context.state.shipmentDetailsRows)
    ) {
      const newState = structuredClone(this.props.context.state);

      newState.shipmentDetailsRows = newState.shipmentDetailsRows.map((shipmentRow, index) => {
        const dgWeightTotalShipmentItem = shipmentRow.dangerousGoodGroup.reduce((accumulator, dgItem) => {
          let valueToAdd = 0;
          if (dgItem.grossWeight.value) {
            valueToAdd = parseFloat(dgItem.grossWeight.value.replace(',', '.'));
          } else if (
            dgItem.quantityMeasurementUnitQualifier?.value?.toLowerCase() === 'kgm' &&
            dgItem.netQuantity.value
          ) {
            valueToAdd = parseFloat(dgItem.netQuantity.value.replace(',', '.'));
          }
          if (valueToAdd) {
            return accumulator + valueToAdd;
          }
          return accumulator;
        }, 0);

        const totalShipmentItemWeight = parseFloat(shipmentRow.weight?.value?.replace(',', '.'));
        shipmentRow.dgWeightTotal = dgWeightTotalShipmentItem;
        shipmentRow.dgWeightError = dgWeightTotalShipmentItem > totalShipmentItemWeight;

        return shipmentRow;
      });

      newState.dgWeightError = newState.shipmentDetailsRows.some(row => row.dgWeightError);

      this.props.context.updateState(newState);
    }
  }

  render() {
    const shipmentType = this.props.shipmentRow.shipmentType.value;
    const dimensions = this.props.context.state.dimensions;
    const { allowedShipmentTypes } = this.state;

    const loadingMeterDisabled =
      config.shipmentTypesFields[shipmentType]['loadingMeter'].disabled ||
      this.props.shipmentRow.loadingMeter.disabled ||
      // swedish specific shipment types (not available in other countries)
      this.props.shipmentRow.shipmentType.value === 'half pallet' ||
      this.props.shipmentRow.shipmentType.value === 'full pallet';

    const disabledNonStackable =
      this.props.shipmentRow.nonStackable.disabled ||
      config.shipmentTypesFields[shipmentType]['nonStackable'].disabled ||
      false;

    return (
      <>
        <Translation>
          {t => (
            <Box display="flex">
              <div className="frc__shipment-details-row">
                <div className="l-grid">
                  {!this.props.shipmentRow.quantity.hidden ? (
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-10pc-m ">
                      {config.shipmentTypesFields[shipmentType]['quantity'] ? (
                        <Input
                          id={'quantity' + this.props.index}
                          regEx={config.regEx.quantity}
                          label={t('general|labels|metrics|quantity')}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          index={this.props.index}
                          name="quantity"
                          dataTestId="quantityInp"
                          isRequired={config.shipmentTypesFields[shipmentType]['quantity'].required || false}
                          disabled={config.shipmentTypesFields[shipmentType]['quantity'].disabled || false}
                          min={dimensions.min_item_quantity}
                          max={dimensions.max_item_quantity}
                          value={this.props.shipmentRow.quantity.value}
                          updateOnParent={params => this.update(params)}
                          afterUpdate={calculateInputs}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['quantity'] && this.props.shipmentRow.quantity.error ? (
                        <Error
                          name={'quantity' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|quantity'),
                            min: dimensions.min_item_quantity,
                            max: dimensions.max_item_quantity,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.shipmentType.hidden ? (
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-15pc-m-m  ">
                      <Select
                        id={'shipmentType' + this.props.index}
                        context={this.props.context}
                        config={config}
                        CONST={{ API_DOMAIN: 'general|labels|shipmentType' }}
                        className=" frc__select--wrapper-flex frc__generic--field-wrapper--no-top "
                        label={t('general|labels|inputs|Shipment Type')}
                        name="shipmentType"
                        isRequired={true}
                        index={this.props.index}
                        values={allowedShipmentTypes}
                        value={this.props.shipmentRow.shipmentType.value}
                        updateOnParent={(params, context) =>
                          params.value !== this.props.shipmentRow.shipmentType.value
                            ? this.update(params, context)
                            : null
                        }
                        beforeUpdate={resetShipmentDetailsInputOnTypeChange}
                        afterUpdate={calculateInputs}
                      />
                      {this.props.shipmentRow.shipmentType.error ? (
                        <Error
                          name={'shipmentType' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidShipmentType')}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.weight.hidden ? (
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['weight'] ? (
                        <Input
                          id={'weight' + this.props.index}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.weight}
                          label={t('general|labels|metrics|Weight')}
                          labelAfter={'<small>(' + t('general|kg') + ')</small>'}
                          index={this.props.index}
                          name="weight"
                          isRequired={config.shipmentTypesFields[shipmentType]['weight'].required || false}
                          disabled={config.shipmentTypesFields[shipmentType]['weight'].disabled || false}
                          min={dimensions.min_item_weight}
                          max={dimensions.max_item_weight}
                          value={this.props.shipmentRow.weight.value}
                          integerCutoff={config.shipmentInputs.weight.maxIntegers}
                          decimalCutoff={config.shipmentInputs.weight.maxDecimals}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={calculateInputs}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['weight'] && this.props.shipmentRow.weight.error ? (
                        <Error
                          name={'weight' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Weight'),
                            min: dimensions.min_item_weight,
                            max: dimensions.max_item_weight,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.length.hidden ? (
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['length'] ? (
                        <Input
                          id={'length' + this.props.index}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.length}
                          label={t('general|labels|metrics|Length')}
                          labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                          index={this.props.index}
                          name="length"
                          isRequired={
                            config.shipmentTypesFields[shipmentType]['length'].required ||
                            ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                              this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                              this.props.shipmentRow.longShipment.value) ||
                            false
                          }
                          disabled={config.shipmentTypesFields[shipmentType]['length'].disabled || false}
                          grayedOut={this.state.defocused.lengthWidthHeight}
                          value={this.props.shipmentRow.length.value}
                          min={dimensions.min_item_length}
                          max={dimensions.max_item_length}
                          lengthCheck={[RegExp(`^.{0,${config.maxLengthDigits}}$`)]}
                          cutTextLimit={config.maxLengthDigits}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={context =>
                            afterUpdateLengthWidth({
                              context,
                              shipmentRow: this.props.shipmentRow,
                              rowIndex: this.props.index,
                            })
                          }
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['length'] && this.props.shipmentRow.length.error ? (
                        <Error
                          name={'length' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Length'),
                            min: dimensions.min_item_length,
                            max: dimensions.max_item_length,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.width.hidden ? (
                    <div className="frc__column-wrapper  l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['width'] ? (
                        <Input
                          id={'width' + this.props.index}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.width}
                          label={t('general|labels|metrics|Width')}
                          labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                          index={this.props.index}
                          name="width"
                          isRequired={
                            config.shipmentTypesFields[shipmentType]['width'].required ||
                            ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                              this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                              this.props.shipmentRow.longShipment.value) ||
                            false
                          }
                          disabled={config.shipmentTypesFields[shipmentType]['width'].disabled || false}
                          grayedOut={this.state.defocused.lengthWidthHeight}
                          min={dimensions.min_item_width}
                          max={dimensions.max_item_width}
                          lengthCheck={[RegExp(`^.{0,${config.maxWidthDigits}}$`)]}
                          cutTextLimit={config.maxWidthDigits}
                          value={this.props.shipmentRow.width.value}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={(context, params) =>
                            afterUpdateLengthWidth({
                              context,
                              shipmentRow: this.props.shipmentRow,
                              rowIndex: this.props.index,
                            })
                          }
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['width'] && this.props.shipmentRow.width.error ? (
                        <Error
                          name={'width' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Width'),
                            min: dimensions.min_item_width,
                            max: dimensions.max_item_width,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.height.hidden ? (
                    <div className="frc__column-wrapper l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['height'] ? (
                        <Input
                          id={'height' + this.props.index}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.height}
                          label={t('general|labels|metrics|Height')}
                          labelAfter={'<small>(' + t('general|cm') + ')</small>'}
                          index={this.props.index}
                          name="height"
                          isRequired={
                            config.shipmentTypesFields[shipmentType]['height'].required ||
                            ((this.props.shipmentRow.shipmentType.value === 'pallet' ||
                              this.props.shipmentRow.shipmentType.value === 'unspecified') &&
                              this.props.shipmentRow.longShipment.value) ||
                            false
                          }
                          disabled={config.shipmentTypesFields[shipmentType]['height'].disabled || false}
                          grayedOut={this.state.defocused.lengthWidthHeight}
                          min={dimensions.min_item_height}
                          max={dimensions.max_item_height}
                          lengthCheck={[RegExp(`^.{0,${config.maxHeightDigits}}$`)]}
                          cutTextLimit={config.maxHeightDigits}
                          value={this.props.shipmentRow.height.value}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={(context, params) =>
                            afterUpdateHeight({
                              context,
                              params,
                              shipmentRow: this.props.shipmentRow,
                              rowIndex: this.props.index,
                            })
                          }
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['height'] && this.props.shipmentRow.height.error ? (
                        <Error
                          name={'height' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Height'),
                            min: dimensions.min_item_height,
                            max: dimensions.max_item_height,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.volume.hidden ? (
                    <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['volume'] ? (
                        <Input
                          id={'volume' + this.props.index}
                          className=" frc__input--wrapper-flex frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.volume}
                          label={t('general|labels|metrics|Volume')}
                          labelAfter={'<small>(' + t('general|m') + '<sup>3</sup>)</small>'}
                          index={this.props.index}
                          name="volume"
                          isRequired={config.shipmentTypesFields[shipmentType]['volume'].required || false}
                          disabled={config.shipmentTypesFields[shipmentType]['volume'].disabled || false}
                          grayedOut={this.state.defocused.volume}
                          min={dimensions.min_item_volume}
                          max={dimensions.max_item_volume}
                          value={this.props.shipmentRow.volume.value}
                          integerCutoff={config.shipmentInputs.volume.maxIntegers}
                          decimalCutoff={config.shipmentInputs.volume.maxDecimals}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={resetLWHLoadingMeterAndCalculateTotals}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['volume'] && this.props.shipmentRow.volume.error ? (
                        <Error
                          name={'volume' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Volume'),
                            min: dimensions.min_item_volume,
                            max: dimensions.max_item_volume,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.loadingMeter.hidden ? (
                    <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-15pc-m-s l-grid--w-88pc-m-m ">
                      {config.shipmentTypesFields[shipmentType]['loadingMeter'] ? (
                        <Input
                          id={'loadingMeter' + this.props.index}
                          className=" frc__input--wrapper-flex frc__input--wrapper-flex--no-direction frc__generic--field-wrapper--no-top "
                          regEx={config.regEx.loadingMeter}
                          label={t('general|LDMLoading')}
                          labelAfter={
                            '&nbsp;<span class="has-tooltip"> ' +
                            t('general|LDMMeter') +
                            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                            t('general|loadingMeterCalculation') +
                            '</div></div>' +
                            '</span>'
                          }
                          index={this.props.index}
                          name="loadingMeter"
                          isRequired={
                            config.shipmentTypesFields[shipmentType]['loadingMeter'].required ||
                            this.props.shipmentRow.loadingMeter.required
                          }
                          disabled={loadingMeterDisabled}
                          grayedOut={this.state.defocused.loadingMeter}
                          min={this.props.shipmentRow.loadingMeter?.min}
                          max={dimensions.max_item_loading_meter}
                          value={this.props.shipmentRow.loadingMeter.value}
                          integerCutoff={config.shipmentInputs.loadingMeter.maxIntegers}
                          decimalCutoff={config.shipmentInputs.loadingMeter.maxDecimals}
                          updateOnParent={(params, context) => this.update(params, context)}
                          // TODO: RESET FIELDS ON INPUT ONLY WHEN NOT DISABLED , similar to Value
                          afterUpdate={calculateInputs}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                        />
                      ) : (
                        ''
                      )}

                      {config.shipmentTypesFields[shipmentType]['loadingMeter'] &&
                      this.props.shipmentRow.loadingMeter.error ? (
                        <Error
                          name={'loadingMeter' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|invalidValue', {
                            name: t('general|labels|metrics|Loading Meter'),
                            min: this.props.shipmentRow.loadingMeter?.min?.toFixed(2),
                            max: dimensions.max_item_loading_meter?.toFixed(2),
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.nonStackable.hidden ? (
                    <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m">
                      {config.shipmentTypesFields[shipmentType]['nonStackable'] ? (
                        <Checkbox
                          id={'nonStackable' + this.props.index}
                          context={this.props.context}
                          config={config}
                          CONST={CONST}
                          className={' frc__checkbox--vertical '}
                          label={t('general|labels|inputs|Non Stackable')}
                          labelAfter={
                            '&nbsp;<span class="has-tooltip"> ' +
                            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                            t(`general|Can another shipment be placed on top of your shipment?`) +
                            '</div></div>' +
                            '</span>'
                          }
                          index={this.props.index}
                          name="nonStackable"
                          isRequired={config.shipmentTypesFields[shipmentType]['nonStackable'].required || false}
                          disabled={disabledNonStackable}
                          checked={this.props.shipmentRow.nonStackable.value}
                          updateOnParent={(params, context) => this.update(params, context)}
                          afterUpdate={(context, params) =>
                            afterUpdateNonStackable(context, params, this.props.shipmentRow, this.props.index)
                          }
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}

                  {!this.props.shipmentRow.longShipment.hidden ? (
                    <div className="frc__column-wrapper frc__column-wrapper--short l-grid--w-100pc-s-all l-grid--w-25pc-m-s l-grid--w-88pc-m-m">
                      {config.shipmentTypesFields[shipmentType]['longShipment'] ? (
                        <Checkbox
                          id={'longShipment' + this.props.index}
                          className={' frc__checkbox--vertical '}
                          label={t('general|labels|inputs|Long Shipment')}
                          index={this.props.index}
                          name="longShipment"
                          isRequired={config.shipmentTypesFields[shipmentType]['longShipment'].required || false}
                          disabled={
                            this.props.shipmentRow.longShipment.disabled ||
                            config.shipmentTypesFields[shipmentType]['longShipment'].disabled ||
                            false
                          }
                          checked={this.props.shipmentRow.longShipment.value}
                          updateOnParent={(params, context) => this.update(params, context)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="l-grid l-grid--w-100pc-s">
                  <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-50pc-m-s l-grid--w-50pc-m-m ">
                    <TextArea
                      id={'goodsDescription' + this.props.index}
                      className="frc__textarea--wrapper  frc__generic--field-wrapper--no-top "
                      textAreaClassName="frc__textarea--tiny"
                      regEx={config.regEx.everything}
                      label={t('general|labels|inputs|Goods Description')}
                      index={this.props.index}
                      enterButtonSkip={true}
                      context={this.props.context}
                      name="goodsDescription"
                      isRequired={this.props.shipmentRow.goodsDescription.required}
                      linesAllowed={2}
                      value={this.props.shipmentRow.goodsDescription.value}
                      lengthCheck={[RegExp(`[\\r\\n]{0,${config.dimensions.goodsDesc_length}}$`)]}
                      cutTextLimit={config.dimensions.goodsDesc_length}
                      updateOnParent={(params, context) => this.update(params, context)}
                    />

                    {config.shipmentTypesFields[shipmentType]['goodsDescription'] &&
                    this.props.shipmentRow.goodsDescription.error ? (
                      <Error
                        name={'goodsDescription' + this.props.index}
                        className="frc__input--error"
                        message={t('general|errors|Goods Description Error', {
                          max: config.dimensions.goodsDesc_length,
                        })}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  {(this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ||
                    (this.props.context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
                      this.props.context.state.deliveryCountry.value === 'SE')) && (
                    <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
                      <Input
                        id={'shippingMark' + this.props.index}
                        className=" frc__generic--field-wrapper--no-top "
                        regEx={config.regEx.everything}
                        label={t('general|Shipping Mark')}
                        index={this.props.index}
                        name="shippingMark"
                        isRequired={false}
                        lengthCheck={[RegExp(`^.{0,${config.dimensions.shipmentMark_length}}$`)]}
                        value={this.props.shipmentRow.shippingMark.value}
                        cutTextLimit={config.dimensions.shipmentMark_length}
                        updateOnParent={(params, context) => this.update(params, context)}
                        context={this.props.context}
                        config={config}
                        CONST={CONST}
                      />
                      {config.shipmentTypesFields[shipmentType]['shippingMark'] &&
                      this.props.shipmentRow.shippingMark.error ? (
                        <Error
                          name={'shippingMark' + this.props.index}
                          className="frc__input--error"
                          message={t('general|errors|Shipping Mark Error', {
                            max: config.dimensions.shipmentMark_length,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>

                {(this.props.context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ||
                  this.state.domesticDangerousGoodsEnabled) && (
                  <>
                    <Box pl={1}>
                      <FormControlLabel
                        control={
                          <MuiCheckbox
                            id={`${this.props.index}-dangerousGoodsCheckbox`}
                            checked={this.props.shipmentRow.dangerousGoods.value}
                            onChange={e => {
                              const newState = structuredClone(this.props.context.state);
                              if (
                                !e.target.checked ||
                                !newState.shipmentDetailsRows[this.props.index].dangerousGoodGroup.length
                              ) {
                                newState.shipmentDetailsRows[this.props.index].dangerousGoodGroup =
                                  config.dangerousGoodGroup;
                              }
                              newState.shipmentDetailsRows[this.props.index].dangerousGoods = {
                                ...newState.shipmentDetailsRows[this.props.index].dangerousGoods,
                                value: e.target.checked,
                              };

                              this.props.context.updateState(newState);
                            }}
                          />
                        }
                        label={t('general|Dangerous Goods')}
                      />
                      <Tooltip title={t(`general|Dangerous goods tooltip`)} placement="top">
                        <Info color="primary" />
                      </Tooltip>
                    </Box>

                    {this.props.shipmentRow.dangerousGoods.value ? (
                      <>
                        {this.props.shipmentRow.dangerousGoodGroup.map(item => {
                          return (
                            <Box
                              key={item.id}
                              sx={{
                                my: '8px',
                              }}
                            >
                              <DangerousGoods
                                initialValues={item}
                                dangerousGoodsExclusions={dangerousGoodsExclusions(this.props.context.state.products)}
                                onDetailSubmit={formData => {
                                  contextStoreDgData({
                                    formData,
                                    context: this.props.context,
                                    shipmentRowIndex: this.props.index,
                                    id: item.id,
                                  });
                                }}
                                onDetailError={(errors, formData) => {
                                  contextStoreDgErrors({
                                    errors,
                                    context: this.props.context,
                                    shipmentRowIndex: this.props.index,
                                    id: item.id,
                                  });
                                  contextStoreDgData({
                                    formData,
                                    context: this.props.context,
                                    shipmentRowIndex: this.props.index,
                                    id: item.id,
                                    clearErrors: false,
                                  });
                                }}
                                onDeleteItem={() => {
                                  const newState = structuredClone(this.props.context.state);
                                  newState.shipmentDetailsRows[this.props.index].dangerousGoodGroup =
                                    newState.shipmentDetailsRows[this.props.index].dangerousGoodGroup.filter(
                                      dgItem => dgItem.id !== item.id,
                                    );
                                  this.props.context.updateState(newState);
                                }}
                                validateRequired={true}
                                disableDelete={this.props.shipmentRow.dangerousGoodGroup.length < 2}
                                dgWeightError={this.props.context.state.dgWeightError}
                                hideErrors={this.props.hideDgErrors}
                              />
                            </Box>
                          );
                        })}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mr: '8px',
                          }}
                        >
                          <Button
                            id={`${this.props.index}-addAnotherAdr`}
                            disabled={this.props.shipmentRow.dangerousGoodGroup.some(item => !item.dgmId.value)}
                            variant="outlined"
                            onClick={() => {
                              const newState = structuredClone(this.props.context.state);
                              newState.shipmentDetailsRows[this.props.index].dangerousGoodGroup.push({
                                ...config.dangerousGoodGroup[0],
                                id: uuidv4(),
                              });
                              this.props.context.updateState(newState);
                            }}
                            startIcon={<Add />}
                          >
                            {t('general|dangerousGoods|addAnotherAdr')}
                          </Button>
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </div>
              <ShipmentRowControls
                showDeleteButton={this.props.context.state.shipmentDetailsRows.length > 1}
                onDelete={() => this.props.removeOnParent(this.props.index)}
                showCloneButton={
                  this.props.context.state.shipmentDetailsRows &&
                  this.props.context.state.shipmentDetailsRows.length < this.props.context.state.shipmentRowsNumberMax
                }
                onClone={() =>
                  cloneShipmentRow({
                    rowIndex: this.props.index,
                    context: this.props.context,
                    maxRows: this.props.context.state.shipmentRowsNumberMax,
                    calculateInputs,
                  })
                }
              />
            </Box>
          )}
        </Translation>
      </>
    );
  }
}

export default connect((state, ownProps) => {
  const { accountNumber, accountsWithProducts } = state.portalOrder;
  const countryCode = ownProps.context.state.user?.countryCode?.toLowerCase();
  const { data: products } = rootApi.endpoints.getProducts.select({ countryCode })(state);
  const userData = rootApi.endpoints.getUserData.select()(state).data;
  return {
    accountNumber,
    accountsWithProducts,
    products,
    countryConfig: rootApi.endpoints.getCountryConfig.select({ countryCode: userData.user.accountCountryCode })(state)
      .data,
    userData,
  };
})(ShipmentDetailsRow);
