import { GLOBAL_CONST, globalConfig } from 'globals/data-config-global';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  SERVICE_POINT_PRODUCT_CODE,
  HEMLEVERANS_PRODUCT_CODE,
  THIRD_PARTY_PAYER,
} from '../globals/constants';
import { allCountries } from '../globals/data-countries';
import { isCountryEnabled } from '../locale/config';

const componentConfig = {
  tabs: {
    tab1: 'tab1',
    tab2: 'tab2',
    tab3: 'tab3',
    tab4: 'tab4',
    tab5: 'tab5',
  },
  priceQuoteOptionsSchema: [
    'cashOnDelivery',
    'eurPalletExchange',
    'insurance',
    'repetitiveOptions',
    'sortAndQuantity',
    'fixedDeliveryDate',
    'removalOfReplacedGoods',
    'timeDefiniteLoading',
    'timeDefiniteUnloading',
    'doorstepDelivery',
  ],
  priceQuoteOptionFieldTypes: {
    inputTypes: {
      Number: 'Number',
      Decimal: 'Decimal',
      NumericText: 'NumericText',
      Currency: 'Currency',
    },
  },
  servicePointDependentVas: ['cashOnDelivery'],
  servicePointFeatureCode: ['SF054'],
  servicePointLockerDisabledVas: ['personalDelivery'],
  vasSpecialCaseForPickupDate: ['timeDefiniteLoading'],
  vasSpecialCaseForDeliveryDate: ['timeDefiniteUnloading'],
  additionalServicesWhichHasNoOptions: ['thermoWarm', 'thermoCold', 'preAdvicePickup'],
  fileSizeMaxLimitInMB: 10,
  resetOptionSchemaFieldTypes: ['Number', 'Decimal', 'NumericText', 'Alphanumerical', 'Select', 'LTCdate'],
  optionSchemaToBeSentAsObject: true,
  optionsSchemaExpectedAsArray: ['customsHandlingStandard'], // this case is valid only optionSchemaToBeSentAsObject is true
  OptionFieldTypes: {
    inputTypes: {
      Number: 'Number',
      Decimal: 'Decimal',
      NumericText: 'NumericText',
      Alphanumerical: 'Alphanumerical',
      Email: 'Email',
    },
    Currency: 'Currency',
    Select: 'Select',
    LtcDate: 'LTCdate',
    collectionAtTerminal: 'collectionAtTerminal',
    Telephone: 'Telephone',
    SubHeading: 'SubHeading',
    ListCOT: 'ListCOT',
    DateTime: 'DateTime',
    Date: 'Date',
    TickBox: 'TickBox',
    Hidden: 'Hidden',
  },

  homeDeliveryCode: '401',
  homeDeliveryMapping: {
    401: {
      code: '401',
      secondaryCode: '501',
      deliveryType: 'Delivery',
      name: 'dhlHomeDelivery',
    },
    402: {
      code: '402',
      secondaryCode: '502',
      deliveryType: 'Return',
      name: 'dhlHomeDeliveryReturn',
    },
    501: {
      code: '501',
      primaryCode: '401',
      deliveryType: 'Delivery',
      name: 'dhlHomeDelivery',
    },
    502: {
      code: '502',
      primaryCode: '402',
      deliveryType: 'Return',
      name: 'dhlHomeDeliveryReturn',
    },
  },
  homeDeliveryProducts: ['dhlHomeDelivery', 'dhlHomeDeliveryReturn'],
  homeDeliveryProductCodes: ['401', '402'],
  homeDeliverySecondaryCodes: ['501', '502'],
  maxAllowedPhoneNumbers: 1,
  maxServicesPerPage: 3,
  maxAllowedAccountNumbers: 10,
  paginationInitialNumberOfItems: 15,
  maxAllowedPalletAccountNumbers: 99999,
  maxAllowedShipmentDetailsRows: 40,
  maxPhoneNumberDigits: 17,
  maxInstructionsLength: 140,
  maxEmailLength: 64,
  maxDefaultInputLength: 35,
  shipmentRanges: [INTERNATIONAL_SHIPMENT_RANGE, DOMESTIC_SHIPMENT_RANGE],
  shipmentTypesImpOrExp: [IMPORT_SHIPMENT_TYPE, EXPORT_SHIPMENT_TYPE],
  additionalOptionsSchema: {
    preAdviceByDriver: { inputType: 'number', isPhone: true },
  },
  optionsSchemaError: {
    error_insurance_pe: 'Enter a value between 1 and 100 000 SEK',
    error_num_and_max_13: 'Above field has to be numerical and has to consist of max 13 characters.',
    error_num_and_max_3: 'Above field has to be numerical and has to consist of max 3 characters.',
    error_insurance: 'Enter a value between 1 and 999 999 SEK',
    error_not_number_or_integer: 'Not a valid number',
  },
  insurance: {
    insurance_enabled: 'yes',
    min_insurance_limit: 1,
    max_insurance_limit: 999999,
    max_insurance_limit_PE: 100000,
  },
  currency: 'SEK',
  checkGroups: {
    1: 'products',
  },
  serviceDescriptions: {
    211: {
      header: 'DHL Stycke',
    },
    212: {
      header: 'DHL Parti',
    },
    210: {
      header: 'DHL Pall',
    },
    102: {
      header: 'DHL Paket',
    },
    112: {
      header: 'DHL Parcel Connect Plus',
    },
    103: {
      header: 'DHL Service Point',
    },
    109: {
      header: 'DHL Parcel Connect',
    },
    118: {
      header: 'DHL Hemleverans Paket',
    },
    401: {
      header: 'DHL Home Delivery',
    },
    501: {
      header: 'DHL Home Delivery',
    },
    402: {
      header: 'DHL Home Delivery Return',
    },
    502: {
      header: 'DHL Home Delivery Return',
    },
    202: {
      header: 'DHL Euroconnect',
    },
    205: {
      header: 'DHL Euroline',
    },
    232: {
      header: 'DHL EuroConnect Plus',
    },
    233: {
      header: 'DHL Eurapid',
    },
    104: {
      header: 'DHL Service Point Return',
    },
    107: {
      header: 'DHL Parcel Return Connect',
    },
    ECD: {
      header: 'DHL Freight Euroconnect Domestic',
    },
    ECI: {
      header: 'DHL Freight Euroconnect International',
    },
    ELD: {
      header: 'DHL Freight Euroline Domestic',
    },
    ELI: {
      header: 'DHL Freight Euroline International',
    },
    ERI: {
      header: 'DHL Freight Eurapid International',
    },
    HDI: {
      header: 'DHL Home Delivery International',
    },
    HDL: {
      header: 'DHL Home Delivery Domestic',
    },
    SPD: {
      header: 'DHL Standard Pallet Domestic',
    },
    SPI: {
      header: 'DHL Standard Pallet International',
    },
    PPD: {
      header: 'DHL Premium Pallet Domestic',
    },
    PPI: {
      header: 'DHL Premium Pallet International',
    },
  },
  productsReturnShipmentAvailable: [SERVICE_POINT_PRODUCT_CODE, '109', HEMLEVERANS_PRODUCT_CODE],
  schedulePickup: [
    '202',
    '205',
    '232',
    '233',
    'ERI',
    'ECD',
    'ECI',
    'ELD',
    'ELI',
    'HDI',
    'HDL',
    'SPD',
    'PPD',
    'SPI',
    'PPI',
  ],
  desiredPickupDateMainProductCode: [
    '202',
    '205',
    '232',
    'ECI',
    'ELI',
    'ERI',
    'ECD',
    'ELD',
    'HDI',
    'HDL',
    'SPD',
    'PPD',
    'SPI',
    'PPI',
  ],
  vasExcludedForFailedLTC: [
    'fixedDeliveryDate',
    //'timeDefiniteLoading',
    //'timeDefiniteUnloading',
    'priorityServiceG7',
    'priorityServiceG10',
    'priorityServiceG12',
  ],
  allowedProductsForDangerousGoods: [
    '202',
    '205',
    '209',
    '210',
    '211',
    '212',
    '233',
    'ECI',
    'ECD',
    'ELI',
    'ELD',
    'ERI',
    'HDI',
    'HDL',
    'SPD',
    'PPD',
    'SPI',
    'PPI',
  ],
  useResidentialAddressDisableProducts: [SERVICE_POINT_PRODUCT_CODE],
  // MAPPING of the products
  allowedProducts: [
    { code: '212', mapName: 'dhlParti', product: 'DHL Parti', range: DOMESTIC_SHIPMENT_RANGE },
    { code: '210', mapName: 'dhlPall', product: 'DHL Pall', range: DOMESTIC_SHIPMENT_RANGE },
    { code: '211', mapName: 'dhlStycke', product: 'DHL Stycke', range: DOMESTIC_SHIPMENT_RANGE },
    { code: '102', mapName: 'dhlPaket', product: 'DHL Paket', range: DOMESTIC_SHIPMENT_RANGE },
    {
      code: '112',
      mapName: 'dhlParcelConnectPlus',
      product: 'DHL Parcel Connect Plus',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: SERVICE_POINT_PRODUCT_CODE,
      mapName: 'dhlServicePoint',
      product: 'DHL Service Point',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    { code: '109', mapName: 'dhlParcelConnect', product: 'DHL Parcel Connect', range: INTERNATIONAL_SHIPMENT_RANGE },
    {
      code: '107',
      mapName: 'dhlParcelReturnConnect',
      product: 'DHL Parcel Return Connect',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: '104',
      mapName: 'dhlServicePointReturn',
      product: 'DHL Service Point Return',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: HEMLEVERANS_PRODUCT_CODE,
      mapName: 'dhlHemleverans',
      product: 'DHL Hemleverans Paket',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    { code: '401', mapName: 'dhlHomeDelivery', product: 'DHL Home Delivery', range: DOMESTIC_SHIPMENT_RANGE },
    {
      code: '402',
      mapName: 'dhlHomeDeliveryReturn',
      product: 'DHL Home Delivery Return',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    //{ code: '501', mapName: 'dhlHomeDelivery', product: 'DHL Home Delivery', range: DOMESTIC_SHIPMENT_RANGE },
    // { code: '502', mapName: 'dhlHomeDeliveryReturn', product: 'DHL Home Delivery Return', range: DOMESTIC_SHIPMENT_RANGE },
    {
      code: '202',
      mapName: 'dhlEuroConnect',
      product: 'DHL Euroconnect',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    { code: '205', mapName: 'dhlEuroLine', product: 'DHL Euroline', alias: 'ECE', range: INTERNATIONAL_SHIPMENT_RANGE },
    {
      code: '232',
      mapName: 'dhlEuroConnectPlus',
      product: 'DHL Euroconnect Plus',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    { code: '233', mapName: 'dhlEurapid', product: 'DHL Eurapid', alias: 'ERA', range: INTERNATIONAL_SHIPMENT_RANGE },
    {
      code: 'ERI',
      mapName: 'ERI',
      product: 'DHL Freight Eurapid International',
      alias: 'ERA',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'ECD',
      mapName: 'ECD',
      product: 'DHL Freight Euroconnect Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'ECI',
      mapName: 'ECI',
      product: 'DHL Freight Euroconnect International',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'ELD',
      mapName: 'ELD',
      product: 'DHL Freight Euroline Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'ELI',
      mapName: 'ELI',
      product: 'DHL Freight Euroline International',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'HDI',
      mapName: 'HDI',
      product: 'DHL Home Delivery International',
      alias: 'ECE',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'HDL',
      mapName: 'HDL',
      product: 'DHL Home Delivery Domestic',
      alias: 'DOM',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'SPD',
      mapName: 'SPD',
      product: 'DHL Standard Pallet Domestic',
      alias: 'SPD',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'SPI',
      mapName: 'SPI',
      product: 'DHL Standard Pallet International',
      alias: 'SPI',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
    {
      code: 'PPD',
      mapName: 'PPD',
      product: 'DHL Premium Pallet Domestic',
      alias: 'PPD',
      range: DOMESTIC_SHIPMENT_RANGE,
    },
    {
      code: 'PPI',
      mapName: 'PPI',
      product: 'DHL Premium Pallet International',
      alias: 'PPI',
      range: INTERNATIONAL_SHIPMENT_RANGE,
    },
  ],
  ltcProducts: {
    ECE: [202, 232, 'ECI', 'ELI', 'HDI'],
    ERA: [233, 'ERI'],
    DOM: ['ECD', 'ELD', 'HDL'],
    SPI: ['SPI'],
    PPI: ['PPI'],
    SPD: ['SPD'],
    PPD: ['PPD'],
  },
  ltcEurapidProductCodes: ['233', 'ERI', 'PPD', 'PPI'],
  ltcNonEurapidProductCodes: ['202', '232', 'ECI', 'ECD', 'ELI', 'ELD', 'HDI', 'HDL', 'SPD', 'SPI'],

  shipmentTypesFields: {
    'full pallet': {
      shipmentType: { value: 'full pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, disabled: true, hidden: true },
      loadingMeter: { value: '', error: false, hidden: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'half pallet': {
      shipmentType: { value: 'half pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: true, required: true, hidden: false },
      width: { value: '80', error: false, disabled: true, required: true, hidden: false },
      height: { value: '', error: false, required: true, hidden: false },
      weight: { value: '', error: false, required: true },
      volume: { value: '', error: false, hidden: true },
      loadingMeter: {
        value: '',
        error: false,
        hidden: false,
      },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    parcel: {
      shipmentType: { value: 'parcel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false, hidden: false },
      width: { value: '', error: false, hidden: false },
      height: { value: '', error: false, hidden: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false, hidden: true },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: true },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    pallet: {
      shipmentType: { value: 'pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    unspecified: {
      shipmentType: { value: 'unspecified', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    tanktainer: {
      shipmentType: { value: 'tanktainer', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'ro/bobbin': {
      shipmentType: { value: 'ro/bobbin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    bundle: {
      shipmentType: { value: 'bundle', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'bag/sack': {
      shipmentType: { value: 'bag/sack', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    bale: {
      shipmentType: { value: 'bale', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'box/case': {
      shipmentType: { value: 'box/case', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    can: {
      shipmentType: { value: 'can', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    cage: {
      shipmentType: { value: 'cage', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    crate: {
      shipmentType: { value: 'crate', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'carton/package': {
      shipmentType: { value: 'carton/package', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    dolly: {
      shipmentType: { value: 'dolly', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'drum/barrel': {
      shipmentType: { value: 'drum/barrel', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    ibc: {
      shipmentType: { value: 'ibc', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    octabin: {
      shipmentType: { value: 'octabin', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet gl': {
      shipmentType: { value: 'pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - double extra large': {
      shipmentType: { value: 'pallet - double extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'euro-pallet': {
      shipmentType: { value: 'euro-pallet', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '120', error: false, disabled: false, required: false, hidden: false },
      width: { value: '80', error: false, disabled: false, required: false, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'half pallet gl': {
      shipmentType: { value: 'half pallet gl', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '60', error: false, disabled: false, required: false, hidden: false },
      width: { value: '80', error: false, disabled: false, required: false, hidden: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - large': {
      shipmentType: { value: 'pallet - large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - oversized': {
      shipmentType: { value: 'pallet - oversized', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet - extra large': {
      shipmentType: { value: 'pallet - extra large', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'pallet euro size': {
      shipmentType: { value: 'pallet euro size', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'skeleton box': {
      shipmentType: { value: 'skeleton box', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
    'garment roller': {
      shipmentType: { value: 'garment roller', error: false },
      quantity: { value: 1, error: false, required: true },
      length: { value: '', error: false },
      width: { value: '', error: false },
      height: { value: '', error: false },
      volume: { value: '', error: false },
      weight: { value: '', error: false, required: true },
      loadingMeter: { value: '', error: false },
      longShipment: { value: false, error: false, hidden: true },
      nonStackable: { value: false, error: false, hidden: false },
      goodsDescription: { value: '', error: false, hidden: false, required: true },
      shippingMark: { value: '', error: false, hidden: false },
      dangerousGoods: { value: false, error: false, hidden: false },
    },
  },

  // products: {
  //   dhlPall: { packageTypes: ["full pallet", "half pallet"] },
  //   dhlPaket: { packageTypes: ["parcel"] }
  // },

  getAdditionalServicesFromAPI: true,
  dhlPALL: '210',
  additionalServicesFilteredByPayerCode: {
    cashOnDelivery: { payerCodeNotAllowed: ['3', '4'] },
    insurance: {
      payerCodeAllowedByProduct: {
        107: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        109: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        112: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        232: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        233: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
    insuranceHighValue: {
      payerCodeAllowedByProduct: {
        107: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        109: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        112: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        232: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        233: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
    timeDefiniteLoading: {
      payerCodeAllowedByProduct: {
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
    timeDefiniteUnloading: {
      payerCodeAllowedByProduct: {
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
    tailLiftLoading: {
      payerCodeAllowedByProduct: {
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
    tailLiftUnloading: {
      payerCodeAllowedByProduct: {
        202: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
        205: { International: { Export: ['DAP', 'DDP', 'CPT', 'CIP', 'DPU'], Import: ['EXW', 'FCA'] } },
      },
    },
  },

  ASToFilterOutIfDangerousGoods: [
    'cashOnDelivery',
    'dangerousGoodsLimitedQuantity',
    'deliveryWithoutProofOfDelivery',
    'indoorDelivery',
    'priorityServiceG7',
    'priorityServiceG10',
    'priorityServiceG12',
    'fixedDeliveryDate',
  ],
  predefinedAdditionalServices: [
    {
      // DHL PALL, DHL STYCKE
      products: ['210', '211'],
      services: [
        {
          name: 'Priority Service',
          code: 'priorityService',
          information: 'Lorem ipsum',
          value: false,
          selectedStatus: false,
          options: [
            {
              value: '',
              required: true,
              type: 'radioGroup',
              values: [
                { name: 'G7', value: 'G7', code: 'priorityServiceG7' },
                { name: 'G10', value: 'G10', code: 'priorityServiceG10' },
                { name: 'G12', value: 'G12', code: 'priorityServiceG12' },
              ],
              error: false,
              errorMessage: 'Please select one option',
            },
            {
              type: 'input',
              validation: '',
              required: true,
              name: 'Shipment Number',
              code: 'shipmentNumber',
              value: '',
              error: false,
              errorMessage: 'Invalid shipment number',
            },
          ],
          error: false,
          errorMessage: '',
        },
        {
          name: 'Temperature controlled',
          code: 'temperatureControlled',
          value: false,
          selectedStatus: false,
          options: [
            {
              type: 'radioGroup',
              required: true,
              values: [],
              value: '',
              error: false,
              errorMessage: 'Please select one option',
            },
          ],
          error: false,
          errorMessage: '',
        },
      ],
    },
    {
      // DHL PARTI
      products: ['212'],
      services: [
        {
          name: 'Temperature controlled',
          code: 'temperatureControlled',
          value: false,
          selectedStatus: false,
          options: [],
          error: false,
          errorMessage: '',
        },
        {
          name: 'Tail Lift Required',
          code: 'tailLiftRequired',
          information: 'Lorem ipsum',
          value: false,
          selectedStatus: false,
          options: [
            {
              type: 'checkboxGroup',
              required: true,
              values: [
                {
                  name: 'Loading',
                  selectedStatus: false,
                  value: false,
                  code: 'tailLiftLoading',
                },
                {
                  name: 'Unloading',
                  selectedStatus: false,
                  value: false,
                  code: 'tailLiftUnloading',
                },
              ],
              value: '',
              error: false,
              errorMessage: 'Please select at least one option',
            },
          ],
          error: false,
          errorMessage: '',
        },
        {
          name: 'Time definite services',
          code: 'timeDefiniteServices',
          selectedStatus: false,
          information: 'Lorem ipsum',
          options: [
            {
              type: 'checkboxDateTimeGroup',
              required: false,
              error: false,
              errorMessage: 'Please select at least one option',
              values: [
                {
                  name: 'Loading',
                  selectedStatus: false,
                  value: '',
                  code: 'timeDefiniteLoading',
                },
                {
                  name: 'Unloading',
                  selectedStatus: false,
                  value: '',
                  code: 'timeDefiniteUnloading',
                },
              ],
            },
          ],
          error: false,
          errorMessage: '',
        },
        {
          name: 'Delivery without proof of delivery (POD)',
          code: 'deliveryWithoutProofOfDelivery',
          information: 'Lorem ipsum',
          value: false,
          selectedStatus: false,
          error: false,
          errorMessage: '',
        },
        {
          name: 'Pre-advice by driver',
          code: 'preAdviceByDriver',
          information: 'Lorem ipsum',
          value: false,
          selectedStatus: false,
          error: false,
          errorMessage: '',
        },
      ],
    },
    {
      // DHL PAKET
      products: ['102'],
      services: [
        {
          name: 'Priority Service',
          code: 'priorityService',
          information: 'Lorem ipsum',
          value: false,
          selectedStatus: false,
          options: [
            {
              type: 'radioGroup',
              required: true,
              values: [
                { name: 'G7', value: 'G7', code: 'priorityServiceG7' },
                { name: 'G10', value: 'G10', code: 'priorityServiceG10' },
                { name: 'G12', value: 'G12', code: 'priorityServiceG12' },
              ],
              value: '',
              error: false,
              errorMessage: 'Please select one option',
            },
            {
              type: 'input',
              validation: '',
              required: true,
              name: 'Shipment Number',
              code: 'shipmentNumber',
              value: '',
              error: false,
              errorMessage: 'Invalid shipment number',
            },
          ],
          error: false,
          errorMessage: '',
        },
      ],
    },
  ],

  dimensions: {
    min_chargeable_weight_check_product_codes: ['212'],
    insurance_enabled: 'yes',
    min_insurance_limit: 1,
    max_insurance_limit: 999999,
    max_insurance_limit_PE: 100000,
    max_item_weight: 38000,
    min_item_weight: 0.1,
    max_shipment_weight: 38000,
    min_shipment_weight: 0.1,
    max_item_quantity: 999,
    min_item_quantity: 1,
    max_total_quantity: 999,
    min_total_quantity: 1,
    max_item_length: 1360,
    min_item_length: 15,
    max_item_width: 240,
    min_item_width: 11,
    max_item_height: 290,
    min_item_height: 1,
    max_item_volume: 150,
    min_item_volume: 0.001,
    min_item_loading_meter: 0.01,
    max_item_loading_meter: 20.8,
    max_item_height_stack_limit: 120,
    min_total_volume: 0,
    max_total_volume: 150,
    min_total_loading_meter: 0,
    max_total_loading_meter: 20.8,
    min_total_palletPlace: 0,
    max_total_palletPlace: 3,
    min_item_palletPlace: 0,
    max_item_palletPlace: 3,
    non_stackable_height: 125,
    loading_meter_const: 2.4,
    min_item_chargeableWeight: 0,
    max_item_chargeableWeight: 999999,
    min_total_chargeableWeight: 0,
    max_total_chargeableWeight: 999999,
    shipmentMark_length: 17,
    goodsDesc_length: 70,
  },
  payers: [SENDER_PAYER, RECEIVER_PAYER, THIRD_PARTY_PAYER],
  domesticCountries: allCountries.filter(country => {
    if (country.code && isCountryEnabled(country.code)) {
      return country;
    }
    return false;
  }),
  b2cProducts: [SERVICE_POINT_PRODUCT_CODE, '109'],
  productsWithUploadCustomsDocumentsForAllCountries: ['dhlEuroConnect', 'dhlEurapid', 'dhlEuroConnectPlus'],
  minLengthToPerformCompanySearch: 3,
  surchargesToExcludeFromDisplayingOnSummary: [
    'TotalPriceIncVAT',
    'VAT',
    'TotalPrice',
    'FreightCost',
    'TotalPriceNoVATNOMarkup',
    'ChargedWeight',
    'Distance',
    'VolWeight',
    'OwnSurChargePerc',
    'OwnSurChargeAmount',
    'additionalServices',
  ],

  mustBeCombinedWithVASRules: [
    {
      code: 'removalOfReplacedGoods',
      mandatoryToBeSelected: ['carryingHelpToRoomOfChoice', 'installation', 'installationAdvanced'],
      mandatoryToUnselect: ['deliveryWithoutProofOfDelivery'],
    },
    {
      code: 'dangerousGoodsLimitedQuantity',
      atLeastOneCode: {
        codes: [],
        toBeSelected: ['deliveryWithIdControl'],
      },
      allCodes: {
        codes: [],
        toBeSelected: [],
      },
      mandatoryToBeSelected: [],
    },
    // {
    //   code: 'doorstepDelivery',
    //   atLeastOneCode: {
    //     codes: ['dangerousGoodsLimitedQuantity'],
    //     toBeSelected: ['deliveryWithIdControl'],
    //   },
    //   mandatoryToBeSelected: [],
    //   mandatoryToUnselect: ['deliveryWithIdControl'],
    // },
    // {
    //   code: 'deliveryWithIdControl',
    //   mandatoryToBeSelected: ['doorstepDelivery'],
    // },
  ],
};

const COMPONENT_CONST = {
  API_DOMAIN: 'portalOrder',
  ANALYTICS: 'portal order entry',
  PALLET_PICKUP_PREFIX: 'PPR',
  PUBLIC_BOOKING_PICKUP: 'PBP',
  PUBLIC_BOOKING_ORDER: 'PBO',
  PORTAL_BOOKING_ORDER: 'POBO',
  CITY_MULTIPLE_ADDRESS: 'VARIOUS',
  DEFAULT_MAP_LAT: 61.24602,
  DEFAULT_MAP_LNG: 12.86366,
  DEFAULT_MAP_ZOOM: 14,
  HOURS_PER_MONTH: 720,
  SERVICE_POINTS: 5,
  EMAIL_LENGTH: 65,
  CURRENCY_LENGTH: 3,
  SENDER: 'sender',
  RECEIVER: 'receiver',
  DIFFERENT_SENDER: 'differentSender',
  DIFFERENT_RECEIVER: 'differentReceiver',
};

componentConfig.domesticCountries.forEach(country => (country.name = country.code));

export const config = Object.assign({}, globalConfig, componentConfig);
export const CONST = Object.assign({}, GLOBAL_CONST, COMPONENT_CONST);
