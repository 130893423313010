import { CountryOption } from 'types/country.types';

export const allCountries: CountryOption[] = [
  {
    code: '',
    example: '',
    name: 'Select country',
  },
  {
    code: 'AD',
    example: 'AD500',
  },
  {
    code: 'AL',
    example: '9011',
  },
  {
    code: 'AT',
    example: '1050',
  },
  {
    code: 'BA',
    example: '71000',
  },
  {
    code: 'BE',
    example: '1000',
  },
  {
    code: 'BG',
    example: '3644',
  },
  {
    code: 'HR',
    example: '10000',
  },
  {
    code: 'CY',
    example: '7110',
  },
  {
    code: 'CZ',
    example: '60200',
  },
  {
    code: 'DK',
    example: '1050',
  },
  {
    code: 'EE',
    example: '48415',
  },
  {
    code: 'FI',
    example: '72630',
  },
  {
    code: 'FR',
    example: '13140',
  },
  {
    code: 'DE',
    example: '24980',
  },
  {
    code: 'GI',
    example: 'GB-GX 11 1AA',
  },
  {
    code: 'GR',
    example: '10431',
  },
  {
    code: 'HU',
    example: '4561',
  },
  {
    code: 'IE',
    example: 'Wicklow',
  },
  {
    code: 'IT',
    example: '65017',
  },
  {
    code: 'KZ',
    example: '020011',
  },
  {
    code: 'LV',
    example: '1001',
  },
  {
    code: 'LT',
    example: '80157',
  },
  {
    code: 'LU',
    example: '4750',
  },
  {
    code: 'MK',
    example: '1000',
  },
  {
    code: 'MA',
    example: '10012',
  },
  {
    code: 'MC',
    example: '98000',
  },
  {
    code: 'ME',
    example: '81101',
  },
  {
    code: 'MT',
    example: 'VLT',
  },
  {
    code: 'NL',
    example: '7851',
  },
  {
    code: 'NO',
    example: '1440',
  },
  {
    code: 'PL',
    example: '86061',
  },
  {
    code: 'PT',
    example: '1000',
  },
  {
    code: 'RO',
    example: '107027',
  },
  {
    code: 'RS',
    example: '11000',
  },
  {
    code: 'SM',
    example: '47890',
  },
  {
    code: 'SK',
    example: '02754',
  },
  {
    code: 'SI',
    example: '1000',
  },
  {
    code: 'SE',
    example: '26234',
  },
  {
    code: 'ES',
    example: '47280',
  },
  {
    code: 'CH',
    example: '3785',
  },
  {
    code: 'TR',
    example: '01010',
  },
  {
    code: 'GB',
    example: 'AB10',
  },
  {
    code: 'LI',
    example: '9495',
  },
  {
    code: 'VA',
    example: '00120',
  },
].map(country => ({
  ...country,
  name: country.name || country.code,
}));
