import { Autocomplete, Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useConfigCountries } from '../../hooks/useConfigCoutries';

const PhoneCountryCode = props => {
  const {
    label,
    onChange,
    error = false,
    helperText = '',
    value = {},
    sx = {},
    inputLabelProps = {},
    labelOverlapped = false,
    field,
  } = props;

  const { companyPhoneCountryCodeOptions } = useConfigCountries();

  return (
    <Autocomplete
      {...field}
      disableClearable
      value={field ? field.value : companyPhoneCountryCodeOptions.filter(i => i.phone === value)?.[0]}
      isOptionEqualToValue={(option, value) => option.phone === value.phone}
      onChange={onChange}
      clearText=""
      id="companyPhoneCountryCode"
      sx={{ ...sx, width: '100%', maxWidth: '180px', shrink: false }}
      autoHighlight
      options={companyPhoneCountryCodeOptions}
      filterOptions={(options, state) => {
        const displayOptions = options.filter(option => {
          let cleanInput = state.inputValue.toLowerCase().trim();
          if (cleanInput[0] === '+') {
            cleanInput = cleanInput.slice(1);
          }
          return (
            `${option.phone}`.toLowerCase().trim().includes(cleanInput) ||
            `${option.code}`.toLowerCase().trim().includes(cleanInput)
          );
        });
        return displayOptions;
      }}
      getOptionLabel={option => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.code}>
          <Box sx={{ mr: '16px' }}>
            <span component="span" className={`fi fi-${option.code.toLowerCase()}`}></span>
          </Box>
          <Box sx={{ width: '30px' }}>{option.code}</Box>
          {option.phone}
        </Box>
      )}
      renderInput={params => {
        const getValue = () => {
          const valueFromOptions = companyPhoneCountryCodeOptions.find(
            item => item.label === params.inputProps.value,
          )?.phone;
          if (valueFromOptions) {
            return valueFromOptions;
          }
          return params.inputProps.value;
        };
        const value = getValue();
        return (
          <TextField
            {...params}
            required={props.required ?? true}
            label={!labelOverlapped || (labelOverlapped && value === '') ? label : ''}
            inputProps={{
              ...params.inputProps,
              value,
            }}
            error={error}
            helperText={helperText}
            InputLabelProps={{
              shrink: false,
              // shrink: value !== null && value !== '',
              sx: inputLabelProps,
            }}
          />
        );
      }}
    />
  );
};

PhoneCountryCode.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  sx: PropTypes.object,
  inputLabelProps: PropTypes.object,
  labelOverlapped: PropTypes.bool,
};

export default PhoneCountryCode;
