import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, TextFieldProps, InputLabel, Stack, Tooltip } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { GLOBAL_CONST } from 'globals/data-config-global';

interface PasswordInputProps
  extends Omit<TextFieldProps, 'onChange' | 'disabled' | 'required' | 'label' | 'InputProps' | 'inputProps' | 'sx'> {
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  testId: string;
  showTooltip?: boolean;
  showMuiLabel?: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  disabled,
  required,
  label,
  testId,
  showTooltip,
  showMuiLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Stack flexGrow={1}>
      {!showMuiLabel && (
        <InputLabel
          disabled={disabled}
          sx={{ fontSize: '15px', color: '#191919' }}
          required={required}
          htmlFor={testId}
        >
          {label}
        </InputLabel>
      )}
      <Tooltip
        title={
          showTooltip &&
          t('general|Password rules info', {
            passwordMinLength: GLOBAL_CONST.PASSWORD_MIN_LENGTH,
          })
        }
        placement="top-start"
        disableHoverListener
      >
        <TextField
          value={value}
          label={showMuiLabel && label}
          type={showPassword ? 'text' : 'password'}
          // onChange={e => onChange(e.target.value.replace(/^\s+/g, ''))}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          required={required}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleTogglePassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: !showMuiLabel
              ? {
                  height: '15px',
                }
              : {},
          }}
          data-testid={testId}
          id={testId}
          sx={
            !showMuiLabel
              ? {
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                  },
                }
              : {}
          }
          autoComplete="off"
          {...props}
        />
      </Tooltip>
    </Stack>
  );
};
