import { Checkbox, Error, Input, PhoneNumber, Select } from 'components';
import { apiLinks } from 'config/api-config';
import { allCountries } from 'globals/data-countries';
import { testRegEx } from 'globals/utils/fieldValidations';
import { DeepMerge } from 'globals/utils/formatting';
import { getTransactionId } from 'globals/utils/generators';
import { apiRequest, getCookie, getPropperAPIData } from 'globals/utils/requests';
import React from 'react';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../globals/constants';
import { CONST, config } from '../../data-config';
import { ValidateAccountNumber } from './Validations/AccountNumber-validations';
import { EnterDeliveryLocationValidations } from './Validations/EnterDeliveryLocation-validations';
import { EnterPickupLocationValidations } from './Validations/EnterPickupLocation-validations';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';
import {
  resetPhoneNumberWhenSwitchedToDomestic,
  swapSenderAndReceiverAddress,
} from '../../../globals/helpers/pickup-helpers';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';

export const getCityName = async (countryCode, postalCode, cityName = false) => {
  const resultMap = {};

  let url = apiLinks.fetchCityName;
  url = url.replace('{countryCode}', countryCode);
  url = url.replace('{postalCode}', postalCode);

  if (cityName) {
    url = `${url}/${cityName}`;
  }

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const setGeneralError = stateObj => {
  const stateToSet = JSON.parse(JSON.stringify(stateObj));

  stateToSet.generalError =
    stateToSet.validationError ||
    stateToSet.sourcePostalCodeError ||
    stateToSet.destinationPostalCodeError ||
    stateToSet.sourceCityError ||
    stateToSet.destinationCityError;

  stateToSet.generalError = stateToSet.apiError ? false : stateToSet.generalError;

  return stateToSet;
};

export const productAPICall = async state => {
  const stateToSet = {};

  if (state.products.length === 0) {
    const url = apiLinks.getFCPAPIProducts;
    const transactionId = state.transactionId ? state.transactionId : getTransactionId(CONST.PUBLIC_BOOKING_ORDER);

    const params = {
      headers: {
        Accept: 'application/json',
      },
    };

    await apiRequest(url, 'GET', params)
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          Object.assign(stateToSet, {
            products: result.data,
            deliveryTypesForParcelConnect: getDeliveryTypes(result.data),
            internationalShippingCountries: getInternationalCountries(result.data),
            transactionId: transactionId,
          });
        } else {
          stateToSet.productAPIError = true;
        }
      })
      .catch(err => {
        console.log(err);
        stateToSet.productAPIError = true;
      });
  }

  return stateToSet;
};

export const showTermsOfDeliveryDropdown = context => {
  const opts = readState(context.state);
  const show = opts.isInternational;
  return show;
};

export const getStateToSet = () => {
  return {
    apiError: false,
    goNextError: true,
    sourcePostalCodeError: false,
    destinationPostalCodeError: false,
    sourceCityError: false,
    destinationCityError: false,
    generalError: false,
  };
};

export const getCountryForCustoms = state => {
  switch (state.shipmentTypesImpOrExp.value) {
    case EXPORT_SHIPMENT_TYPE:
      return state.deliveryCountry.value;

    case IMPORT_SHIPMENT_TYPE:
      return state.pickupCountry.value;

    default:
      break;
  }
};

export const afterCountry = context => {
  const stateObj = {};
  const isInternational = context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE;
  const toCountries = context.state.internationalShippingCountries;

  if (isInternational) {
    const selectedCountry = getCountryForCustoms(context.state);
    const customsCountry = toCountries.find(toCountry => {
      return toCountry.countryCode === selectedCountry;
    });

    termsOfDeliveryValue(context, stateObj, context.state.deliveryCountry.value);
    stateObj['countryBasedCustoms'] = customsCountry && customsCountry.customs;
  }

  stateObj['differentPickupCountry'] = JSON.parse(JSON.stringify(context.state.differentPickupCountry));
  stateObj['differentDeliveryCountry'] = JSON.parse(JSON.stringify(context.state.differentDeliveryCountry));
  stateObj['differentPickupCountry'].value = context.state.pickupCountry.value;
  stateObj['differentDeliveryCountry'].value = context.state.deliveryCountry.value;

  updatePostalCode(context.state, context.state.pickupCountry.value, context.state.deliveryCountry.value, stateObj);

  if (stateObj['differentPickupCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentPickupCountry'].value, 'differentPickupPhoneNumbers');
  }
  if (stateObj['differentDeliveryCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentDeliveryCountry'].value, 'differentDeliveryPhoneNumbers');
  }

  context.updateState(stateObj);
};

export const getDeliveryTypes = productData => {
  const parcelConnect = productData.find(product => product.code === '109');
  const deliveryTypeBasedOnCountry = {};

  parcelConnect.rulesForCountryAndDeliveryTypes.forEach(deliveryTypeVal => {
    if (deliveryTypeBasedOnCountry.hasOwnProperty([deliveryTypeVal.country.countryCode])) {
      let existingDeliveryTypes = deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode];
      existingDeliveryTypes.push(deliveryTypeVal.deliveryType);
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = existingDeliveryTypes;
    } else {
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = Array.isArray(deliveryTypeVal.deliveryType)
        ? deliveryTypeVal.deliveryType
        : [deliveryTypeVal.deliveryType];
    }
  });

  return deliveryTypeBasedOnCountry;
};

export const getInternationalCountries = products => {
  const internationalShippingCountries = [];

  products.forEach((product, index) => {
    if (!product.isDomestic && product.toCountries) {
      product.toCountries.forEach(toCountry => {
        if (toCountry.country && toCountry.country.customs) {
          internationalShippingCountries.push(toCountry.country);
        }
      });
    }
  });

  return internationalShippingCountries;
};

export const fillReceiverData = (importExport, context, resetCityValidationError = false) => {
  try {
    if (getCookie('addressOrder')) {
      const address = JSON.parse(decodeURIComponent(Buffer.from(getCookie('addressOrder'), 'base64').toString()));
      const { code, number } = preparePhoneNumber(address.phone);
      const phoneNumber = address.phone
        ? [
            {
              code,
              error: false,
              isRequired: true,
              number,
            },
          ]
        : [
            {
              code: config.defaultCountryPhoneCode,
              error: false,
              isRequired: true,
              number: '',
            },
          ];

      if (
        context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
        (importExport === EXPORT_SHIPMENT_TYPE && !context.state.pickupSender.value)
      ) {
        const state = {
          ...context.state,
          pickupCity: { error: false, value: address.address.cityName || '', isRequired: true },
          pickupCountry: {
            error: false,
            value: address.address.countryCode || '',
            comparisonError: false,
            isRequired: true,
          },
          pickupEmail: { error: false, value: address.email || '', isRequired: false },
          pickupName: { error: false, value: address.contactName || '', isRequired: true },
          pickupPostalCode: { error: false, value: address.address.postalCode || '', isRequired: true },
          pickupPhoneNumbers: phoneNumber,
          pickupSender: { error: false, value: address.name || '', isRequired: true },
          pickupStreet: { error: false, value: address.address.street || '', isRequired: true },
          deliveryCity: { value: '', error: false, isRequired: true },
          deliveryCountry: { value: config.defaultCountry, error: false, comparisonError: false, isRequired: true },
          deliveryEmail: { error: false, value: '', isRequired: false },
          deliveryName: { error: false, value: '', isRequired: true },
          deliveryPostalCode: { error: false, value: '', isRequired: true },
          deliveryPhoneNumbers: [
            {
              code: config.defaultCountryPhoneCode,
              error: false,
              isRequired: true,
              number: '',
            },
          ],
          deliveryReceiver: { error: false, value: '', isRequired: true },
          deliveryStreet: { error: false, value: '', isRequired: true },
        };

        context.updateState(state);
      } else if (importExport === IMPORT_SHIPMENT_TYPE && !context.state.deliveryReceiver.value) {
        const state = {
          ...context.state,
          pickupCity: { error: false, value: '', isRequired: true },
          pickupCountry: { error: false, value: '', comparisonError: false, isRequired: true },
          pickupEmail: { error: false, value: '', isRequired: false },
          pickupName: { error: false, value: '', isRequired: true },
          pickupPostalCode: { error: false, value: '', isRequired: true },
          pickupPhoneNumbers: [
            {
              code: config.defaultCountryPhoneCode,
              error: false,
              isRequired: true,
              number: '',
            },
          ],
          pickupSender: { error: false, value: '', isRequired: true },
          pickupStreet: { error: false, value: '', isRequired: true },
          deliveryCity: { error: false, value: address.address.cityName || '' },
          deliveryCountry: {
            error: false,
            value: address.address.countryCode || '',
            comparisonError: false,

            isRequired: true,
          },
          deliveryEmail: { error: false, value: address.email || '', isRequired: false },
          deliveryName: { error: false, value: address.contactName || '', isRequired: true },
          deliveryPostalCode: { error: false, value: address.address.postalCode || '', isRequired: true },
          deliveryPhoneNumbers: phoneNumber,
          deliveryReceiver: { error: false, value: address.name || '', isRequired: true },
          deliveryStreet: { error: false, value: address.address.street || '', isRequired: true },
        };

        context.updateState(state);

        if (resetCityValidationError && typeof resetCityValidationError === 'function') {
          resetCityValidationError();
        }
      }
    }
  } catch {
    return;
  }
};

/**
 * To validate Source/Destination postalcode
 * @param {*} state {pickupCountry, pickupPostalCode, deliveryCountry, deliveryPostalCode}
 * @param {*} isInternational true | false
 *
 */
export const genericPostalCodeValidation = async (state, isInternational = false) => {
  let sourceURL = apiLinks.fetchCityName
    .replace('{countryCode}', state.pickupCountry.value)
    .replace('{postalCode}', state.pickupPostalCode.value);

  if (state.pickupCountry.value === config.defaultCountry) {
    sourceURL = `${sourceURL}/${state.pickupCity.value}`;
  }

  let destinationUrl = apiLinks.fetchCityName
    .replace('{countryCode}', state.deliveryCountry.value)
    .replace('{postalCode}', state.deliveryPostalCode.value);

  if (state.deliveryCountry.value === config.defaultCountry) {
    destinationUrl = `${destinationUrl}/${state.deliveryCity.value}`;
  }

  const resultMap = {
    destinationBookable: false,
    sourceBookable: false,
    internationalSourceCityError: false,
    internationalDestinationCityError: false,
    data: {
      destination: null,
      source: null,
    },
    status: null,
  };

  //For International
  const compareCityName = (city, responseCity) => {
    return !responseCity || city.trim().toUpperCase() !== responseCity.toUpperCase();
  };

  const doApiCall = (type, url) =>
    apiRequest(url, 'GET')
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          const data = result.data;

          if (data && data.validated && data.bookable === undefined) {
            data.bookable = true;
          }

          if (data && type === 'destination') {
            resultMap.destinationBookable = data.bookable;
            resultMap.data.destination = data;
            if (isInternational) {
              resultMap.internationalDestinationCityError = compareCityName(state.deliveryCity.value, result.data.city);
            }
          }

          if (data && type === 'source') {
            resultMap.sourceBookable = data.bookable;
            resultMap.data.source = data;
            if (isInternational) {
              resultMap.internationalSourceCityError = compareCityName(state.pickupCity.value, result.data.city);
            }
          }
        } else {
          resultMap.error = true;
        }
        return result;
      })
      .catch(() => {
        resultMap.error = true;
      });

  const [sourceValidation, destinationValidation] = await Promise.all([
    doApiCall('source', sourceURL),
    doApiCall('destination', destinationUrl),
  ]);

  if (
    sourceValidation &&
    sourceValidation.status === CONST.STATUS_OK &&
    destinationValidation &&
    destinationValidation.status === CONST.STATUS_OK
  ) {
    resultMap.status = CONST.STATUS_OK;
  }
  return resultMap;
};

const requiredLTCDetails = state => {
  const properState = getPropperAPIData(state);
  const map = {};

  map.countryFrom = properState.pickupCountry.value;
  map.zipcodeFrom = properState.pickupPostalCode.value;
  map.countryTo = properState.deliveryCountry.value;
  map.zipcodeTo = properState.deliveryPostalCode.value;

  return map;
};

export const getLTCUrl = urlDetails => {
  const formURL =
    'url' +
    '?country_from=' +
    urlDetails.countryFrom +
    '&zipcode_from=' +
    urlDetails.zipcodeFrom.toUpperCase() +
    '&country_to=' +
    urlDetails.countryTo +
    '&zipcode_to=' +
    urlDetails.zipcodeTo.toUpperCase() +
    '&_=' +
    new Date().getTime().toString();

  return formURL;
};

export const internationalShipmentValidation = async state => {
  const resultMap = { status: false, error: false };
  const urlDetails = requiredLTCDetails(state);

  await internationalLTCPostalCodeValidation(getLTCUrl(urlDetails), resultMap);

  return resultMap;
};

export const internationalLTCPreCal = async url => {
  const products = {};

  await apiRequest(url.replace('url', apiLinks.ltc.precalc), 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        products.status = true;
        products.data = result.data;
      } else {
        products.status = false;
        products.error = true;
      }
    })
    .catch(() => {
      products.error = true;
    });

  return products;
};

const getPossiblePickupDate = ltcPreCalcData => {
  const possiblePickupDate = {};

  ltcPreCalcData.forEach(preCalc => {
    possiblePickupDate[preCalc.product] =
      preCalc.possible_pickups && preCalc.possible_pickups.length > 0 ? preCalc.possible_pickups[0] : false;
  });

  //1st preference to euroconnect possible pickup date
  return possiblePickupDate['ECE'] ? possiblePickupDate['ECE'] : possiblePickupDate['ERA'];
};

export const internationalLTCProducts = async state => {
  const urlDetails = requiredLTCDetails(state);
  const resultMap = {};

  const ltcPreCalc = await internationalLTCPreCal(getLTCUrl(urlDetails));

  if (ltcPreCalc.status && ltcPreCalc.data) {
    let deliveryDateURL = getLTCUrl(urlDetails).replace('url', apiLinks.ltc.commitedLeadtimes);

    deliveryDateURL = deliveryDateURL.replace('zipcode_from', 'zip_from');
    deliveryDateURL = deliveryDateURL.replace('zipcode_to', 'zip_to');
    deliveryDateURL =
      deliveryDateURL + '&customer_pickup_date=' + getPossiblePickupDate(JSON.parse(JSON.stringify(ltcPreCalc.data)));

    const ltcDeliveryDate = await internationalLTCDeliveryTime(deliveryDateURL);

    if (ltcDeliveryDate.status) {
      resultMap.status = true;
      resultMap.data = mergeDeliveryDatePickupDateForProducts(ltcPreCalc.data, ltcDeliveryDate.data);
    } else if (ltcDeliveryDate.error) {
      //CR-54
      resultMap.ltcFailed = true;
      resultMap.error = true;
    }
  } else if (ltcPreCalc.error) {
    resultMap.error = true;
    resultMap.ltcFailed = true;
  }

  return resultMap;
};

const mergeDeliveryDatePickupDateForProducts = (source, target) => {
  target.forEach(prod => {
    prod.product = prod.product_code;
  });

  const newSource = source.filter(sourceValue =>
    target.some(targetValue => targetValue.product === sourceValue.product),
  );

  let mergedValues = DeepMerge(target, newSource);

  if (source.some(sourceValue => !target.some(targetValue => targetValue.product === sourceValue.product))) {
    mergedValues = mergedValues.concat(
      source.filter(sourceValue => target.some(targetValue => targetValue.product !== sourceValue.product)),
    );
  }

  return mergedValues;
};

export const findCustomBasedCountry = (state, selectedToCountry) => {
  const toCountries = state.internationalShippingCountries;

  const customsCountry = toCountries.find(toCountry => {
    return toCountry.countryCode === selectedToCountry;
  });

  return customsCountry;
};

export const internationalLTCDeliveryTime = async url => {
  const products = {};

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        products.status = true;
        products.data = result.data;
      } else {
        products.status = false;
        products.error = true;
      }
    })
    .catch(() => {
      products.error = true;
    });

  return products;
};

export const internationalLTCPostalCodeValidation = async (url, resultMap) => {
  await apiRequest(url.replace('url', apiLinks.ltc.zipvalidator), 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      if (!Array.isArray(result.data)) {
        resultMap.status = true;
        resultMap.data = result.data;
      }
    } else if (result.status === CONST.BAD_REQUEST_ERROR) {
      if (result.data.length > 0) {
        result.data.forEach(data => {
          switch (data.code) {
            case -1:
              resultMap.sourcePostalCode = true;
              break;
            case -2:
              resultMap.destinationPostalCode = true;
              break;
            default:
              return;
          }
        });
      } else {
        resultMap.error = true;
      }
    } else {
      resultMap.error = true;
    }
  });

  return resultMap;
};

export const clearDeliveryLocation = context => {
  const obj = {
    deliveryReceiver: { value: '', error: false },
    deliveryEmail: { value: '', error: false },
    deliveryCountry: { value: config.defaultCountry, error: false },
    deliveryCompany: { value: '', error: false },
    deliveryVatNumber: { value: '', error: false },
    deliveryName: { value: '', error: false },
    deliveryStreet: { value: '', error: false },
    deliveryPostalCode: { value: '', error: false },
    deliveryCity: { value: '', error: false },
    deliveryPhoneNumbers: [{ code: config.defaultCountryPhoneCode, number: '', error: '' }],
    deliveryReceiverReference: { value: '', error: false },
  };

  context.updateState(obj);
};

export const payerCode = opts => {
  if (opts.isInternational && opts.isExport && opts.isReceiverPayer) return 'case1';
  if (opts.isInternational && opts.isExport && opts.isSenderPayer) return 'case2';
  if (opts.isInternational && opts.isExport && opts.isThirdPartyPayer) return 'case3';
  if (opts.isInternational && opts.isImport && opts.isReceiverPayer) return 'case4';
  if (opts.isInternational && opts.isImport && opts.isThirdPartyPayer) return 'case5';
  if (!opts.isInternational && opts.isSenderPayer) return 'case6';
  if (!opts.isInternational && opts.isReceiverPayer) return 'case7';
  if (!opts.isInternational && opts.isThirdPartyPayer) return 'case8';
  if (opts.isInternational && opts.isImport && opts.isSenderPayer) return 'case9';
};

export const readState = state => {
  const isDomestic = state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE,
    isInternational = state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE,
    isImport = state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE,
    isExport = state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE,
    isSenderPayer = state.shipmentPayer.value === SENDER_PAYER,
    isReceiverPayer = state.shipmentPayer.value === RECEIVER_PAYER,
    isThirdPartyPayer = state.shipmentPayer.value === THIRD_PARTY_PAYER;

  return {
    isDomestic,
    isInternational,
    isImport,
    isExport,
    isSenderPayer,
    isReceiverPayer,
    isThirdPartyPayer,
  };
};
const getUpdatedState = (casePayerCode, state, stateObj) => {
  if (casePayerCode === 'case1' || casePayerCode === 'case2') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false };
    stateObj.receiverNumber = { value: '', error: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case3') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false };
    stateObj.receiverNumber = { value: '', error: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false };
  } else if (casePayerCode === 'case4') {
    stateObj.accountNumber = { value: '', error: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case5') {
    stateObj.accountNumber = { value: '', error: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false };
  } else if (casePayerCode === 'case6') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false };
    stateObj.receiverNumber = { value: '', error: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case7') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case8') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false };
    stateObj.receiverNumber = { value: '', error: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false };
  }

  return stateObj;
};

export const displayDropdownWithPayersWithoutSender = (context, params) => {
  const exportImport = params !== undefined ? params.value : context.state.shipmentTypesImpOrExp.value;

  return context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && exportImport === IMPORT_SHIPMENT_TYPE;
};

export const checkSenderPayer = (context, params) => {
  const condition = displayDropdownWithPayersWithoutSender(context, params);
  const map = {};

  if (condition) {
    if (context.state.shipmentPayer.value === SENDER_PAYER) {
      map.shipmentPayer = {};
      map.deliveryAddressResidential = {};
      map.shipmentPayer.value = RECEIVER_PAYER;
      map.deliveryAddressResidential.value = false;
    }
  }
  map.prevShipmentTypesImpOrExp = { value: context.state.shipmentTypesImpOrExp.value };
  context.updateState(map);
};

export const termsOfDeliveryValue = (context, stateObj, deliveryCountry) => {
  const opts = readState(context.state);
  const casePayerCode = payerCode(opts);

  if (casePayerCode !== 'case9') {
    getUpdatedState(casePayerCode, context.state, stateObj);
  }

  stateObj['termsOfDelivery'] = {};
  stateObj['termsOfDelivery'].value = '';
  stateObj['termsOfDelivery'].error = false;
  stateObj['termsOfDelivery'].isRequired = true;

  return stateObj;
};

export const updatePostalCode = (state, pickupCountry, deliveryCountry, stateObj) => {
  if (config.numberPostalCodeCountries.indexOf(pickupCountry) !== -1) {
    if (!testRegEx(state.pickupPostalCode.value, config.regEx.number)) {
      stateObj['pickupPostalCode'] = JSON.parse(JSON.stringify(state.pickupPostalCode));
      stateObj['pickupPostalCode'].value = '';
    }

    if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
      if (!testRegEx(state.differentPickupPostalCode.value, config.regEx.number)) {
        stateObj['differentPickupPostalCode'] = JSON.parse(JSON.stringify(state.differentPickupPostalCode));
        stateObj['differentPickupPostalCode'].value = '';
      }
    }
  }
  if (config.numberPostalCodeCountries.indexOf(deliveryCountry) !== -1) {
    if (!testRegEx(state.deliveryPostalCode.value, config.regEx.number)) {
      stateObj['deliveryPostalCode'] = JSON.parse(JSON.stringify(state.deliveryPostalCode));
      stateObj['deliveryPostalCode'].value = '';
    }

    if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
      if (!testRegEx(state.differentDeliveryPostalCode.value, config.regEx.number)) {
        stateObj['differentDeliveryPostalCode'] = JSON.parse(JSON.stringify(state.differentDeliveryPostalCode));
        stateObj['differentDeliveryPostalCode'].value = '';
      }
    }
  }
};

export const contactEmailMandatoryForInternational = (stateObj, context) => {
  let caseTxt = '';
  const { state } = context;
  const isInternational = context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE;

  const internationalPickupAdd = isInternational && state.pickupAddressResidential.value;
  const internationalDeliveryAdd = isInternational && state.deliveryAddressResidential.value;
  const internationalDiffPickupAdd =
    isInternational && state.pickupFromDifferentAddress.value && state.differentPickupAddressResidential.value;
  const internationalDiffDeliveryAdd =
    isInternational && state.deliverToDifferentAddress.value && state.differentDeliveryAddressResidential.value;

  if (internationalPickupAdd && internationalDeliveryAdd) caseTxt = 'case1_2';
  else if (internationalDiffDeliveryAdd && internationalDiffPickupAdd) caseTxt = 'case3_4';
  else if (internationalPickupAdd) caseTxt = 'case1';
  else if (internationalDeliveryAdd) caseTxt = 'case2';
  else if (internationalDiffPickupAdd) caseTxt = 'case3';
  else if (internationalDiffDeliveryAdd) caseTxt = 'case4';

  stateObj.pickupEmail = JSON.parse(JSON.stringify(context.state.pickupEmail));
  stateObj.deliveryEmail = JSON.parse(JSON.stringify(context.state.deliveryEmail));
  stateObj.differentPickupEmail = JSON.parse(JSON.stringify(context.state.differentPickupEmail));
  stateObj.differentDeliveryEmail = JSON.parse(JSON.stringify(context.state.differentDeliveryEmail));

  switch (caseTxt) {
    case 'case1':
      stateObj.pickupEmail.isRequired = true;
      stateObj.deliveryEmail.isRequired = false;
      break;
    case 'case2':
      stateObj.deliveryEmail.isRequired = true;
      stateObj.pickupEmail.isRequired = false;
      break;
    case 'case3':
      stateObj.differentPickupEmail.isRequired = true;
      stateObj.differentDeliveryEmail.isRequired = false;
      break;
    case 'case4':
      stateObj.differentDeliveryEmail.isRequired = true;
      stateObj.differentPickupEmail.isRequired = false;
      break;
    case 'case3_4':
      stateObj.differentPickupEmail.isRequired = true;
      stateObj.differentDeliveryEmail.isRequired = true;
      break;
    case 'case1_2':
      stateObj.pickupEmail.isRequired = true;
      stateObj.deliveryEmail.isRequired = true;
      break;
    default:
      stateObj.pickupEmail.isRequired = false;
      stateObj.pickupEmail.error = false;
      stateObj.deliveryEmail.isRequired = false;
      stateObj.deliveryEmail.error = false;
      stateObj.differentDeliveryEmail.isRequired = false;
      stateObj.differentDeliveryEmail.error = false;
      stateObj.differentPickupEmail.isRequired = false;
      stateObj.differentPickupEmail.error = false;
  }
};

export const checkContactEmail = context => {
  const stateObj = {};

  contactEmailMandatoryForInternational(stateObj, context);

  context.updateState(stateObj);
};

export const getPayerCodeCheck = (context, params) => {
  const stateObj = {};

  if (
    context.state.shipmentTypesImpOrExp.value !== context.state.prevShipmentTypesImpOrExp.value &&
    (!context.state.deliveryReceiver.value || !context.state.pickupSender.value)
  ) {
    Object.assign(stateObj, swapSenderAndReceiverAddress(context));
  }

  termsOfDeliveryValue(context, stateObj, context.state.deliveryCountry.value);

  if (params.name === 'shipmentRange' || params.name === 'shipmentPayer') {
    contactEmailMandatoryForInternational(stateObj, context);
  }

  if (params.name === 'shipmentRange' && params.value === DOMESTIC_SHIPMENT_RANGE) {
    Object.assign(stateObj, resetPhoneNumberWhenSwitchedToDomestic(context.state));
  }

  context.updateState(stateObj);
};

export const checkSubmit = context => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  let state = JSON.parse(JSON.stringify(context.state));
  const getState = readState(state);

  validate = ValidateAccountNumber(state, validate.stateToSet, validate.hasErrors);

  validate = EnterPickupLocationValidations(state, validate.stateToSet, validate.hasErrors, 'pickup', getState);

  validate = EnterDeliveryLocationValidations(state, validate.stateToSet, validate.hasErrors, 'delivery', getState);

  if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    validate = EnterDeliveryLocationValidations(
      state,
      validate.stateToSet,
      validate.hasErrors,
      'differentDelivery',
      getState,
    );
  }

  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    validate = EnterPickupLocationValidations(
      state,
      validate.stateToSet,
      validate.hasErrors,
      'differentPickup',
      getState,
    );
  }

  context.extendedSetState(validate.stateToSet);

  return validate.hasErrors;
};

export const setAccountInfo = (casePayerCode, state, stateObj) => {
  if (casePayerCode === 'case1' || casePayerCode === 'case2') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true };
    stateObj.receiverNumber = { value: '', error: false, display: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case3' || casePayerCode === 'case8') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true };
    stateObj.receiverNumber = { value: '', error: false, display: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true };
  } else if (casePayerCode === 'case4') {
    stateObj.accountNumber = { value: '', error: false, display: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  } else if (casePayerCode === 'case5') {
    stateObj.accountNumber = { value: '', error: false, display: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true };
  } else if (casePayerCode === 'case6') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true };
    stateObj.receiverNumber = { value: '', error: false, display: false };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  } else if (casePayerCode === 'case7') {
    [stateObj.accountNumber, stateObj.receiverNumber] = domesticAndReceiverPay(state);
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  }

  stateObj.accountNumber.isRequired =
    state.shipmentPayer.value === SENDER_PAYER ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      state.shipmentPayer.value === RECEIVER_PAYER &&
      !state.pickupAddressResidential.value) ||
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE) ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === THIRD_PARTY_PAYER);

  stateObj.receiverNumber.isRequired =
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE) ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === RECEIVER_PAYER);

  stateObj.thirdPartyNumber.isRequired = state.shipmentPayer.value === THIRD_PARTY_PAYER;

  // set disabled and error to false
  stateObj.accountNumber.disabled =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.pickupAddressResidential.value ? true : false;
  stateObj.accountNumber.error = false;

  stateObj.receiverNumber.disabled = false;
  stateObj.receiverNumber.error = false;

  stateObj.thirdPartyNumber.disabled = false;
  stateObj.thirdPartyNumber.error = false;
};

const domesticAndReceiverPay = state => {
  const stateToSet = {};

  stateToSet.accountNumber = { value: state.accountNumber.value, error: false, display: true };
  stateToSet.receiverNumber = { value: state.receiverNumber.value, error: false, display: true };

  return [stateToSet.accountNumber, stateToSet.receiverNumber];
};

export const renderDifferentDeliveryAddress = (
  context,
  t,
  state,
  checkAppropriateCity,
  setAppropriateCity,
  checkIfEnteredAndSuggestedCityIsValid,
) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t(`general|Delivery Address`)}</h4>
      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Delivery residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentDeliveryAddressResidential"
          isRequired={false}
          checked={context.state.differentDeliveryAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={checkContactEmail}
          disabled={context.state.shipmentPayer.value === RECEIVER_PAYER}
        />
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Receiver')}
            name="differentDeliveryReceiver"
            dataTestId="differentDeliveryReceiverInp"
            isRequired={true}
            value={context.state.differentDeliveryReceiver.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />

          {context.state.differentDeliveryReceiver.error ? (
            <Error
              name="differentDeliveryReceiver"
              className="frc__input--error"
              message={
                context.state.differentDeliveryAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentDeliveryStreet"
              isRequired={true}
              value={context.state.differentDeliveryStreet.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentDeliveryStreet.error ? (
              <Error
                name="differentDeliveryStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div
            className=" l-grid--w-100pc-s l-grid--w-50pc-m"
            onBlur={
              context.state.differentDeliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                ? () =>
                    checkAppropriateCity(
                      'differentDeliveryCountry',
                      'differentDeliveryPostalCode',
                      'differentDeliveryCity',
                      'destinationPostalCodeError',
                      'destinationCityError',
                    )
                : null
            }
          >
            <PostalCodeInput
              value={context.state.differentDeliveryPostalCode.value}
              name="differentDeliveryPostalCode"
              isRequired
              countryCode={context.state.deliveryCountry.value}
              error={
                context.state.differentDeliveryPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : state.cityValidationError?.differentDeliveryCity?.postalCodeError
                  ? POSTALCODE_ERRORS.cityPostalCodeError
                  : context.state?.deliverToDifferentAddress?.value && state?.destinationPostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentDeliveryCity"
              isRequired={true ? true : false}
              value={context.state.differentDeliveryCity.value}
              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
              afterUpdate={
                state.cityValidationError.differentDeliveryCity
                  ? () => checkIfEnteredAndSuggestedCityIsValid('differentDeliveryCity')
                  : null
              }
              afterChangeOnBlur={
                !(
                  state.cityValidationError.differentDeliveryCity &&
                  state.cityValidationError.differentDeliveryCity.mismatchError
                ) &&
                context.state.differentDeliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                context.state.differentDeliveryCountry.value === config.defaultCountry
                  ? () =>
                      checkAppropriateCity(
                        'differentDeliveryCountry',
                        'differentDeliveryPostalCode',
                        'differentDeliveryCity',
                        'destinationPostalCodeError',
                        'destinationCityError',
                      )
                  : null
              }
            />
            {context.state.differentDeliveryCity.error ? (
              <Error
                name="differentDeliveryCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : state.cityValidationError.differentDeliveryCity &&
              state.cityValidationError.differentDeliveryCity.mismatchError ? (
              <div className="l-grid">
                <Error
                  name="cityMismtachError"
                  className="frc__input--error"
                  message={t('general|errors|invalidCity')}
                />
                <span className="frc__city-suggestion">
                  {'"'}
                  <span
                    className="frc__city-underline"
                    onClick={() => setAppropriateCity(false, 'differentDeliveryCity', 'destinationCityError')}
                  >
                    {state.cityValidationError.differentDeliveryCity.value}
                  </span>
                  {'" ?'}
                </span>
              </div>
            ) : context.state.deliverToDifferentAddress.value ? (
              state.destinationCityError && (
                <Error
                  name="deliverToDifferentAddressdestinationCityError"
                  className="frc__input--error"
                  message={t('general|errors|City API Error', {
                    max: config.maxDefaultInputLength,
                  })}
                />
              )
            ) : (
              ''
            )}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentDeliveryCountry"
            isRequired={true}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentDeliveryCountry.value}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentDeliveryCountry.error ? (
            <Error name="differentDeliveryCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentDeliveryName"
            isRequired={true}
            value={context.state.differentDeliveryName.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentDeliveryName.error ? (
            <Error
              name="differentDeliveryName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentDeliveryEmail"
            value={context.state.differentDeliveryEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
            isRequired={context.state.differentDeliveryEmail.isRequired}
          />
          {context.state.differentDeliveryEmail.error ? (
            <Error
              name="differentDeliveryEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentDeliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumber
              key={ind}
              context={context}
              config={config}
              CONST={CONST}
              index={ind}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentDeliveryPhoneNumbers"
              isRequired={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const renderDifferentPickUpAddress = (
  context,
  t,
  state,
  checkAppropriateCity,
  setAppropriateCity,
  checkIfEnteredAndSuggestedCityIsValid,
) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t('general|Pickup Address')}</h4>
      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Pickup residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentPickupAddressResidential"
          isRequired={false}
          checked={context.state.differentPickupAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={checkContactEmail}
          disabled={context.state.shipmentPayer.value === SENDER_PAYER}
        />
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Sender')}
            name="differentPickupSender"
            isRequired={true}
            value={context.state.differentPickupSender.value}
            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />

          {context.state.differentPickupSender.error ? (
            <Error
              name="differentPickupSender"
              className="frc__input--error"
              message={
                context.state.differentPickupAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>
        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentPickupStreet"
              isRequired={true}
              value={context.state.differentPickupStreet.value}
              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentPickupStreet.error ? (
              <Error
                name="differentPickupStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div
            className=" l-grid--w-100pc-s l-grid--w-50pc-m"
            onBlur={
              context.state.differentPickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                ? () =>
                    checkAppropriateCity(
                      'differentPickupCountry',
                      'differentPickupPostalCode',
                      'differentPickupCity',
                      'sourcePostalCodeError',
                      'sourceCityError',
                    )
                : null
            }
          >
            <PostalCodeInput
              value={context.state.differentPickupPostalCode.value}
              name="differentPickupPostalCode"
              isRequired
              countryCode={context.state.pickupCountry.value}
              error={
                context.state.differentPickupPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : state.cityValidationError?.differentPickupCity?.postalCodeError
                  ? POSTALCODE_ERRORS.cityPostalCodeError
                  : context.state?.pickupFromDifferentAddress?.value && state?.sourcePostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentPickupCity"
              isRequired={true}
              value={context.state.differentPickupCity.value}
              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
              afterUpdate={
                state.cityValidationError.differentPickupCity
                  ? () => checkIfEnteredAndSuggestedCityIsValid('differentPickupCity')
                  : null
              }
              afterChangeOnBlur={
                !(
                  state.cityValidationError.differentPickupCity &&
                  state.cityValidationError.differentPickupCity.mismatchError
                ) &&
                context.state.differentPickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                context.state.differentPickupCountry.value === config.defaultCountry
                  ? () =>
                      checkAppropriateCity(
                        'differentPickupCountry',
                        'differentPickupPostalCode',
                        'differentPickupCity',
                        'sourcePostalCodeError',
                        'sourceCityError',
                      )
                  : null
              }
            />
            {context.state.differentPickupCity.error ? (
              <Error
                name="differentPickupCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : state.cityValidationError.differentPickupCity &&
              state.cityValidationError.differentPickupCity.mismatchError ? (
              <div className="l-grid">
                <Error
                  name="cityMismtachError"
                  className="frc__input--error"
                  message={t('general|errors|invalidCity')}
                />
                <span className="frc__city-suggestion">
                  {'"'}
                  <span
                    className="frc__city-underline"
                    onClick={() => setAppropriateCity(false, 'differentPickupCity', 'sourceCityError')}
                  >
                    {state.cityValidationError.differentPickupCity.value}
                  </span>
                  {'" ?'}
                </span>
              </div>
            ) : context.state.pickupFromDifferentAddress.value ? (
              state.sourceCityError && (
                <Error
                  name="pickupFromDifferentAddresssourceCityError"
                  className="frc__input--error"
                  message={t('general|errors|City API Error', {
                    max: config.maxDefaultInputLength,
                  })}
                />
              )
            ) : (
              ''
            )}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentPickupCountry"
            isRequired={true}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentPickupCountry.value}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentPickupCountry.error ? (
            <Error name="differentPickupCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentPickupName"
            isRequired={true}
            value={context.state.differentPickupName.value}
            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentPickupName.error ? (
            <Error
              name="differentPickupName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentPickupEmail"
            isRequired={context.state.differentPickupEmail.isRequired}
            value={context.state.differentPickupEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
          />
          {context.state.differentPickupEmail.error ? (
            <Error
              name="differentPickupEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentPickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumber
              key={ind}
              index={ind}
              context={context}
              config={config}
              CONST={CONST}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentPickupPhoneNumbers"
              isRequired={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};
