import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Delete, LocalShippingOutlined, ReceiptOutlined } from '@mui/icons-material';
import { ROUTES } from '../../../router/routes';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { StyledTableCell, StyledTableRow } from './StyledTable';
import { isValidCountryLanguage } from '../../../utils/urls';

export const PalletAccountTable = ({ accounts, setDeleteDialog, showActionButtons }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToPalletPickupOrTransactionPage = ({ account, pageURL }) => {
    const localeUrl = localStorage.getItem('i18nextLng');

    const url = '/' + localeUrl + '/' + pageURL;
    const params = `?${createSearchParams({ accountNumber: [account.palletAccount] })}`;

    if (isValidCountryLanguage(localeUrl)) {
      navigate({
        pathname: url,
        search: params,
      });
    }
  };

  return (
    <>
      {accounts.length === 0 ? (
        <Box my="16px">---</Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t(`editProfile|Pallet account number`)}</StyledTableCell>
                <StyledTableCell align="left">{t('editProfile|Pallet Balance')}</StyledTableCell>
                {showActionButtons ? <StyledTableCell></StyledTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map(item => (
                <StyledTableRow key={`${item.palletAccount}`}>
                  <StyledTableCell component="th" scope="row">
                    <strong>{item.palletAccount}</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">{item.palletBalance?.balance || null}</StyledTableCell>
                  {showActionButtons ? (
                    <StyledTableCell align="right">
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            maxWidth: '300px',
                          }}
                        >
                          <Button
                            startIcon={<LocalShippingOutlined />}
                            id={`orderPalletPickupBtn${item.palletAccount}`}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              navigateToPalletPickupOrTransactionPage({ pageURL: ROUTES.palletPickup, account: item })
                            }
                            sx={{
                              justifyContent: 'flex-start',
                            }}
                            dataTestId="orderPalletPickupBtn"
                          >
                            {t('general|labels|buttons|Order Pallet Pickup')}
                          </Button>
                          <Button
                            startIcon={<ReceiptOutlined />}
                            id={`removePalletAccount${item.palletAccount}`}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              navigateToPalletPickupOrTransactionPage({ pageURL: ROUTES.palletHistory, account: item })
                            }
                            sx={{
                              justifyContent: 'flex-start',
                            }}
                            dataTestId="viewTransactionsBtn"
                          >
                            {t('editProfile|View Transactions')}
                          </Button>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <IconButton
                            size="small"
                            color="error"
                            sx={{
                              '& svg': {
                                fontSize: '26px',
                              },
                            }}
                            onClick={() => {
                              setDeleteDialog({ ...item, open: true });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    </StyledTableCell>
                  ) : null}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
