import React from 'react';
import { Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useController, UseFormReturn, Path, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PROFILE_CONSTANTS } from './edit-profile/constants';
import { PhoneCountryAutoComplete } from './PhoneCountryAutocomplete';

interface PhoneFormFieldsProps<TField extends FieldValues> {
  phoneCountryField: Path<TField>;
  phoneNumberField: Path<TField>;
  formContext: UseFormReturn<TField, any, undefined>;
}

export const PhoneFormFields = <TField extends FieldValues>({
  phoneCountryField,
  phoneNumberField,
  formContext,
}: PhoneFormFieldsProps<TField>) => {
  const { t } = useTranslation();
  const { control, watch } = formContext;

  const watchCompanyPhoneCountryCode = watch(phoneCountryField);

  const companyPhoneNumberMaxLength =
    PROFILE_CONSTANTS.companyPhoneNumberLimit - (watchCompanyPhoneCountryCode?.phone?.length || 0);

  const {
    field: phoneCountryFieldProps,
    fieldState: { invalid: phoneCountryInvalid, error: phoneCountryError, isTouched: phoneCountryIsTouched },
  } = useController({
    name: phoneCountryField,
    control,
    rules: { required: t('general|labels|inputs|required') },
  });

  const {
    field: phoneNumberFieldProps,
    fieldState: { invalid: phoneNumberInvalid, error: phoneNumberError, isTouched: phoneNumberIsTouched },
  } = useController({
    name: phoneNumberField,
    control,
    rules: {
      required: true,
      maxLength: companyPhoneNumberMaxLength,
      pattern: /^(((\d|\(\d{1,4}\))[ /.-]?)+(\d|\(\d{1,4}\)))*$/,
    },
  });

  const getPhoneNumberError = () => {
    if (phoneNumberError) {
      if (phoneNumberError.type === 'maxLength') {
        return t('general|errors|Invalid maxchar error', {
          name: t('registration|yourPhoneNumber'),
          max: companyPhoneNumberMaxLength,
        });
      }
      if (phoneNumberError.type === 'required') {
        return t('general|labels|inputs|required');
      }
      if (phoneNumberError.type === 'pattern') {
        return t('general|labels|inputs|invalidFormat');
      }
    }
    return null;
  };

  return (
    <Grid container>
      <Grid xs={12} sm={3}>
        <Stack direction="column">
          <InputLabel required sx={{ fontSize: '15px' }} error={phoneCountryInvalid && phoneCountryIsTouched}>
            {t('general|Country code')}
          </InputLabel>
          <PhoneCountryAutoComplete
            phoneCountryFieldProps={phoneCountryFieldProps}
            phoneCountryError={phoneCountryError}
          />
        </Stack>
      </Grid>
      <Grid xs={12} sm={9}>
        <Stack direction="column">
          <InputLabel required sx={{ fontSize: '15px' }} error={phoneNumberInvalid && phoneNumberIsTouched}>
            {t('general|labels|inputs|Phone number')}
          </InputLabel>
          <TextField
            {...phoneNumberFieldProps}
            variant="outlined"
            onChange={event => {
              const targetValue = event.target.value;
              const newValue =
                targetValue?.length > 0 ? targetValue.slice(0, companyPhoneNumberMaxLength) : targetValue;
              const noSpacesValue = newValue?.replace(/\s/g, '');
              phoneNumberFieldProps.onChange(noSpacesValue);
            }}
            required
            sx={{ width: '100%' }}
            error={!!phoneNumberError}
            helperText={getPhoneNumberError()}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
