import { useCountryLanguage } from 'hooks/useCountryLanguage';
import { useGetCountryConfigQuery } from 'store/api/fcpConfig';

export const useGetCountryConfig = () => {
  const { country: countryCode } = useCountryLanguage();
  if (!countryCode) {
    throw new Error('Country code is missing');
  }

  return useGetCountryConfigQuery({ countryCode });
};
