import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMoveOnHoldShipmentsMutation, useReleaseOnHoldShipmentMutation } from 'store/api/fcpBooking';
import { useCallback, useEffect, useState } from 'react';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';
import { useAppSelector } from 'store/hooks';
import { AccountFilter } from './AccountFilter';
import { BookingDetail } from './BookingDetail';
import { GlobalLoader } from '../GlobalLoader';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router';
import { setCookie } from 'globals/utils/requests';
import { GLOBAL_CONST, globalConfig } from 'globals/data-config-global';
import { BulkLimitWarningDialog } from '../bulk-booking-popups/BulkLimitWarningDialog';
import { OnholdOrdersTable } from './OnholdOrdersTable';
import { useOnholdOrders } from './useOnholdOrders';

export const OnholdOrders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLimitErrorDialog, setOpenLimitErrorDialog] = useState(false);
  const [openLimitMovedDialog, setOpenLimitMovedDialog] = useState(false);
  const [detailRequestId, setDetailRequestId] = useState<string | null>(null);
  const [confirmationDialogData, setConfirmationDialogData] = useState<{
    open: boolean;
    onConfirm: () => void;
  }>({ open: false, onConfirm: () => {} });

  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const { onHoldList, isBulkBookingDisabled, isSingleMoveDisabled } = useOnholdOrders();
  const [releaseOnHold, { isLoading: releaseInProgress }] = useReleaseOnHoldShipmentMutation();
  const [moveOnHold, { isLoading: moveInProgress }] = useMoveOnHoldShipmentsMutation();

  useEffect(() => {
    if (onHoldList) {
      if (isBulkBookingDisabled) {
        setOpenLimitErrorDialog(true);
      }
      if (isSingleMoveDisabled) {
        setOpenLimitMovedDialog(true);
      }
    }
  }, [onHoldList, isBulkBookingDisabled, isSingleMoveDisabled]);

  const handleRelease = useCallback(
    (requestId: string) => {
      setConfirmationDialogData({
        open: true,
        onConfirm: () => {
          releaseOnHold({ accountCountryCode, requestId });
          setDetailRequestId(null);
        },
      });
    },
    [accountCountryCode, releaseOnHold],
  );

  const handleMove = useCallback(
    (requestId: string) => {
      setConfirmationDialogData({
        open: true,
        onConfirm: () => {
          moveOnHold({ accountCountryCode, requestIds: [requestId] });
          setDetailRequestId(null);
        },
      });
    },
    [accountCountryCode, moveOnHold],
  );

  const handleBulkMove = useCallback(
    (requestIds: string[]) => {
      setConfirmationDialogData({
        open: true,
        onConfirm: () => {
          moveOnHold({ accountCountryCode, requestIds: requestIds });
          setDetailRequestId(null);
        },
      });
    },
    [accountCountryCode, moveOnHold],
  );

  const handleBook = useCallback(
    async (requestId: string) => {
      try {
        const result = await moveOnHold({ accountCountryCode, requestIds: [requestId] }).unwrap();
        const bookingId = result.shipments?.[0]?.id;
        if (!bookingId) {
          throw new Error('No booking ID returned');
        }
        setCookie(globalConfig.portalOrderCookie, bookingId, GLOBAL_CONST.SECONDS_PER_MINUTE, 's');
        navigate(`../${ROUTES.portalOrder}`);
      } catch {
        setDetailRequestId(null);
      }
    },
    [accountCountryCode, moveOnHold, navigate],
  );

  const anyActionInProgress = releaseInProgress || moveInProgress;

  return (
    <Box p={2} display="flex" flexDirection="column">
      <Typography variant="h1">{t('general|onholdOrders|pageTitle')}</Typography>
      <Box display="flex" gap={2}>
        <AccountFilter />
        <Box sx={{ flexGrow: 1 }} overflow="auto">
          <OnholdOrdersTable
            data={onHoldList?.shipments ?? []}
            onDetailOpen={setDetailRequestId}
            onRelease={handleRelease}
            onMove={handleMove}
            onBulkMove={handleBulkMove}
            onBook={handleBook}
          />
        </Box>
      </Box>
      {/* Popups */}
      {anyActionInProgress && <GlobalLoader sx={{ background: 'rgba(0, 0, 0, 0.5)' }} />}

      <BulkLimitWarningDialog
        open={openLimitMovedDialog}
        onClose={() => {
          setOpenLimitMovedDialog(false);
        }}
        title={t('general|limitDialogMovedTitle')}
        description={t('general|limitDialogMovedDescription', {
          current: onHoldList?.limits.counts.DOWNLOADED_MOVED.current,
          maxAllowed: onHoldList?.limits.counts.DOWNLOADED_MOVED.maxAllowed,
        })}
      />

      <BulkLimitWarningDialog
        open={openLimitErrorDialog}
        onClose={() => {
          setOpenLimitErrorDialog(false);
        }}
        title={t('general|limitDialogErrorTitle')}
        description={t('general|limitDialogErrorDescription', {
          current: onHoldList?.limits.counts.BULK_ERROR.current,
          maxAllowed: onHoldList?.limits.counts.BULK_ERROR.maxAllowed,
        })}
      />

      <ConfirmationDialog
        open={confirmationDialogData.open}
        title={t('general|confirmActionTitle')}
        dialogContent={
          <Typography variant="body1" textAlign="center">
            {t('general|confirmActionText')}
          </Typography>
        }
        onConfirm={confirmationDialogData.onConfirm}
        confirmLabel={t('general|Yes')}
        cancelLabel={t('general|No')}
        onClose={() => setConfirmationDialogData({ open: false, onConfirm: () => {} })}
      />

      {detailRequestId && (
        <BookingDetail
          onClose={() => setDetailRequestId(null)}
          onMoveShipment={() => handleMove(detailRequestId)}
          onBookShipment={() => handleBook(detailRequestId)}
          detailRequestId={detailRequestId}
        />
      )}
    </Box>
  );
};
