import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { globalConfig } from 'globals/data-config-global';
import { useAppSelector } from 'store/hooks';
import { selectApprovedTmsAccounts, useGetUserDataQuery, useRemoveTmsAccountMutation } from 'store/api/fcpUser';
import {
  Alert,
  Box,
  Paper,
  Stack,
  List,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ChatWithUs } from '../ChatWithUs';
import { toast } from 'react-toastify';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { StyledTableCell } from './StyledTable';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { useQueryClient } from '@tanstack/react-query';

export const ApprovedAccounts = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [confirmationDialogData, setConfirmationDialogData] = useState<{
    open: boolean;
    accountNumber: null | string;
    onConfirm: () => void;
  }>({
    open: false,
    accountNumber: null,
    onConfirm: () => {},
  });

  const { isFetching: userRefetching } = useGetUserDataQuery();
  const [removeAccount, { isLoading, isError, isSuccess }] = useRemoveTmsAccountMutation();
  const approvedAccounts = useAppSelector(selectApprovedTmsAccounts);

  const deleteInProgress = isLoading || userRefetching;

  const handleDelete = ({ accountNumber, accountReference }: { accountNumber: string; accountReference?: string }) => {
    setConfirmationDialogData({
      open: true,
      accountNumber: accountNumber,
      onConfirm: () =>
        removeAccount({
          tmsAccounts: [{ accountNumber, accountReference }],
        }),
    });
  };

  useEffect(() => {
    if (!isError) return;
    toast(
      <Alert severity="error" variant="standard">
        {`${t(`editProfile|Account removal failed. Try again or call technical support`)} ${t('general|or')} `}
        <ChatWithUs />
      </Alert>,
    );
  }, [isError, t]);

  useEffect(() => {
    if (!isSuccess) return;
    toast(
      <Alert severity="success" variant="standard">
        {t(`editProfile|TMS Account has been removed`)}
      </Alert>,
    );
    // todo: remove once RTK is used everywhere
    queryClient.invalidateQueries([userQueryKey]);
  }, [isSuccess, queryClient, t]);

  if (!approvedAccounts?.length) {
    return <Box py={2}>---</Box>;
  }

  return (
    <>
      <Typography variant="body1" mb="16px">
        {t(`editProfile|DHL Freight account number(s) approved`)}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ whiteSpace: 'nowrap' }} width={200}>
                {t('registration|accountNumber')}
              </StyledTableCell>
              <StyledTableCell align="left">{t('editProfile|Account products')}</StyledTableCell>
              <StyledTableCell width={200}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {approvedAccounts.map((account, index) => {
              return (
                <TableRow key={`${account.accountNumber}`}>
                  <StyledTableCell component="th" scope="row">
                    <Stack>
                      <strong data-testid={`${index}-item-account-number`}>{account.accountNumber}</strong>
                      {account.accountReference ? <>({account.accountReference})</> : null}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <List
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {account.products
                        .filter(
                          product => !globalConfig.productsFilteredAtProductSelection.includes(product.productCode),
                        )
                        .map(product => (
                          <Box
                            key={product.productCode}
                            component="li"
                            sx={{
                              flexBasis: '250px',
                              marginLeft: '20px',
                            }}
                            data-testid={`${product.productCode}-text`}
                          >
                            {t(`products|${product.productCode}|name`)}
                          </Box>
                        ))}
                    </List>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px',
                      }}
                    >
                      <Button
                        disabled={deleteInProgress}
                        loading={deleteInProgress && confirmationDialogData.accountNumber === account.accountNumber}
                        variant="outlined"
                        startIcon={<Delete />}
                        color="primary"
                        sx={{ '& svg': { fontSize: '26px' } }}
                        onClick={() =>
                          handleDelete({
                            accountNumber: account.accountNumber,
                            accountReference: account.accountReference,
                          })
                        }
                        dataTestId={`${index}-delete-button`}
                      >
                        {t('general|labels|buttons|Delete')}
                      </Button>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {confirmationDialogData.open && (
        <ConfirmationDialog
          open={true}
          title={t(`editProfile|You are about to remove account number`)}
          dialogContent={
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1">
                <strong className="color-DHL-red">{confirmationDialogData.accountNumber}</strong>
              </Typography>
              <Typography variant="body1">{t('general|Are you sure?')}</Typography>
            </Box>
          }
          onConfirm={confirmationDialogData.onConfirm}
          confirmLabel={t('general|Yes')}
          cancelLabel={t('general|No')}
          // we want to keep reference to removed account for loading indicator
          onClose={() => setConfirmationDialogData(prev => ({ ...prev, open: false, onConfirm: () => {} }))}
        />
      )}
    </>
  );
};
