import { Button, Error } from 'components';
import { apiLinks } from 'config/api-config';
import cssVars from 'css-vars-ponyfill';
import 'globals/utils/debugHelpers';
import { apiRequest } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { PASSWORD_EXPIRY_COOKIE } from '../../../components/fcp-components/PasswordExpiryModal';
import { CONST } from '../../data-config';
import { canProceedToApiCall, resetErrors, validatePasswords } from './ChangePassword-helpers';

import './../../css/global/_variables.css';
import { Typography } from '@mui/material';
import './ChangePassword.css';
import { setCookie } from '../../../utils/cookies';
import { PasswordInput } from 'components/fcp-components/PasswordInput';

const ENV_DEV = process.env.NODE_ENV === 'development';
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPasswordDoesNotMatchAccountPasswordError: false,
      keycloakPasswordRulesDoesNotFitError: false,
      oldAndNewPasswordsMatchError: false,
      confirmationDoesNotMatchNewPasswordError: false,
      technicalError: false,
      success: false,
      isLoadingChangePassword: false,
      formError: false,
      buttonDisabled: false,
    };
  }

  componentDidMount() {
    cssVars();
    this.setState({ languageCode: localStorage.getItem('i18nextLng') });
  }

  setPasswordExpiryCookie = () => {
    const expirationDate = new Date();
    expirationDate.setHours(23, 59, 59);
    setCookie(PASSWORD_EXPIRY_COOKIE, 'changed', expirationDate);
  };

  checkAndSubmit = () => {
    let stateObj = resetErrors();
    const { context } = this.props;
    const { stateToSet, validationParams } = validatePasswords(context);
    const canProceed = canProceedToApiCall(validationParams);

    // Merge reset state object with newly found errors
    stateToSet.buttonDisabled = canProceed;
    stateToSet.isLoadingChangePassword = canProceed;
    stateToSet.formError = !canProceed;
    stateObj = Object.assign({}, stateObj, stateToSet);

    this.setState(stateObj, () => {
      if (canProceed) {
        let params = {
          body: {
            oldPassword: validationParams.oldPassword.value,
            newPassword: validationParams.newPassword.value,
          },
        };

        ENV_DEV && console.message('API call started');

        apiRequest(apiLinks.putResetPassword, 'PUT', params).then(response => {
          if (response.status === CONST.STATUS_ACCEPTED) {
            ENV_DEV && console.message('API request successful');
            stateToSet.success = true;
            this.setPasswordExpiryCookie();
          } else {
            ENV_DEV && console.error('API request failed');
            stateToSet.technicalError = true;
            stateToSet.buttonDisabled = false;

            if (response.status === CONST.BAD_REQUEST_ERROR) {
              ENV_DEV && console.error('Old password is incorrect');
              stateToSet.oldPasswordDoesNotMatchAccountPasswordError = true;
            }
            if (response.status === CONST.UNPROCESSABLE_CONTENT) {
              ENV_DEV && console.error('Keycloak rules does not fit');
              // response.message possible values:
              //    invalidPasswordMinLengthMessage / invalidPasswordHistoryMessage
              stateToSet.keycloakPasswordRulesDoesNotFitError = response?.data.message;
            }
          }

          stateToSet.isLoadingChangePassword = false;
          this.setState(stateToSet);
        });
      }
    });
  };

  render() {
    const { context } = this.props;
    const { oldPassword, newPassword, newPasswordConfirmation } = context.state;
    const {
      oldPasswordDoesNotMatchAccountPasswordError,
      keycloakPasswordRulesDoesNotFitError,
      oldAndNewPasswordsMatchError,
      confirmationDoesNotMatchNewPasswordError,
      technicalError,
      formError,
      success,
    } = this.state;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        autoComplete="off"
        noValidate
        className={ENV_DEV ? 'l-view' : ''}
      >
        <Translation>
          {t => (
            <div className="c--fcp-header-spacer">
              <div className="c-page-headline component-small has-rte component-margin frc__heading">
                <div className="c-page-headline--wrapper">
                  <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                    {t('general|pageTitles|changePassword')}
                  </Typography>
                  <p className="has-rte">{t(`changePassword|Confirm your old password and enter a new one.`)}</p>
                </div>
              </div>
              <div className="l-grid l-grid--center-s">
                <div className="l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-50pc-m frc__change-password">
                  <div className="l-grid--left-s">
                    <div className="l-grid--w-100pc-s" data-form-item-id="item-oldpassword">
                      <PasswordInput
                        value={oldPassword.value}
                        label={t(`changePassword|Old password`) + ':'}
                        onChange={newValue =>
                          context.updateField({
                            name: 'oldPassword',
                            value: newValue,
                            isRequired: true,
                          })
                        }
                        testId="oldPasswordInp"
                      />
                      {oldPassword.error && (
                        <Error
                          name="oldPassword"
                          className="frc__change-password-message--error"
                          message={t('general|errors|Please enter valid password')}
                        />
                      )}
                    </div>

                    <div
                      className="l-grid--w-100pc-s frc__change-password--input-wrapper has-top-margin-2"
                      data-form-item-id="item-newpassword"
                    >
                      <PasswordInput
                        value={newPassword.value}
                        label={t('general|labels|inputs|New password') + ':'}
                        showTooltip
                        onChange={newValue =>
                          context.updateField({
                            name: 'newPassword',
                            value: newValue,
                            isRequired: true,
                          })
                        }
                        testId="newPasswordInp"
                      />
                      {newPassword.error && (
                        <Error
                          name="newPassword"
                          className="frc__change-password-message--error"
                          message={t('general|errors|Please enter valid password')}
                        />
                      )}

                      {oldAndNewPasswordsMatchError && (
                        <Error
                          name="oldAndNewPasswordsMatchError"
                          className="frc__change-password-message--error"
                          message={t(`changePassword|New password should be different`)}
                        />
                      )}
                      {keycloakPasswordRulesDoesNotFitError && (
                        <Error
                          name="keycloakPasswordRulesDoesNotFitError"
                          className="frc__change-password-message frc__change-password-message--error"
                          message={t(`changePassword|${keycloakPasswordRulesDoesNotFitError}`)}
                        />
                      )}
                    </div>

                    <div className="l-grid--w-100pc-s has-top-margin-2" data-form-item-id="item-confirmpassword">
                      <PasswordInput
                        value={newPasswordConfirmation.value}
                        label={t(`general|Confirm password`) + ':'}
                        onChange={newValue =>
                          context.updateField({
                            name: 'newPasswordConfirmation',
                            value: newValue,
                            isRequired: true,
                          })
                        }
                        testId="newPasswordConfirmationInp"
                      />
                      {newPasswordConfirmation.error && (
                        <Error
                          name="newPasswordConfirmation"
                          className="frc__change-password-message--error"
                          message={t('general|errors|Please enter valid password')}
                        />
                      )}
                      {confirmationDoesNotMatchNewPasswordError && (
                        <Error
                          name="confirmationDoesNotMatchNewPasswordError"
                          className="frc__change-password-message--error"
                          message={t('general|errors|Password confirmation does not match')}
                        />
                      )}
                    </div>

                    <div className="l-grid--w-60pc-m l-grid--w-50pc-m-m frc__change-password-submit-item has-button-submit c-calculator">
                      <div className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-40pc-m">
                        <div
                          className={' l-grid--w-100pc-s ' + (this.state.isLoadingChangePassword ? ' is-loading ' : '')}
                        />
                      </div>

                      <Button
                        id="resetPasswordBtn"
                        dataTestId="resetPasswordBtn"
                        label={t('general|labels|buttons|Reset password')}
                        disabled={this.state.buttonDisabled}
                        className={
                          'l-grid--w-100pc-s base-button' +
                          (this.state.isLoadingChangePassword ? ' hidden ' : '') +
                          (this.state.buttonDisabled ? ' is-disabled ' : '')
                        }
                        submit={this.checkAndSubmit}
                      />
                      {technicalError && (
                        <Error
                          name="technicalError"
                          className="frc__change-password-message frc__change-password-message--error"
                          message={t(`general|errors|Due to a technical error, the password cannot be changed`)}
                        />
                      )}

                      {formError && (
                        <Error
                          name="formError"
                          className="frc__reset-password-message frc__change-password-message--error"
                          message={t('general|errors|Please fill properly all necessary fields')}
                        />
                      )}

                      {oldPasswordDoesNotMatchAccountPasswordError && (
                        <Error
                          name="oldPasswordDoesNotMatchAccountPasswordError"
                          className="frc__change-password-message frc__change-password-message--error"
                          message={t(`changePassword|Old password does not match`)}
                        />
                      )}

                      {success && (
                        <p
                          className="frc__change-password-message frc__change-password-message--success"
                          id="password_changed_info"
                        >
                          {t(`changePassword|Password has been successfully changed`)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      </form>
    );
  }
}
