import { Alert } from '@mui/material';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Show toast on async error
 */
export const rtkErrorLogger = api => next => action => {
  if (isRejectedWithValue(action) && !action?.payload?.skipToast) {
    toast(() => (
      <Alert severity="error" variant="standard">
        {`The action failed with error: ${action?.payload?.data?.status ?? 'unknown'}
        ${action?.payload?.data?.message ? ' ' + action?.payload?.data?.message : ''}
        ${action?.payload?.data?.error ? ' ' + action?.payload?.data?.error : ''}`}
      </Alert>
    ));
  }

  return next(action);
};
