import { Box, Typography, Stack, Button, Theme, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OnHoldShipmentListItem } from 'store/api/fcpBooking.types';
import { DataGrid, gridClasses, DataGridProps } from '@mui/x-data-grid';
import type {
  GridColDef,
  GridRowHeightReturnValue,
  GridRowClassNameParams,
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import { useCallback, useMemo, useState, memo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { BulkActionsWrapper } from './BulkActions';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import { useUserLocalStorage } from 'hooks/useUserLocalStorage';
import { useOnholdOrders } from './useOnholdOrders';

const PAGE_SIZE_KEY = 'onholdPageSize';

export const OnholdOrdersTable = memo(
  ({
    data,
    onDetailOpen,
    onRelease,
    onMove,
    onBulkMove,
    onBook,
  }: {
    data: OnHoldShipmentListItem[];
    onDetailOpen: (requestId: string) => void;
    onRelease: (requestId: string) => void;
    onMove: (requestId: string) => void;
    onBulkMove: (requestIds: string[]) => void;
    onBook: (requestId: string) => void;
  }) => {
    const { t } = useTranslation();
    const { getItem: getPageSize, setItem: storePageSize } = useUserLocalStorage(PAGE_SIZE_KEY);
    const { loading, isBulkBookingDisabled, isBulkMoveDisabled, isSingleMoveDisabled, totalShipmentCount } =
      useOnholdOrders();

    const [paginationModel, setPaginationModel] = useState({
      pageSize: getPageSize() ? Number(getPageSize()) : 10,
      page: 0,
    });

    const handlePaginationModelChange = useCallback(
      (newPaginationModel: { pageSize: number; page: number }) => {
        setPaginationModel(newPaginationModel);
        storePageSize(newPaginationModel.pageSize.toString());
      },
      [storePageSize],
    );

    const renderShipmentNumber = useCallback(
      (params: GridRenderCellParams<OnHoldShipmentListItem, any, any, GridTreeNodeWithRender>) => {
        return (
          <Button
            variant="text"
            sx={{ textAlign: 'left', pl: 0, wordBreak: 'break-word' }}
            onClick={() => onDetailOpen(params.row.requestId)}
          >
            <span>
              {params.row.reference ? (
                <>
                  <span data-testid={`${params.row.requestId}-reference`}>{params.row.reference}</span>
                  <br />
                </>
              ) : null}
              <span data-testid={`${params.row.requestId}-shipmentNumber`}>{params.row.shipmentNumber}</span>
            </span>
          </Button>
        );
      },
      [onDetailOpen],
    );

    const renderReceiver = useCallback(
      (params: GridRenderCellParams<OnHoldShipmentListItem, any, any, GridTreeNodeWithRender>) => {
        return (
          <Stack py={1}>
            <Typography variant="body2" data-testid={`${params.row.requestId}-receiverContactName`}>
              {params.row.receiverContactName}
            </Typography>
            <Typography
              fontSize={13}
              textOverflow="ellipsis"
              sx={{ opacity: 0.7, wordBreak: 'break-word' }}
              data-testid={`${params.row.requestId}-address`}
            >{`${params.row.receiverCityName}, ${params.row.receiverCountryCode}, ${params.row.receiverPostalCode}`}</Typography>
          </Stack>
        );
      },
      [],
    );

    const renderActions = useCallback(
      (params: GridRenderCellParams<OnHoldShipmentListItem, any, any, GridTreeNodeWithRender>) => {
        return (
          <Box display="flex" flexWrap="wrap" gap={0.5} pl={1}>
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => onRelease(params.row.requestId)}
              sx={{
                width: 30,
                minWidth: 30,
                '& .MuiButton-icon': {
                  m: 0,
                },
              }}
              data-testid={`${params.row.requestId}-delete`}
            ></Button>
            <Button
              variant="outlined"
              startIcon={<CachedIcon />}
              onClick={() => onMove(params.row.requestId)}
              disabled={isSingleMoveDisabled}
              data-testid={`${params.row.requestId}-move`}
              sx={{ px: 1.5 }}
            >
              {t('general|onholdOrders|move')}
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => onBook(params.row.requestId)}
              data-testid={`${params.row.requestId}-book`}
              sx={{ px: 1.5 }}
            >
              {t('general|onholdOrders|book')}
            </Button>
          </Box>
        );
      },
      [isSingleMoveDisabled, onBook, onMove, onRelease, t],
    );

    const columns: GridColDef<OnHoldShipmentListItem>[] = useMemo(
      () => [
        { field: 'shopName', headerName: t('general|onholdOrders|columns|shopName'), flex: 1, sortable: false },
        {
          field: 'shipmentNumber',
          headerName: t('general|onholdOrders|columns|shipmentNumber'),
          flex: 1,
          sortable: false,
          renderCell: renderShipmentNumber,
        },
        { field: 'createdAt', headerName: t('general|onholdOrders|columns|createdAt'), flex: 1, sortable: false },
        {
          field: 'receiver',
          headerName: t('general|Receiver'),
          flex: 1,
          minWidth: 150,
          sortable: false,
          renderCell: renderReceiver,
        },
        {
          field: 'service',
          headerName: t('general|onholdOrders|columns|service'),
          flex: 1,
          sortable: false,
          valueGetter: params => (params.row.productCode ? t(`products|${params.row.productCode}|name`) : '-'),
        },
        { field: 'weight', headerName: t('general|labels|metrics|Weight'), width: 75, sortable: false },
        {
          field: 'price',
          headerName: t('general|onholdOrders|columns|price'),
          sortable: false,
          valueGetter: params => `${params.row.priceValue ?? ''} ${params.row.priceCurrency ?? ''}`,
        },
        {
          field: 'actions',
          headerName: t('general|actions'),
          flex: 2,
          minWidth: 280,
          sortable: false,
          renderCell: renderActions,
        },
      ],
      [renderShipmentNumber, renderReceiver, renderActions, t],
    );

    const slots: DataGridProps['slots'] = useMemo(
      () => ({
        toolbar: BulkActionsWrapper,
      }),
      [],
    );

    const slotProps: DataGridProps['slotProps'] = useMemo(
      () => ({
        pagination: {
          ActionsComponent: () => <span />,
          labelDisplayedRows: ({ from, to }) => `${from}-${to} ${t('general|of')} ${totalShipmentCount}`,
          labelRowsPerPage: `${t('general|Display')}:`,
        },
        toolbar: {
          onBulkMove,
        },
      }),
      [onBulkMove, t, totalShipmentCount],
    );

    return (
      <>
        <Box width="100%" minWidth={900} minHeight={400} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box id="actions-panel" display="flex" justifyContent="flex-end" />
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            checkboxSelection={!isBulkBookingDisabled || !isBulkMoveDisabled}
            disableRowSelectionOnClick
            disableColumnMenu
            getRowClassName={getRowClassName}
            getRowId={getRowId}
            getRowHeight={getRowHeight}
            loading={loading}
            pageSizeOptions={pageSizeOptions}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            slots={slots}
            slotProps={slotProps}
            sx={gridSx}
          />
        </Box>
      </>
    );
  },
);

// Stable references to improve performance
const pageSizeOptions = [10, 25, 50, 100];
const getRowId = (row: OnHoldShipmentListItem) => row.requestId;
const getRowHeight = () => 'auto' as GridRowHeightReturnValue;
const getRowClassName = ({ indexRelativeToCurrentPage }: GridRowClassNameParams<OnHoldShipmentListItem>) => {
  return indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
};

const gridSx: SxProps<Theme> = {
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeaders}`]: {
    bgcolor: '#333',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
  [`& .${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`& .${gridClasses.row}:nth-of-type(even):not(.Mui-selected)`]: {
    bgcolor: '#F9F9F9',
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontWeight: 'bold',
  },
  '& .MuiTablePagination-input': {
    fontSize: 14,
    alignSelf: 'flex-start',
    pt: '5px',
    mr: 2,
  },
  '& .MuiTablePagination-displayedRows': {
    pr: 2,
  },
};
