/**
 * Utility function to create a K:V from a list of strings
 * Usage:
 *  export const TAB_POSITIONS = strEnum(['left', 'right']);
 *  export type TabPosition = keyof typeof TAB_POSITIONS;
 */
export const strEnum = <T extends string>(o: T[]): { [K in T]: K } =>
  o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
