import { DEFAULT_LANGUAGE } from 'locale/config';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

type CountryLanguageContextType = {
  country: string;
  language: string;
  countryLanguage: string;
};

const CountryLanguageContext = createContext<CountryLanguageContextType>(null as unknown as CountryLanguageContextType);

// todo: localStorage 'country' and 'language' should be removed completely from the app we should use this hook and / or create
// todo: a helper function which will split the 'i18nextLng' as only that one is guaranteed to always have value
export const CountryLanguageProvider = ({ children }: { children: ReactNode }) => {
  // i18nextLng should never be undefined at runtime but just in case we provide also default lang
  const countryLanguage = localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE;
  const i18data = countryLanguage.split('-');
  const country = i18data[0]!;
  const language = i18data[1]!;

  const value = useMemo(
    () => ({
      country,
      language,
      countryLanguage,
    }),
    [country, language, countryLanguage],
  );

  // on initial landing only "i18nextLng" will be defined as automatic lang selection will be done in following render cycle
  // this useEffect will run BEFORE i18.onChange listener so for the initial landing we rather set the localStorage values here if empty
  useEffect(() => {
    const localCountry = localStorage.getItem('country');
    const localLanguage = localStorage.getItem('language');

    if (!localCountry || !localLanguage) {
      localStorage.setItem('country', country);
      localStorage.setItem('language', language);
    }
  }, [country, language]);

  return <CountryLanguageContext.Provider value={value}>{children}</CountryLanguageContext.Provider>;
};

export function useCountryLanguage() {
  return useContext(CountryLanguageContext);
}
