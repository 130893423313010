import { Stack, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectIsSwedishUser, selectUserAccountCountryCode } from 'store/api/fcpUser';
import { ControlledSelect, ControlledTextField } from './form-inputs/FormInputs';
import { getPostalCodePlaceholder } from 'globals/helpers/country-helpers';
import { useLazyGetCityQuery } from '../../store/api/fcpPostalCode';
import { type FieldValues, type Path, type UseFormReturn } from 'react-hook-form';
import { globalConfig } from 'globals/data-config-global';
import { caseInsensitiveEquals } from 'globals/utils/strings';
import { useCountryOptions } from '../../hooks/useCountryOptions';
import { PROFILE_CONSTANTS } from './edit-profile/constants';
import { getPostalCodeRegex } from 'globals/helpers/postalcode';
import { useAppSelector } from 'store/hooks';
import { PhoneFormFields } from './PhoneFormFields';
import { useConfigCountries } from '../../hooks/useConfigCoutries';

export const Address = <TField extends FieldValues>({
  formContext,
  countryField,
  senderField,
  streetField,
  nameField,
  emailField,
  phoneCountryField,
  phoneNumberField,
  postalCodeField,
  cityField,
  onPostalCodeValidated,
  countryDisabled,
  testIdPrefix,
}: {
  formContext: UseFormReturn<TField, any, undefined>;
  countryField: Path<TField>;
  senderField: Path<TField>;
  streetField: Path<TField>;
  nameField: Path<TField>;
  emailField: Path<TField>;
  phoneCountryField: Path<TField>;
  phoneNumberField: Path<TField>;
  postalCodeField: Path<TField>;
  cityField: Path<TField>;
  onPostalCodeValidated: ({ deviating, city }: { deviating: boolean; city: string }) => void;
  countryDisabled: boolean;
  testIdPrefix: 'pickup' | 'delivery';
}) => {
  const { t } = useTranslation();
  const [validatePostalCode, { isFetching }] = useLazyGetCityQuery();
  const { control, getValues, watch, trigger, setValue } = formContext;
  const countryOptions = useCountryOptions();

  const country = watch(countryField);
  const isSwedishUser = useAppSelector(selectIsSwedishUser);
  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const displayCity = isSwedishUser && !globalConfig.disableCityFetchCountryList.includes(country?.toUpperCase());
  const isIreland = caseInsensitiveEquals(country, 'IE');
  const { companyPhoneCountryCodeOptions } = useConfigCountries();

  // Pre-select account country if no country is selected
  if (!country && countryOptions.length > 0) {
    // @ts-expect-error
    setValue(countryField, accountCountryCode);
    setValue(
      phoneCountryField,
      // @ts-expect-error
      companyPhoneCountryCodeOptions.find(phoneCountry => phoneCountry.code === accountCountryCode) || null,
    );
  }

  const maxLength = isIreland ? PROFILE_CONSTANTS.irelandCountyCharacterLimit : globalConfig.maxPostalCode;

  const postalCodeValidation = async (value: string) => {
    try {
      const { data } = await validatePostalCode(
        {
          countryCode: getValues(countryField) ?? '',
          postalCode: value,
          accountCountryCode,
        },
        true, // it is imperative to use cache when possible
      );
      // @ts-expect-error
      setValue(cityField, data?.city ?? '');
      if (!data?.validated) {
        return t('general|errors|Postalcode API Error');
      }
      onPostalCodeValidated({ deviating: data?.deviating === '1', city: data.city ?? '' });
      return true;
    } catch {
      return t('general|errors|General error');
    }
  };

  return (
    <Stack>
      <Grid container spacing="16px">
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName={senderField}
            label={t('general|Sender')}
            testId={`${testIdPrefix}Sender`}
            required
            rules={{
              required: t('general|labels|inputs|required'),
            }}
            TextFieldProps={{ inputProps: { maxLength: globalConfig.maxDefaultInputLength } }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName={streetField}
            label={t('general|labels|inputs|Street')}
            testId={`${testIdPrefix}Street`}
            required
            rules={{
              required: t('general|labels|inputs|required'),
            }}
            TextFieldProps={{ inputProps: { maxLength: globalConfig.maxDefaultInputLength } }}
          />
        </Grid>

        <Grid item xs={12} sm={displayCity ? 6 : 12}>
          <ControlledTextField
            control={control}
            fieldName={postalCodeField}
            testId={`${testIdPrefix}PostalCodeInputId`}
            label={isIreland ? t('general|labels|inputs|County') : t('general|labels|inputs|Postalcode')}
            TextFieldProps={{
              placeholder: `${t('quoteTool|e.g.')} ${getPostalCodePlaceholder(country)}`,
              inputProps: {
                'data-testid': `${testIdPrefix}PostalCodeInp`,
                maxLength: maxLength,
              },
            }}
            rules={{
              required: t('general|errors|Postalcode Error', {
                max: maxLength,
              }),
              maxLength: {
                value: maxLength,
                message: t('general|errors|Postalcode Error', {
                  max: maxLength,
                }),
              },
              validate: postalCodeValidation,
            }}
            required
            inputRegex={getPostalCodeRegex({
              countryCode: getValues(countryField) as string,
              accountCountryCode,
              strict: false,
            })}
          />
        </Grid>

        {displayCity && (
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              control={control}
              fieldName={cityField}
              label={t('general|labels|inputs|City')}
              loading={isFetching}
              testId={`${testIdPrefix}City`}
              required
              rules={{
                required: t('general|labels|inputs|required'),
              }}
              TextFieldProps={{ inputProps: { maxLength: globalConfig.maxDefaultInputLength } }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ControlledSelect
            control={control}
            fieldName={countryField}
            label={t('general|Country')}
            testId={`${testIdPrefix}CountrySlt`}
            onChangeCb={() => {
              if (getValues(postalCodeField)) {
                trigger(postalCodeField);
              }
            }}
            options={countryOptions}
            rules={{
              required: t('general|Country Error'),
            }}
            required
            disabled={countryDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName={nameField}
            label={t('general|Contact Name')}
            testId={`${testIdPrefix}Name`}
            required
            rules={{
              required: t('general|labels|inputs|required'),
            }}
            TextFieldProps={{ inputProps: { maxLength: globalConfig.maxDefaultInputLength } }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName={emailField}
            label={t('general|Contact E-mail')}
            testId={`${testIdPrefix}Email`}
            required
            rules={{
              required: t('general|labels|inputs|required'),
              pattern: {
                value: globalConfig.regEx.emailV2,
                message: t('general|labels|inputs|invalidFormat'),
              },
            }}
            TextFieldProps={{ inputProps: { maxLength: globalConfig.maxEmailLength } }}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneFormFields
            phoneCountryField={phoneCountryField}
            phoneNumberField={phoneNumberField}
            formContext={formContext}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
