import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import cssVars from 'css-vars-ponyfill';
import { Typography } from '@mui/material';

import './../../css/global/_variables.css';
// import './../../css/global/_icons.css';
// import './../../css/base/iconfont.css';
import './ResetPassword.css';

import 'globals/utils/debugHelpers';
import { apiRequest } from 'globals/utils/requests';
import { CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { Error, Button } from 'components';
import { resetErrors, validatePasswordsAndResetCode, canProceedToApiCall } from './ResetPassword-helpers';
import { PasswordInput } from 'components/fcp-components/PasswordInput';

const ENV_DEV = process.env.NODE_ENV === 'development';
export default class AppResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicalError: false,
      passwordMismatchError: false,
      success: false,
      isLoadingResetPassword: false,
      formError: false,
      buttonDisabled: false,
      keycloakPasswordRulesDoesNotFitError: false,
    };
  }

  componentDidMount() {
    cssVars();
  }

  checkAndSubmit = () => {
    const { context } = this.props;
    let stateObj = resetErrors();
    const { stateToSet, validationParams } = validatePasswordsAndResetCode(context);
    const canProceed = canProceedToApiCall(validationParams);

    stateToSet.buttonDisabled = canProceed;
    stateToSet.isLoadingResetPassword = canProceed;
    stateToSet.formError = !canProceed;
    stateObj = Object.assign({}, stateObj, stateToSet);

    this.setState(stateObj, () => {
      if (canProceed) {
        const url = apiLinks.postResetPassword;
        const params = {
          body: {
            password: context.state.newPasswordConfirmation.value,
            resetCode: validationParams.resetCode,
          },
        };

        ENV_DEV && console.message('API request started');

        apiRequest(url, 'POST', params)
          .then(response => {
            if (response.status === CONST.STATUS_ACCEPTED) {
              ENV_DEV && console.message('API request successful');
              stateToSet.success = true;
              stateToSet.buttonDisabled = true;
            } else if (response.status === CONST.UNPROCESSABLE_CONTENT) {
              ENV_DEV && console.error('Keycloak rules does not fit');
              // response.message possible values:
              //    invalidPasswordMinLengthMessage / invalidPasswordHistoryMessage
              stateToSet.keycloakPasswordRulesDoesNotFitError = response?.data.message;
              stateToSet.buttonDisabled = false;
            } else {
              stateToSet.technicalError = true;
              stateToSet.buttonDisabled = false;
            }
          })
          .catch(err => {
            ENV_DEV && console.error(err);
            stateToSet.technicalError = true;
            stateToSet.buttonDisabled = false;
          })
          .finally(() => {
            stateToSet.isLoadingResetPassword = false;
            this.setState(stateToSet);
          });
      }
    });
  };

  render() {
    const { context } = this.props;
    const { newPassword, newPasswordConfirmation } = context.state;
    const { technicalError, success, passwordMismatchError, formError, keycloakPasswordRulesDoesNotFitError } =
      this.state;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        autoComplete="off"
        noValidate
      >
        <Translation>
          {t => (
            <div className="c--fcp-header-spacer">
              <div className="c-page-headline component-small has-rte component-margin frc__heading">
                <div className="c-page-headline--wrapper">
                  <Typography variant="h1" mt={{ xs: 0, sm: 2 }}>
                    {t(`resetPassword|CREATE NEW PASSWORD`)}
                  </Typography>
                </div>
              </div>

              <div className="l-grid l-grid--center-s">
                <div className="l-grid--w-100pc-s l-grid--w-80pc-m-s l-grid--w-50pc-m frc__reset-password">
                  <div className="l-grid--left-s">
                    <div
                      className="l-grid--w-100pc-s frc__reset-password--input-wrapper"
                      data-form-item-id="item-newpassword"
                    >
                      <PasswordInput
                        value={newPassword.value}
                        label={t('general|labels|inputs|New password') + ':'}
                        showTooltip
                        onChange={newValue =>
                          context.updateField({
                            name: 'newPassword',
                            value: newValue,
                            isRequired: true,
                          })
                        }
                        testId="newPasswordInp"
                      />
                      {newPassword.error && (
                        <Error
                          name="newPassword"
                          className="frc__reset-password-message--error"
                          message={t('general|errors|Please enter valid password')}
                        />
                      )}
                      {keycloakPasswordRulesDoesNotFitError && (
                        <Error
                          name="keycloakPasswordRulesDoesNotFitError"
                          className="frc__change-password-message frc__change-password-message--error"
                          message={t(`changePassword|${keycloakPasswordRulesDoesNotFitError}`)}
                        />
                      )}
                    </div>
                    <div className="l-grid--w-100pc-s has-top-margin-1" data-form-item-id="item-confirmpassword">
                      <PasswordInput
                        value={newPasswordConfirmation.value}
                        label={t(`general|Confirm password`) + ':'}
                        onChange={newValue =>
                          context.updateField({
                            name: 'newPasswordConfirmation',
                            value: newValue,
                            isRequired: true,
                          })
                        }
                        testId="newPasswordConfirmationInp"
                      />
                      {newPasswordConfirmation.error && (
                        <Error
                          name="newPasswordConfirmation"
                          className="frc__reset-password-message--error"
                          message={t('general|errors|Please enter valid password')}
                        />
                      )}
                      {passwordMismatchError && (
                        <Error
                          name="passwordMismatchError"
                          className="frc__reset-password-message--error"
                          message={t('general|errors|Password confirmation does not match')}
                        />
                      )}
                    </div>
                    <div className="l-grid--w-60pc-m l-grid--w-50pc-m-m frc__reset-password-submit-item has-button-submit c-calculator has-top-margin-1">
                      <div className="l-grid--w-100pc-s l-grid--w-40pc-s-l l-grid--w-40pc-m">
                        <div
                          className={' l-grid--w-100pc-s ' + (this.state.isLoadingResetPassword ? ' is-loading ' : '')}
                        />
                      </div>
                      <Button
                        label={t('general|labels|buttons|Reset password')}
                        disabled={this.state.buttonDisabled}
                        className={
                          'l-grid--w-100pc-s base-button' +
                          (this.state.isLoadingResetPassword ? ' hidden ' : '') +
                          (this.state.buttonDisabled ? ' is-disabled ' : '')
                        }
                        submit={this.checkAndSubmit}
                        dataTestId="reset-password-btn"
                      />
                      {formError && (
                        <Error
                          name="formError"
                          className="frc__reset-password-message frc__reset-password-message--error"
                          message={t('general|errors|Please fill properly all necessary fields')}
                        />
                      )}
                      {technicalError && (
                        <Error
                          name="technicalError"
                          className="frc__reset-password-message frc__reset-password-message--error"
                          message={t(`resetPassword|Due to a technical error, the password cannot be reset`)}
                        />
                      )}
                      {success && (
                        <p className="frc__reset-password-message--success" id="reset_password_error_text">
                          {t(`resetPassword|Password has been successfully reset.`)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      </form>
    );
  }
}
