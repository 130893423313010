import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import { DEFAULT_LANGUAGE } from './config';
import { convertCountriesToFlatArray, getSupportedLanguages } from '../globals/helpers/language-helpers';
import ConfigService from '../globals/utils/configService';

export const TRANSLATION_FAILED_KEY = 'IS_TRANSLATION_LOAD_FAILED';
const ENV_DEV = process.env.NODE_ENV === 'development';
const urlParams = new URLSearchParams(window.location.search);

let isPreview = false;
if (urlParams.get('preview') === 'true') {
  isPreview = true;
}
const supportedLanguages = getSupportedLanguages(convertCountriesToFlatArray(await ConfigService.getCountries()));

const browserDetector = {
  name: 'browserDetector',
  lookup(options) {
    if (!navigator.languages) return DEFAULT_LANGUAGE;
    for (const lang of navigator.languages) {
      const [langRaw, countryRaw] = lang.split('-');
      if (countryRaw) {
        const parsedLang = `${countryRaw?.toLowerCase()}-${langRaw?.toLowerCase()}`;
        if (supportedLanguages.includes(parsedLang)) return parsedLang;
      }
      return (
        supportedLanguages.find(l => l?.split('-')?.[1]?.toLowerCase() === langRaw.toLowerCase()) ?? DEFAULT_LANGUAGE
      );
    }
    return DEFAULT_LANGUAGE;
  },
  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(browserDetector);

const detectionOptions = {
  order: ['path', 'localStorage', 'browserDetector'],
  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  convertDetectedLanguage: lng => (supportedLanguages.includes(lng) ? lng : DEFAULT_LANGUAGE),
};

i18n
  /**
   * LanguageDetector handles localStorage value i18nextLng
   */
  .use(languageDetector)
  .use(initReactI18next)
  .use(backend)
  .init({
    initImmediate: false,
    lowerCaseLng: true,
    load: 'currentOnly',
    detection: detectionOptions,

    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: supportedLanguages,
    returnNull: true,
    returnEmptyString: true,

    debug: true,

    // have a common namespace used around the full app
    // ns: [''],
    // defaultNS: '',
    keySeparator: '|', // we use content as keys
    // nsSeparator: '|', // we use content as keys
    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: isPreview ? '/fcp-cms-api/public/{{lng}}.json?isPreview=true' : '/fcp-cms-api/public/{{lng}}.json',
    },

    react: {
      // Turn off the use of React Suspense
      useSuspense: false,
    },
  });

i18n.on('failedLoading', function (lng, ns, msg) {
  console.error('i18n -- failedLoading -- lng', lng, 'ns', ns, 'msg', msg);
  /**
   * To locally reproduce this scenario, point your network to DHL proxy
   * without connecting to DHL VPN. All requests will then timeout
   * in the browser (Chrome) after 60 seconds.
   */
  sessionStorage.setItem(TRANSLATION_FAILED_KEY, 'true');
});

// todo: localStorage 'country' and 'language' should be removed completely from the app we should use the useCountryLanguage hook
// todo:  and / or create a helper function which will split the 'i18nextLng' as only that one is guaranteed to always have value
i18n.on('languageChanged', (lng, ns, msg) => {
  ENV_DEV && console.log('i18n -- languageChanged -- lng', lng);
  const [country, language] = lng.split('-');
  if (!country || !language) return;
  localStorage.setItem('country', country.toLowerCase());
  localStorage.setItem('language', language.toLowerCase());
});

export default i18n;
