import React from 'react';

type VolumeDisplayProps = {
  volume?: number | string;
};
export const VolumeDisplay: React.FC<VolumeDisplayProps> = ({ volume }) => {
  const numericVolume = typeof volume === 'string' ? parseFloat(volume) : volume;

  const formattedVolume =
    numericVolume !== undefined && !isNaN(numericVolume) ? (Math.ceil(numericVolume * 1000) / 1000).toFixed(3) : '---';

  return (
    <>
      {formattedVolume} m<sup>3</sup>
    </>
  );
};
