import { caseInsensitiveEquals } from 'globals/utils/strings';
import { HUNGARY_CODE, POLAND_CODE, ROMANIA_CODE } from '../constants';
import { globalConfig } from 'globals/data-config-global';

export const SENT_REF = 'SENT_REF';
export const SENT_CARKEY = 'SENT_CARKEY';
export const EKAER_FREE = 'EKAER_FREE';
export const EKAER_NUMBER = 'EKAER_NUMBER';
export const UIT_FREE = 'UIT_FREE';
export const UIT_NUMBER = 'UIT_NUMBER';

export const generateAdditionalInformationList = state => {
  const additionalInformationList = [];
  if (
    state.pickupCountry.value.toLowerCase() === POLAND_CODE ||
    state.deliveryCountry.value.toLowerCase() === POLAND_CODE
  ) {
    additionalInformationList.push({
      code: SENT_REF,
      stringValue: state.sentReference.value,
    });
    additionalInformationList.push({
      code: SENT_CARKEY,
      stringValue: state.sentCarrierKey.value,
    });
  }

  if (
    state.pickupCountry.value.toLowerCase() === HUNGARY_CODE ||
    state.deliveryCountry.value.toLowerCase() === HUNGARY_CODE
  ) {
    if (state.ekaerFree.value !== null) {
      additionalInformationList.push({
        code: EKAER_FREE,
        stringValue: state.ekaerFree.value.toString(),
      });
    }
    if (state.ekaerFree.value === false) {
      additionalInformationList.push({
        code: EKAER_NUMBER,
        stringValue: state.ekaerNumber.value,
      });
    }
  }

  if (
    globalConfig.etransportEnabled &&
    (caseInsensitiveEquals(state.pickupCountry.value, ROMANIA_CODE) ||
      caseInsensitiveEquals(state.deliveryCountry.value, ROMANIA_CODE))
  ) {
    if (state.uitFree.value !== null) {
      additionalInformationList.push({
        code: UIT_FREE,
        stringValue: state.uitFree.value.toString(),
      });
    }
    if (state.uitFree.value === false) {
      additionalInformationList.push({
        code: UIT_NUMBER,
        stringValue: state.uitNumber.value,
      });
    }
  }

  return additionalInformationList;
};

/* Poland*/
export const getSentReference = storedShipment => {
  const sentReferenceObj = storedShipment.savedShipmentAdditionalInformationList?.find(i => i.code === SENT_REF);
  return sentReferenceObj !== undefined ? sentReferenceObj.stringValue : '';
};
export const getSentCarrierKey = storedShipment => {
  const sentCarrierKeyObj = storedShipment.savedShipmentAdditionalInformationList?.find(i => i.code === SENT_CARKEY);
  return sentCarrierKeyObj !== undefined ? sentCarrierKeyObj.stringValue : '';
};

/*Hungary*/
export const isEkaerFree = storedShipment => {
  const ekaerFreeObj = storedShipment.savedShipmentAdditionalInformationList?.find(i => i.code === EKAER_FREE);
  return ekaerFreeObj !== undefined ? ekaerFreeObj.stringValue === 'true' : null;
};
export const getEkaerNumber = storedShipment => {
  const ekaerNumberObj = storedShipment.savedShipmentAdditionalInformationList?.find(i => i.code === EKAER_NUMBER);
  return ekaerNumberObj !== undefined ? ekaerNumberObj.stringValue : '';
};

/*Romania*/
export const isUitFree = storedShipment => {
  const uitFreeObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === UIT_FREE);
  return uitFreeObj !== undefined ? uitFreeObj.stringValue === 'true' : null;
};
export const getUitNumber = storedShipment => {
  const uitNumberObj = storedShipment.savedShipmentAdditionalInformationList.find(i => i.code === UIT_NUMBER);
  return uitNumberObj !== undefined ? uitNumberObj.stringValue : '';
};
