import { Box, Button as MuiButton, ButtonProps } from '@mui/material';
// @ts-expect-error
import { ReactComponent as Loader } from '../../loader.svg';

interface FcpButtonProps extends Omit<ButtonProps, 'sx' | 'disabled'> {
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  sx?: any;
  dataTestId?: string;
  id?: string;
}

export const Button = ({ loading, disabled, children, sx, dataTestId, id, ...rest }: FcpButtonProps) => {
  // @ts-expect-error
  const testId = dataTestId || rest['data-testid'] || id;
  return (
    <MuiButton
      disabled={loading || disabled}
      sx={{
        ...sx,
        px: sx?.px ?? '3rem',
      }}
      id={id}
      {...rest}
      data-testid={testId}
    >
      {children}
      {loading ? (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            marginTop: '8px',
            '& svg': {
              width: '30px',
              filter: 'grayscale(1)',
            },
          }}
        >
          <Loader />
        </Box>
      ) : null}
    </MuiButton>
  );
};
