import React from 'react';
import { getDisplayedPrice } from 'globals/utils/formatting';

import './Custom.css';
import { useGetCountryConfig } from '../../../../hooks/api/useGetCountryConfig';
import {
  getEkaerNumber,
  getSentCarrierKey,
  getSentReference,
  isEkaerFree,
  isUitFree,
  getUitNumber,
} from '../../../../globals/helpers/additionalInformation';
import { globalConfig } from 'globals/data-config-global';

export default function SummaryCustomCell(props) {
  const { cellValue, isShipmentDetail, t } = props;
  const { data: countryConfig } = useGetCountryConfig();

  const displayedPrice = getDisplayedPrice(t, cellValue.calculatedPrice, {
    CURRENCY_CODE: cellValue.calculatedPriceCurrency
      ? cellValue.calculatedPriceCurrency
      : countryConfig?.defaultCurrency,
  });

  const productName = cellValue.productID ? t(`products|${cellValue.productID}|name`) : cellValue.productName;

  /* Poland*/
  const sentReference = getSentReference(cellValue);
  const sentCarrierKey = getSentCarrierKey(cellValue);
  /* Hungary*/
  const ekaerFree = isEkaerFree(cellValue);
  const ekaerNumber = getEkaerNumber(cellValue);
  /* Romania*/
  const uitFree = isUitFree(cellValue);
  const uitNumber = getUitNumber(cellValue);

  return (
    <>
      {isShipmentDetail ? (
        <>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t('products|common|productLabel')}: </div>
            <div>{productName || ''}</div>
          </div>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t(`general|Estimated Price`)}: </div>
            <div>{displayedPrice || ''}</div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div>{t('products|common|productLabel')}: </div>
            <strong>{productName || ''}</strong>
          </div>
          <div>
            <div>{t(`general|Estimated Price`)}: </div>
            <strong>{displayedPrice || ''}</strong>
          </div>
        </>
      )}
      <div className={'frc__summary--row l-grid--left-s'}>
        <div>{t('general|labels|metrics|quantity')}: </div>
        <div>{(cellValue.totalNumberOfPieces || 0) + ' ' + t('general|pcs')}</div>
      </div>
      <div className={'frc__summary--row l-grid--left-s'}>
        <div>{t('general|labels|metrics|Weight')}: </div>
        <div>{(cellValue.totalWeight || 0) + ' ' + t('general|kg')}</div>
      </div>
      {isShipmentDetail && (
        <>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t('general|labels|metrics|Volume')}: </div>
            <div>
              {cellValue.totalVolume || 0} {t('general|m')}
              <sup>3</sup>
            </div>
          </div>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t('general|labels|metrics|Loading Meter')}: </div>
            <div>{cellValue.totalLDM || 0}</div>
          </div>
        </>
      )}
      {(sentReference !== '' || sentCarrierKey !== '') && (
        <>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t('general|labels|sentReference')}: </div>
            <div>{sentReference}</div>
          </div>
          <div className={'frc__summary--row l-grid--left-s'}>
            <div>{t('general|labels|sentCarrierKey')}: </div>
            <div>{sentCarrierKey}</div>
          </div>
        </>
      )}
      {(ekaerFree !== null || ekaerNumber !== '') && (
        <>
          {ekaerFree && (
            <div className={'frc__summary--row l-grid--left-s'}>
              <div>{t('general|labels|ekaerFree')}: </div>
              <div>{ekaerFree}</div>
            </div>
          )}
          {!ekaerFree && (
            <div className={'frc__summary--row l-grid--left-s'}>
              <div>{t('general|labels|ekaerNumber')}: </div>
              <div>{ekaerNumber}</div>
            </div>
          )}
        </>
      )}
      {globalConfig.etransportEnabled && (uitFree !== null || ekaerNumber !== '') && (
        <>
          {uitFree && (
            <div className={'frc__summary--row l-grid--left-s'}>
              <div>{t('general|labels|inputs|uitFree')}: </div>
              <div>{uitFree}</div>
            </div>
          )}
          {!uitFree && (
            <div className={'frc__summary--row l-grid--left-s'}>
              <div>{t('general|labels|inputs|uitNumber')}: </div>
              <div>{uitNumber}</div>
            </div>
          )}
        </>
      )}
    </>
  );
}
