import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT } from 'globals/utils/formatting';
import { locales } from 'locale/datePicker';
import { useTranslation } from 'react-i18next';

import DatePicker from 'react-datepicker';
import { Error } from 'components';

import { useDispatch, useSelector } from 'react-redux';
import {
  pickupDatesSelector,
  selectedPickupDateSelector,
  setSelectedPickupDate,
} from '../../../../../store/portalOrderSlice';
import { Box, Stack } from '@mui/material';

const formatDateISO = dateParam => {
  const date = new Date(dateParam);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

const CheckboxDateTime = ({
  option,
  context,
  index,
  groupIndex,
  errorId,
  serviceIndex,
  optionParentIndex,
  config,
  loaderRef,
}) => {
  const contextVASes = context.state.additionalServices;
  const contextVASCode = contextVASes[serviceIndex].code;
  const contextPickupDateValue = context.state.pickupDate.value;
  const contextUpdateState = context.updateState;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const now = useMemo(() => new Date(), []);

  const optionValue = useMemo(() => {
    return new Date(option?.value) || now;
  }, [option?.value, now]);

  const [minDate, setMinDate] = useState(optionValue);
  const [minTime, setMinTime] = useState(optionValue);

  const { pickupDates } = useSelector(pickupDatesSelector);
  const { preSelectedTDLDate } = useSelector(state => state.portalOrder);
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);

  const isTimeDefiniteLoading = config.vasSpecialCaseForPickupDate.indexOf(contextVASCode) !== -1;
  const isTimeDefiniteUnloading = config.vasSpecialCaseForDeliveryDate.indexOf(contextVASCode) !== -1;

  const timeDefiniteLoadingService = contextVASes.find(item => {
    return item.code === 'timeDefiniteLoading';
  });
  const timeDefiniteUnloadingService = contextVASes.find(item => {
    return item.code === 'timeDefiniteUnloading';
  });

  const timeDefiniteLoadingDate = useMemo(
    () => timeDefiniteLoadingService?.value && new Date(timeDefiniteLoadingService.groups[0].options[0][0].value),
    [timeDefiniteLoadingService],
  );

  const language = localStorage.getItem(config.locale.localStorageVariable) || config.locale.defaultLanguage;

  const updateValueInContext = useCallback(
    date => {
      // date is Date or null
      const additionalServices = contextVASes;
      const additionalService = JSON.parse(JSON.stringify(additionalServices[serviceIndex]));
      const additionalServiceParentOption = additionalService.groups[groupIndex].options[optionParentIndex];
      const additionalServiceOption = additionalServiceParentOption[index];
      additionalServiceOption.value = date;
      additionalServices[serviceIndex] = additionalService;

      const newIsoPickupDate = formatDateISO(date);

      contextUpdateState({
        additionalServices,
      });

      if (isTimeDefiniteLoading) {
        dispatch(setSelectedPickupDate(newIsoPickupDate));
      }
    },
    [
      contextVASes,
      contextUpdateState,
      groupIndex,
      index,
      optionParentIndex,
      serviceIndex,
      dispatch,
      isTimeDefiniteLoading,
    ],
  );

  const handleChange = useCallback(
    date => {
      // date is Date or null
      updateValueInContext(date);
    },
    [updateValueInContext],
  );

  const getErrorMessage = () => {
    let errorMessage = '';

    if (option.error) {
      errorMessage = t('general|errors|Please select date');
    }

    return <Error name={errorId ? errorId : 'OptionDateTime'} className="frc__input--error" message={errorMessage} />;
  };

  useEffect(() => {
    if (isTimeDefiniteLoading) {
      setMinDate(new Date(pickupDates[0]));
    }
  }, [isTimeDefiniteLoading, pickupDates]);

  const [timeWasSet, setTimeWasSet] = useState(false);

  useEffect(() => {
    if (isTimeDefiniteLoading && selectedPickupDate && optionValue) {
      const selectedPickupDateISO = new Date(formatDateISO(selectedPickupDate));
      const optionValueISO = new Date(formatDateISO(optionValue));

      if (selectedPickupDateISO.getTime() !== optionValueISO.getTime()) {
        const newDate = new Date(selectedPickupDate);
        newDate.setHours(optionValue.getHours(), optionValue.getMinutes(), 0);
        handleChange(newDate);
      } else if (!timeWasSet && !preSelectedTDLDate) {
        const newDate = new Date(selectedPickupDate);
        const now = new Date();
        newDate.setHours(now.getHours(), now.getMinutes(), 0);
        const adjusted = new Date(newDate);
        handleChange(adjusted);
        setTimeWasSet(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeDefiniteLoading, handleChange, selectedPickupDate, optionValue, timeWasSet]);

  useEffect(() => {
    if (isTimeDefiniteLoading) {
      if (formatDateISO(option?.value) === formatDateISO(now)) {
        setMinTime(now);
      } else {
        const newMinTime = new Date(now);
        newMinTime.setHours(0);
        newMinTime.setMinutes(0);
        newMinTime.setSeconds(0);
        newMinTime.setMilliseconds(0);

        setMinTime(newMinTime);
      }
    }
  }, [now, option?.value, isTimeDefiniteLoading]);

  /**
   * Compare time-definite-loading and time-definite-unloading dates
   * and set the correct time-definite-unloading
   */
  useEffect(() => {
    if (!timeDefiniteLoadingService || !timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteLoadingService.value &&
      timeDefiniteUnloadingService.value &&
      timeDefiniteLoadingDate.getTime() > optionValue.getTime()
    ) {
      handleChange(timeDefiniteLoadingDate);
    }
  }, [
    optionValue,
    handleChange,
    isTimeDefiniteUnloading,
    timeDefiniteLoadingService,
    timeDefiniteUnloadingService,
    timeDefiniteLoadingDate,
  ]);

  /**
   * Compare time-definite-unloading and pickup date
   */
  useEffect(() => {
    if (!timeDefiniteUnloadingService || !isTimeDefiniteUnloading) {
      return;
    }

    if (
      isTimeDefiniteUnloading &&
      timeDefiniteUnloadingService.value &&
      new Date(contextPickupDateValue).getTime() > optionValue.getTime()
    ) {
      /**
       * Time definite unloading is in the past, the pickup date is in the future,
       * so set the time definite unloading to the same value as pickup date
       */

      handleChange(contextPickupDateValue);
    }
  }, [contextPickupDateValue, handleChange, isTimeDefiniteUnloading, timeDefiniteUnloadingService, optionValue]);

  useEffect(() => {
    if (isTimeDefiniteUnloading && timeDefiniteLoadingDate) {
      setMinDate(timeDefiniteLoadingDate);

      const timeDefiniteLoadingDateISO = new Date(formatDateISO(timeDefiniteLoadingDate));
      const optionValueISO = new Date(formatDateISO(optionValue));

      if (timeDefiniteLoadingDateISO.getTime() !== optionValueISO.getTime()) {
        const newDate = new Date(timeDefiniteLoadingDate);

        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        setMinTime(newDate);
      } else {
        setMinTime(timeDefiniteLoadingDate);
      }
    }
  }, [isTimeDefiniteUnloading, timeDefiniteLoadingDate, optionValue]);

  return (
    <Box>
      <Box>{t(`additionalServices|${option.optionCode}`)}</Box>
      <Stack direction="row" alignItems="center" gap="8px">
        <Box
          sx={{
            '& .react-datepicker__input-container > input': {
              p: '1.4rem 1.2rem',
              borderRadius: '4px',
              border: '1px solid #949494',
              width: '120px',
            },
          }}
        >
          <DatePicker
            selected={optionValue}
            showDisabledMonthNavigation
            onChange={date => handleChange(date)}
            locale={locales[language]}
            dateFormat={DATE_FORMAT}
            includeDates={pickupDates.map(item => new Date(item))}
            autoComplete="off"
            minDate={minDate}
          />
        </Box>
        <Box
          sx={{
            '& .react-datepicker__input-container > input': {
              p: '1.4rem 1.2rem',
              borderRadius: '4px',
              border: '1px solid #949494',
              width: '75px',
            },
          }}
        >
          <DatePicker
            autoComplete="off"
            selected={optionValue}
            onChange={date => handleChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            locale={locales[language]}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            timeCaption={t('general|labels|inputs|Time')}
            minTime={minTime}
            maxTime={new Date(0, 0, 0, 23, 59)}
          />
        </Box>
      </Stack>
      {option.error && option.errorMessage ? getErrorMessage() : ''}
    </Box>
  );
};

export default CheckboxDateTime;
