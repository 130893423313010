export const state = {
  pickupDate: { value: new Date(), error: false },
  desiredPickupDates: [],
  additionalServices: [],
  chargeableWeight: '',
  payerCode: '',
  additionalServicesError: false,
  additionalServicesGeneralError: false,
  deliveryMonitoringExpanded: false,
  selectedProducts: [],
  ltcReturnedProducts: [],
  products: [],
  selectProductError: false,
  matchedProducts: [],
  loaders: {
    products: { value: false },
    additionalServices: { value: false },
    shipmentDetails: { value: false },
  },
  dhlParti: {
    value: false,
    allowed: false,
    code: '212',
    description: 'DHL Parti Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlPall: {
    value: false,
    allowed: false,
    code: '210',
    name: 'DHL Pall',
    description: 'DHL Pall Tooltip',
    packageTypes: [
      { code: '701', name: 'DHL Helpall' },
      { code: '702', name: 'DHL Halvpall' },
    ],
  },
  dhlStycke: {
    value: false,
    allowed: false,
    code: '211',
    description: 'DHL Stycke Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlPaket: {
    value: false,
    allowed: false,
    code: '102',
    description: 'DHL Paket Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlParcelConnectPlus: {
    value: false,
    allowed: false,
    code: '112',
    description: 'DHL Parcel Connect Plus Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlServicePoint: {
    value: false,
    allowed: false,
    code: '103',
    description: 'DHL Service Point Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlServicePointReturn: {
    value: false,
    allowed: false,
    code: '104',
    description: 'DHL Service Point Retur Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlParcelConnect: {
    value: false,
    allowed: false,
    code: '109',
    description: 'DHL Parcel Connect Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlParcelReturnConnect: {
    value: false,
    allowed: false,
    code: '107',
    description: 'DHL Parcel Return Connect Tooltip',
    packageTypes: [
      { code: 'CLL', name: 'Unspecified Colli' },
      { code: 'PK', name: 'Package' },
    ],
  },
  dhlHomeDelivery: {
    value: false,
    allowed: false,
    code: '401',
    description: 'DHL Home Delivery Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlHomeDeliveryReturn: {
    value: false,
    allowed: false,
    code: '402',
    description: 'DHL Home Delivery Return Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlEuroConnect: {
    value: false,
    allowed: false,
    code: '202',
    description: 'DHL Euroconnect Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlEuroLine: {
    value: false,
    allowed: false,
    code: '205',
    description: 'DHL Euroline Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlEuroConnectPlus: {
    value: false,
    allowed: false,
    code: '232',
    description: 'DHL Euroconnect Plus Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  dhlEurapid: {
    value: false,
    allowed: false,
    code: '233',
    description: 'DHL Eurapid Tooltip',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ECD: {
    value: false,
    allowed: false,
    code: 'ECD',
    description: 'ECD',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ECI: {
    value: false,
    allowed: false,
    code: 'ECI',
    description: 'ECI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ELD: {
    value: false,
    allowed: false,
    code: 'ELD',
    description: 'ELD',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ELI: {
    value: false,
    allowed: false,
    code: 'ELI',
    description: 'ELI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  ERI: {
    value: false,
    allowed: false,
    code: 'ERI',
    description: 'ERI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  HDI: {
    value: false,
    allowed: false,
    code: 'HDI',
    description: 'HDI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  HDL: {
    value: false,
    allowed: false,
    code: 'HDL',
    description: 'HDL',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  SPD: {
    value: false,
    allowed: false,
    code: 'SPD',
    description: 'SPD',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  SPI: {
    value: false,
    allowed: false,
    code: 'SPI',
    description: 'SPI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  PPD: {
    value: false,
    allowed: false,
    code: 'PPD',
    description: 'PPD',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },
  PPI: {
    value: false,
    allowed: false,
    code: 'PPI',
    description: 'PPI',
    packageTypes: [
      { code: 'PK', name: 'Package' },
      { code: 'PL', name: 'Pallet' },
      { code: 'CLL', name: 'Unspecified Colli' },
    ],
  },

  markup: {
    value: false,
    error: false,
    percentage: { value: '', error: false },
    amount: { value: '', error: false },
  },
  quotePriceMap: {},
  allProductPrice: {},
  selectedProduct: {},
  goNextError: false,
  totals: [],
};
