import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { QuickBookReport } from './QuickBookReport';
import { useLazyGetBookingDocumentsQuery } from 'store/api/fcpBooking';
import { BulkBooking, DocumentType } from 'store/api/fcpBooking.types';
import { useAppSelector } from 'store/hooks';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';

type BulkBookingCompleteProps = {
  open: boolean;
  bookedCount?: number;
  shipmentCount?: number;
  documentList?: { label: string; count: number; documentType: DocumentType }[];
  bulkBookingId: BulkBooking['bulkBookingId'];
  onClose: () => void;
  dismissCompletdeBulkBookInProgress: boolean;
  bulkBooking: BulkBooking;
  pickupDateMoved: boolean;
};

export const BulkBookingComplete: React.FC<BulkBookingCompleteProps> = ({
  open,
  bookedCount = 0,
  shipmentCount = 0,
  pickupDateMoved,
  documentList = [],
  onClose,
  dismissCompletdeBulkBookInProgress,
  bulkBookingId,
  bulkBooking,
}) => {
  const { t } = useTranslation();

  const [openQuickBookReport, setOpenQuickBookReport] = React.useState(false);

  const [downloadDocument, { isFetching }] = useLazyGetBookingDocumentsQuery();

  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const documentHandleClick = async (documentType: DocumentType) => {
    const { data } = await downloadDocument({
      bulkBookingId,
      documentType,
      accountCountryCode,
    });
    window.open(data, '_blank');
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-testid="bulk-booking-complete-dialog"
        disableEscapeKeyDown
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            onClose();
          }
        }}
      >
        <DialogTitle data-testid="dialog-title" sx={{ textAlign: 'left' }}>
          {t('general|onholdOrders|shipmentsQuickBookComplete', { shipmentCount })}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                width: '100%',
              }}
              data-testid="booked-section"
            >
              <Box
                bgcolor="grey.100"
                p={2}
                sx={{
                  flex: 1,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <span data-testid="success-count">{bookedCount} </span>
                  <CheckCircleIcon
                    color="success"
                    sx={{
                      fontSize: '22px',
                    }}
                    data-testid="booked-icon"
                  />
                </Typography>
                <Typography data-testid="booked-label">
                  {t('general|onholdOrders|shipmentsQuickBookCompleteBookedLabel')}
                </Typography>
              </Box>
            </Grid>
            {bulkBooking.result?.errorCount && bulkBooking.result.errorCount > 0 ? (
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
                data-testid="error-section"
              >
                <Box
                  bgcolor="grey.100"
                  p={2}
                  sx={{
                    flex: 1,
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <span data-testid="error-count">{bulkBooking.result.errorCount || null}</span>

                    <ErrorIcon
                      color="error"
                      sx={{
                        fontSize: '22px',
                      }}
                      data-testid="error-icon"
                    />
                  </Typography>
                  <Typography data-testid="errors-label">
                    {t('general|onholdOrders|shipmentsQuickBookCompleteErrorsLabel')}
                  </Typography>
                  <Typography color="error" data-testid="requires-attention">
                    {t('general|requiresAttention')}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Typography variant="body1" sx={{ mt: '16px' }}>
            <Button
              onClick={() => {
                setOpenQuickBookReport(true);
              }}
              data-testid="quick-book-report-link"
            >
              {t('general|viewQuickBookReport')}
            </Button>
          </Typography>

          {pickupDateMoved && (
            <div data-testid="pickupDateMovedSection">
              <Typography variant="h4" sx={{ mt: '32px' }}>
                {t('general|pickupDateMovedNoticeTitle')}
              </Typography>
              <p>{t('general|onholdOrders|pickupDateMovedToNextBusinessDay')}</p>
              <p>{t('general|If there are any questions, please contact Customer Service for further information.')}</p>
            </div>
          )}

          {documentList.length > 0 ? (
            <>
              <Typography variant="h4" sx={{ mt: '32px' }} data-testid="documents-heading">
                {t('general|documentsFor', { count: bookedCount })}
              </Typography>

              <List>
                {documentList.map((doc, index) => (
                  <ListItem
                    key={doc.label}
                    sx={{ bgcolor: index % 2 === 0 ? 'grey.100' : 'white', justifyContent: 'space-between' }}
                    data-testid={`document-item-${index}`}
                  >
                    <ListItemText primary={`${doc.label} (${doc.count})`} data-testid={`document-label-${index}`} />
                    <Button
                      startIcon={isFetching ? <CircularProgress color="inherit" size="20px" /> : <DownloadIcon />}
                      color="error"
                      onClick={() => {
                        documentHandleClick(doc.documentType);
                      }}
                      data-testid={`download-button-${index}`}
                      disabled={isFetching}
                    >
                      {t('general|download')}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            data-testid="close-button"
            disabled={dismissCompletdeBulkBookInProgress}
            endIcon={dismissCompletdeBulkBookInProgress ? <CircularProgress color="inherit" size="20px" /> : undefined}
          >
            {t('general|close')}
          </Button>
        </DialogActions>
      </Dialog>
      <QuickBookReport
        bulkBooking={bulkBooking}
        open={openQuickBookReport}
        bookingId=""
        onClose={() => setOpenQuickBookReport(false)}
      />
    </>
  );
};
