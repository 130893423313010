// @ts-expect-error
import { ReactComponent as Loader } from '../../loader.svg';
import { GlobalBox } from './GlobalBox';
import type { BoxProps } from '@mui/material';

type Props = {
  BoxProps?: Omit<BoxProps, 'sx'>;
  sx?: BoxProps['sx'];
};

export const GlobalLoader = ({ BoxProps, sx }: Props) => {
  return (
    <GlobalBox BoxProps={BoxProps} sx={sx}>
      <Loader />
    </GlobalBox>
  );
};
