import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import {
  resetShipmentType,
  setShipmentDimensionsForProduct,
  setShipmentRowsNumberMax,
} from '../EnterShipmentDetails/EnterShipmentDetails-helpers';
import { clearDeliveryLocation } from '../EnterPickupLocation/EnterPickupLocation-helpers';
// import { testRegEx } from 'globals/utils/fieldValidations';
import { filterArrayOfObjectsByValuesInArray } from 'globals/utils/filtering';
import { runPostalCodesValidationForGServices } from '../../context/postal-codes-g-services-logic';
import { RECEIVER_PAYER, SENDER_PAYER, THIRD_PARTY_PAYER } from '../../../globals/constants';
import { resetPhoneNumberWhenSwitchedToDomestic } from '../../../globals/helpers/pickup-helpers';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const getUsersAccounts = async context => {
  const url = apiLinks.getUsersAccountsAndProductsData;

  // API call to get and format the data
  const stateObject = {};

  await apiRequest(url, 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      stateObject.originalAccounts = prepareAPIDataForAccountSelect(result);
    } else {
      stateObject.apiError = true;
    }
  });

  return stateObject;
};

// accounts with 'only' ERA EC EC Plus are filtered out
const filterOutProductsNotAllowed = products => {
  let filteredList;

  filteredList = products.filter(prod => {
    return config.allowedProducts.find(p => p.code === prod.productCode);
  });

  return filteredList;
};

export const prepareAPIDataForAccountSelect = result => {
  const accounts = [];

  if (result.data.accounts && result.data.accounts.length > 0) {
    result.data.accounts.forEach((account, index) => {
      const filteredList = filterOutProductsNotAllowed(account.products);

      if (filteredList && filteredList.length > 0) {
        accounts.push({
          name: `${account.accountNumber} (${account.accountReference})`,
          code: account.accountNumber,
          products: filteredList,
        });
      }
    });
  }

  return accounts;
};

export const productAPICall = async context => {
  const stateObject = {};
  const url = apiLinks.getFCPAPIProducts;
  const params = {
    headers: {
      Accept: 'application/json',
    },
  };

  ENV_DEV && console.message('API Request started ...');

  await apiRequest(url, 'GET', params)
    .then(result => {
      // ENV_DEV && console.log(result.data);

      if (result.status === CONST.STATUS_OK) {
        let products = [];
        let allowedProducts = [];
        const contextStateObject = {};

        if (config.allowedProducts && result.data) {
          allowedProducts = config.allowedProducts.map(prod => {
            context.state[prod.mapName].allowed = true;
            return prod.code;
          });
          products = filterArrayOfObjectsByValuesInArray(result.data, allowedProducts);
        }

        if (result.data && Array.isArray(result.data)) {
          result.data.forEach(res => {
            if (res.code === '9999') {
              config.maxAllowedShipmentDetailsRows = res.shipmentRowsNumberMax;
            }
          });
        } else {
          ENV_DEV && console.log(result);
        }

        contextStateObject.products = products;
        contextStateObject.minMaxDimensions = {};
        contextStateObject.toCountryMap = getInternationalCountries(result.data);

        // migrated missing property from config
        contextStateObject.minMaxDimensions.min_chargeable_weight_check_product_codes = ['212'];
        contextStateObject.minMaxDimensions.non_stackable_height = config.dimensions.non_stackable_height;
        contextStateObject.minMaxDimensions.loading_meter_const = config.dimensions.loading_meter_const;

        contextStateObject.products.forEach((product, ind) => {
          let mapCode = '';

          mapCode = config.allowedProducts.find(prod => {
            return product.code === prod.code;
          });

          contextStateObject.products[ind].description = context.state[mapCode.mapName].description;

          if (product.code === '109') {
            contextStateObject.minMaxDimensions.min_item_weight = product.piece.actualWeightMin;
            contextStateObject.minMaxDimensions.min_item_quantity = product.shipment.numberOfPiecesMin;
            contextStateObject.minMaxDimensions.min_total_quantity = product.shipment.numberOfPiecesMin;
            contextStateObject.minMaxDimensions.min_item_length = product.piece.lengthMin;
            contextStateObject.minMaxDimensions.min_item_width = product.piece.widthMin;
            contextStateObject.minMaxDimensions.min_item_height = product.piece.heightMin;
            contextStateObject.minMaxDimensions.min_item_volume = product.piece.volumeMin;
            contextStateObject.minMaxDimensions.min_item_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.min_total_volume = product.piece.volumeMin;
            contextStateObject.minMaxDimensions.min_total_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.min_shipment_weight = product.shipment.actualWeightMin;
            contextStateObject.minMaxDimensions.min_total_palletPlace = product.shipment.palletPlaceMin;
            contextStateObject.minMaxDimensions.min_item_palletPlace = product.shipment.palletPlaceMin;
          } else if (product.code === '212') {
            contextStateObject.minMaxDimensions.max_item_weight = product.piece.actualWeightMax;
            contextStateObject.minMaxDimensions.max_item_quantity = product.shipment.numberOfPiecesMax;
            contextStateObject.minMaxDimensions.max_total_quantity = product.shipment.numberOfPiecesMax;
            contextStateObject.minMaxDimensions.max_item_length = product.piece.lengthMax;
            contextStateObject.minMaxDimensions.max_item_width = product.piece.widthMax;
            contextStateObject.minMaxDimensions.max_item_height = product.piece.heightMax;
            contextStateObject.minMaxDimensions.max_item_volume = product.piece.volumeMax;
            contextStateObject.minMaxDimensions.max_item_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.max_total_volume = product.shipment.volumeMax;
            contextStateObject.minMaxDimensions.max_total_loading_meter = product.shipment.loadingMetreMax;
            contextStateObject.minMaxDimensions.max_shipment_weight = product.shipment.actualWeightMax;
            contextStateObject.minMaxDimensions.max_total_palletPlace = product.shipment.palletPlaceMax;
            contextStateObject.minMaxDimensions.max_item_palletPlace = product.shipment.palletPlaceMax;

            contextStateObject.minMaxDimensions.max_item_chargeableWeight = product.shipment.chargeableWeightMax;
            contextStateObject.minMaxDimensions.max_total_chargeableWeight = product.shipment.chargeableWeightMax;
          }
        });

        context.updateState(contextStateObject);
        ENV_DEV && console.success('API Request successful ...');
      } else {
        ENV_DEV && console.alert('API Request failed ...');
        stateObject.apiError = true;
      }
    })
    .catch(error => {
      ENV_DEV && console.log('fail to get product catalog', error);
    });

  return stateObject;
};

const getInternationalCountries = products => {
  const internationalShippingCountriesMap = {};

  products.forEach(product => {
    if (!product.isDomestic && product.toCountries && config.internationalProducts.indexOf(product.code) !== -1) {
      if (product.toCountries) {
        internationalShippingCountriesMap[product.code] = product.toCountries;
      }
    }
  });
  return internationalShippingCountriesMap;
};

export const getProductDescription = (code, productsList) => {
  let product = {};

  if (productsList && productsList.length > 0) {
    product = productsList.find(productData => {
      return code === productData.code;
    });
  }

  return product.description ? `${CONST.API_DOMAIN}|` + product.description : '';
};

export const beforeCheck = context => {
  return setLoadersState(context, true);
};

export const setLoadersState = (context, state) => {
  let stateObject = {};
  stateObject.loaders = JSON.parse(JSON.stringify(context.state.loaders));
  stateObject.loaders.products.value = state;
  stateObject.loaders.additionalServices.value = state;
  stateObject.loaders.locationDetails.value = state;

  return stateObject;
};

const getSelectedProductCodes = context => {
  const selectedProductCodes = [];
  const selectedProducts = [];

  config.allowedProducts.forEach(elm => {
    if (context.state[elm.mapName].value) {
      selectedProducts.push(elm.mapName);
      selectedProductCodes.push(elm.code);
    }
  });

  return {
    selectedProductCodes,
    selectedProducts,
    selectedProductCode: selectedProducts.length > 0 ? context.state[selectedProducts[0]].code.toString() : '',
  };
};

/**
 * afterCheck
 * performs additional logic after ticking a checkbox
 */
export const afterCheck = (context, params, t, callVas = true) => {
  const callbackState = {};

  let resetRow;
  let productDimensionReset = false;
  let shippingToMultipleAddresses = false;
  let incomingParamIsMutipleAddress = params && params.name === 'shippingToMultipleAddresses';
  let isOneInternationalProductSelected;

  callbackState.deliveryCountry = JSON.parse(JSON.stringify(context.state.deliveryCountry));
  // callbackState.deliveryPostalCode = JSON.parse(JSON.stringify(context.state.deliveryPostalCode));

  const { selectedProducts, selectedProductCodes, selectedProductCode } = getSelectedProductCodes(context);

  callbackState.shippingToMultipleAddresses = JSON.parse(JSON.stringify(context.state.shippingToMultipleAddresses));

  // One selected product
  if (selectedProducts.length === 1) {
    // Filter shipment types for the specific product
    let filteredTypes = context.state[selectedProducts[0]].packageTypes;

    callbackState.allowedShipmentTypes = [];

    filteredTypes.forEach(filtered => {
      callbackState.allowedShipmentTypes.push(config.shipmentTypes.find(obj => obj.dataRel === filtered.code));
    });

    // DHL Parti special case
    if (selectedProductCode === '212') {
      callbackState.shippingToMultipleAddresses.value = false;
    }

    if (callVas) {
      // API call for the Additional Services of the product
      if (config.getAdditionalServicesFromAPI) {
        getAdditionalServicesForOneSelectedProduct(selectedProductCode, context);
      } else {
        getPredefinedAdditionalServicesForOneSelectedProduct(selectedProductCode, context);
      }
    }

    // Set shipment details dimensions validations
    callbackState.dimensions = setShipmentDimensionsForProduct(selectedProductCode, context);

    // Set shipment rows max number
    callbackState.shipmentRowsNumberMax = setShipmentRowsNumberMax(selectedProductCode, context);

    resetRow = resetShipmentType(selectedProducts);
    // Removes select at least 1 product
    callbackState.productSelectionError = false;

    // MULTIPLE OR NO PRODUCTS
  } else {
    // Resets
    callbackState.additionalServices = [];
    callbackState.additionalServicesError = false;
    callbackState.allowedShipmentTypes = config.shipmentTypes;
    productDimensionReset = true;
    if (selectedProducts.length !== 0) {
      clearDeliveryLocation(context);
    }
    callbackState.shippingToMultipleAddresses.value = selectedProducts.length > 0 ? true : false;

    // Uncomment in case when 1 product should be selected
    // callbackState.productSelectionError = selectedProducts.length > 0 ? false : true;

    callbackState.shipmentRowsNumberMax = config.maxAllowedShipmentDetailsRows;

    // Reset Payer and accounts on DHL Parti deselect back to sender
    if (
      selectedProducts.length === 0 &&
      !params.selectedStatus &&
      context.state[params.name].code &&
      context.state[params.name].code.toString() === '212'
    ) {
      callbackState.payer = JSON.parse(JSON.stringify(context.state.payer));
      callbackState.payer.value = SENDER_PAYER;
      callbackState.payer.error = !!callbackState.payer.error;
      callbackState.receiverNumber = { value: '', error: false };
      callbackState.thirdPartyNumber = { value: '', error: false };
    }
  }

  if (incomingParamIsMutipleAddress) shippingToMultipleAddresses = params.selectedStatus;

  if (incomingParamIsMutipleAddress) {
    if (shippingToMultipleAddresses) {
      productDimensionReset = true;
      callbackState.shippingToMultipleAddresses.value = true;
      Object.assign(callbackState, clearDeliveryLocation(context, true));
    } else {
      callbackState.shippingToMultipleAddresses.value = false;
    }
  } else if (context.state.shippingToMultipleAddresses.value) {
    productDimensionReset = true;
  }

  if (productDimensionReset) {
    resetRow = JSON.parse(JSON.stringify(config.shipmentTypesFields['unspecified']));
    callbackState.dimensions = setShipmentDimensionsForProduct('212', context);
  }

  isOneInternationalProductSelected =
    config.internationalProducts.filter(product => selectedProductCodes.some(selectedProd => product === selectedProd))
      .length > 0 && selectedProducts.length === 1;

  if (callbackState.shippingToMultipleAddresses.value || !isOneInternationalProductSelected) {
    callbackState.internationalProductsSelected = false;
    context.state.deliveryMandatoryByPriorityService = false;
    callbackState.deliveryCountry.value = config.defaultCountryPortalPickup;
    callbackState.deliveryCountry.error = false;

    Object.assign(callbackState, resetPhoneNumberWhenSwitchedToDomestic(context.state));
  } else {
    callbackState.internationalProductsSelected = true;
    callbackState.deliveryCountry.value = '';
    callbackState.toCountries = reviseToCountries(context.state.toCountryMap[selectedProductCode], t);
  }

  Object.assign(callbackState, setLoadersState(context, false));
  // Resets shipment type select to first possible option

  // Selected products
  callbackState.selectedProducts = selectedProducts;
  callbackState.productSelectionError = selectedProducts.length > 0 ? false : true;

  // Reset details rows
  callbackState.shipmentDetailsRows = [];
  callbackState.shipmentDetailsRows.push(resetRow);

  // Reset totals
  callbackState.totals = JSON.parse(JSON.stringify(context.state.totals));
  callbackState.totals.shipmentDetailsRows.quantity.value = 1;
  callbackState.totals.shipmentDetailsRows.volume.value = 0;
  callbackState.totals.shipmentDetailsRows.weight.value = 0;
  callbackState.totals.shipmentDetailsRows.loadingMeter.value = 0;
  callbackState.totals.shipmentDetailsRows.palletPlace.value = resetRow.palletPlace.value;
  callbackState.totals.shipmentDetailsRows.chargeableWeight.value = null;
  [callbackState.accountNumber, callbackState.accounts] = updateAccountsBasedOnProductSelection(
    selectedProductCodes,
    context.state,
  );

  return callbackState;
};

const updateVASForSpecificProduct = async context => {
  const { selectedProductCode } = getSelectedProductCodes(context);

  if (config.internationalProducts.indexOf(selectedProductCode) !== -1 || selectedProductCode === '212') {
    await getAdditionalServicesForOneSelectedProduct(selectedProductCode, context);
  }

  await context.updateState(setLoadersState(context, false));
};

export const callVASOnCountryOrPayerChange = context => {
  context.extendedSetState(setLoadersState(context, true), {
    afterUpdate: updateVASForSpecificProduct,
  });
};

export const callbackAfterUpdate = async context => {
  if (context && context.state.selectedProducts && context.state.selectedProducts.length > 0) {
    await runPostalCodesValidationForGServices(context);
  }
};

const updateAccountsBasedOnProductSelection = (selectedProductCodes, state) => {
  const updatedAccountsCode = [];
  let originalAccounts = [];
  const accountNumber = JSON.parse(JSON.stringify(state.accountNumber));

  selectedProductCodes.forEach(code => {
    // 1st iterate accounts
    const updatedAccount = state.originalAccounts.filter(account => {
      // 2nd iterate products to check if matching with selected product codes
      const products = account.products.filter(product => product.productCode === code);
      // if true return account else account is filtered out
      return products.length > 0 ? true : false;
    });
    // identify the correct account
    if (updatedAccount.length > 0) {
      updatedAccount.forEach(account => {
        if (updatedAccountsCode.indexOf(account.code) === -1) {
          updatedAccountsCode.push(account.code);
        }
      });
    }
  });

  if (updatedAccountsCode.length > 0) {
    originalAccounts = JSON.parse(JSON.stringify(state.originalAccounts));
    originalAccounts.forEach(account => {
      if (updatedAccountsCode.some(updateAccountCode => updateAccountCode === account.code)) {
        account.disabled = false;
      } else {
        account.disabled = true;
      }
    });
  }

  //check if already selected account disabled in original account. reset selected account number
  if (originalAccounts.some(account => account.code === state.accountNumber.value * 1 && account.disabled)) {
    accountNumber.value = '';
    accountNumber.error = true;
  }

  return [accountNumber, originalAccounts];
};

// Update the toCountries array used for customs countries
const reviseToCountries = (toCountries, t) => {
  let toCountriesList = [
    {
      value: '',
      name: 'Select country',
    },
  ];

  toCountries.forEach(toCountry => {
    if (toCountry.country) {
      toCountriesList.push({
        name: toCountry.country.countryCode,
        value: toCountry.country.countryCode,
        customs: toCountry.country.customs,
      });
    }
  });

  return sortToCountries(toCountriesList, t);
};

// Sort order: first the Nordic countries in alphabetical order, then Germany, then the other countries in alphabetical order
const sortToCountries = (toCountriesList, t) => {
  const sortedToCountries = [];
  const topDisplayedCountries = ['DK', 'FI', 'NO', 'DE'];
  const bottomDisplayedCountries = [];

  sortedToCountries.push(toCountriesList[0]);

  // Add Scandinavian countries and Germany if present
  topDisplayedCountries.forEach(countryName => {
    let country = toCountriesList.find(country => country.name === countryName);
    if (country !== -1) {
      sortedToCountries.push(country);
    }
  });

  // Build bottomDisplayedCountries array
  toCountriesList.forEach(country => {
    if (country.value && topDisplayedCountries.indexOf(country.name) === -1) {
      bottomDisplayedCountries.push(country);
    }
  });

  bottomDisplayedCountries.sort((a, b) => t(`countries|${a.name}`).localeCompare(t(`countries|${b.name}`)));
  sortedToCountries.push(...bottomDisplayedCountries);

  return sortedToCountries;
};

const getPredefinedAdditionalServicesForOneSelectedProduct = (productCode, context) => {
  let stateObject = {};
  let additionalServices = [];

  config.predefinedAdditionalServices.forEach(additionalServiceGroup => {
    if (additionalServiceGroup.products.indexOf(productCode) > -1 && additionalServiceGroup.services) {
      additionalServiceGroup.services.forEach(service => {
        additionalServices.push(service);
      });
    }
  });

  // Reset of AS on product switch
  stateObject.additionalServices = [];
  context.updateState(stateObject, () => {
    // Add AS to context
    stateObject.additionalServices = JSON.parse(JSON.stringify(additionalServices));

    // Remove loaders
    Object.assign(stateObject, setLoadersState(context, false));

    context.updateState(stateObject);
  });
};

export const checkAndTranslateOptionSchemas = (additionalServices, pickupDate) => {
  const newVAS = additionalServices.map(additionalService => {
    let optionNotToHide = false;

    // Initialization of errors property for the conditional groups
    additionalService.errors = additionalService.errors || {};

    if (additionalService.groups) {
      additionalService.groups = additionalService.groups.map(group => {
        group.options = group.options.map(optionList => {
          optionList = optionList.map(option => {
            if (option.min === '0' && option.max === '0') {
              option.min = null;
              option.max = null;
            }

            if (option.type !== config.OptionFieldTypes.Hidden) {
              optionNotToHide = true;
            } else {
              optionNotToHide = false;
            }

            if (option.type === config.OptionFieldTypes.Telephone) {
              if (option.value) {
                const code = option.value.split('-')[0];
                const number = option.value.split('-')[1];
                option.value = [{ code: code, number: number, error: false }];
              } else {
                option.value = [{ code: config.defaultCountryPhoneCode, number: '', error: false }];
              }
            } else if (option.type === config.OptionFieldTypes.DateTime) {
              option.value =
                config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1 ||
                config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1
                  ? new Date(pickupDate).setHours(0, 0, 0, 0)
                  : new Date();
            } else if (option.type === config.OptionFieldTypes.LtcDate) {
              option.values = [];
            }

            if (option.conditionalGroup && option.conditionalGroup.length > 0) {
              let groupType = 1;
              let groupDelimiter = ',';

              config.conditionalGroupOptionsTypes.forEach(t => {
                if (option.conditionalGroup.indexOf(t.delimiter) > -1) {
                  groupType = t.type;
                  groupDelimiter = t.delimiter;
                }
              });

              option.conditionalGroupType = groupType;
              option.conditionalGroupDelimiter = groupDelimiter;
              option.conditionalGroup = option.conditionalGroup.split(groupDelimiter);
            }
            return option;
          });

          return optionList;
        });

        return group;
      });
    }
    additionalService.optionNotToHide = optionNotToHide;

    return additionalService;
  });

  return newVAS;
};

export const getTranslatedProperty = (VAS, languageCode) => {
  VAS.forEach(AD => {
    if (AD.translations) {
      let obj = AD.translations.find(obj => obj.languageCode === languageCode);

      if (typeof obj === 'object') {
        AD.translatedName = obj['name'];
        AD.translatedToolTip = obj['information'];
      } else {
        AD.translatedName = AD.name;
        AD.translatedToolTip = AD.information;
      }
    }
  });

  return VAS;
};

export const resetDateForLoadingUnloadingTimeDefiniteVas = (additionalServices, pickupDate) => {
  const newAdditionalServices = JSON.parse(JSON.stringify(additionalServices));

  // Time loading date
  const loadingDateVas = newAdditionalServices.find(
    additionalService => config.vasSpecialCaseForPickupDate.indexOf(additionalService.code) !== -1,
  );

  if (loadingDateVas && loadingDateVas.groups) {
    loadingDateVas.groups.forEach(group => {
      group.options.forEach(optionList => {
        optionList.forEach(option => {
          if (option.type === config.OptionFieldTypes.DateTime) {
            option.value = new Date(pickupDate);
          }
        });
      });
    });
  }

  // Time unloading date
  const unLoadingDateVas = newAdditionalServices.find(
    additionalService => config.vasSpecialCaseForDeliveryDate.indexOf(additionalService.code) !== -1,
  );

  if (unLoadingDateVas) {
    unLoadingDateVas.groups.forEach(group => {
      group.options.forEach(optionList => {
        optionList.forEach(option => {
          if (option.type === config.OptionFieldTypes.DateTime) {
            // if new selected pickup date greater than current unloading date; set new date to unloading
            if (new Date(pickupDate).getTime() > new Date(option.value).getTime()) {
              option.value = new Date(pickupDate);
            }
          }
        });
      });
    });
  }

  return newAdditionalServices;
};

const mapPayerCode = state => {
  const isDomestic =
    state.pickupCountry.value === config.defaultCountryPortalPickup &&
    state.deliveryCountry.value === config.defaultCountryPortalPickup;

  if (!isDomestic) return 'DAP'; // Sender always pays
  if (state.payer.value === RECEIVER_PAYER) return '3';
  if (state.payer.value === THIRD_PARTY_PAYER) return '4';

  return '1'; // Sender
};

/**
 * getAdditionalServicesForOneSelectedProduct
 * main filtering functionality
 */
const getAdditionalServicesForOneSelectedProduct = async (productCode, context) => {
  const stateObject = {};

  const pickupCode = context.state.pickupCountry.value;
  const deliveryCode = context.state.deliveryCountry.value;
  const payerCode = mapPayerCode(context.state);
  const urlAdditionalServices = apiLinks.getAdditionalServicesExtended
    .replace('{productCode}', productCode)
    .replace('{pickupCode}', pickupCode)
    .replace('{deliveryCode}', deliveryCode)
    .replace('{payerCode}', payerCode);
  let additionalServicesError = false;
  let additionalServices = [];

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      // Initial filtering of the VAS
      if (result.status === CONST.STATUS_OK) {
        additionalServices = filterServicesForGivenProduct(productCode, result.data);
      } else {
        additionalServicesError = true;
      }

      // List of additional services after first API call
      stateObject.additionalServices = additionalServices;
      stateObject.additionalServicesError = additionalServicesError;

      // Options Schema and validations
      if (additionalServices && additionalServices.length > 0) {
        // Functionality to apply validation results:
        await getValidationResults(additionalServices, productCode);

        // Options Schema
        await getOptions(additionalServices, productCode, context.state.pickupDate.value);
        getTranslatedProperty(additionalServices, context.state.languageCode);
      }
    })
    .catch(() => {
      stateObject.additionalServicesError = true;
    });

  // Removes loaders
  Object.assign(stateObject, setLoadersState(context, false));

  // Renders final additional services
  context.updateState(stateObject);
};

/**
 * filterServicesForGivenProduct
 * initial filtering from the static config
 */
const filterServicesForGivenProduct = (productCode, allAS) => {
  let filteredAS;
  let ASAllowed = config.additionalServicesAllowed[productCode];
  // let ASToFilterOut = config.additionalServicesToBeFilteredOut[productCode];
  // if (!ASToFilterOut) {
  //   return allAS;
  // }

  if (!ASAllowed) {
    return {};
  }

  filteredAS = allAS.filter(additionalServices => {
    // let AStoStay = true;
    let AStoStay = false;

    ASAllowed.forEach(ASnameToRemove => {
      if (ASnameToRemove === additionalServices.code) {
        // AStoStay = false;
        AStoStay = true;
      }
    });

    return AStoStay;
  });

  return filteredAS;
};

/**
 * getValidationResultst
 * secondary filtering from validations recieved by API call
 */
const getValidationResults = async (additionalServices, productCode) => {
  const result = {};
  const urlValudationResults = apiLinks.getValidationResults.replace('{productCode}', productCode);
  let payloadDataForValidationResults = {};

  // Prepare payload for the API call
  additionalServices.forEach(service => {
    payloadDataForValidationResults[service.code] = true;
  });

  // API Call for validations
  await apiRequest(urlValudationResults, 'POST', {
    body: payloadDataForValidationResults,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyValidationRulesToASlist(additionalServices, result.data);
      }
    })
    .catch(() => {});

  return result;
};

const getOptions = async (additionalServices, productCode, pickupDate) => {
  const urlOptions = apiLinks.getPortalPickupVAS.replace('{productCode}', productCode);

  // API Call for optional schema
  await apiRequest(urlOptions, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyOptionSchemaResultToVAS(additionalServices, result.data);
        if (additionalServices) {
          additionalServices = checkAndTranslateOptionSchemas(additionalServices, pickupDate);
        }
      }
    })
    .catch(() => {});
};

/**
 * applyValidationRulesToASlist
 * apply data from the API call for secondary filtering
 */
const applyValidationRulesToASlist = (ASlist, validationResults) => {
  // apply notCompatible with list
  ASlist = ASlist.map(AS => {
    let validationResultsToApply = validationResults.find(ASwithValidationResults => {
      return ASwithValidationResults.additionalService === AS.code;
    });

    AS.notCompatibleWith = [];

    if (validationResultsToApply && validationResultsToApply.validationErrors.length > 0) {
      validationResultsToApply.validationErrors.forEach(validation => {
        if (validation.message && validation.message.indexOf('Not compatible') !== -1 && validation.fields) {
          AS.notCompatibleWith = AS.notCompatibleWith.concat(validation.fields);
        }
      });
    }

    return AS;
  });

  return ASlist;
};

export const applyOptionSchemaResultToVAS = (ASlist, optionsResults) => {
  ASlist = ASlist.map(AS => {
    const optionsToApply = optionsResults.find(osVAS => {
      return AS.code === osVAS.code;
    });

    if (optionsToApply && optionsToApply.groups) {
      AS.groups = transformOptionsToArrayOfOptions(optionsToApply.groups);
    }

    return AS;
  });

  return ASlist;
};

export const transformOptionsToArrayOfOptions = groups => {
  const newGroups = groups.map(group => {
    if (group.options) {
      group.options = [group.options];
    }

    return group;
  });

  return newGroups;
};
