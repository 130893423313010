import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ControllerRenderProps, FieldError, FieldValues, Path } from 'react-hook-form';
import { useConfigCountries } from 'hooks/useConfigCoutries';

/**
 * Example:
 *
 *   interface CountryType {
 *       code: string;
 *       label: string;
 *       phone: string;
 *       suggested?: boolean;
 *   }
 */

interface PhoneCountryAutoCompleteProps<TField extends FieldValues> {
  phoneCountryFieldProps: ControllerRenderProps<TField, Path<TField>>;
  phoneCountryError: FieldError | undefined;
}

export const PhoneCountryAutoComplete = <TField extends FieldValues>({
  phoneCountryFieldProps,
  phoneCountryError,
}: PhoneCountryAutoCompleteProps<TField>) => {
  const { companyPhoneCountryCodeOptions } = useConfigCountries();

  return (
    <Autocomplete
      id="PhoneCountryAutoComplete"
      sx={{ width: '100%', maxWidth: '180px' }}
      options={companyPhoneCountryCodeOptions}
      autoHighlight
      getOptionLabel={option => option.label}
      value={
        // @ts-expect-error
        companyPhoneCountryCodeOptions.find(country => country.code === phoneCountryFieldProps.value?.code) || null
      }
      onChange={(_, newValue) => phoneCountryFieldProps.onChange(newValue)}
      filterOptions={(options, state) => {
        const displayOptions = options.filter(option => {
          let cleanInput = state.inputValue.toLowerCase().trim();
          if (cleanInput[0] === '+') {
            cleanInput = cleanInput.slice(1);
          }
          return (
            `${option.phone}`.toLowerCase().trim().includes(cleanInput) ||
            `${option.code}`.toLowerCase().trim().includes(cleanInput)
          );
        });
        return displayOptions;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
            <Box sx={{ mr: '16px' }}>
              <span className={`fi fi-${option.code.toLowerCase()}`}></span>
            </Box>
            <Box sx={{ width: '30px' }}>{option.code}</Box>
            {option.phone}
          </Box>
        );
      }}
      renderInput={params => {
        const getValue = () => {
          const valueFromOptions = companyPhoneCountryCodeOptions.find(
            // @ts-expect-error
            item => item.label === params.inputProps.value,
          )?.phone;
          if (valueFromOptions) {
            return valueFromOptions;
          }
          return params.inputProps.value;
        };
        const value = getValue();
        return (
          <TextField
            {...params}
            error={!!phoneCountryError?.message}
            helperText={phoneCountryError?.message}
            inputProps={{
              ...params.inputProps,
              value,
              autoComplete: 'new-password', // Disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
};
