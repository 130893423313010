import i18n from 'i18next';

import { CONST } from './data-config';
import { apiLinks } from '../../config/api-config';
import { AUTHORIZATION } from '../../Login/constants';
import { RECEIVER_PAYER, SENDER_PAYER, THIRD_PARTY_PAYER } from '../constants';
import Cookies from 'js-cookie';
import { ROUTES } from '../../router';
import { setCookie as setCookieSecurely } from '../../utils/cookies';
import { isCountryEnabled } from 'locale/config';

const hashString = async value => {
  const msgUint8 = new TextEncoder().encode(value); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

export const setScopedCookie = async ({ cookieName, key, value, expiresDays = 30 }) => {
  if (!value || !key || !cookieName) return;
  const currentValue = Cookies.get(cookieName);
  // first 7 characters of SHA256 are considered uniqie to extent
  const hashedKey = (await hashString(key)).slice(0, 7);
  setCookieSecurely(
    cookieName,
    JSON.stringify({ ...(currentValue && JSON.parse(currentValue)), [hashedKey]: value }),
    expiresDays,
  );
};

export const getScopedCookie = async ({ cookieName, key }) => {
  if (!key || !cookieName) return;
  const currentValue = Cookies.get(cookieName);
  const hashedKey = (await hashString(key)).slice(0, 7);

  if (!currentValue) return;
  const parsedValue = JSON.parse(currentValue);

  return parsedValue?.[hashedKey];
};

const handleErrors = response => {
  if (!response.ok) throw response;
  return response;
};

export const getCookie = function (name) {
  let nameEQ = name + '=',
    cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    const c = cookieArray[i].trim();
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export const setToken = function (tokenValue_, time_, type_) {
  if (type_) {
    tokenValue_ = type_ + ' ' + tokenValue_;
  }
  localStorage.setItem(AUTHORIZATION, tokenValue_);
};

export const setCookie = function (name, value, milliseconds, timeFormat, path) {
  let expires = '',
    date = new Date(),
    time = milliseconds,
    timeFormat_ = timeFormat || 's',
    cookie;

  if (timeFormat_ === 's') {
    time = milliseconds * 1000;
  } else if (timeFormat_ === 'm') {
    time = milliseconds * 60 * 1000;
  } else if (timeFormat_ === 'h') {
    time = milliseconds * 60 * 60 * 1000;
  }

  if (time) {
    date.setTime(date.getTime() + time);
    expires = '; expires=' + date.toUTCString();
  }
  cookie = name + '=' + (value || '') + expires + '; path=/ ; secure';
  document.cookie = cookie;

  return true;
};

export const eraseCookie = function (name) {
  if (name) {
    Cookies.remove(name);
    return true;
  }
  return false;
};

export const downloadApiRequest = async (url, method = 'GET', params, callback) => {
  const ENV_DEV = process.env.NODE_ENV === 'development';
  const isPublicUrl = url.toLowerCase().includes('public') || url.toLowerCase().includes('error');

  const input = {
    responseType: 'blob',
    method,
    headers: {
      ...(!isPublicUrl && { Authorization: localStorage.getItem(AUTHORIZATION) }),
      'Country-Code': localStorage.getItem('country'),
      ...params?.headers,
    },
    body: params?.body ? JSON.stringify(params.body) : null,
  };

  return (
    fetch(url, input)
      // Successful request
      .then(handleErrors)
      .then(async response => {
        return response.blob();
      })

      // Error handling
      .catch(error => {
        error.message = parseInt(error.message);

        if (!ENV_DEV && error.status === CONST.UNAUTHORIZED_ERROR) {
          returnToSessionExpirePage();
          return;
        } else if (isNaN(error.message)) {
          error.message = error.status || 404;
        }

        return error;
      })
  );
};

export const getPayerAccount = state => {
  let payerAccount = {
    number: '',
    senderPays: false,
    receiverPays: false,
    thirdPartyPays: false,
  };

  if (state.shipmentPayer?.value) {
    switch (state.shipmentPayer.value) {
      case SENDER_PAYER:
        payerAccount.number = state.accountNumber.value;
        payerAccount.senderPays = true;
        break;
      case RECEIVER_PAYER:
        payerAccount.number = state.receiverNumber.value;
        payerAccount.receiverPays = true;
        break;
      case THIRD_PARTY_PAYER:
        payerAccount.number = state.thirdPartyNumber.value;
        payerAccount.thirdPartyPays = true;
        break;
      default: {
        // Falling in default = business requirements change
        payerAccount.number = null;
        payerAccount.thirdPartyPays = null;
      }
    }
  }

  return payerAccount;
};

export const getPropperAPIData = (state, servicepoint = false) => {
  const obj = JSON.parse(JSON.stringify(state));
  let payerAccount = {};

  if (state.pickupFromDifferentAddress?.value) {
    obj.pickupAddressResidential = obj.differentPickupAddressResidential;
    obj.pickupSender = obj.differentPickupSender;
    obj.pickupEmail = obj.differentPickupEmail;
    obj.pickupCountry = obj.differentPickupCountry;
    obj.pickupCompany = obj.differentPickupCompany;
    obj.pickupVatNumber = obj.differentPickupVatNumber;
    obj.pickupName = obj.differentPickupName;
    obj.pickupStreet = obj.differentPickupStreet;
    obj.pickupPostalCode = obj.differentPickupPostalCode;
    obj.pickupCity = obj.differentPickupCity;
    obj.pickupPhoneNumbers = obj.differentPickupPhoneNumbers;
  }

  if (state.deliverToDifferentAddress?.value && !servicepoint) {
    obj.deliveryAddressResidential = obj.differentDeliveryAddressResidential;
    obj.deliveryReceiver = obj.differentDeliveryReceiver;
    obj.deliveryEmail = obj.differentDeliveryEmail;
    obj.deliveryCountry = obj.differentDeliveryCountry;
    obj.deliveryCompany = obj.differentDeliveryCompany;
    obj.deliveryVatNumber = obj.differentDeliveryVatNumber;
    obj.deliveryName = obj.differentDeliveryName;
    obj.deliveryStreet = obj.differentDeliveryStreet;
    obj.deliveryPostalCode = obj.differentDeliveryPostalCode;
    obj.deliveryCity = obj.differentDeliveryCity;
    obj.deliveryPhoneNumbers = obj.differentDeliveryPhoneNumbers;
  }

  if (servicepoint) {
    Object.assign(obj, servicePointAddress(obj, state));
  }

  payerAccount = getPayerAccount(obj);
  obj.payerAccount = payerAccount.number;

  return obj;
};

export const servicePointAddress = (oldObj, state) => {
  const obj = JSON.parse(JSON.stringify(oldObj));

  obj.deliveryCountry.value = state.selectedServicePoint.countryCode;
  obj.deliveryStreet.value = state.selectedServicePoint.street;
  obj.deliveryPostalCode.value = state.selectedServicePoint.postalCode;
  obj.deliveryCity.value = state.selectedServicePoint.cityName;

  return obj;
};

export const apiRequest = async (url, method = 'GET', params, callback, redirect401 = true) => {
  try {
    const ENV_DEV = process.env.NODE_ENV === 'development';
    let requestObj = {},
      responseObj = {};

    // Set METHOD
    requestObj.method = method;

    const isPublicUrl = url.toLowerCase().includes('public') || url.toLowerCase().includes('error');

    // Set HEADERS
    if (params && params.headers) {
      requestObj.headers = {};
      requestObj.headers = { 'Country-Code': localStorage.getItem('country'), ...params.headers };
      requestObj.headers.Accept = params.headers.accept || 'application/json';
      requestObj.headers['Content-Type'] = params.headers.contentType || 'application/json';
      params.headers.Referer
        ? (requestObj.headers.Referer = params.headers.Referer)
        : (requestObj.headers.Referer = null);
      if (params.headers.transactionId) {
        requestObj.headers['Transaction-Id'] = params.headers.transactionId;
      }
      if (params.headers.UniqueCode) {
        requestObj.headers.UniqueCode = params.headers.UniqueCode;
      }
      if (params.headers.responseType) {
        requestObj.headers.responseType = params.headers.responseType;
        delete requestObj.headers.Accept;
      }
    } else {
      requestObj.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Country-Code': localStorage.getItem('country'),
      };
    }

    if (params && params.headers && params.headers.file) {
      delete requestObj.headers['Content-Type'];
      requestObj.body = params.body;

      if (params.headers && params.headers.answer) {
        requestObj.headers.answer = params.headers.answer;
      }
      if (params.headers && params.headers.token) {
        requestObj.headers.token = params.headers.token;
      }
    } else if (params && params.body) {
      requestObj.body = JSON.stringify(params.body);
    }

    if (!isPublicUrl) {
      requestObj.headers.Authorization = localStorage.getItem(AUTHORIZATION);
    }

    // RESPONSE
    return await fetch(url, requestObj)
      // Successful request
      .then(handleErrors)
      .then(async response => {
        responseObj.status = response.status;
        if (params && params.headers && params.headers.responseType === 'blob') {
          const blob = await response.blob();

          const disposition = response.headers.get('content-disposition');
          if (disposition && disposition.indexOf('filename') !== -1) {
            const [, filename] = disposition.split('filename=');
            if (filename) {
              blob.filename = filename.replace(/['"]/g, '');
            }
          }

          return blob;
        } else if (params && params.headers && params.headers.type === 'pdf') {
          responseObj.data = await response.body.getReader();
        } else if (params && params.headers && params.headers.accept === 'text/csv') {
          responseObj.data = await response.text();
        } else if (params && params.headers && params.headers.accept === 'application/text') {
          responseObj.data = await response.text();
        } else {
          try {
            responseObj.data = await response.json();
          } catch (err) {
            ENV_DEV && console.log('Error parsing API response (the response could be empty): ', err);
            responseObj.data = {};
          }
        }

        return responseObj;
      })

      // Error handling
      .catch(error => {
        // ENV_DEV && console.log('Error occurred', error);

        try {
          if (error.status === CONST.UNAUTHORIZED_ERROR && redirect401) {
            returnToSessionExpirePage();
          } else {
            let errorMap = {};
            return error
              .json()
              .then(body => {
                errorMap.data = body;
                errorMap.status = error.status;
                return errorMap;
              })
              .catch(e => {
                return error;
              });
          }
        } catch (e) {
          ENV_DEV && console.log('Error parsing promise', error);
          return error;
        }
      });
  } catch (error) {
    console.error('Error', error);
    return null;
  }
};

const returnToSessionExpirePage = () => {
  const countryLanguages = i18n.languages; // for example ['se-en']
  const urlRegexPattern = /^[A-Za-z]{2}/g;
  const language = localStorage.getItem('language');
  const country = localStorage.getItem('country').replaceAll('"', '');
  const countryHasTwoChars = country.length === 2;
  const currentCountryLanguage = `${country?.toLowerCase()}-${language?.toLowerCase()}`;

  if (
    urlRegexPattern.test(language) &&
    countryHasTwoChars &&
    isCountryEnabled(country) &&
    countryLanguages.includes(currentCountryLanguage)
  ) {
    window.location = encodeURI(`/${country}-${language}/${ROUTES.error401}`);
  }
};

export const handleAddressBookCalls = async ({ state }) => {
  let hasAddressBookErrors = false;
  let hasMaxLimitReached = false;

  // Add sender to address book
  if (state.addToAddressBookSender.value) {
    const url = apiLinks.postSaveAddress;
    const params = {
      body: {
        buildingNumber: null,
        city: state.pickupCity?.value || null,
        companyName: state.pickupSender?.value || null,
        countryCode: state.pickupCountry?.value || null,
        deliveryInstructions: null,
        email: state.pickupEmail?.value || null,
        emailConfirmation: state.pickupEmailConfirmation?.value || null,
        name: state.pickupName?.value || null,
        notes: null,
        postalCode: state.pickupPostalCode?.value || null,
        reference: state.pickupSenderReference?.value || null,
        street: state.pickupStreet?.value || null,
        residentialAddress: state.pickupAddressResidential?.value,
      },
    };

    if (state.pickupPhoneNumbers[0].number) {
      params.body.phone = state.pickupPhoneNumbers[0].code + '-' + state.pickupPhoneNumbers[0].number;
    }

    await apiRequest(url, 'POST', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
        hasMaxLimitReached = hasMaxLimitReached || CONST.TOO_MANY_REQUESTS_ERROR === result.status;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Add different sender to address book
  if (state.addToAddressBookDifferentSender && state.addToAddressBookDifferentSender.value) {
    const url = apiLinks.postSaveAddress;
    const params = {
      body: {
        buildingNumber: null,
        city: state.differentPickupCity?.value || null,
        companyName: state.differentPickupSender?.value || null,
        countryCode: state.differentPickupCountry?.value || null,
        deliveryInstructions: null,
        email: state.differentPickupEmail?.value || null,
        emailConfirmation: state.differentPickupEmailConfirmation?.value || null,
        name: state.differentPickupName?.value || null,
        notes: null,
        postalCode: state.differentPickupPostalCode?.value || null,
        reference: state.pickupSenderReference?.value || null,
        street: state.differentPickupStreet?.value || null,
        residentialAddress: state.differentPickupAddressResidential?.value || null,
      },
    };

    if (state.differentPickupPhoneNumbers[0].number) {
      params.body.phone = state.differentPickupPhoneNumbers[0].code + '-' + state.differentPickupPhoneNumbers[0].number;
    }

    await apiRequest(url, 'POST', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
        hasMaxLimitReached = hasMaxLimitReached || CONST.TOO_MANY_REQUESTS_ERROR === result.status;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Update address for sender
  if (state.updateInAddressBookSender.value) {
    const url = apiLinks.putUpdateAddress.replace('{id}', state.senderAddressID.value);
    const params = {
      body: {
        buildingNumber: null,
        city: state.pickupCity?.value || null,
        companyName: state.pickupSender?.value || null,
        countryCode: state.pickupCountry?.value || null,
        deliveryInstructions: null,
        email: state.pickupEmail?.value || null,
        emailConfirmation: state.pickupEmailConfirmation?.value || null,
        name: state.pickupName?.value || null,
        notes: null,
        postalCode: state.pickupPostalCode?.value || null,
        reference: state.pickupSenderReference?.value || null,
        street: state.pickupStreet.value,
        id: state.senderAddressID?.value || null,
        addressId: state.senderAddressAddressID?.value || null,
        residentialAddress: state.pickupAddressResidential?.value || null,
      },
    };

    if (state.pickupPhoneNumbers[0].number) {
      params.body.phone = state.pickupPhoneNumbers[0].code + '-' + state.pickupPhoneNumbers[0].number;
    }

    await apiRequest(url, 'PUT', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Update different address for sender
  if (state.updateInAddressBookDifferentSender && state.updateInAddressBookDifferentSender.value) {
    const url = apiLinks.putUpdateAddress.replace('{id}', state.differentSenderAddressID.value);
    const params = {
      body: {
        buildingNumber: null,
        city: state.differentPickupCity.value,
        companyName: state.differentPickupSender.value,
        countryCode: state.differentPickupCountry.value,
        deliveryInstructions: null,
        email: state.differentPickupEmail?.value || null,
        emailConfirmation: state.differentPickupEmailConfirmation?.value || null,
        name: state.differentPickupName.value,
        notes: null,
        postalCode: state.differentPickupPostalCode.value,
        reference: state.pickupSenderReference.value,
        street: state.differentPickupStreet.value,
        id: state.differentSenderAddressID?.value || null,
        addressId: state.differentSenderAddressAddressID?.value || null,
        residentialAddress: state.differentPickupAddressResidential?.value || null,
      },
    };

    if (state.differentPickupPhoneNumbers[0].number) {
      params.body.phone = state.differentPickupPhoneNumbers[0].code + '-' + state.differentPickupPhoneNumbers[0].number;
    }

    await apiRequest(url, 'PUT', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Add receiver to address book
  if (state.addToAddressBookReceiver.value) {
    const url = apiLinks.postSaveAddress;
    const params = {
      body: {
        buildingNumber: null,
        city: state.deliveryCity?.value || null,
        companyName: state.deliveryReceiver?.value || null,
        countryCode: state.deliveryCountry?.value || null,
        deliveryInstructions: null,
        email: state.deliveryEmail?.value || null,
        emailConfirmation: state.deliveryEmailConfirmation?.value || null,
        name: state.deliveryName?.value || null,
        notes: null,
        postalCode: state.deliveryPostalCode?.value || null,
        reference: state.deliveryReceiverReference?.value || null,
        street: state.deliveryStreet?.value || null,
        residentialAddress: state.deliveryAddressResidential?.value || null,
      },
    };

    if (state.deliveryPhoneNumbers[0].number) {
      params.body.phone = state.deliveryPhoneNumbers[0].code + '-' + state.deliveryPhoneNumbers[0].number;
    }

    await apiRequest(url, 'POST', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
        hasMaxLimitReached = hasMaxLimitReached || CONST.TOO_MANY_REQUESTS_ERROR === result.status;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Add different receiver to address book
  if (state.addToAddressBookDifferentReceiver && state.addToAddressBookDifferentReceiver.value) {
    const url = apiLinks.postSaveAddress;
    const params = {
      body: {
        buildingNumber: null,
        city: state.differentDeliveryCity?.value || null,
        companyName: state.differentDeliveryReceiver?.value || null,
        countryCode: state.differentDeliveryCountry?.value || null,
        deliveryInstructions: null,
        email: state.differentDeliveryEmail?.value || null,
        emailConfirmation: state.differentDeliveryEmailConfirmation?.value || null,
        name: state.differentDeliveryName?.value || null,
        notes: null,
        postalCode: state.differentDeliveryPostalCode?.value || null,
        reference: state.deliveryReceiverReference?.value || null,
        street: state.differentDeliveryStreet?.value || null,
        residentialAddress: state.differentPickupAddressResidential?.value || null,
      },
    };

    if (state.differentDeliveryPhoneNumbers[0].number) {
      params.body.phone =
        state.differentDeliveryPhoneNumbers[0].code + '-' + state.differentDeliveryPhoneNumbers[0].number;
    }

    await apiRequest(url, 'POST', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
        hasMaxLimitReached = hasMaxLimitReached || CONST.TOO_MANY_REQUESTS_ERROR === result.status;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Update receiver in address book
  if (state.updateInAddressBookReceiver.value) {
    const url = apiLinks.putUpdateAddress.replace('{id}', state.receiverAddressID.value);
    const params = {
      body: {
        buildingNumber: null,
        city: state.deliveryCity.value,
        companyName: state.deliveryReceiver.value,
        countryCode: state.deliveryCountry.value,
        deliveryInstructions: null,
        email: state.deliveryEmail?.value || null,
        emailConfirmation: state.deliveryEmailConfirmation?.value || null,
        name: state.deliveryName.value,
        notes: null,
        postalCode: state.deliveryPostalCode.value,
        reference: state.deliveryReceiverReference.value,
        street: state.deliveryStreet.value,
        id: state.receiverAddressID?.value || null,
        addressId: state.receiverAddressAddressID?.value || null,
        residentialAddress: state.deliveryAddressResidential?.value || null,
      },
    };

    if (state.deliveryPhoneNumbers[0].number) {
      params.body.phone = state.deliveryPhoneNumbers[0].code + '-' + state.deliveryPhoneNumbers[0].number;
    }

    await apiRequest(url, 'PUT', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // Update different receiver in address book
  if (state.updateInAddressBookDifferentReceiver && state.updateInAddressBookDifferentReceiver.value) {
    const url = apiLinks.putUpdateAddress.replace('{id}', state.differentReceiverAddressID.value);
    const params = {
      body: {
        buildingNumber: null,
        city: state.differentDeliveryCity.value,
        companyName: state.differentDeliveryReceiver.value,
        countryCode: state.differentDeliveryCountry.value,
        deliveryInstructions: null,
        email: state.differentDeliveryEmail?.value || null,
        emailConfirmation: state.differentDeliveryEmailConfirmation?.value || null,
        name: state.differentDeliveryName.value,
        notes: null,
        postalCode: state.differentDeliveryPostalCode.value,
        reference: state.deliveryReceiverReference.value,
        street: state.differentDeliveryStreet.value,
        id: state.differentReceiverAddressID?.value || null,
        addressId: state.differentReceiverAddressAddressID?.value || null,
        residentialAddress: state.differentPickupAddressResidential?.value || null,
      },
    };

    if (state.differentDeliveryPhoneNumbers[0].number) {
      params.body.phone =
        state.differentDeliveryPhoneNumbers[0].code + '-' + state.differentDeliveryPhoneNumbers[0].number;
    }

    await apiRequest(url, 'PUT', params)
      .then(result => {
        hasAddressBookErrors = hasAddressBookErrors || result.status !== CONST.STATUS_OK;
      })
      .catch(() => {
        hasAddressBookErrors = true;
      });
  }

  // max limit of 5000 reached, then it has highest priority in error section
  hasAddressBookErrors = hasMaxLimitReached ? false : hasAddressBookErrors;

  return { hasAddressBookErrors, hasMaxLimitReached };
};
