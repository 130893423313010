import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';
import { useAppSelector } from 'store/hooks';

import { sortCountriesAlpha } from 'globals/data-sorted-countries';
import { caseInsensitiveEquals } from 'globals/utils/strings';
import { CountryOption } from '../types/country.types';

export const useCountryOptions = (oppositeDirectionCountry?: string) => {
  const { t } = useTranslation();

  const accountCountryCode = useAppSelector(selectUserAccountCountryCode);
  if (!accountCountryCode) {
    throw new Error('No account country detected');
  }

  const allCountryOptions = useMemo(
    () =>
      sortCountriesAlpha({ ignoreSelectCountry: true }).map((country: CountryOption) => ({
        value: country.code,
        label: t(`countries|${country.code}`),
      })),
    [t],
  );

  const userCountryOption = useMemo(
    () => allCountryOptions.filter((option: any) => option.value === accountCountryCode),
    [accountCountryCode, allCountryOptions],
  );

  if (caseInsensitiveEquals(oppositeDirectionCountry, accountCountryCode) || oppositeDirectionCountry === undefined) {
    return allCountryOptions;
  }
  return userCountryOption;
};
