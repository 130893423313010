import { apiLinks } from '../../config/api-config';
import { AUTHORIZATION } from '../../Login/constants';
import { rootApi, TagTypes } from './apiSlice';
import {
  OnHoldShipmentListResponse,
  OnHoldShipmentCountResponse,
  OnHoldDetailResponse,
  BulkBookStatusResponse,
  BulkBookingPayload,
  MoveOnHoldShipmentsResponse,
  BulkBooking,
  DocumentType,
} from './fcpBooking.types';

const bookingApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getOnHoldList: builder.query<OnHoldShipmentListResponse, { accountCountryCode: string; accountNumbers?: string[] }>(
      {
        providesTags: [TagTypes.BOOKING_API_TAG, TagTypes.ONHOLD_LIST_TAG],
        query: ({ accountCountryCode, accountNumbers }) => ({
          url: apiLinks.getOnholdList,
          headers: {
            'Country-Code': accountCountryCode,
            Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
          },
          params: accountNumbers?.length ? { accountNumbers: accountNumbers.join(',') } : undefined,
        }),
        keepUnusedDataFor: 0,
      },
    ),
    getOnHoldCount: builder.query<OnHoldShipmentCountResponse, { accountCountryCode: string }>({
      providesTags: [TagTypes.BOOKING_API_TAG, TagTypes.ONHOLD_LIST_TAG],
      query: ({ accountCountryCode }) => ({
        url: apiLinks.getOnholdCount,
        headers: {
          'Country-Code': accountCountryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getOnHoldDetail: builder.query<OnHoldDetailResponse, { accountCountryCode: string; requestId: string }>({
      providesTags: [TagTypes.BOOKING_API_TAG],
      query: ({ accountCountryCode, requestId }) => ({
        url: apiLinks.getOnholdDetail.replace('{requestId}', requestId),
        headers: {
          'Country-Code': accountCountryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
    }),
    releaseOnHoldShipment: builder.mutation<void, { accountCountryCode: string; requestId: string }>({
      query: ({ accountCountryCode, requestId }) => ({
        url: apiLinks.releaseOnHoldShipment.replace('{requestId}', requestId),
        method: 'POST',
        headers: {
          'Country-Code': accountCountryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      invalidatesTags: [TagTypes.ONHOLD_LIST_TAG],
    }),
    bookOnHoldShipments: builder.mutation<
      BulkBookStatusResponse,
      BulkBookingPayload & { accountCountryCode: string; language: string }
    >({
      query: ({ accountCountryCode, language, ...rest }) => ({
        url: apiLinks.bookOnHoldShipments,
        method: 'POST',
        headers: {
          'Country-Code': accountCountryCode,
          Language: language,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: rest,
      }),
      invalidatesTags: [TagTypes.ONHOLD_LIST_TAG],
      transformErrorResponse: error => {
        if (error.status === 409) {
          return { ...error, skipToast: true };
        }
        return error;
      },
    }),
    moveOnHoldShipments: builder.mutation<
      MoveOnHoldShipmentsResponse,
      { accountCountryCode: string; requestIds: string[] }
    >({
      query: ({ accountCountryCode, requestIds }) => ({
        url: apiLinks.moveOnHoldShipments,
        method: 'POST',
        headers: {
          'Country-Code': accountCountryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: { requestIds },
      }),
      invalidatesTags: [TagTypes.ONHOLD_LIST_TAG],
    }),

    /**
     * bulk book status
     *    - nothing in progress if bulkBooking is null
     *    - in progress if result is null
     *    - completed if response has some result defined
     **/
    getBulkBookStatus: builder.query<BulkBookStatusResponse, void>({
      query: () => ({
        url: apiLinks.bookOnHoldShipments,
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      providesTags: [TagTypes.BOOKING_API_TAG],
    }),

    // Download bulk booking excel report
    getBookingReportExcel: builder.query<
      { blobUrl: string; filename: string },
      { bulkBookingId: BulkBooking['bulkBookingId']; accountCountryCode: string; language: string }
    >({
      query: ({ bulkBookingId, accountCountryCode, language }) => ({
        url: apiLinks.getBookingReportExcel.replace('{bulkBookingId}', `${bulkBookingId}`),
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
          'Country-Code': accountCountryCode,
          Language: language,
        },
        responseHandler: async response => await response.blob(),
        cache: 'no-cache',
      }),
      transformResponse: (response: Blob, meta) => {
        const fileName = meta?.response?.headers?.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1];
        return {
          blobUrl: window.URL.createObjectURL(response),
          filename: fileName || 'bulk-booking-report.xlsx',
        };
      },
      providesTags: [TagTypes.BOOKING_API_TAG],
    }),

    // Download booking documents afer bulk booking is completed
    getBookingDocuments: builder.query<
      string,
      { bulkBookingId: BulkBooking['bulkBookingId']; documentType: DocumentType; accountCountryCode: string }
    >({
      query: ({ bulkBookingId, documentType, accountCountryCode }) => ({
        url: apiLinks.getBookingDocuments
          .replace('{bulkBookingId}', `${bulkBookingId}`)
          .replace('{documentType}', documentType),
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
          'Country-Code': accountCountryCode,
        },
        responseHandler: async response => {
          const url = window.URL.createObjectURL(await response.blob());
          return url;
        },
        cache: 'no-cache',
      }),
      providesTags: [TagTypes.BOOKING_API_TAG],
    }),

    // bulk book status (dismiss completed booking)
    dismissCompletedBulkBook: builder.mutation<void, void>({
      query: () => ({
        url: apiLinks.dismissCompletedBulkBooking,
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      invalidatesTags: [TagTypes.BOOKING_API_TAG],
    }),
  }),
});

export const {
  useGetOnHoldListQuery,
  useGetOnHoldCountQuery,
  useGetOnHoldDetailQuery,
  useReleaseOnHoldShipmentMutation,
  useBookOnHoldShipmentsMutation,
  useMoveOnHoldShipmentsMutation,
  useGetBulkBookStatusQuery,
  useLazyGetBookingDocumentsQuery,
  useLazyGetBookingReportExcelQuery,
  useDismissCompletedBulkBookMutation,
} = bookingApi;
