import { useGetOnHoldCountQuery, useGetOnHoldListQuery } from 'store/api/fcpBooking';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';
import { useAppSelector } from 'store/hooks';
import { createContext, useContext } from 'react';
import type { OnHoldShipmentCountResponse, OnHoldShipmentListResponse } from 'store/api/fcpBooking.types';

type OnholdOrdersContexType = {
  setAccountsFilter: Dispatch<SetStateAction<string[]>>;
  accountsFilter: string[];
  totalShipmentCount: number;
  isBulkBookingDisabled: boolean;
  isSingleMoveDisabled: boolean;
  isBulkMoveDisabled: boolean;
  loading: boolean;
  onHoldList: OnHoldShipmentListResponse | undefined;
  onHoldCount: OnHoldShipmentCountResponse | undefined;
};

const OnholdOrdersContext = createContext<OnholdOrdersContexType>(null as unknown as OnholdOrdersContexType);

export const OnholdOrdersProvider = ({ children }: { children: React.ReactNode }) => {
  const [accountsFilter, setAccountsFilter] = useState<string[]>([]);
  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const { data: onHoldCount } = useGetOnHoldCountQuery({ accountCountryCode });
  const { data: onHoldList, isFetching } = useGetOnHoldListQuery({
    accountCountryCode,
    accountNumbers: accountsFilter,
  });

  const totalShipmentCount = useMemo(() => {
    if (!accountsFilter?.length) {
      return onHoldCount?.totalCount ?? 0;
    }
    return accountsFilter.reduce((acc, account) => acc + (onHoldCount?.countByAccount[account] ?? 0), 0);
  }, [accountsFilter, onHoldCount]);

  const isBulkBookingDisabled = onHoldList
    ? onHoldList.limits.counts.BULK_ERROR.current >= onHoldList.limits.counts.BULK_ERROR.maxAllowed
    : false;

  const isSingleMoveDisabled = onHoldList
    ? onHoldList.limits.counts.DOWNLOADED_MOVED.current >= onHoldList.limits.counts.DOWNLOADED_MOVED.maxAllowed
    : false;

  const isBulkMoveDisabled = isSingleMoveDisabled;

  return (
    <OnholdOrdersContext.Provider
      value={{
        setAccountsFilter,
        accountsFilter,
        totalShipmentCount,
        isBulkBookingDisabled,
        isSingleMoveDisabled,
        isBulkMoveDisabled,
        loading: isFetching,
        onHoldList,
        onHoldCount,
      }}
    >
      {children}
    </OnholdOrdersContext.Provider>
  );
};

export const useOnholdOrders = () => {
  const context = useContext(OnholdOrdersContext);
  if (!context) {
    throw new Error('Trying to use OnholdOrders context without context provider in the tree!');
  }
  return context;
};
