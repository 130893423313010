import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import {
  selectIsSwedishUser,
  selectTmsAccounts,
  selectUserAccountCountryCode,
  useAddTmsAccountMutation,
  useGetUserDataQuery,
} from 'store/api/fcpUser';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Typography,
} from '@mui/material';
import { useMemo, useState, useCallback, useEffect, SetStateAction, Dispatch } from 'react';
import { StyledTableCell } from './StyledTable';
import { Button } from '../Button';
import { Add, Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { ControlledTextField } from '../form-inputs';
import { PROFILE_CONSTANTS } from './constants';
import { toast } from 'react-toastify';
import { ChatWithUs } from '../ChatWithUs';

export const AddWaitingAccounts = () => {
  const { t } = useTranslation();
  const [accountFormOpen, setAccountFormOpen] = useState(false);
  const { isFetching: userRefetching } = useGetUserDataQuery();

  const allAccounts = useAppSelector(selectTmsAccounts);
  const waitingAccounts = useMemo(() => allAccounts.filter(account => account.status === 'WAITING'), [allAccounts]);

  return (
    <Stack spacing={2} pt={3}>
      <Typography variant="body1">{t(`editProfile|Account number(s) waiting for approval`)}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ whiteSpace: 'nowrap' }} width={200}>
                {t('registration|accountNumber')}
              </StyledTableCell>
              <StyledTableCell width={200}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waitingAccounts.map((account, index) => (
              <TableRow key={`${account.accountNumber}`}>
                <StyledTableCell component="th" scope="row" colSpan={2}>
                  <Stack>
                    <strong data-testid={`${index}-item-account-number`}>{account.accountNumber}</strong>
                    {account.accountReference ? <>({account.accountReference})</> : null}
                  </Stack>
                </StyledTableCell>
              </TableRow>
            ))}
            {accountFormOpen && (
              <TableRow>
                <StyledTableCell colSpan={2}>
                  <AddAccountForm setAccountFormOpen={setAccountFormOpen} />
                </StyledTableCell>
              </TableRow>
            )}
            {!waitingAccounts?.length && !accountFormOpen && (
              <TableRow>
                <StyledTableCell colSpan={2} align="left">
                  ---
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box pt={2}>
        <Button
          dataTestId="add-another-tms-account-button"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          loading={userRefetching}
          disabled={accountFormOpen}
          onClick={() => setAccountFormOpen(true)}
        >
          {t('general|Add another TMS account')}
        </Button>
      </Box>
    </Stack>
  );
};

type AddAccountFormValues = {
  accountNumber: string;
  accountReference: string;
};

export const AddAccountForm = ({ setAccountFormOpen }: { setAccountFormOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();

  const [addAccount, { isLoading, isError, isSuccess, error }] = useAddTmsAccountMutation();

  const accountCountryCode = useAppSelector(selectUserAccountCountryCode);
  const isSwedishUser = useAppSelector(selectIsSwedishUser);
  const allAccounts = useAppSelector(selectTmsAccounts);

  const { control, handleSubmit, formState } = useForm<AddAccountFormValues>({
    defaultValues: {
      accountNumber: '',
      accountReference: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const validateAccount = useCallback(
    (accountNumber: string) => {
      const swedishRegex = /^(\d{5,6}|\d{8}|\d{8}\.se\d{4})$/i;
      const globalRegex = new RegExp(`^\\d{8}\\.${accountCountryCode}\\d{4}$`, 'i');

      if (!accountNumber) {
        return t('general|labels|inputs|required');
      }

      if (allAccounts.some(account => account.accountNumber === accountNumber)) {
        return t('general|TMS Account Duplicated');
      }

      if (accountNumber.length > PROFILE_CONSTANTS.accountNumberCharacterLimit) {
        return t('general|errors|Invalid maxchar error', {
          name: t('registration|accountNumber'),
          max: PROFILE_CONSTANTS.accountNumberCharacterLimit,
        });
      }

      if (isSwedishUser && !swedishRegex.test(accountNumber)) {
        return t('general|labels|inputs|invalidFormat');
      }

      if (!isSwedishUser && !globalRegex.test(accountNumber)) {
        return t('general|labels|inputs|invalidFormat');
      }

      return true;
    },
    [accountCountryCode, allAccounts, isSwedishUser, t],
  );

  const onSubmit = (data: AddAccountFormValues) => {
    addAccount({
      tmsAccounts: [
        {
          accountNumber: data.accountNumber,
          accountReference: data.accountReference,
        },
      ],
    });
  };

  useEffect(() => {
    if (!isError) return;
    // @ts-expect-error
    if (error?.data?.message?.toLowerCase() === 'trying to add existing account') {
      toast(() => (
        <Alert severity="error" variant="standard">
          {t(`editProfile|existingAccountError`)}
        </Alert>
      ));
    } else {
      toast(() => (
        <Alert severity="error" variant="standard">
          {t(`editProfile|Account adding failed. Try again or call technical support`)} {t('general|or')}
          <ChatWithUs />
        </Alert>
      ));
    }
  }, [error, isError, t]);

  useEffect(() => {
    if (!isSuccess) return;
    toast(() => (
      <Alert severity="success" variant="standard">
        {t(`editProfile|TMS Account has been added`)}
      </Alert>
    ));
    setAccountFormOpen(false);
  }, [isSuccess, setAccountFormOpen, t]);

  return (
    <Box display="flex" justifyContent="space-between" component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box display="flex" gap={2}>
        <ControlledTextField
          control={control}
          fieldName="accountNumber"
          label={t('registration|accountNumber')}
          rules={{
            validate: validateAccount,
          }}
          TextFieldProps={{
            inputProps: {
              maxLength: PROFILE_CONSTANTS.accountNumberCharacterLimit,
            },
          }}
          testId="form-account-number"
          required
        />
        <ControlledTextField
          control={control}
          fieldName="accountReference"
          label={t('registration|accountReferenceNameOptional')}
          rules={{
            maxLength: {
              value: PROFILE_CONSTANTS.accountReferenceNameCharacterLimit,
              message: t('general|errors|Invalid maxchar error', {
                name: t('registration|accountReference'),
                max: PROFILE_CONSTANTS.accountReferenceNameCharacterLimit,
              }),
            },
          }}
          TextFieldProps={{
            inputProps: {
              maxLength: PROFILE_CONSTANTS.accountReferenceNameCharacterLimit,
            },
            sx: { maxWidth: '300px' },
          }}
          testId="form-account-reference-name"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '16px',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<Delete />}
          color="primary"
          disabled={isLoading}
          onClick={() => setAccountFormOpen(false)}
          sx={{
            '& svg': {
              fontSize: '26px',
            },
          }}
          dataTestId={`form-delete-button`}
        >
          {t('general|labels|buttons|Delete')}
        </Button>

        <Button
          color="primary"
          variant="contained"
          sx={{
            '& svg': {
              fontSize: '26px',
            },
          }}
          dataTestId={`form-submit-button`}
          type="submit"
          loading={isLoading}
          disabled={!formState.isValid}
        >
          {t('general|Submit')}
        </Button>
      </Box>
    </Box>
  );
};
