import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Box, IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import { locales } from 'locale/datePicker';
import { config, DATE_FORMAT } from '../../globals';
import getLanguage from 'globals/utils/getLanguage';
import { ErrorMessage } from './ErrorMessage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const getDatesForNextMonth = (): string[] => {
  const today = new Date();
  const oneMonthLater = new Date(today);
  oneMonthLater.setMonth(today.getMonth() + 1);

  const dates: string[] = [];

  for (let date = new Date(today); date <= oneMonthLater; date.setDate(date.getDate() + 1)) {
    const isoStringDate = new Date(date).toISOString();
    dates.push(isoStringDate);
  }

  return dates;
};

type Props = {
  pickupDates: string[];
  selectedPickupDate?: string;
  setSelectedPickupDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  disabled?: boolean;
  id?: string;
};

export const PickupDate = ({
  pickupDates,
  selectedPickupDate,
  setSelectedPickupDate,
  disabled: disabledProp,
  id,
}: Props) => {
  const { t } = useTranslation();

  const [openCalendar, setOpenCalendar] = useState(false);

  const language = useMemo(() => {
    return getLanguage(config.regEx.urlValidation);
    // TODO: Is the dependency on window.location.pathname necessary?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const locale = language ? locales[language as keyof typeof locales] : 'en';

  const includeDates = useMemo(() => {
    return pickupDates.map(item => new Date(item));
  }, [pickupDates]);

  const onChange = (date: Date | null) => {
    const newDate = date?.toISOString() || undefined;
    setSelectedPickupDate(newDate);
    setOpenCalendar(false);
  };

  const disabled = pickupDates.length === 0 || disabledProp;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          '& .react-datepicker__input-container > input': {
            pl: '44px',
            pr: '12px',
            py: '14px',
            borderRadius: '4px',
            border: '1px solid #949494',
            color: disabled ? '#b1b1b1' : undefined,
            borderColor: theme => (openCalendar ? theme.palette.primary.main : undefined),
            outline: theme => (openCalendar ? `1px solid ${theme.palette.primary.main}` : undefined),
          },
        }}
      >
        <IconButton
          aria-label="open calendar"
          sx={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            zIndex: 10,
          }}
          onClick={() => {
            setOpenCalendar(true);
          }}
          size="large"
          disabled={disabled}
        >
          <CalendarMonthIcon sx={{ fontSize: 25, color: openCalendar ? 'primary.main' : undefined }} />
        </IconButton>
        <DatePicker
          onClickOutside={() => setOpenCalendar(false)}
          onInputClick={() => setOpenCalendar(true)}
          open={openCalendar}
          disabled={disabled}
          showDisabledMonthNavigation
          autoComplete="off"
          id={id ?? 'pickupDate'}
          selected={selectedPickupDate ? new Date(selectedPickupDate) : null}
          locale={locale}
          dateFormat={DATE_FORMAT}
          onChange={onChange}
          includeDates={includeDates}
        />
      </Box>
      {!selectedPickupDate && pickupDates.length ? (
        <ErrorMessage id="pickupDateError" message={t(`general|errors|Please select date`)} />
      ) : null}
    </>
  );
};
