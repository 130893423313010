import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';
import { TagTypes } from './apiSlice';
import { AUTHORIZATION } from '../../Login/constants';

const quoteApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getPickupDatesMerged: builder.query<
      PickupDatesResponse,
      { countryCode: string; pickupDatesPayload: PickupDatesPayloadItem[] }
    >({
      providesTags: [TagTypes.QUOTE_TAG],
      query: ({ countryCode, pickupDatesPayload }) => ({
        url: apiLinks.pickupDatesMerged,
        method: 'POST',
        headers: {
          'Country-Code': countryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: { data: pickupDatesPayload },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetPickupDatesMergedQuery, useLazyGetPickupDatesMergedQuery } = quoteApi;

export type PickupDatesResponse = {
  pickupDates: string[];
};

export type PickupDatesPayloadItem = {
  accountNumber: string;
  productCode: string;
  countryFrom: string;
  zipcodeFrom: string;
  countryTo: string;
  zipcodeTo: string;
};
