import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';

const configApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getCountryConfig: builder.query<AggregatedCountryDto, { countryCode: string }>({
      query: ({ countryCode }) => ({
        url: apiLinks.getCountryConfig,
        headers: {
          'Country-Code': countryCode,
        },
      }),
      keepUnusedDataFor: 60 * 60,
    }),
  }),
});

export const { useGetCountryConfigQuery } = configApi;

type AggregatedCountryDto = {
  languages?: string[];
  defaultCurrency?: string;
  domesticDangerousGoodsEnabled?: boolean;
  disabledVass?: {
    [key: string]: string[];
  };
  freeVass?: {
    [key: string]: string[];
  };
  vasFilters?: {
    [key: string]: VasFilterDto[];
  };
};

type VasFilterDto = {
  name?: string;
  value?: string;
};
