import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  errors: SavedShipmentError[];
  onCloseDialog: () => void;
}

export interface SavedShipmentError {
  phraseCode: string;
  parameters: { [key: string]: string };
}

export const OnHoldErrorMessagesDialog: React.FC<Props> = ({ open, onCloseDialog, errors }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="md" fullWidth data-testid="onhold-error-messages-dialog">
      <DialogTitle data-testid="dialog-title" sx={{ textAlign: 'left' }}>
        {t('general|onHoldErrorsDialog|title')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="body1" mb="16px">
          {t('general|onHoldErrorsDialog|paragraph')}
        </Typography>

        <Typography variant="body1">{t('general|onHoldErrorsDialog|TheFollowingIssuesHaveBeenIdentified')}:</Typography>
        <List sx={{ mx: '32px' }}>
          {errors &&
            errors.map((error, index) => {
              const translatedParams = JSON.parse(JSON.stringify(error.parameters));
              // iterate over error.parameters keys and check if some of is 'vasCode' translate it than
              if (translatedParams && translatedParams.hasOwnProperty('vasCode')) {
                translatedParams['vasCode'] = t(`additionalServices|${translatedParams['vasCode']}|name`);
              }

              const translatedMessage = (
                <Trans i18nKey={error.phraseCode} values={translatedParams} components={[<strong />]} />
              );
              // @ts-ignore
              return (
                <ListItem data-testid={`errorListItem-${index}`} sx={{ listStyleType: 'square', display: 'list-item' }}>
                  {translatedMessage}
                </ListItem>
              );
            })}
        </List>
      </DialogContent>

      <DialogActions
        sx={{
          pt: '24px',
        }}
      >
        <Button onClick={onCloseDialog} color="primary" variant="contained" data-testid="close-button">
          {t('general|close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
