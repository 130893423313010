import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Stack,
  Container,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';
import ShipmentDetails from '../../../saved-shipments/components/ShipmentDetails/ShipmentDetails';
import { Loader } from '../Loader';
import { useGetOnHoldDetailQuery } from 'store/api/fcpBooking';
import { selectUserAccountCountryCode } from 'store/api/fcpUser';
import { useAppSelector } from 'store/hooks';

type PickupFormProps = {
  detailRequestId: string;
  onClose: () => void;
  onMoveShipment: () => void;
  onBookShipment: () => void;
};

export const BookingDetail: React.FC<PickupFormProps> = ({
  detailRequestId,
  onClose,
  onMoveShipment,
  onBookShipment,
}) => {
  const { t } = useTranslation();
  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const { data, isFetching, isError } = useGetOnHoldDetailQuery(
    { accountCountryCode, requestId: detailRequestId! },
    {
      skip: !detailRequestId,
    },
  );

  const actionsAvailable = !isFetching && !isError && data;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg" data-testid="dialog-bookingdetail">
      <DialogTitle
        sx={{
          textAlign: 'left',
          pb: '4px',
        }}
      >
        {t('general|Shipment Details')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 26, top: 26 }}
          data-testid="close-dialog"
        >
          <CloseIcon
            sx={{
              fontSize: '25px',
            }}
          />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isError && (
          <Container maxWidth="md" sx={{ py: 2 }}>
            <Alert variant="standard" severity="error">
              {t('general|errors|unexpectedErrorText')}
            </Alert>
          </Container>
        )}
        {isFetching && <Loader sx={{}} />}
        {!isFetching && data && <ShipmentDetails savedShipment={structuredClone(data)} dialogMode />}
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'space-between',
          borderTop: '1px solid #E0E0E0',
          pb: 0,
          pt: '16px',
          mx: '-16px',
          px: '36px',
        }}
      >
        <Button onClick={onClose} color="primary" variant="outlined" data-testid="close-button">
          {t('general|labels|buttons|Close')}
        </Button>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="16px">
          <Button
            onClick={onMoveShipment}
            color="primary"
            variant="outlined"
            startIcon={<CachedIcon />}
            data-testid="move-shipment-from-dialog"
            disabled={!actionsAvailable}
          >
            {t('general|onholdOrders|move')}
          </Button>
          <Button
            onClick={onBookShipment}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            data-testid="book-shipment-from-dialog"
            disabled={!actionsAvailable}
          >
            {t('general|Book Shipment')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
