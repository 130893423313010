import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import './css';

import 'flag-icons/css/flag-icons.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { Close } from '@mui/icons-material';
import { Alert, Box, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalLoader } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ROUTES } from 'router';
import AppPortalLogin from './Login/components/AppPortalLogin/AppPortalLogin';
import AppPortalAddressBook from './address-book/components/AppPortalAddressBook/AppPortalAddressBook';
import AppChangePassword from './change-password/components/AppChangePassword/AppChangePassword';
import { Analytics } from './components/Analytics';
import CountryLanguageLoader from './components/CountryLanguageLoader/CountryLanguageLoader';
import { ScrollToTop } from './components/ScrollToTop';
import { ErrorBoundary, RegistrationPage } from './components/fcp-components';
import { CountryConfigLoader } from './components/fcp-components/CountryConfigLoader';
import { Layout } from './components/fcp-components/Layout';
import { PasswordExpiry } from './components/fcp-components/PasswordExpiryModal.js';
import { EditProfile } from './components/fcp-components/edit-profile/EditProfile';
import { RegistrationThankYouPage } from './components/fcp-components/registration/RegistrationThankYouPage.js';
import AppDashboard from './dashboard/components/AppDashboard/AppDashboard';
import AppForgotPassword from './forgot-password/components/AppForgotPassword/AppForgotPassword';
import { useGetUser } from './hooks/api/useGetUser';
import { AuthProvider } from './hooks/useAuth';
import { ConfigCountriesProvider } from './hooks/useConfigCoutries';
import { CountryLanguageProvider } from './hooks/useCountryLanguage';
import { DEFAULT_LANGUAGE } from './locale/config';
import './locale/i18n.js';
import { ErrorPage } from './pages/ErrorPage';
import GeneralPage from './pages/GeneralPage';
import { HomePage } from './pages/HomePage';
import { LegalNotice } from './pages/LegalNotice.js';
import UnderMaintenancePage from './pages/UnderMaintenancePage';
import AppPalletPickup from './pallet-pickup/components/AppPalletPickup/AppPalletPickup';
import AppPalletTransactions from './pallet-transactions/components/AppPalletTransactions/AppPalletTransactions';
import AppPortalOrder from './portal-order/components/AppPortalOrder/AppPortalOrder';
import AppPortalPickup from './portal-pickup/components/AppPortalPickup/AppPortalPickup';
import AppPublicOrder from './public-order/components/AppPublicOrder/AppPublicOrder';
import AppPublicPickup from './public-pickup/components/AppPublicPickup/AppPublicPickup';
import AppQuoteTool from './quote/components/AppQuoteTool/AppQuoteTool';
import AppResetPassword from './reset-password/components/AppResetPassword/AppResetPassword';
import AppSavedShipments from './saved-shipments/components/AppSavedShipments/AppSavedShipments';
import { GlobalStyles } from './styles/global-styles';
import { muiTheme } from './styles/mui-theme';
import AppPortalTemplates from './templates/components/AppPortalTemplates/AppPortalTemplates';
import { isValidCountryLanguage } from './utils/urls.js';
import { CookieList } from './pages/CookieList.js';
import { OnholdOrders } from 'components/fcp-components/onhold-orders/OnholdOrders';
import { useGetUserDataQuery } from 'store/api/fcpUser';
import { PilPage } from './pages/PilPage';
import { OnholdOrdersProvider } from 'components/fcp-components/onhold-orders/useOnholdOrders';

const ENV_DEV = process.env.NODE_ENV === 'development';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 30 * 1000,
    },
  },
});

export default function App() {
  const { ready } = useTranslation();
  const defaultLanguage = localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE;

  return (
    <ThemeProvider theme={muiTheme}>
      {GlobalStyles}
      {isValidCountryLanguage ? (
        <CountryLanguageProvider>
          <QueryClientProvider client={queryClient}>
            <ConfigCountriesProvider>
              <Analytics />
              <CountryLanguageLoader />
              <AuthProvider>
                <ScrollToTop />
                <CountryConfigLoader />
                <ErrorBoundary>
                  <Routes>
                    <Route path="/:lang/under-maintenance" element={<UnderMaintenancePage />} />
                    <Route element={<Layout ready={ready} />}>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/login" element={<Navigate to={`../${defaultLanguage}/login`} replace />} />
                      <Route
                        path="/registration"
                        element={<Navigate to={`../${defaultLanguage}/registration`} replace />}
                      />
                      <Route
                        path="/forgot-password"
                        element={<Navigate to={`../${defaultLanguage}/forgot-password`} replace />}
                      />

                      <Route path="/:lang">
                        <Route path={ROUTES.login} element={<AppPortalLogin />} />
                        <Route path={ROUTES.registration} element={<RegistrationPage />} />
                        <Route path={ROUTES.registrationThankYou} element={<RegistrationThankYouPage />} />
                        <Route element={<ProtectedLayout />}>
                          <Route path={ROUTES.portalOrder} element={<AppPortalOrder />} />
                          <Route path={ROUTES.portalPickup} element={<AppPortalPickup />} />
                          <Route path={ROUTES.palletPickup} element={<AppPalletPickup />} />
                          <Route path={ROUTES.palletHistory} element={<AppPalletTransactions />} />
                          <Route path={ROUTES.quoteTool} element={<AppQuoteTool />} />
                          <Route path={ROUTES.dashboard} element={<AppDashboard />} />
                          <Route path={ROUTES.pilDashboard} element={<PilPage />} />
                          <Route path={ROUTES.savedShipments} element={<AppSavedShipments />} />
                          <Route path={ROUTES.addressBook} element={<AppPortalAddressBook />} />
                          <Route path={ROUTES.editProfile} element={<EditProfile />} />
                          <Route path={ROUTES.templates} element={<AppPortalTemplates />} />
                          <Route path={ROUTES.changePassword} element={<AppChangePassword />} />
                          <Route
                            path={ROUTES.onholdOrders}
                            element={
                              <OnholdOrdersProvider>
                                <OnholdOrders />
                              </OnholdOrdersProvider>
                            }
                          />
                        </Route>
                        <Route path={ROUTES.resetPassword} element={<AppResetPassword />} />
                        <Route path={ROUTES.forgotPassword} element={<AppForgotPassword />} />
                        <Route path={ROUTES.publicOrder} element={<AppPublicOrder />} />
                        <Route path={ROUTES.publicPickup} element={<AppPublicPickup />} />
                        {/* redirect OLD pages */}
                        <Route path="home.html" element={<Navigate to={'../'} replace />} />
                        <Route path="login.html" element={<Navigate to={'../'} replace />} />
                        <Route
                          path="home/public-tools/public-order-entry.html"
                          element={<Navigate to={`../${ROUTES.publicOrder}`} replace />}
                        />
                        <Route
                          path="home/public-tools/public-booking-pickup.html"
                          element={<Navigate to={`../${ROUTES.publicPickup}`} replace />}
                        />
                        <Route path="error/:errorNumber" element={<ErrorPage />} />
                        <Route path={ROUTES.legalNotice} element={<LegalNotice />} />
                        <Route path={ROUTES.cookieList} element={<CookieList />} />
                        <Route path=":slug" element={<GeneralPage />} />
                      </Route>
                      <Route path=":lang/" element={<HomePage />} />
                      <Route path="*" element={<Navigate to={`../${defaultLanguage}/error/404`} replace />} />
                    </Route>
                  </Routes>
                </ErrorBoundary>
              </AuthProvider>
            </ConfigCountriesProvider>
            {!ready ? <GlobalLoader /> : null}
            {ENV_DEV && <ReactQueryDevtools initialIsOpen={false} />}
            <ToastContainer
              autoClose={5000}
              position="bottom-center"
              closeButton={({ closeToast }) => (
                <IconButton onClick={closeToast}>
                  <Close />
                </IconButton>
              )}
              draggable={false}
              closeOnClick={false}
            />
          </QueryClientProvider>
        </CountryLanguageProvider>
      ) : (
        <Box
          sx={{
            maxWidth: '800px',
            m: '160px auto',
          }}
        >
          <Alert variant="outlined" severity="error">
            Invalid country language: {defaultLanguage}
          </Alert>
        </Box>
      )}
    </ThemeProvider>
  );
}

const ProtectedLayout = () => {
  const location = useLocation();
  const { lang } = useParams();
  const { data, isInitialLoading } = useGetUser();
  // RTK query should replace the rest eventually
  const { isLoading } = useGetUserDataQuery();

  const loading = isInitialLoading || isLoading;

  if (!data && !loading && isValidCountryLanguage(lang)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    ENV_DEV && console.log('**** redirecting because of not authenticated. location:', location);
    return <Navigate to={'/' + lang + '/login'} state={{ from: location }} replace />;
  }

  if (loading) {
    return <GlobalLoader />;
  }
  const passwordRulesChecked = location.state?.passwordRulesChecked;

  return (
    <>
      {!passwordRulesChecked && <PasswordExpiry />}
      <Outlet />
    </>
  );
};
