//  USER SCOPED LOCAL STORAGE - ONLY FOR LOGGED IN USERS
import { AUTHORIZATION } from 'Login/constants';
import { useCallback } from 'react';
import { useGetUserDataQuery } from 'store/api/fcpUser';

export const useUserLocalStorage = (key: string) => {
  if (!localStorage.getItem(AUTHORIZATION)) {
    throw new Error('User not logged in');
  }

  const { email } = useGetUserDataQuery(undefined, {
    selectFromResult: ({ data }) => ({
      email: data?.user.email,
    }),
  });

  const email64 = btoa(email!);
  const userScopedKey = `${email64}-${key}`;

  const setItem = useCallback(
    (value: string) => {
      localStorage.setItem(userScopedKey, value);
    },
    [userScopedKey],
  );

  const getItem = useCallback(() => {
    return localStorage.getItem(userScopedKey);
  }, [userScopedKey]);

  const removeItem = useCallback(() => {
    localStorage.removeItem(userScopedKey);
  }, [userScopedKey]);

  return {
    setItem,
    getItem,
    removeItem,
  };
};
