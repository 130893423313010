import { Box, Grid } from '@mui/material';
import { Button } from '../Button';
import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SetReadOnlySectionFunc } from './EditProfile.types';
import { GetUserDataResponse } from 'store/api/fcpUser.types';

export const ContactDetails = ({
  userData,
  setReadOnly,
}: {
  userData: GetUserDataResponse;
  setReadOnly: SetReadOnlySectionFunc;
}) => {
  const { t } = useTranslation();
  const { user } = userData;

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Box data-testid="user-name">{user.name}</Box>
          <Box data-testid="user-company-name">{user.companyName}</Box>
          <Box data-testid="user-primary-phone-number">{user.phoneNumbers[0]?.number}</Box>
          <Box data-testid="user-vat-number">{user.vatNumber}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box data-testid="user-street">{user.street}</Box>
          <Box>
            <span data-testid="user-postal-code">{user.postalCode}</span>{' '}
            <span data-testid="user-city">{user.city}</span>
          </Box>
          <Box data-testid="user-country">{user.country}</Box>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        sx={{
          mt: '16px',
        }}
        onClick={() =>
          setReadOnly({
            section: 'contactDetails',
            readOnly: false,
          })
        }
        dataTestId="editContactButton"
      >
        {t('general|editContactInformation')}
      </Button>
    </Box>
  );
};
