import { AUTHENTICATED, AUTHORIZATION, REFRESH_TOKEN_DATA } from '../../Login/constants';
import { PASSWORD_EXPIRY_COOKIE } from '../../components/fcp-components/PasswordExpiryModal';
import { apiLinks } from '../../config/api-config';
import { CONST } from './data-config';
import { apiRequest, eraseCookie } from './requests';

const getRefreshToken = () => {
  let tokenData, expiration, tokenValue;

  if (localStorage.getItem(REFRESH_TOKEN_DATA)) {
    tokenData = JSON.parse(localStorage.getItem(REFRESH_TOKEN_DATA));
    expiration = tokenData.expiration;
  }

  if (!tokenData) {
    return null;
  }

  if (expiration <= new Date().getTime()) {
    // if already expired
    localStorage.removeItem(AUTHORIZATION);
    localStorage.setItem(AUTHENTICATED, 'false');
    localStorage.setItem(REFRESH_TOKEN_DATA, JSON.stringify({}));
    tokenValue = null;
  } else {
    // if still valid
    tokenValue = tokenData.value;
  }

  return tokenValue;
};

const AuthService = {
  THOUSAND: 1000,
  SIXTY: 60,
  refreshTokensIntervalTime: 300000,

  logIn(loginObject) {
    return apiRequest(apiLinks.logIn, 'POST', loginObject).then(response => {
      if (response && response.data && response.data.token_type) {
        this.setAuthData(response);

        window.location.reload();
      }
    });
  },

  async logOut(event) {
    if (event) {
      event.preventDefault();
    }
    let refreshTokenObj = {
      body: {
        value: getRefreshToken(),
      },
    };

    await apiRequest(apiLinks.logOut, 'POST', refreshTokenObj);
    localStorage.removeItem(AUTHORIZATION);
    eraseCookie(PASSWORD_EXPIRY_COOKIE);
    localStorage.setItem(AUTHENTICATED, 'false');
    localStorage.setItem(REFRESH_TOKEN_DATA, JSON.stringify({}));
    clearInterval(window.refreshIntervalID);
  },

  countRefreshTokensIntervalTime(refresh_expires_in) {
    return (refresh_expires_in - this.SIXTY) * this.THOUSAND;
  },

  setAuthData(response) {
    let tokenValue = `${response.data.token_type} ${response.data.access_token}`;

    localStorage.setItem(AUTHORIZATION, tokenValue);
    this.setRefreshToken(response.data.refresh_token, response.data.refresh_expires_in);
  },

  performRefreshTokens() {
    let refreshTokenObj = {
      body: {
        value: getRefreshToken(),
      },
    };

    return apiRequest(apiLinks.refreshTokens, 'POST', refreshTokenObj).then(response => {
      if (response && response.data && response.data.token_type && response.status !== CONST.BAD_REQUEST_ERROR) {
        this.setAuthData(response);

        if (this.refreshTokensIntervalTime === undefined) {
          this.refreshTokensIntervalTime = this.countRefreshTokensIntervalTime(response.data.refresh_expires_in);
        }
      } else {
        localStorage.removeItem(REFRESH_TOKEN_DATA);
        clearInterval(window.refreshIntervalID);
      }
    });
  },

  async runRefreshTokensHandler() {
    // perform refresh tokens request on start cause there
    // can be a situation that during application start
    // refreshToken was valid but it's going to expire
    // sooner than next refresh tokens request
    await this.performRefreshTokens();

    window.refreshIntervalID = setInterval(() => {
      this.performRefreshTokens();
    }, this.refreshTokensIntervalTime);
  },

  getRefreshTokenExpiration() {
    let refreshTokenExpiration;

    if (localStorage.getItem(REFRESH_TOKEN_DATA)) {
      refreshTokenExpiration = JSON.parse(localStorage.getItem(REFRESH_TOKEN_DATA));
      return refreshTokenExpiration.expiration;
    }

    return null;
  },

  isRefreshTokenExpired() {
    let refreshTokenExpiration = this.getRefreshTokenExpiration();
    if (refreshTokenExpiration >= new Date().getTime()) {
      return false;
    } else {
      localStorage.removeItem(AUTHORIZATION);
      localStorage.setItem(AUTHENTICATED, 'false');
      localStorage.setItem(REFRESH_TOKEN_DATA, JSON.stringify({}));
      return true;
    }
  },

  getAuthToken() {
    return localStorage.getItem(AUTHORIZATION);
  },

  setRefreshToken(tokenValue, secondsToExpire) {
    var date = new Date(),
      expirationDate,
      refreshTokenData = {};

    expirationDate = date.getTime() + secondsToExpire * this.THOUSAND;
    // to get ms needs to multiply secondsToExpire and this.THOUSAND

    refreshTokenData.value = tokenValue;
    refreshTokenData.expiration = expirationDate;

    localStorage.setItem(REFRESH_TOKEN_DATA, JSON.stringify(refreshTokenData));
  },

  getUserDetails() {
    const resultMap = {};
    return apiRequest(apiLinks.getUserData, 'GET')
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          resultMap.data = result.data;
        } else {
          resultMap.error = true;
        }
        return resultMap;
      })
      .catch(() => {
        resultMap.error = true;
        return resultMap;
      });
  },

  getUserAuthorizationCode() {
    const authenticated = localStorage.getItem(AUTHENTICATED)?.toLowerCase() === 'true';
    let authorizationCodeRequest = null;
    if (authenticated) {
      authorizationCodeRequest = {
        body: {
          accessToken: this.getAuthToken().split(' ')[1],
          refreshToken: getRefreshToken(),
        },
      };
    }
    const resultMap = {};
    return apiRequest(apiLinks.getUserAuthorizationCode, 'POST', authorizationCodeRequest)
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          resultMap.data = result.data;
        } else {
          resultMap.error = true;
        }
        return resultMap;
      })
      .catch(() => {
        resultMap.error = true;
        return resultMap;
      });
  },
};

export default AuthService;
