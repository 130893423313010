import { Button, Checkbox, Error, Input, PhoneNumberV2, Select } from 'components';
import { apiLinks } from 'config/api-config';
import { allCountries } from 'globals/data-countries';
import { testRegEx } from 'globals/utils/fieldValidations';
import { apiRequest, eraseCookie, getCookie, getPropperAPIData } from 'globals/utils/requests';
import React from 'react';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../globals/constants';
import { CONST, config } from '../../data-config';

import { getSavedShipmentByIdCall } from './../../helpers/savedShipments';
import CompanyNameSearch from './CompanyNameSearch/CompanyNameSearch';
import { filterAccountsBasedOnShipmentRange } from './ImportExportSection/ImportExportSection-helpers';
import { fillTemplateData } from './SetTemplate-helpers';
import { validateAccountNumber } from './Validations/AccountNumber-validations';
import { enterDeliveryLocationValidations } from './Validations/EnterDeliveryLocation-validations';
import { enterPickupLocationValidations } from './Validations/EnterPickupLocation-validations';
import { Typography } from '@mui/material';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';
import { resetPhoneNumberWhenSwitchedToDomestic } from '../../../globals/helpers/pickup-helpers';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';
import { store } from 'store/store';
import { setPreSelectedPickupDate, setPreSelectedTDLDate } from 'store/portalOrderSlice';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const setAppropriateReferenceAndInstructions = ({ state }) => {
  const stateObj = {};
  const { addressReferenceInstructions, pickupSenderReference, deliveryReceiverReference } = state;
  let pickupRef = '';
  let deliveryRef = '';
  let deliveryInstructions = '';

  if (pickupSenderReference) {
    pickupRef = pickupSenderReference.value;
  }

  if (deliveryReceiverReference) {
    deliveryRef = deliveryReceiverReference.value;
  }

  if (state.deliverToDifferentAddress.value && addressReferenceInstructions[CONST.DIFFERENT_RECEIVER]) {
    deliveryInstructions = addressReferenceInstructions[CONST.DIFFERENT_RECEIVER].deliveryInstructions;
  } else if (addressReferenceInstructions[CONST.RECEIVER]) {
    deliveryInstructions = addressReferenceInstructions[CONST.RECEIVER].deliveryInstructions;
  }

  stateObj.pickupSenderReference = {
    value: pickupRef || '',
    error: false,
  };

  stateObj.deliveryReceiverReference = {
    value: deliveryRef || '',
    error: false,
  };

  stateObj.deliveryInstructions = {
    value: deliveryInstructions || '',
    error: false,
  };

  return stateObj;
};

export const setGeneralError = stateObj => {
  const stateToSet = JSON.parse(JSON.stringify(stateObj));

  stateToSet.generalError =
    stateToSet.validationError ||
    stateToSet.sourcePostalCodeError ||
    stateToSet.destinationPostalCodeError ||
    stateToSet.sourceCityError ||
    stateToSet.destinationCityError;

  stateToSet.generalError = stateToSet.apiError ? false : stateToSet.generalError;
  stateToSet.addressBookError = false;
  stateToSet.addressBookMaxLimitReachedError = false;

  return stateToSet;
};

export const resetAddUpdateAddressBookCheckBox = () => {
  return {
    addToAddressBookSender: { value: false, error: false },
    addToAddressBookReceiver: { value: false, error: false },
    updateInAddressBookSender: { value: false, error: false },
    updateInAddressBookReceiver: { value: false, error: false },
    addToAddressBookDifferentSender: { value: false, error: false },
    addToAddressBookDifferentReceiver: { value: false, error: false },
    updateInAddressBookDifferentSender: { value: false, error: false },
    updateInAddressBookDifferentReceiver: { value: false, error: false },
  };
};

export const getUserAccounts = async (ctxState, userAccountsAndProducts) => {
  const stateToSet = {};

  // accounts call
  const accountAPIBackendCall = await getUsersAccountsCall(userAccountsAndProducts);

  if (accountAPIBackendCall.apiError) {
    stateToSet.apiError = true;
  } else {
    stateToSet.originalAccounts = accountAPIBackendCall.originalAccounts;
    stateToSet.accounts = filterAccountsBasedOnShipmentRange(
      accountAPIBackendCall.originalAccounts,
      ctxState.shipmentRange.value,
    );
    stateToSet.productSpecifications = accountAPIBackendCall.productSpecifications;
  }

  return stateToSet;
};

export const getUsersAccountsCall = async userAccountsAndProducts => {
  const url = apiLinks.getUsersAccountsAndProductsData;

  // API call to get and format the data
  const stateObject = {};

  const prepareData = data => {
    stateObject.originalAccounts = prepareAPIDataForAccountSelect(data);
    Object.assign(stateObject, prepareAPIDataForProductSpecifications(data));
  };

  if (userAccountsAndProducts) {
    prepareData({ data: userAccountsAndProducts });
  } else {
    await apiRequest(url, 'GET').then(result => {
      if (result.status === CONST.STATUS_OK) {
        prepareData(result);
      } else {
        stateObject.apiError = true;
      }
    });
  }

  return stateObject;
};

const prepareAPIDataForProductSpecifications = result => {
  const productSpecifications = {};

  if (result.data.productSpecifications && result.data.productSpecifications.length > 0) {
    result.data.productSpecifications.forEach(product => {
      const key = Object.keys(product)[0];

      // Adds product specifications
      productSpecifications[key] = product[key];
    });
  }

  return { productSpecifications };
};

export const prepareAPIDataForAccountSelect = result => {
  const accounts = [];

  if (result.data.accounts && result.data.accounts.length > 0) {
    result.data.accounts.forEach(account => {
      const filteredList = filterOutProductsNotAllowed(account.products);

      if (filteredList && filteredList.length > 0) {
        accounts.push({
          name: `${account.accountNumber} (${account.accountReference})`,
          code: account.accountNumber,
          products: filteredList,
        });
      }
    });
  }

  return accounts;
};

export const filterOutProductsNotAllowed = products => {
  let filteredList;

  filteredList = products.filter(prod => {
    return config.allowedProducts.find(p => p.code === prod.productCode);
  });

  return filteredList;
};

export const productAPICall = async products => {
  const stateToSet = {};
  const url = apiLinks.getFCPAPIProducts;

  const params = {
    headers: {
      Accept: 'application/json',
    },
  };

  const prepareData = result => {
    stateToSet.products = result.data;
    stateToSet.deliveryTypesForParcelConnect = getDeliveryTypes(result.data);
    stateToSet.internationalShippingCountries = getInternationalCountries(result.data);
  };

  if (products) {
    prepareData({ data: products });
  } else {
    await apiRequest(url, 'GET', params)
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          prepareData(result);
        } else {
          stateToSet.apiError = true;
        }
      })
      .catch(() => {
        stateToSet.apiError = true;
      });
  }

  return stateToSet;
};

export const showTermsOfDeliveryDropdown = context => {
  const opts = readState(context.state);
  const show = opts.isInternational;
  return show;
};

export const getStateToSet = () => {
  return {
    apiError: false,
    goNextError: true,
    sourcePostalCodeError: false,
    destinationPostalCodeError: false,
    sourceCityError: false,
    destinationCityError: false,
    generalError: false,
  };
};

export const findCustomBasedCountry = (state, selectedToCountry) => {
  const toCountries = state.internationalShippingCountries;

  const customsCountry = toCountries.find(toCountry => {
    return toCountry.countryCode === selectedToCountry;
  });

  return customsCountry;
};

export const afterCountry = context => {
  const stateObj = {};

  const isInternational = context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE;

  if (isInternational) {
    const selectedCountry = getCountryForCustoms(context.state);
    const customsCountry = findCustomBasedCountry(context.state, selectedCountry);

    termsOfDeliveryValue(context, stateObj, context.state.deliveryCountry.value);
    stateObj['countryBasedCustoms'] = customsCountry && customsCountry.customs;
  }

  stateObj['differentPickupCountry'] = JSON.parse(JSON.stringify(context.state.differentPickupCountry));
  stateObj['differentDeliveryCountry'] = JSON.parse(JSON.stringify(context.state.differentDeliveryCountry));
  stateObj['differentPickupCountry'].value = context.state.pickupCountry.value;
  stateObj['differentDeliveryCountry'].value = context.state.deliveryCountry.value;

  updatePostalCode(context.state, context.state.pickupCountry.value, context.state.deliveryCountry.value, stateObj);

  if (stateObj['differentPickupCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentPickupCountry'].value, 'differentPickupPhoneNumbers');
  }
  if (stateObj['differentDeliveryCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentDeliveryCountry'].value, 'differentDeliveryPhoneNumbers');
  }

  context.updateState(stateObj);
};

export const getDeliveryTypes = productData => {
  const parcelConnect = productData.find(product => product.code === '109');
  const deliveryTypeBasedOnCountry = {};

  parcelConnect?.rulesForCountryAndDeliveryTypes.forEach(deliveryTypeVal => {
    if (deliveryTypeBasedOnCountry.hasOwnProperty([deliveryTypeVal.country.countryCode])) {
      let existingDeliveryTypes = deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode];
      existingDeliveryTypes.push(deliveryTypeVal.deliveryType);
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = existingDeliveryTypes;
    } else {
      deliveryTypeBasedOnCountry[deliveryTypeVal.country.countryCode] = Array.isArray(deliveryTypeVal.deliveryType)
        ? deliveryTypeVal.deliveryType
        : [deliveryTypeVal.deliveryType];
    }
  });

  return deliveryTypeBasedOnCountry;
};

export const getInternationalCountries = products => {
  const internationalShippingCountries = [];

  products.forEach((product, index) => {
    if (!product.isDomestic && product.toCountries) {
      product.toCountries.forEach(toCountry => {
        if (toCountry.country && toCountry.country.customs) {
          internationalShippingCountries.push(toCountry.country);
        }
      });
    }
  });

  return internationalShippingCountries;
};

export const fillAddressData = async (importExport, context, loaderRef) => {
  try {
    let stateObj = JSON.parse(JSON.stringify(context.state));
    let fillSenderFromUserProfile = true;
    let isSavedShipment = false;

    if (!getCookie(config.portalOrderCookie)) {
      store.dispatch(setPreSelectedPickupDate(undefined));
      store.dispatch(setPreSelectedTDLDate(undefined));
    }

    // Prefill booking data from portal templates/saved shipments/quote tool
    if (getCookie(config.portalOrderCookie)) {
      ENV_DEV && console.log('Portal templates/saved shipments/quote tool cookie detected');

      await fillFromSavedShipmentData(context, JSON.parse(getCookie(config.portalOrderCookie)), loaderRef);
      fillSenderFromUserProfile = false;
      isSavedShipment = true;
      eraseCookie(config.portalOrderCookie);
    }
    // Fill data from address book module
    else if (getCookie(config.bookingCookieName)) {
      ENV_DEV && console.log('Filling data from address book module');
      stateObj = await fillFromAddressBook(context.state);
      stateObj.addressFilledFromCookie = true;
      stateObj.accounts = filterAccountsBasedOnShipmentRange(stateObj.originalAccounts, stateObj.shipmentRange.value);

      eraseCookie(config.bookingCookieName);
    }

    // prefill send notification
    else if (getCookie('sendNotification')) {
      ENV_DEV && console.log('Filling data from sendNotification cookie');
      const sendNotificationObject = JSON.parse(decodeURIComponent(getCookie('sendNotification')));
      stateObj.deliveryEmailConfirmation = sendNotificationObject.deliveryEmailConfirmation;
      stateObj.differentDeliveryEmailConfirmation = sendNotificationObject.differentDeliveryEmailConfirmation;
      stateObj.pickupEmailConfirmation = sendNotificationObject.pickupEmailConfirmation;
      stateObj.differentPickupEmailConfirmation = sendNotificationObject.differentPickupEmailConfirmation;
    }

    // Fill data from previous successful order
    else if (getCookie('addressOrder')) {
      ENV_DEV && console.log('Filling data from previous successful order');
      try {
        const address = JSON.parse(decodeURIComponent(Buffer.from(getCookie('addressOrder'), 'base64').toString()));
        const { code, number } = preparePhoneNumber(address.phone);
        const phoneNumber = address.phone
          ? [
              {
                code,
                error: false,
                isRequired: true,
                number,
              },
            ]
          : [
              {
                code: config.defaultCountryPhoneCode,
                error: false,
                isRequired: true,
                number: '',
              },
            ];

        if (
          context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
          (importExport === EXPORT_SHIPMENT_TYPE && !context.state.pickupSender.value)
        ) {
          stateObj = {
            ...context.state,
            pickupCity: { error: false, value: address.address.cityName || '', isRequired: true },
            pickupCountry: {
              error: false,
              value: address.address.countryCode || '',
              comparisonError: false,
              isRequired: true,
            },
            pickupEmail: { error: false, value: address.email || '', isRequired: false },
            pickupName: { error: false, value: address.contactName || '', isRequired: true },
            pickupPostalCode: { error: false, value: address.address.postalCode || '', isRequired: true },
            pickupPhoneNumbers: phoneNumber,
            pickupSender: { error: false, value: address.name || '', isRequired: true },
            pickupStreet: { error: false, value: address.address.street || '', isRequired: true },
            // deliveryCity: { value: '', error: false, isRequired: true },
            // deliveryCountry: { value: config.defaultCountry, error: false, comparisonError: false, isRequired: true },
            // deliveryEmail: { error: false, value: '', isRequired: false },
            // deliveryName: { error: false, value: '', isRequired: true },
            // deliveryPostalCode: { error: false, value: '', isRequired: true },
            // deliveryPhoneNumbers: [
            //   {
            //     code: config.defaultCountryPhoneCode,
            //     error: false,
            //     isRequired: true,
            //     number: '',
            //   },
            // ],
            // deliveryReceiver: { error: false, value: '', isRequired: true },
            // deliveryStreet: { error: false, value: '', isRequired: true },
          };
          fillSenderFromUserProfile = false;
        } else if (importExport === IMPORT_SHIPMENT_TYPE && !context.state.deliveryReceiver.value) {
          stateObj = {
            ...context.state,
            pickupCity: { error: false, value: '', isRequired: true },
            pickupCountry: { error: false, value: '', comparisonError: false, isRequired: true },
            pickupEmail: { error: false, value: '', isRequired: false },
            pickupName: { error: false, value: '', isRequired: true },
            pickupPostalCode: { error: false, value: '', isRequired: true },
            pickupPhoneNumbers: [
              {
                code: config.defaultCountryPhoneCode,
                error: false,
                isRequired: true,
                number: '',
              },
            ],
            pickupSender: { error: false, value: '', isRequired: true },
            pickupStreet: { error: false, value: '', isRequired: true },
            deliveryCity: { error: false, value: address.address.cityName || '' },
            deliveryCountry: {
              error: false,
              value: address.address.countryCode || '',
              comparisonError: false,
              isRequired: true,
            },
            deliveryEmail: { error: false, value: address.email || '', isRequired: false },
            deliveryName: { error: false, value: address.contactName || '', isRequired: true },
            deliveryPostalCode: { error: false, value: address.address.postalCode || '', isRequired: true },
            deliveryPhoneNumbers: phoneNumber,
            deliveryReceiver: { error: false, value: address.name || '', isRequired: true },
            deliveryStreet: { error: false, value: address.address.street || '', isRequired: true },
          };
        }
      } catch {
        console.error("Failed to parse data from cookie 'addressOrder'");
      }
    }

    // If no cookie from previous shipment is found, prefill from user account data
    ENV_DEV && console.log('Prefilling from user account data');

    if (!context.state.userDataWasDownloaded) {
      await apiRequest(apiLinks.getUserData, 'GET').then(result => {
        if (result.status === CONST.STATUS_OK) {
          if (fillSenderFromUserProfile) {
            stateObj = formatUserData(result.data, stateObj, importExport);
          }

          if (result.data && result.data.user) {
            stateObj.confirmationEmail = { value: result.data.user.email || '', error: false };
          }
        } else {
          context.updateState({ generalError: true });
        }
      });
    }

    loaderRef.classList.remove('is-loading');

    // update state only if cookie is not from saved shipment/templates
    if (!isSavedShipment) {
      context.updateState(stateObj);
    }
  } catch {
    return;
  }
};

export const fillFromSavedShipmentData = async (context, savedShipmentId, loaderRef) => {
  const result = await getSavedShipmentByIdCall(savedShipmentId);

  if (result?.data?.pickupDate) {
    const localDateString = result.data.pickupDate;
    const date = new Date(localDateString.endsWith('Z') ? localDateString : `${localDateString}Z`);
    const utcDateString = date.toISOString();
    store.dispatch(setPreSelectedPickupDate(utcDateString));
  } else {
    store.dispatch(setPreSelectedPickupDate(undefined));
  }

  const TDLVas = result?.data?.savedShipmentVass.find(item => item.vas?.toLowerCase() === 'timedefiniteloading');

  if (TDLVas) {
    const localDateString = TDLVas.vasFieldValue;
    const date = new Date(localDateString.endsWith('Z') ? localDateString : `${localDateString}Z`);
    const utcDateString = date.toISOString();
    store.dispatch(setPreSelectedTDLDate(utcDateString));
  } else {
    store.dispatch(setPreSelectedTDLDate(undefined));
  }

  if (!result.error) {
    ENV_DEV && console.log('Filling contexts with shipment data from template...');
    loaderRef.classList.remove('is-loading');

    await fillTemplateData(context, result.data);
  } else {
    loaderRef.classList.remove('is-loading');
  }
};

export const fillFromAddressBook = async stateObj => {
  // Fill receiver data from Address Book
  let newStateObj = {};
  const party = CONST.RECEIVER;
  ENV_DEV && console.log('Filling receiver data from Address Book id');

  // GET call for address data
  const result = await getAddressByIDCall(getCookie(config.bookingCookieName));

  if (result.error) {
    ENV_DEV && console.log('Could not fill received data from address book');
  } else {
    const cutPhoneByDash = result.data.phone?.split('-');
    const phoneNumber = result.data.phone
      ? [
          {
            code: cutPhoneByDash[0],
            error: false,
            isRequired: true,
            number: cutPhoneByDash.slice(1).join('-'),
          },
        ]
      : [
          {
            code: config.defaultCountryPhoneCode,
            error: false,
            isRequired: true,
            number: '',
          },
        ];

    newStateObj = {
      ...stateObj,
      deliveryCity: { error: false, value: result.data.city || '' },
      deliveryCountry: {
        error: false,
        value: result.data.countryCode || '',
        comparisonError: false,
        isRequired: true,
      },
      deliveryEmail: { error: false, value: result.data.email || '', isRequired: result.data.residentialAddress },
      deliveryName: { error: false, value: result.data.name || '', isRequired: true },
      deliveryPostalCode: { error: false, value: result.data.postalCode || '', isRequired: true },
      deliveryPhoneNumbers: phoneNumber,
      deliveryReceiver: { error: false, value: result.data.companyName || '', isRequired: true },
      deliveryStreet: { error: false, value: result.data.street || '', isRequired: false },
      deliveryAddressResidential: { value: result.data.residentialAddress },
      receiverPickedFromAddressBook: true,
      receiverAddressID: { value: result.data.id },
    };

    // If international address, set route to International/Export
    if (result.data.countryCode !== localStorage.getItem('country')?.toUpperCase()) {
      newStateObj.shipmentRange = { value: INTERNATIONAL_SHIPMENT_RANGE };
      newStateObj.shipmentTypesImpOrExp = { value: EXPORT_SHIPMENT_TYPE };
    }
  }

  setCustomsFlag(newStateObj, stateObj, newStateObj.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE);
  fillDeliveryInstruction(newStateObj, party, stateObj, result.data);
  fillSenderReceiverReference(newStateObj, party, result.data);
  setAddressReferenceInstructions(newStateObj, party, stateObj, result.data);

  return newStateObj;
};

const getAddressByIDCall = async addressBookCookie => {
  const url = apiLinks.searchUpdateAddress.replace('{id}', addressBookCookie);
  const address = {};

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        address.status = true;
        address.data = result.data;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        address.status = false;
      } else {
        address.error = true;
      }
    })
    .catch(() => {
      address.error = true;
    });

  return address;
};

export const formatUserData = (data, stateObj, importExport) => {
  const [phoneCode, phoneNumber] =
    data.user.phoneNumbers.length !== 0
      ? data.user.phoneNumbers[0].number.split('-')
      : [config.defaultCountryPhoneCode, ''];
  let state = {};

  state = {
    ...stateObj,
    pickupCity: { ...stateObj.pickupCity, error: false, value: data.user.city || '', isRequired: true },
    pickupCountry: {
      ...stateObj.pickupCountry,
      value: data?.user?.accountCountryCode?.toUpperCase(),
    },
    deliveryCountry: {
      ...stateObj.deliveryCountry,
      value: stateObj.addressFilledFromCookie
        ? stateObj.deliveryCountry.value
        : data?.user?.accountCountryCode?.toUpperCase(),
    },
    pickupEmail: { ...stateObj.pickupEmail, error: false, value: data.user.email || '' },
    pickupName: { ...stateObj.pickupName, error: false, value: data.user.name || '' },
    pickupPostalCode: { ...stateObj.pickupPostalCode, error: false, value: data.user.postalCode || '' },
    pickupPhoneNumbers: [
      {
        ...stateObj.pickupPhoneNumbers[0],
        code: phoneCode,
        number: phoneNumber,
      },
    ],
    pickupSender: { ...stateObj.pickupSender, error: false, value: data.user.companyName || '' },
    pickupStreet: { ...stateObj.pickupStreet, error: false, value: data.user.street || '' },
    userDataWasDownloaded: true,
    user: JSON.parse(JSON.stringify(data.user)),
  };

  return state;
};

/**
 * To validate Source/Destination postalcode
 * @param {*} state {pickupCountry, pickupPostalCode, deliveryCountry, deliveryPostalCode}
 * @param {*} isInternational true | false
 *
 */
export const genericPostalCodeValidation = async (state, isInternational = false) => {
  let sourceURL = apiLinks.fetchCityName
    .replace('{countryCode}', state.pickupCountry.value)
    .replace('{postalCode}', state.pickupPostalCode.value);

  if (state.pickupCity.value) {
    if (state.pickupCountry.value?.toLowerCase() === 'se') {
      sourceURL = `${sourceURL}/${state.pickupCity.value}`;
    } else {
      sourceURL = `${sourceURL}`;
    }
  }

  let destinationUrl = apiLinks.fetchCityName
    .replace('{countryCode}', state.deliveryCountry.value)
    .replace('{postalCode}', state.deliveryPostalCode.value);

  if (state.deliveryCity.value) {
    if (state.deliveryCountry.value?.toLowerCase() === 'se') {
      destinationUrl = `${destinationUrl}/${state.deliveryCity.value}`;
    } else {
      destinationUrl = `${destinationUrl}`;
    }
  }

  const resultMap = {
    destinationBookable: false,
    sourceBookable: false,
    internationalSourceCityError: false,
    internationalDestinationCityError: false,
    data: {
      destination: null,
      source: null,
    },
    status: null,
  };

  //For International
  const compareCityName = (city, responseCity) => {
    return !responseCity || city.trim().toUpperCase() !== responseCity.toUpperCase();
  };

  const doApiCall = (type, url) =>
    apiRequest(url, 'GET')
      .then(result => {
        if (result.status === CONST.STATUS_OK) {
          const data = result.data;

          if (data && data.validated && data.bookable === undefined) {
            data.bookable = true;
          }

          // if we recieve bookable true but no city value, we must assume it is valid
          // relevant only for non swedish account country codes
          const isCityAssumedValid = data.bookable && result.data.city === undefined;

          if (data && type === 'destination') {
            resultMap.destinationBookable = data.bookable;
            resultMap.data.destination = data;
            if (isInternational) {
              resultMap.internationalDestinationCityError = isCityAssumedValid
                ? false
                : compareCityName(state.deliveryCity.value, result.data.city);
            }
          }

          if (data && type === 'source') {
            resultMap.sourceBookable = data.bookable;
            resultMap.data.source = data;
            if (isInternational) {
              resultMap.internationalSourceCityError = isCityAssumedValid
                ? false
                : compareCityName(state.pickupCity.value, result.data.city);
            }
          }
        } else {
          resultMap.error = true;
        }
        return result;
      })
      .catch(() => {
        resultMap.error = true;
      });

  const [sourceValidation, destinationValidation] = await Promise.all([
    doApiCall('source', sourceURL),
    doApiCall('destination', destinationUrl),
  ]);

  if (
    sourceValidation &&
    sourceValidation.status === CONST.STATUS_OK &&
    destinationValidation &&
    destinationValidation.status === CONST.STATUS_OK
  ) {
    resultMap.status = CONST.STATUS_OK;
  }
  return resultMap;
};

const requiredLTCDetails = state => {
  const properState = getPropperAPIData(state);
  const map = {};

  map.countryFrom = properState.pickupCountry.value;
  map.zipcodeFrom = properState.pickupPostalCode.value;
  map.countryTo = properState.deliveryCountry.value;
  map.zipcodeTo = properState.deliveryPostalCode.value;

  return map;
};

const getLTCUrl = urlDetails => {
  const formURL =
    'url' +
    '?country_from=' +
    urlDetails.countryFrom?.toUpperCase() +
    '&zipcode_from=' +
    urlDetails.zipcodeFrom.toUpperCase() +
    '&country_to=' +
    urlDetails.countryTo +
    '&zipcode_to=' +
    urlDetails.zipcodeTo.toUpperCase() +
    '&_=' +
    new Date().getTime().toString();

  return formURL;
};

export const internationalShipmentValidation = async state => {
  const resultMap = { status: false, error: false };
  const urlDetails = requiredLTCDetails(state);

  await internationalLTCPostalCodeValidation(getLTCUrl(urlDetails), resultMap);

  return resultMap;
};

export const internationalLTCDeliveryTime = async url => {
  const products = {};

  await apiRequest(url, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        products.status = true;
        products.data = result.data;
      } else {
        products.status = false;
        products.error = true;
      }
    })
    .catch(() => {
      products.error = true;
    });

  return products;
};

export const internationalLTCPostalCodeValidation = async (url, resultMap) => {
  await apiRequest(url.replace('url', apiLinks.ltc.zipvalidator), 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      if (!Array.isArray(result.data)) {
        resultMap.status = true;
        resultMap.data = result.data;
      }
    } else if (result.status === CONST.BAD_REQUEST_ERROR) {
      if (result.data.length > 0) {
        result.data.forEach(data => {
          switch (data.code) {
            case -1:
              resultMap.sourcePostalCode = true;
              break;
            case -2:
              resultMap.destinationPostalCode = true;
              break;
            default:
              return;
          }
        });
      } else {
        resultMap.error = true;
      }
    } else {
      resultMap.error = true;
    }
  });

  return resultMap;
};

export const clearDeliveryLocation = context => {
  // TODO remove unused function?
  const obj = {
    deliveryReceiver: { value: '', error: false },
    deliveryEmail: { value: '', error: false },
    deliveryCountry: { value: context.user?.accountCountryCode, error: false },
    deliveryCompany: { value: '', error: false },
    deliveryVatNumber: { value: '', error: false },
    deliveryName: { value: '', error: false },
    deliveryStreet: { value: '', error: false },
    deliveryPostalCode: { value: '', error: false },
    deliveryCity: { value: '', error: false },
    deliveryPhoneNumbers: [{ code: '', number: '', error: '' }],
    deliveryReceiverReference: { value: '', error: false },
  };

  context.updateState(obj);
};

export const payerCode = opts => {
  if (opts.isInternational && opts.isExport && opts.isReceiverPayer) return 'case1';
  if (opts.isInternational && opts.isExport && opts.isSenderPayer) return 'case2';
  if (opts.isInternational && opts.isExport && opts.isThirdPartyPayer) return 'case3';
  if (opts.isInternational && opts.isImport && opts.isReceiverPayer) return 'case4';
  if (opts.isInternational && opts.isImport && opts.isThirdPartyPayer) return 'case5';
  if (!opts.isInternational && opts.isSenderPayer) return 'case6';
  if (!opts.isInternational && opts.isReceiverPayer) return 'case7';
  if (!opts.isInternational && opts.isThirdPartyPayer) return 'case8';
  if (opts.isInternational && opts.isImport && opts.isSenderPayer) return 'case9';
};

export const readState = state => {
  const isDomestic = state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE,
    isInternational = state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE,
    isImport = state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE,
    isExport = state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE,
    isSenderPayer = state.shipmentPayer.value === SENDER_PAYER,
    isReceiverPayer = state.shipmentPayer.value === RECEIVER_PAYER,
    isThirdPartyPayer = state.shipmentPayer.value === THIRD_PARTY_PAYER;

  return {
    isDomestic,
    isInternational,
    isImport,
    isExport,
    isSenderPayer,
    isReceiverPayer,
    isThirdPartyPayer,
  };
};

export const setAccountInfo = (casePayerCode, state, stateObj) => {
  if (casePayerCode === 'case1' || casePayerCode === 'case2') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case3' || casePayerCode === 'case8') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true, isSelect: false };
  } else if (casePayerCode === 'case4') {
    stateObj.accountNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true, isSelect: true };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false, isSelect: false };
  } else if (casePayerCode === 'case5') {
    stateObj.accountNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true, isSelect: true };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true, isSelect: false };
  } else if (casePayerCode === 'case6') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  } else if (casePayerCode === 'case7') {
    [stateObj.accountNumber, stateObj.receiverNumber] = domesticAndReceiverPay(state);
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  }

  stateObj.accountNumber.isRequired =
    state.shipmentPayer.value === SENDER_PAYER ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      state.shipmentPayer.value === RECEIVER_PAYER &&
      !state.pickupAddressResidential.value) ||
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE) ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === THIRD_PARTY_PAYER);

  stateObj.receiverNumber.isRequired =
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE) ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === RECEIVER_PAYER);

  stateObj.thirdPartyNumber.isRequired = state.shipmentPayer.value === THIRD_PARTY_PAYER;

  // reset account info if select and not present in account list
  resetAccountInfoIfNotPresentInAccount(state, stateObj);

  // set disabled and error to false
  stateObj.accountNumber.disabled =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.pickupAddressResidential.value ? true : false;
  stateObj.accountNumber.error = false;

  stateObj.receiverNumber.disabled = false;
  stateObj.receiverNumber.error = false;

  stateObj.thirdPartyNumber.disabled = false;
  stateObj.thirdPartyNumber.error = false;
};

const domesticAndReceiverPay = state => {
  const stateToSet = {};

  stateToSet.accountNumber = { value: state.accountNumber.value, error: false, display: true };
  stateToSet.receiverNumber = { value: state.receiverNumber.value, error: false, display: true };

  // special case :  Domestic -  Receiver Payer
  // case 1: sender selected - receiver free text
  // case 2: receiver selected - sender free text
  stateToSet.receiverNumber.isSelect = !checkIfEnteredAccountIsPresentInAccountList(state, state.accountNumber);
  stateToSet.accountNumber.isSelect = !checkIfEnteredAccountIsPresentInAccountList(state, state.receiverNumber);

  // case 3: when sender and receiver both are filled - sender is select , receiver is text input
  if (!stateToSet.receiverNumber.isSelect && !stateToSet.accountNumber.isSelect) {
    stateToSet.accountNumber.isSelect = true;
  }

  return [stateToSet.accountNumber, stateToSet.receiverNumber];
};

const resetAccountInfoIfNotPresentInAccount = (state, stateObj) => {
  if (stateObj.accountNumber.isSelect && !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.accountNumber)) {
    stateObj.accountNumber.value = '';
  }

  if (
    stateObj.receiverNumber.isSelect &&
    !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.receiverNumber)
  ) {
    stateObj.receiverNumber.value = '';
  }
  if (
    stateObj.thirdPartyNumber.isSelect &&
    !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.thirdPartyNumber)
  ) {
    stateObj.thirdPartyNumber.value = '';
  }
};

export const displayDropdownWithPayersWithoutSender = (context, params) => {
  const exportImport = params !== undefined ? params.value : context.state.shipmentTypesImpOrExp.value;

  return context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && exportImport === IMPORT_SHIPMENT_TYPE;
};

export const getPayerCode = state => {
  const opts = readState(state);
  const casePayerCode = payerCode(opts);

  if (localStorage.getItem('country')?.toUpperCase() === 'SE') {
    // Sweden terms of delivery
    if (casePayerCode === 'case1') {
      return 'EXW';
    } else if (casePayerCode === 'case2') {
      return 'DAP';
    } else if (casePayerCode === 'case3') {
      return 'DAP';
    } else if (casePayerCode === 'case4') {
      return 'EXW';
    } else if (casePayerCode === 'case5') {
      return 'EXW';
    } else if (casePayerCode === 'case6') {
      return '1';
    } else if (casePayerCode === 'case7') {
      return '3';
    } else if (casePayerCode === 'case8') {
      return '4';
    } else {
      // we need to fill some value because the logic was moved
      // to tab 3 - DeliveryOptions
      return state.termsOfDelivery.value || 'DAP';
    }
  } else {
    // global terms of delivery
    if (casePayerCode === 'case1') {
      return 'EXW';
    } else if (casePayerCode === 'case2') {
      return 'DAP';
    } else if (casePayerCode === 'case3') {
      return 'DAP';
    } else if (casePayerCode === 'case4') {
      return 'EXW';
    } else if (casePayerCode === 'case5') {
      return 'EXW';
    } else if (casePayerCode === 'case6') {
      return 'DAP';
    } else if (casePayerCode === 'case7') {
      return 'EXW';
    } else if (casePayerCode === 'case8') {
      return 'EXW';
    } else {
      // we need to fill some value because the logic was moved
      // to tab 3 - DeliveryOptions
      return state.termsOfDelivery.value || 'DAP';
    }
  }
};

export const termsOfDeliveryValue = (context, stateObj, deliveryCountry) => {
  const opts = readState(context.state);
  const casePayerCode = payerCode(opts);

  if (casePayerCode !== 'case9') {
    setAccountInfo(casePayerCode, context.state, stateObj);
  }

  stateObj['termsOfDelivery'] = {};
  stateObj['termsOfDelivery'].value = '';
  stateObj['termsOfDelivery'].error = false;
  stateObj['termsOfDelivery'].isRequired = true;
  stateObj['payerCode'] = '';

  return stateObj;
};

export const updatePostalCode = (state, pickupCountry, deliveryCountry, stateObj) => {
  if (config.numberPostalCodeCountries.indexOf(pickupCountry) !== -1) {
    if (!testRegEx(state.pickupPostalCode.value, config.regEx.number)) {
      stateObj['pickupPostalCode'] = JSON.parse(JSON.stringify(state.pickupPostalCode));
      stateObj['pickupPostalCode'].value = '';
    }

    if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
      if (!testRegEx(state.differentPickupPostalCode.value, config.regEx.number)) {
        stateObj['differentPickupPostalCode'] = JSON.parse(JSON.stringify(state.differentPickupPostalCode));
        stateObj['differentPickupPostalCode'].value = '';
      }
    }
  }
  if (config.numberPostalCodeCountries.indexOf(deliveryCountry) !== -1) {
    if (!testRegEx(state.deliveryPostalCode.value, config.regEx.number)) {
      stateObj['deliveryPostalCode'] = JSON.parse(JSON.stringify(state.deliveryPostalCode));
      stateObj['deliveryPostalCode'].value = '';
    }

    if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
      if (!testRegEx(state.differentDeliveryPostalCode.value, config.regEx.number)) {
        stateObj['differentDeliveryPostalCode'] = JSON.parse(JSON.stringify(state.differentDeliveryPostalCode));
        stateObj['differentDeliveryPostalCode'].value = '';
      }
    }
  }
};

export const updateCountryValue = (state, shipmentRange, shipmentTypesImpOrExp, stateObj) => {
  const countries = allCountries.filter(item => item.code.toLowerCase() !== state?.user?.accountCountryCode);
  const userAccountCountryCode = state?.reactQueryUser?.user?.accountCountryCode?.toUpperCase();
  if (!userAccountCountryCode) console.error('FATAL ERROR: user is undefined');

  stateObj['pickupCountry'] = JSON.parse(JSON.stringify(state.pickupCountry));
  stateObj['deliveryCountry'] = JSON.parse(JSON.stringify(state.deliveryCountry));
  stateObj['differentPickupCountry'] = JSON.parse(JSON.stringify(state.differentPickupCountry));
  stateObj['differentDeliveryCountry'] = JSON.parse(JSON.stringify(state.differentDeliveryCountry));

  if (shipmentRange === DOMESTIC_SHIPMENT_RANGE) {
    stateObj['pickupCountry'].value = userAccountCountryCode;
    stateObj['pickupCountry'].error = false;
    stateObj['deliveryCountry'].value = userAccountCountryCode;
    stateObj['deliveryCountry'].error = false;
    stateObj['customs'] = false;
    stateObj['countryBasedCustoms'] = false;
  } else {
    if (shipmentTypesImpOrExp === EXPORT_SHIPMENT_TYPE) {
      stateObj['pickupCountry'].value = userAccountCountryCode;
      stateObj['pickupCountry'].error = false;
      stateObj['deliveryCountry'].value = countries[0].code;
    } else {
      stateObj['pickupCountry'].value = countries[0].code;
      stateObj['deliveryCountry'].value = userAccountCountryCode;
      stateObj['deliveryCountry'].error = false;
    }
  }

  stateObj['differentPickupCountry'].value = stateObj['pickupCountry'].value;
  stateObj['differentPickupCountry'].error = false;
  stateObj['differentDeliveryCountry'].value = stateObj['deliveryCountry'].value;
  stateObj['differentDeliveryCountry'].error = false;
};

export const contactEmailMandatoryForInternational = (stateObj, context) => {
  const userAccountCountryCode = context.state?.reactQueryUser?.user?.accountCountryCode?.toUpperCase();

  let caseTxt = '';
  const { state } = context;
  const isGlobalAccountCountry = userAccountCountryCode !== 'SE';
  const isInternational = context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE;

  const internationalPickupAddressResidential = isInternational && state.pickupAddressResidential.value;
  const internationalDeliveryAddressResidential = isInternational && state.deliveryAddressResidential.value;
  const internationalDiffPickupAddressResidential =
    isInternational && state.pickupFromDifferentAddress.value && state.differentPickupAddressResidential.value;
  const internationalDiffDeliveryAddressResidential =
    isInternational && state.deliverToDifferentAddress.value && state.differentDeliveryAddressResidential.value;

  if (internationalPickupAddressResidential && internationalDeliveryAddressResidential) caseTxt = 'internationalC2C';
  else if (internationalDiffDeliveryAddressResidential && internationalDiffPickupAddressResidential)
    caseTxt = 'internationalCustomerDiffAddressToCustomerDiffAddress';
  else if (internationalPickupAddressResidential) caseTxt = 'internationalC2B';
  else if (internationalDeliveryAddressResidential) caseTxt = 'internationalB2C';
  else if (internationalDiffPickupAddressResidential) caseTxt = 'internationalDiffPickupAddressResidential';
  else if (internationalDiffDeliveryAddressResidential) caseTxt = 'internationalDiffDeliveryAddressResidential';
  else if (isGlobalAccountCountry && state.pickupAddressResidential.value) caseTxt = 'globalResidentialDeliveryAddress';
  else if (isGlobalAccountCountry && state.deliveryAddressResidential.value) caseTxt = 'globalResidentialPickupAddress';
  else if (isGlobalAccountCountry && state.differentDeliveryAddressResidential.value)
    caseTxt = 'globalResidentialDifferentDeliveryAddress';
  else if (isGlobalAccountCountry && state.differentPickupAddressResidential.value)
    caseTxt = 'globalResidentialDifferentPickupAddress';

  stateObj.pickupEmail = JSON.parse(JSON.stringify(context.state.pickupEmail));
  stateObj.deliveryEmail = JSON.parse(JSON.stringify(context.state.deliveryEmail));
  stateObj.differentPickupEmail = JSON.parse(JSON.stringify(context.state.differentPickupEmail));
  stateObj.differentDeliveryEmail = JSON.parse(JSON.stringify(context.state.differentDeliveryEmail));

  switch (caseTxt) {
    case 'internationalC2B':
    case 'globalResidentialDeliveryAddress':
      stateObj.pickupEmail.isRequired = true;
      stateObj.deliveryEmail.isRequired = false;
      break;
    case 'internationalB2C':
    case 'globalResidentialPickupAddress':
      stateObj.deliveryEmail.isRequired = true;
      stateObj.pickupEmail.isRequired = false;
      break;
    case 'internationalDiffPickupAddressResidential':
    case 'globalResidentialDifferentPickupAddress':
      stateObj.differentPickupEmail.isRequired = true;
      stateObj.differentDeliveryEmail.isRequired = false;
      break;
    case 'internationalDiffDeliveryAddressResidential':
    case 'globalResidentialDifferentDeliveryAddress':
      stateObj.differentDeliveryEmail.isRequired = true;
      stateObj.differentPickupEmail.isRequired = false;
      break;
    case 'internationalCustomerDiffAddressToCustomerDiffAddress':
      stateObj.differentPickupEmail.isRequired = true;
      stateObj.differentDeliveryEmail.isRequired = true;
      break;
    case 'internationalC2C':
      stateObj.pickupEmail.isRequired = true;
      stateObj.deliveryEmail.isRequired = true;
      break;
    default:
      stateObj.pickupEmail.isRequired = false;
      stateObj.pickupEmail.error = false;
      stateObj.deliveryEmail.isRequired = false;
      stateObj.deliveryEmail.error = false;
      stateObj.differentDeliveryEmail.isRequired = false;
      stateObj.differentDeliveryEmail.error = false;
      stateObj.differentPickupEmail.isRequired = false;
      stateObj.differentPickupEmail.error = false;
  }
};

export const afterResidentialAddressUpdate = context => {
  context.updateState(checkContactEmail(context));
};

export const checkContactEmail = context => {
  const stateObj = {};
  const { state } = context;

  contactEmailMandatoryForInternational(stateObj, context);

  // Domestic - Payer: Receiver case
  const isDomesticPickupResidential =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
    (state.pickupAddressResidential.value || state.differentPickupAddressResidential.value);

  stateObj.accountNumber = JSON.parse(JSON.stringify(state.accountNumber));
  stateObj.receiverNumber = JSON.parse(JSON.stringify(state.receiverNumber));

  if (isDomesticPickupResidential) {
    // set sender account to free text
    stateObj.accountNumber.isSelect = false;
    stateObj.accountNumber.disabled = true;
    stateObj.accountNumber.isRequired = false;
    stateObj.accountNumber.error = false;

    // set receiver account to select
    stateObj.receiverNumber.isSelect = true;
    stateObj.receiverNumber.disabled = false;
    stateObj.receiverNumber.isRequired = true;

    // reset receiver value if not present in account array
    if (!checkIfEnteredAccountIsPresentInAccountList(state, stateObj.receiverNumber)) {
      stateObj.receiverNumber.value = '';
    }
  } else {
    termsOfDeliveryValue(context, stateObj, state.deliveryCountry.value);
  }

  return stateObj;
};

const checkIfEnteredAccountIsPresentInAccountList = (state, accountEvaluation) => {
  return state.accounts.some(account => account.code === accountEvaluation.value);
};

export const setPayerCodeCheck = (context, params, resetCityValidationError) => {
  const stateObj = {};

  if (
    context.state.shipmentTypesImpOrExp.value !== context.state.prevShipmentTypesImpOrExp.value &&
    (!context.state.deliveryReceiver.value || !context.state.pickupSender.value)
  ) {
    Object.assign(stateObj, swapSenderAndReceiverAddress(context));
    resetCityValidationError();
  }

  termsOfDeliveryValue(context, stateObj, context.state.deliveryCountry.value);

  if (params.name === 'shipmentRange' || params.name === 'shipmentPayer') {
    contactEmailMandatoryForInternational(stateObj, context);
  }

  if (params.name === 'shipmentRange' && params.value === DOMESTIC_SHIPMENT_RANGE) {
    Object.assign(stateObj, resetDangerousGoodsWhenSwitchedToDomestic(context.state));
    Object.assign(stateObj, resetPhoneNumberWhenSwitchedToDomestic(context.state));
  }
  if (
    context.state.accounts.length === 1 &&
    context.state.shipmentRange &&
    context.state.shipmentPayer &&
    !(
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      context.state.shipmentPayer.value === RECEIVER_PAYER
    )
  ) {
    if (
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
      (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE)
    ) {
      Object.assign(stateObj, {
        accountNumber: {
          ...stateObj.accountNumber,
          value: context.state.accounts[0].code,
          error: false,
        },
      });
    }
    if (
      context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
    ) {
      Object.assign(stateObj, {
        receiverNumber: {
          ...stateObj.receiverNumber,
          value: context.state.accounts[0].code,
          error: false,
        },
      });
    }
  }
  context.updateState(stateObj);
};

export const setDefaultSenderReceiverAccountNumber = (context, accounts) => {
  const stateObj = {};
  if (
    accounts.length === 1 &&
    context.state.shipmentRange &&
    context.state.shipmentPayer &&
    !(
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      context.state.shipmentPayer.value === RECEIVER_PAYER
    )
  ) {
    if (
      context.state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE ||
      (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
        context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE)
    ) {
      stateObj.accountNumber = {
        ...context.state.accountNumber,
        value: accounts[0].code,
        error: false,
      };
    }
    if (
      context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
    ) {
      stateObj.receiverNumber = {
        ...context.state.receiverNumber,
        value: accounts[0].code,
        error: false,
      };
    }
  }
  context.updateState(stateObj);
};

const resetDangerousGoodsWhenSwitchedToDomestic = state => {
  const shipmentDetailsRows = JSON.parse(JSON.stringify(state.shipmentDetailsRows));

  shipmentDetailsRows.forEach(shipmentDetailRow => {
    shipmentDetailRow.dangerousGoods.value = false;
    shipmentDetailRow.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));
  });

  return { shipmentDetailsRows };
};

const swapSenderAndReceiverAddress = context => {
  const stateObj = {
    ...context.state,
    pickupCity: { ...context.state.deliveryCity },
    pickupCountry: {
      ...context.state.deliveryCountry,
      value: context.state.deliveryCountry.value,
    },
    pickupEmail: { ...context.state.deliveryEmail },
    pickupName: { ...context.state.deliveryName },
    pickupPostalCode: { ...context.state.deliveryPostalCode },
    pickupPhoneNumbers: [
      {
        ...context.state.deliveryPhoneNumbers[0],
      },
    ],
    pickupSender: { ...context.state.deliveryReceiver },
    pickupStreet: { ...context.state.deliveryStreet },
    deliveryCity: {
      ...context.state.pickupCity,
    },
    deliveryCountry: {
      ...context.state.pickupCountry,
    },
    deliveryEmail: { ...context.state.pickupEmail },
    deliveryName: { ...context.state.pickupName },
    deliveryPostalCode: {
      ...context.state.pickupPostalCode,
    },
    deliveryPhoneNumbers: [
      {
        ...context.state.pickupPhoneNumbers[0],
      },
    ],
    deliveryReceiver: { ...context.state.pickupSender },
    deliveryStreet: { ...context.state.pickupStreet },
    differentPickupPhoneNumbers: [
      {
        ...context.state.differentDeliveryPhoneNumbers[0],
      },
    ],
    differentDeliveryPhoneNumbers: [
      {
        ...context.state.differentPickupPhoneNumbers[0],
      },
    ],
  };

  updateCountryValue(
    context.state,
    context.state.shipmentRange.value,
    context.state.shipmentTypesImpOrExp.value,
    stateObj,
  );

  return stateObj;
};

export const checkSubmit = context => {
  let validate = {
    stateToSet: {},
    hasErrors: false,
  };

  const state = JSON.parse(JSON.stringify(context.state));
  const getState = readState(state);

  const userAccountCountryCode = state?.reactQueryUser?.user?.accountCountryCode?.toUpperCase();
  validate = validateAccountNumber(state, validate.stateToSet, validate.hasErrors);

  validate = enterPickupLocationValidations(
    state,
    validate.stateToSet,
    validate.hasErrors,
    'pickup',
    getState,
    userAccountCountryCode,
  );

  validate = enterDeliveryLocationValidations(
    state,
    validate.stateToSet,
    validate.hasErrors,
    'delivery',
    getState,
    userAccountCountryCode,
  );

  if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    validate = enterDeliveryLocationValidations(
      state,
      validate.stateToSet,
      validate.hasErrors,
      'differentDelivery',
      getState,
      userAccountCountryCode,
    );
  }

  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    validate = enterPickupLocationValidations(
      state,
      validate.stateToSet,
      validate.hasErrors,
      'differentPickup',
      getState,
      userAccountCountryCode,
    );
  }

  context.updateState(validate.stateToSet);

  return validate.hasErrors;
};

export const renderDifferentDeliveryAddress = (
  context,
  t,
  state,
  showAddressPickup,
  checkAppropriateCity,
  setAppropriateCity,
  checkIfEnteredAndSuggestedCityIsValid,
  enableResidentialToAddress,
  residentialAddressToRequired,
) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t(`general|Delivery Address`)}</h4>
      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="frc__address-book-btn l-grid--w-100pc-s">
          <Button
            id="addressBook"
            dataTestId="diffReceiverAddressBookBtn"
            submit={() => showAddressPickup(CONST.DIFFERENT_RECEIVER)}
            className="base-button"
            label={t(`general|Address Book`)}
          />
        </div>

        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Delivery residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentDeliveryAddressResidential"
          isRequired={false}
          checked={context.state.differentDeliveryAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={afterResidentialAddressUpdate}
          disabled={context.state.shipmentPayer.value === RECEIVER_PAYER || !enableResidentialToAddress}
        />
        {residentialAddressToRequired && !context.state.differentDeliveryAddressResidential.value ? (
          <Typography
            variant="body1"
            color={theme => theme.palette.error.main}
            sx={{
              ml: '35px',
              fontSize: '15px',
            }}
          >
            {t('general|labels|inputs|required')}
          </Typography>
        ) : null}

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__company-search-position">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Receiver')}
            name="differentDeliveryReceiver"
            dataTestId="differentDeliveryReceiverInp"
            isRequired={true}
            value={context.state.differentDeliveryReceiver.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            updateOnParent={(params, contextC) =>
              addressAutoComplete(params, contextC, 'showCompanySearchDifferentReceiver')
            }
            cutTextLimit={config.maxDefaultInputLength}
            hideAutoComplete={true}
          />

          {context.state.showCompanySearchDifferentReceiver ? (
            <CompanyNameSearch
              party={CONST.DIFFERENT_RECEIVER}
              searchTerm={context.state.differentDeliveryReceiver.value}
              context={context}
              checkAppropriateCity={checkAppropriateCity}
            />
          ) : (
            ''
          )}

          {context.state.differentDeliveryReceiver.error ? (
            <Error
              name="differentDeliveryReceiver"
              className="frc__input--error"
              message={
                context.state.differentDeliveryAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentDeliveryStreet"
              isRequired={context.state.differentDeliveryStreet.isRequired}
              value={context.state.differentDeliveryStreet.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentDeliveryStreet.error ? (
              <Error
                name="differentDeliveryStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div
            className=" l-grid--w-100pc-s l-grid--w-50pc-m"
            onBlur={
              context.state.differentDeliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                ? () =>
                    checkAppropriateCity(
                      'differentDeliveryCountry',
                      'differentDeliveryPostalCode',
                      'differentDeliveryCity',
                      'destinationPostalCodeError',
                      'destinationCityError',
                    )
                : null
            }
          >
            <PostalCodeInput
              value={context.state.differentDeliveryPostalCode.value}
              name="differentDeliveryPostalCode"
              isRequired
              countryCode={context.state.deliveryCountry.value}
              error={
                context.state.differentDeliveryPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : state.cityValidationError?.differentDeliveryCity?.postalCodeError
                  ? POSTALCODE_ERRORS.cityPostalCodeError
                  : context.state?.deliverToDifferentAddress?.value && state?.destinationPostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentDeliveryCity"
              isRequired={true}
              value={context.state.differentDeliveryCity.value}
              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
              afterUpdate={
                state.cityValidationError.differentDeliveryCity
                  ? () => checkIfEnteredAndSuggestedCityIsValid('differentDeliveryCity')
                  : null
              }
              afterChangeOnBlur={
                !(
                  state.cityValidationError.differentDeliveryCity &&
                  state.cityValidationError.differentDeliveryCity.mismatchError
                ) &&
                context.state.differentDeliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                context.state.differentDeliveryCountry.value === state.user?.accountCountryCode?.toUpperCase()
                  ? () =>
                      checkAppropriateCity(
                        'differentDeliveryCountry',
                        'differentDeliveryPostalCode',
                        'differentDeliveryCity',
                        'destinationPostalCodeError',
                        'destinationCityError',
                      )
                  : null
              }
            />
            {context.state.differentDeliveryCity.error ? (
              <Error
                name="differentDeliveryCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : state.cityValidationError.differentDeliveryCity &&
              state.cityValidationError.differentDeliveryCity.mismatchError ? (
              <div className="l-grid">
                <Error
                  name="cityMismtachError"
                  className="frc__input--error"
                  message={t('general|errors|invalidCity')}
                />
                <span className="frc__dhl-red-color">
                  {'"'}
                  <span
                    className="frc__city-underline"
                    onClick={() => setAppropriateCity(false, 'differentDeliveryCity', 'destinationCityError')}
                  >
                    {state.cityValidationError.differentDeliveryCity.value}
                  </span>
                  {'" ?'}
                </span>
              </div>
            ) : context.state.deliverToDifferentAddress.value ? (
              state.destinationCityError && (
                <Error
                  name="deliverToDifferentAddressdestinationCityError"
                  className="frc__input--error"
                  message={t('general|errors|City API Error', {
                    max: config.maxDefaultInputLength,
                  })}
                />
              )
            ) : (
              ''
            )}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentDeliveryCountry"
            isRequired={true}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentDeliveryCountry.value}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentDeliveryCountry.error ? (
            <Error name="differentDeliveryCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentDeliveryName"
            isRequired={true}
            value={context.state.differentDeliveryName.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentDeliveryName.error ? (
            <Error
              name="differentDeliveryName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentDeliveryEmail"
            value={context.state.differentDeliveryEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
            isRequired={context.state.differentDeliveryEmail.isRequired}
          />
          {context.state.differentDeliveryEmail.error ? (
            <Error
              name="differentDeliveryEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|Send notification delivery')}
            name="differentDeliveryEmailConfirmation"
            isRequired={false}
            checked={context.state.differentDeliveryEmailConfirmation.value}
            className="frc__email-checkbox-confirmation"
          />
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentDeliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumberV2
              key={ind}
              context={context}
              config={config}
              CONST={CONST}
              index={ind}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentDeliveryPhoneNumbers"
              isRequired={true}
            />
          ))}
        </div>
      </div>
      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper has-top-margin-1">
        <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-m">
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Add to address book`)}
            name="addToAddressBookDifferentReceiver"
            isRequired={false}
            checked={context.state.addToAddressBookDifferentReceiver.value}
            disabled={context.state.updateInAddressBookDifferentReceiver.value}
          />
        </div>

        <div className="l-grid--w-50pc-s l-grid--w-50pc-m-m">
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|labels|inputs|Update in Address Book')}
            name="updateInAddressBookDifferentReceiver"
            isRequired={false}
            checked={context.state.updateInAddressBookDifferentReceiver.value}
            disabled={
              !context.state.differentReceiverPickedFromAddressBook ||
              context.state.addToAddressBookDifferentReceiver.value
            }
          />

          {context.state.updateInAddressBookDifferentReceiver.value && (
            <div className="frc__update-note">
              {t(`general|Address`)} <strong>{context.state.differentDeliveryReceiver.value}</strong>{' '}
              {t('general|will be updated')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const renderDifferentPickUpAddress = (
  context,
  t,
  state,
  showAddressPickup,
  checkAppropriateCity,
  setAppropriateCity,
  checkIfEnteredAndSuggestedCityIsValid,
  enableResidentialFromAddress,
  residentialAddressFromRequired,
) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t('general|Pickup Address')}</h4>

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="frc__address-book-btn l-grid--w-100pc-s">
          <Button
            id="addressBook"
            dataTestId="diffSenderAddressBookBtn"
            submit={() => showAddressPickup(CONST.DIFFERENT_SENDER)}
            className="base-button"
            label={t(`general|Address Book`)}
          />
        </div>

        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Pickup residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentPickupAddressResidential"
          isRequired={false}
          checked={context.state.differentPickupAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={afterResidentialAddressUpdate}
          disabled={context.state.shipmentPayer.value === SENDER_PAYER || !enableResidentialFromAddress}
        />
        {residentialAddressFromRequired && !context.state.differentPickupAddressResidential.value ? (
          <Typography
            variant="body1"
            color={theme => theme.palette.error.main}
            sx={{
              ml: '35px',
              fontSize: '15px',
            }}
          >
            {t('general|labels|inputs|required')}
          </Typography>
        ) : null}

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__company-search-position">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Sender')}
            name="differentPickupSender"
            isRequired={true}
            value={context.state.differentPickupSender.value}
            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
            updateOnParent={(params, contextC) =>
              addressAutoComplete(params, contextC, 'showCompanySearchDifferentSender')
            }
            cutTextLimit={config.maxDefaultInputLength}
            hideAutoComplete={true}
          />
          {context.state.showCompanySearchDifferentSender ? (
            <CompanyNameSearch
              party={CONST.DIFFERENT_SENDER}
              searchTerm={context.state.differentPickupSender.value}
              context={context}
              checkAppropriateCity={checkAppropriateCity}
            />
          ) : (
            ''
          )}

          {context.state.differentPickupSender.error ? (
            <Error
              name="differentPickupSender"
              className="frc__input--error"
              message={
                context.state.differentPickupAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>
        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentPickupStreet"
              isRequired={context.state.differentPickupStreet.isRequired}
              value={context.state.differentPickupStreet.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentPickupStreet.error ? (
              <Error
                name="differentPickupStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div
            className=" l-grid--w-100pc-s l-grid--w-50pc-m"
            onBlur={
              context.state.differentPickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                ? () =>
                    checkAppropriateCity(
                      'differentPickupCountry',
                      'differentPickupPostalCode',
                      'differentPickupCity',
                      'sourcePostalCodeError',
                      'sourceCityError',
                    )
                : null
            }
          >
            <PostalCodeInput
              value={context.state.differentPickupPostalCode.value}
              name="differentPickupPostalCode"
              isRequired
              countryCode={context.state.pickupCountry.value}
              error={
                context.state.differentPickupPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : state.cityValidationError?.differentPickupCity?.postalCodeError
                  ? POSTALCODE_ERRORS.cityPostalCodeError
                  : context.state?.pickupFromDifferentAddress?.value && state?.sourcePostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentPickupCity"
              isRequired={true}
              value={context.state.differentPickupCity.value}
              lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
              afterUpdate={
                state.cityValidationError.differentPickupCity
                  ? () => checkIfEnteredAndSuggestedCityIsValid('differentPickupCity')
                  : null
              }
              afterChangeOnBlur={
                !(
                  state.cityValidationError.differentPickupCity &&
                  state.cityValidationError.differentPickupCity.mismatchError
                ) &&
                context.state.differentPickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                context.state.differentPickupCountry.value === context?.user?.accountCountryCode?.toUpperCase()
                  ? () =>
                      checkAppropriateCity(
                        'differentPickupCountry',
                        'differentPickupPostalCode',
                        'differentPickupCity',
                        'sourcePostalCodeError',
                        'sourceCityError',
                      )
                  : null
              }
            />
            {context.state.differentPickupCity.error ? (
              <Error
                name="differentPickupCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : state.cityValidationError.differentPickupCity &&
              state.cityValidationError.differentPickupCity.mismatchError ? (
              <div className="l-grid">
                <Error
                  name="cityMismtachError"
                  className="frc__input--error"
                  message={t('general|errors|invalidCity')}
                />
                <span className="frc__dhl-red-color">
                  {'"'}
                  <span
                    className="frc__city-underline"
                    onClick={() => setAppropriateCity(false, 'differentPickupCity', 'sourceCityError')}
                  >
                    {state.cityValidationError.differentPickupCity.value}
                  </span>
                  {'" ?'}
                </span>
              </div>
            ) : context.state.pickupFromDifferentAddress.value ? (
              state.sourceCityError && (
                <Error
                  name="pickupFromDifferentAddresssourceCityError"
                  className="frc__input--error"
                  message={t('general|errors|City API Error', {
                    max: config.maxDefaultInputLength,
                  })}
                />
              )
            ) : (
              ''
            )}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentPickupCountry"
            isRequired={true}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentPickupCountry.value}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentPickupCountry.error ? (
            <Error name="differentPickupCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentPickupName"
            isRequired={true}
            value={context.state.differentPickupName.value}
            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentPickupName.error ? (
            <Error
              name="differentPickupName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentPickupEmail"
            isRequired={context.state.differentPickupEmail.isRequired}
            value={context.state.differentPickupEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
          />
          {context.state.differentPickupEmail.error ? (
            <Error
              name="differentPickupEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|Send notification pickup')}
            name="differentPickupEmailConfirmation"
            isRequired={false}
            checked={context.state.differentPickupEmailConfirmation.value}
            className="frc__email-checkbox-confirmation"
          />
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentPickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumberV2
              key={ind}
              index={ind}
              context={context}
              config={config}
              CONST={CONST}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentPickupPhoneNumbers"
              isRequired={true}
            />
          ))}
        </div>
      </div>

      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper has-top-margin-1">
        <div className=" l-grid--w-50pc-s l-grid--w-50pc-m-m">
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Add to address book`)}
            name="addToAddressBookDifferentSender"
            isRequired={false}
            checked={context.state.addToAddressBookDifferentSender.value}
            disabled={context.state.updateInAddressBookDifferentSender.value}
          />
        </div>
        <div className="l-grid--w-50pc-s l-grid--w-50pc-m-m">
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|labels|inputs|Update in Address Book')}
            name="updateInAddressBookDifferentSender"
            isRequired={false}
            checked={context.state.updateInAddressBookDifferentSender.value}
            disabled={
              !context.state.differentSenderPickedFromAddressBook || context.state.addToAddressBookDifferentSender.value
            }
          />

          {context.state.updateInAddressBookDifferentSender.value && (
            <div className="frc__update-note">
              {t(`general|Address`)} <strong>{context.state.differentPickupSender.value}</strong>{' '}
              {t('general|will be updated')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const getOrderPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    const cutPhoneByDash = phoneNumber.split('-');
    return [
      {
        code: cutPhoneByDash[0],
        error: false,
        isRequired: true,
        number: cutPhoneByDash.slice(1).join('-') || '',
      },
    ];
  }

  return [
    {
      code: config.defaultCountryPhoneCode,
      error: false,
      isRequired: true,
      number: '',
    },
  ];
};

export const addressAutoComplete = (params, context, companySearchAttribute, addressBookAttribute) => {
  const showCompanySearch = params.value.length >= config.minLengthToPerformCompanySearch;

  params.secondParam = {
    [companySearchAttribute]: showCompanySearch,
    [addressBookAttribute]: true,
  };

  context.updateField(params);
};

export const getPickupPhoneNumber = phoneNumber => {
  const { code, number } = preparePhoneNumber(phoneNumber);
  return phoneNumber
    ? [
        {
          code,
          error: false,
          isRequired: true,
          number,
        },
      ]
    : [
        {
          code: config.defaultCountryPhoneCode,
          error: false,
          isRequired: true,
          number: '',
        },
      ];
};

export const getCountryForCustoms = state => {
  switch (state.shipmentTypesImpOrExp.value) {
    case EXPORT_SHIPMENT_TYPE:
      return state.deliveryCountry.value;

    case IMPORT_SHIPMENT_TYPE:
      return state.pickupCountry.value;

    default:
      break;
  }
};

export const setCustomsFlag = (stateToSet, ctxState, isInternational) => {
  if (isInternational) {
    const selectedCountry = getCountryForCustoms(stateToSet);
    const customsCountry = findCustomBasedCountry(ctxState, selectedCountry);
    stateToSet.countryBasedCustoms = customsCountry && customsCountry.customs;
  }
};

export const setAddressReferenceInstructions = (stateToSet, party, ctxState, address) => {
  stateToSet.addressReferenceInstructions = JSON.parse(JSON.stringify(ctxState.addressReferenceInstructions));
  stateToSet.addressReferenceInstructions[party] = {
    deliveryInstructions: address.deliveryInstructions,
    reference: address.reference,
  };
};

export const fillDeliveryInstruction = (stateToSet, party, ctxState, address) => {
  if (
    (ctxState.deliverToDifferentAddress.value && party === CONST.DIFFERENT_RECEIVER) ||
    (!ctxState.deliverToDifferentAddress.value && party === CONST.RECEIVER)
  ) {
    stateToSet.deliveryInstructions = { value: address.deliveryInstructions || '', error: false };
  }
};

export const fillSenderReceiverReference = (stateToSet, party, address) => {
  if (party === CONST.SENDER) {
    stateToSet.pickupSenderReference = { value: address.reference || '', error: false };
  } else if (party === CONST.RECEIVER) {
    stateToSet.deliveryReceiverReference = { value: address.reference || '', error: false };
  }
};
