import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES, useRouting } from 'router';

type Props = {
  open: boolean;
};

export const BulkBookingCompleteBrief: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation();
  const { navigate } = useRouting();

  return (
    <Dialog open={open} maxWidth="md" fullWidth data-testid="bulk-booking-dialog">
      <DialogTitle data-testid="dialog-title" sx={{ textAlign: 'left' }}>
        {t('general|quickBookingCompleteTitle')}
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="body1">{t('general|quickBookingCompleteDescription')}</Typography>
      </DialogContent>

      <DialogActions
        sx={{
          pt: '24px',
        }}
      >
        <Button
          onClick={() => {
            navigate(ROUTES.onholdOrders);
          }}
          color="primary"
          variant="contained"
          data-testid="close-button"
        >
          {t('general|onHoldOrdersLink')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
