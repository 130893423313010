import { apiLinks } from 'config/api-config';
import { returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { formatDate, formatDateTime } from 'globals/utils/formatting';
import getBaseUrl from 'globals/utils/getUrlBase';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import { ROUTES } from 'router';
import { ACCESS_POINT_CUSTOMER_TYPE_LONG, RECEIVER_PAYER, SENDER_PAYER } from '../../globals/constants';
import { generateAdditionalInformationList } from '../../globals/helpers/additionalInformation';
import { CONST, config } from './../data-config';

const ENV_DEV = process.env.NODE_ENV === 'development';

export const onSaveForFutureClick = async (context, t) => {
  const baseUrl = getBaseUrl(ROUTES.portal);
  const result = await saveShipmentCall(context, 1);

  if (!result.error) {
    // delete call only when id present
    if (context.state.templateType0SavedShipment.id) {
      await deleteTemporarySavedShipment(context);
    }
    // Redirect to dashboard
    window.location = `${baseUrl}/${ROUTES.dashboard}`;
  }

  return result;
};

export const saveShipmentCall = async (context, templateType = 0) => {
  const resultMap = {};
  const url = apiLinks.postAddSavedShipmentPortal;
  const stateToSet = {};

  const params = {
    body: prepareSavedShipmentData(context.state, templateType),
  };

  if (templateType === 3) {
    params.body.id = 0;
  }

  await apiRequest(url, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        if (templateType === 1) {
          stateToSet.templateType1SavedShipment = result.data;
        } else {
          stateToSet.templateType0SavedShipment = result.data;
        }

        context.updateState(stateToSet);
        resultMap.data = result.data;
      } else {
        resultMap.error = true;
        resultMap.status = result.status;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

const getCalculatedPrice = quotePriceMap => {
  // freightCost field is called different based on selected package type
  const freightCostExists =
    quotePriceMap?.FreightCost?.value || quotePriceMap?.PALL?.value || quotePriceMap?.HALV?.value;

  if (freightCostExists && quotePriceMap?.TotalPrice?.value) {
    return { price: quotePriceMap.TotalPrice.value, currency: quotePriceMap.TotalPrice?.unit };
  } else {
    return { price: '0', currency: null };
  }
};

export const prepareSavedShipmentData = (state, templateType) => {
  const properState = getPropperAPIData(state);
  const { price, currency } = getCalculatedPrice(state.quotePriceMap);

  const savedShipmentData = {
    createdAt: new Date().toISOString(),
    id: (templateType === 1 ? state.templateType1SavedShipment.id : state.templateType0SavedShipment.id) || 0,
    requestId: state.requestId,
    shipmentNumber: state.shipmentNumber,
    calculatedPrice: price,
    calculatedPriceCurrency: currency,
    importExport: state.shipmentTypesImpOrExp.value,
    route: state.shipmentRange.value,
    rreference: state.deliveryReceiverReference.value,
    rresidential: state.deliverToDifferentAddress.value
      ? state.differentDeliveryAddressResidential.value
      : state.deliveryAddressResidential.value,
    rdifferent: state.deliverToDifferentAddress.value,
    productRange: state.productRange,

    // party
    savedShipmentParties: formPartyData(state),

    sdifferent: state.pickupFromDifferentAddress.value,
    sreference: state.pickupSenderReference.value,

    sresidential: state.pickupFromDifferentAddress.value
      ? state.differentPickupAddressResidential.value
      : state.pickupAddressResidential.value,

    templateName: state.templateName.value || 'Portal Order Saved Shipments',
    // templateOrder: "string", // User-defined order of templates. Not used.
    templateType: templateType,
    termsOfDelivery: state.termsOfDelivery.value,

    // schedule pickup
    dropAtDHL: state.pickupOption.value === 'Drop off a package at a DHL location',
    useExistingPickup: state.pickupOption.value === 'Use an already scheduled pickup at my location',
    schedulePickup: state.pickupOption.value === 'Schedule Pickup',

    whoPay: state.shipmentPayer.value,
    status: CONST.IN_COMPLETE,
    savedShipmentAdditionalInformationList: generateAdditionalInformationList(state),
  };

  if (state.pickupDate.value) {
    savedShipmentData.pickupDate = formatDate(new Date(state.pickupDate.value));
  }
  if (state.selectedProduct?.deliveryDate) {
    savedShipmentData.deliveryDate = state.selectedProduct?.deliveryDate;
  }

  // sender account , other account
  if (state.accountNumber.display) {
    savedShipmentData.senderAccount = state.accountNumber.value || '';
    savedShipmentData.otherAccount = state.receiverNumber.value || state.thirdPartyNumber.value || '';
  } else if (state.receiverNumber.display) {
    savedShipmentData.senderAccount = state.receiverNumber.value || '';
    savedShipmentData.otherAccount = state.thirdPartyNumber.value || '';
  }

  savedShipmentData.isSenderAccSelect = state.accountNumber.isSelect || false;
  savedShipmentData.isOtherAccSelect = state.receiverNumber.isSelect || state.thirdPartyNumber.isSelect || false;

  // shipment
  savedShipmentData.savedShipmentPieces =
    state.shipmentDetailsRows.length > 0 ? formShipmentData(state.shipmentDetailsRows) : [];

  savedShipmentData.savedShipmentVass =
    state.additionalServices.length > 0 ? savedShipmentVASs(state.additionalServices) : [];

  // product ID
  if (state.selectedProduct) {
    savedShipmentData.productID = state.selectedProduct.code;
    state.isb2cProduct = config.b2cProducts.indexOf(state.selectedProduct.code) !== -1;

    // if secondary code selected, we would still save as primary code to avoid confusion in templates
    if (config.homeDeliverySecondaryCodes.indexOf(state.selectedProduct.code) !== -1) {
      savedShipmentData.productID = config.homeDeliveryMapping[state.selectedProduct.code].primaryCode;
    }
  }

  if (state.pickupInstructions.value) savedShipmentData.pickupInstruction = state.pickupInstructions.value;

  if (state.deliveryInstructions.value) savedShipmentData.deliveryInstruction = state.deliveryInstructions.value;

  if (state.isb2cProduct) {
    savedShipmentData.servicePoint = state.servicePointUseResidentialAddress.value ? '' : state.servicePointId;
  }

  if (state.servicePointUseResidentialAddress.isProductServicePointDelivery) {
    const notification =
      state.notificationsForReceiver && state.notificationsForReceiver.find(notification => notification.value);

    savedShipmentData.notifyByEmail = notification && notification.type === 'email';
    savedShipmentData.notifyBySMS = notification && notification.type === 'sms';
  } else {
    savedShipmentData.useResidential = state.servicePointUseResidentialAddress.value;
  }

  if (state.termsOfDelivery.value) savedShipmentData.termsOfDelivery = state.termsOfDelivery.value;

  // used for shipment history
  if (state.isb2cProduct) {
    if (state.servicePointUseResidentialAddress.value) {
      savedShipmentData.savedShipmentParties.push(
        formData(properState, 'delivery', RECEIVER_PAYER, ACCESS_POINT_CUSTOMER_TYPE_LONG),
      );
    } else {
      savedShipmentData.savedShipmentParties.push(
        formData(state.selectedServicePoint, '', '', ACCESS_POINT_CUSTOMER_TYPE_LONG),
      );
    }
  }

  // confirmation email
  if (state.confirmationEmail.value) {
    savedShipmentData.shipmentConfirmationEmail = state.confirmationEmail.value;
  }

  Object.assign(savedShipmentData, formTotalShipmentDetails(state.totals.shipmentDetailsRows));

  return savedShipmentData;
};

const formTotalShipmentDetails = totalShipmentDetail => {
  const stateToSet = {};
  stateToSet.totalLDM = (totalShipmentDetail.loadingMeter && totalShipmentDetail.loadingMeter.value) || '';
  stateToSet.totalVolume = (totalShipmentDetail.volume && totalShipmentDetail.volume.value) || 0;
  stateToSet.totalWeight = (totalShipmentDetail.weight && totalShipmentDetail.weight.value) || 0;
  stateToSet.totalNumberOfPieces = (totalShipmentDetail.quantity && totalShipmentDetail.quantity.value) || 0;

  return stateToSet;
};

const savedShipmentVASs = additionalServices => {
  const hasPredefinedVAS =
    additionalServices?.length && additionalServices?.some(additionalService => additionalService.vasFieldValue);

  if (hasPredefinedVAS) {
    const checkedVAS = additionalServices.filter(additionalService => additionalService.vasFieldValue);

    return checkedVAS;
  }

  const savedShipmentVass = [];

  additionalServices
    .filter(vas => vas.value)
    .forEach(additionalService => {
      if (config.additionalServicesWhichHasNoOptions.indexOf(additionalService.code) !== -1) {
        savedShipmentVass.push({
          vas: additionalService.code,
          vasField: '',
        });
      } else {
        if (additionalService.groups && additionalService.groups.length > 0) {
          additionalService.groups.forEach((group, groupIndex) => {
            if (group.options) {
              group.options.forEach((optionList, optionIndex) => {
                optionList.forEach(option => {
                  if (
                    config.OptionFieldTypes.inputTypes[option.type] ||
                    option.type === 'LTCdate' ||
                    config.OptionFieldTypes[option.type]
                  ) {
                    savedShipmentVass.push({
                      vas: additionalService.code,
                      vasField: option.optionCode,
                      vasFieldValue:
                        config.OptionFieldTypes.Telephone === option.type
                          ? option.value[0].code && option.value[0].number
                            ? option.value[0].code + '-' + option.value[0].number
                            : ''
                          : config.OptionFieldTypes.DateTime === option.type
                          ? formatDateTime(option.value)
                          : option.value,
                      groupIndex: groupIndex,
                      optionIndex: optionIndex,
                    });
                  }
                });
              });
            }
          });
        } else {
          savedShipmentVass.push({
            vas: additionalService.code,
            vasField: '',
          });
        }
      }
    });

  return savedShipmentVass;
};

const formShipmentData = shipmentDetails => {
  const savedShipmentPieces = [];

  shipmentDetails.forEach(shipmentRow => {
    const piece = {
      goodsDescription: shipmentRow.goodsDescription?.value || null,
      shipmentMark: shipmentRow.shippingMark?.value || null,
      height: shipmentRow.height?.value || null,
      length: shipmentRow.length?.value || null,
      loadMeter: returnProperValueOrNull(shipmentRow.loadingMeter.value),
      quantity: shipmentRow.quantity?.value || null,
      type: shipmentRow.shipmentType.value,
      nonStackable: shipmentRow.nonStackable.value,
      volume: returnProperValueOrNull(shipmentRow.volume.value),
      weight: returnProperValueOrNull(shipmentRow.weight.value),
      width: shipmentRow.width?.value || null,
    };

    if (shipmentRow.dangerousGoods.value) {
      piece.dangerousGoods = shipmentRow.dangerousGoodGroup.map(item => {
        return {
          adrClass: item.adrClass.value,
          unNumber: item.unNumber.value,
          flamePoint: item.flamePoint.value,
          packagingGroup: item.packagingGroup.value,
          tunnelDescriptionCode: item.tunnelDescriptionCode.value,
          properShippingName: item.properShippingName.value,
          technicalDescription: item.technicalDescription.value,
          limitedQuantity: item.limitedQuantity.value,
          grossWeight: item.grossWeight.value,
          netQuantity: item.netQuantity.value,
          numberOfUnits: item.numberOfUnits.value,
          packageCode: item.packageCode.value,
          quantityMeasurementUnitQualifier: item.quantityMeasurementUnitQualifier.value,
          environmentHazardous: item.environmentHazardous.value,
          marinePollutant: item.marinePollutant.value,
          marinePollutantName: item.marinePollutantName.value,
          exceptedQuantity: item.exceptedQuantity.value,
          emptyContainer: item.emptyContainer.value,
          waste: item.waste.value,
          dgmId: item.dgmId.value,
        };
      });
    }

    savedShipmentPieces.push(piece);
  });

  return savedShipmentPieces;
};

const formPartyData = state => {
  const parties = [];

  parties.push(formData(state, 'pickup', SENDER_PAYER, 'sender'));
  parties.push(formData(state, 'delivery', RECEIVER_PAYER, 'receiver'));

  if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
    parties.push(formData(state, 'differentPickup', SENDER_PAYER, 'differentSender'));
  }
  if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
    parties.push(formData(state, 'differentDelivery', RECEIVER_PAYER, 'differentReceiver'));
  }

  return parties;
};

const formData = (state, type, name, customerType) => {
  let party;

  if (state[type + 'City'] !== undefined) {
    party = {
      id: state[type + 'Id'] || 0,
      city: state[type + 'City'].value || null,
      companyName: state[type + name].value || null,
      countryCode: state[type + 'Country'].value || null,
      postalCode: state[type + 'PostalCode'].value || null,
      street: state[type + 'Street'].value || null,

      email: state[type + 'Email'].value || null,
      emailConfirmation: state[type + 'EmailConfirmation'].value || null,
      name: state[type + 'Name'].value || null,
      phone: state[type + 'PhoneNumbers'][0].code + '-' + state[type + 'PhoneNumbers'][0].number,
      type: customerType,
    };
  } else {
    party = {
      companyName: state.partyName || null,
      cityName: state.cityName || null,
      countryCode: state.countryCode || config.defaultCountry,
      postalCode: state.postalCode || null,
      street: state.street || state.address1 || null,
      confirmationEmail: null,
      contactName: null,
      email: state.email || null,
      emailConfirmation: state.emailConfirmation || null,
      id: 0,
      name: state.name || null,
      phone: state.phone || null,
      type: customerType,
    };
  }

  if (customerType === ACCESS_POINT_CUSTOMER_TYPE_LONG) {
    party.city = state.cityName || null;
    party.subtype = state.locationType || null;
  }

  return party;
};

export const deleteTemporarySavedShipment = async context => {
  const url = apiLinks.postAddSavedShipmentPortal;
  const params = {
    body: [context.state.templateType0SavedShipment.id],
  };

  await apiRequest(url, 'DELETE', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        context.updateState({ templateType0SavedShipment: {} });
      } else {
        ENV_DEV && console.log('delete shipment error');
      }
    })
    .catch(() => {
      ENV_DEV && console.log('error');
    });
};

export const getSavedShipmentByIdCall = async id => {
  const url = apiLinks.useTemplate.replace('{id}', id);
  const resultMap = {};

  await apiRequest(url, 'PUT')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        resultMap.status = true;
        resultMap.data = result.data;
      } else if (result.status === CONST.BAD_REQUEST_ERROR) {
        resultMap.status = false;
        resultMap.error = true;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};
