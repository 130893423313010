import { rootApi } from './apiSlice';
import { apiLinks } from '../../config/api-config';
import { TagTypes } from './apiSlice';
import { AUTHORIZATION } from '../../Login/constants';

export type AddressType = {
  id: number;
  addressId: string;
  companyName: string;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
  email: string;
  phone: string;
  notes: string;
  residentialAddress: boolean;
};

type SearchAddressParams = {
  countryCriteria: string;
  criteriaCountries: string[];
  page: number;
  pageSize: number;
  searchTerm: string | null;
  countryCode: string;
};

type SearchAddressResponse = {
  total: number;
  lastPage: number;
  pageSize: number;
  page: number;
  addressBookList: AddressType[];
};

const addressApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    searchAddresses: builder.query<SearchAddressResponse, SearchAddressParams>({
      query: ({ countryCriteria, criteriaCountries, page, pageSize, searchTerm, countryCode }) => ({
        url: apiLinks.addressSearch,
        method: 'POST',
        headers: {
          'Country-Code': countryCode,
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: {
          countryCriteria,
          criteriaCountries,
          page,
          pageSize,
          searchTerm,
        },
      }),
      providesTags: [TagTypes.ADDRESS_BOOK_TAG],
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useLazySearchAddressesQuery } = addressApi;
