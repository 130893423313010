import { caseInsensitiveEquals } from '../utils/strings';
import { DOMESTIC_SHIPMENT_RANGE, PRODUCTS_RANGE } from '../constants';
import { config } from 'portal-order/data-config';
/**
 * Returns array of description bullet points parsed
 * from resolved translation
 *
 * @param {string | number} productCode - Product code
 * @param {Function} t - Translation function from i18next
 * @param {Object} i18n - i18 instance
 * @returns {Array<string>} - Array of bullet points for product description.
 */
export const getDescBulletPoints = (productCode, i18n, t) => {
  const translationKey = `products|${productCode}|descBulletPoints`;
  if (!i18n.exists(translationKey)) {
    return [];
  }

  const translation = t(translationKey);
  if (!translation || translation === '' || translation === translationKey) {
    return [];
  }
  return translation.split(';');
};

/**
 * Returns readMore link or null for the specific
 * product code based on resolved translation
 *
 * @param {string | number} productCode - Product code
 * @param {Function} t - Translation function from i18next
 * @param {Object} i18n - i18 instance
 * @returns {string | null} - Returns an URL string or null
 */
export const getReadMoreLink = (productCode, i18n, t) => {
  const translationKey = `products|${productCode}|readMoreLink`;
  if (!i18n.exists(translationKey)) {
    return null;
  }

  const translation = t(translationKey);
  if (translation === '' || translation === translationKey) {
    return null;
  }
  return translation;
};

/**
 * Check if product is using commited delivery
 *
 * @param {string} productCode - Product code
 * @returns {boolean} - Returns an URL string or null
 */
export const shouldUseCommitedDelivery = productCode => {
  // product flag will be provided by backend
  // then convert from this hardcoded variant
  const isCommitedDelivery = productCode === '233' || caseInsensitiveEquals(productCode, 'ERI'); // EURAPID

  return isCommitedDelivery;
};

export const getProductSameDay = (userAccounts, accountNumber, productCode) => {
  const account = userAccounts?.filter(account => account.accountNumber === accountNumber)[0];
  const product = account?.products.find(product => product.productCode === productCode);
  return product?.sameDayEnabled;
};

/**
 * Prefilters products based on shipment data
 *
 * @param {Array} productList - Product list
 * @param {object} shipmentData - Shipment data to calculate filtering criteria
 * @returns {boolean} - Returns an URL string or null
 */
export const prefilterShipmentProducts = ({
  productList,
  shipmentData: { deliveryResidential, pickupResidential, shipmentRange },
}) => {
  const isB2C = !!deliveryResidential;
  const isC2B = !!pickupResidential;
  const isDomestic = shipmentRange === DOMESTIC_SHIPMENT_RANGE;

  if (!productList) return [];
  const prefilteredProducts = productList?.filter(product => {
    if (isDomestic !== product.isDomestic) return false;
    if (!product?.marketAvailability) return false;

    if (isB2C && !product.marketAvailability.some(market => market.name === 'B2C')) return false;
    if (!isB2C && product.marketAvailability.every(market => market.name === 'B2C')) return false;

    if (isC2B && !product.marketAvailability.some(market => market.name === 'C2B')) return false;
    if (!isC2B && product.marketAvailability.every(market => market.name === 'C2B')) return false;
    return true;
  });

  return prefilteredProducts;
};

export const isProductRangeSingle = productRange =>
  productRange !== PRODUCTS_RANGE.ACCOUNT && productRange !== PRODUCTS_RANGE.COUNTRY;

export const isSecondaryHomeDelivery = productCode => config.homeDeliverySecondaryCodes.includes(productCode);

/**
 * Checks whether selected product range is valid for product code, we never store
 * secondary home delivery codes in product range so we must check the mapping
 *
 * @param {string} productRange - Product range
 * @param {string} productCode - Product code
 * @returns {boolean} - Returns true if the product range is matching productCode
 */
export const checkProductRangeMatch = ({ productRange, productCode }) => {
  const productRangeSingle = isProductRangeSingle(productRange);
  const secondaryHomeDelivery = isSecondaryHomeDelivery(productCode);

  // single product not selected
  if (!productRangeSingle) {
    return true;
  }

  // primary home delivery and non hd products we compare directly
  if (!secondaryHomeDelivery) {
    return productRange === productCode;
  }

  // we never store secondary hd codes in product range so we must translate the values
  if (secondaryHomeDelivery) {
    return productRange === config.homeDeliveryMapping[productCode]?.primaryCode;
  }
};
