import { Box, BoxProps } from '@mui/material';

type Props = {
  BoxProps?: Omit<BoxProps, 'sx'>;
  sx?: BoxProps['sx'];
  children: React.ReactNode;
};

export const GlobalBox = ({ BoxProps, sx, children }: Props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 2000,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#fff',
        ...sx,
      }}
      {...BoxProps}
    >
      {children}
    </Box>
  );
};
