import { BookingSetupMode } from 'components/fcp-components/bulk-booking-popups/BulkBookingSetup';
import { SavedShipmentDTO } from 'types/savedShipment.types';

export type BulkBookingPayload = {
  pickupOption: BookingSetupMode;
  address: {
    sender: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
    name: string;
    email: string;
    phoneCountryPrefix: string; // "+46"
    phoneNumber: string; // "12345-6789"
  } | null;
  pickupDate: null | string;
  pickupInstructions: null | string;
  keepExistingPickupDate: null | boolean;
  sendDocuments: boolean;
  requestIds: string[];
};

export type OnHoldShipmentListItem = {
  requestId: string;
  accountNumber: string;
  shopName: string;
  reference?: string;
  shipmentNumber: string;
  createdAt: string; // Assuming LocalDate is serialized as a string
  receiverContactName: string;
  receiverCityName: string;
  receiverCountryCode: string;
  receiverPostalCode: string;
  senderCountryCode: string;
  senderPostalCode: string;
  weight: number;
  priceValue: number;
  priceCurrency: string;
  productCode: string;
};

export type OnHoldShipmentCountResponse = {
  totalCount: number;
  countByAccount: { [key: string]: number };
};

type CountsType = {
  BULK_ERROR: {
    current: number;
    maxAllowed: number;
  };
  DOWNLOADED_MOVED: {
    current: number;
    maxAllowed: number;
  };
};

type OnHoldLimits = {
  counts: CountsType;
};

export type OnHoldShipmentListResponse = {
  featureDisabled: boolean;
  limits: OnHoldLimits;
  shipments: OnHoldShipmentListItem[];
};

export type OnHoldDetailResponse = SavedShipmentDTO;

export type MoveOnHoldShipmentsResponse = {
  shipments: SavedShipmentDTO[];
};

export enum DocumentType {
  LABEL = 'LABEL',
  WAYBILL = 'WAYBILL',
  SHIPMENT_LIST = 'SHIPMENT_LIST',
  RETURN_LABEL = 'RETURN_LABEL',
  PRE_7 = 'PRE_7',
  PRE_10 = 'PRE_10',
  PRE_12 = 'PRE_12',
}

export enum ShipmentStatus {
  INCOMPLETE = 'INCOMPLETE',
  ORDERSENT = 'ORDERSENT',
  BOOKED = 'BOOKED',
  INTRANSIT = 'INTRANSIT',
  DELIVERED = 'DELIVERED',
  MOVED = 'MOVED',
  ERROR = 'ERROR',
}

export type QuickBookingShipment = {
  shipmentId: number;
  shipmentNumber: string;
  reference: string;
  shopName: string;
  productCode: string;
  status: ShipmentStatus;
  pickupOrderNumber: null | string;
  effectivePickupDate: null | string;
  errors: { phraseCode: string; parameters: any }[];
};

export type BulkBookingResult = {
  successCount: number;
  errorCount: number;
  documentCount: {
    [key in DocumentType]?: number;
  };
  shipments: QuickBookingShipment[];
  pickupDateMoved: boolean;
};

export type BulkBooking = {
  bulkBookingId: number;
  createdAt: string;
  elapsedTimeInMillis: number;
  shipmentProcessedCount: number;
  shipmentTotalCount: number;
  result: BulkBookingResult | null;
};

export type BulkBookStatusResponse = {
  bulkBooking: BulkBooking | null;
};
