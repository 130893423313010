import { useTranslation } from 'react-i18next';
import { formatBrowserDateTimeReadable } from 'globals/utils/formatting';
import { LoginOutlined, RunningWithErrorsOutlined, SvgIconComponent, WarningOutlined } from '@mui/icons-material';

import { Box, Stack } from '@mui/material';
import { GetUserDataResponse } from 'store/api/fcpUser.types';
import React from 'react';

interface UserStatisticProps {
  Icon: SvgIconComponent;
  label: string;
  value: string | number | undefined | null;
}

const UserStatistic: React.FC<UserStatisticProps> = ({ Icon, label, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon sx={{ mr: '10px', fontSize: '25px' }} />
      <strong>{label}</strong>: {value ?? '-'}
    </Box>
  );
};

export const UserStatictics = ({ userData }: { userData: GetUserDataResponse }) => {
  const { t } = useTranslation();
  const { user } = userData;

  return (
    <Stack spacing={1}>
      <UserStatistic
        Icon={RunningWithErrorsOutlined}
        label={t('editProfile|Number of failed logins')}
        value={user.failedLoginCount}
      />
      <UserStatistic
        Icon={LoginOutlined}
        label={t('editProfile|Last Login')}
        value={user.lastLogin ? formatBrowserDateTimeReadable(user.lastLogin) : null}
      />
      <UserStatistic
        Icon={WarningOutlined}
        label={t('editProfile|Last Unsuccessful Login')}
        value={user.lastFailedLogin ? formatBrowserDateTimeReadable(user.lastFailedLogin) : null}
      />
    </Stack>
  );
};
