import { Error } from 'components';
import { apiLinks } from 'config/api-config';
import { checkFieldAndReturnState, returnProperValueOrNull } from 'globals/utils/fieldValidations';
import { fixDecimalPointAndConvertToNumber, toFloatOrEmptyString } from 'globals/utils/formatting';
import getBaseUrl from 'globals/utils/getUrlBase';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import React from 'react';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import {
  CONSIGNEE_CUSTOMER_TYPE,
  CONSIGNOR_CUSTOMER_TYPE,
  HUNGARY_CODE,
  ROMANIA_CODE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
  PRODUCTS_RANGE,
} from '../../../globals/constants';
import {
  setShipmentDimensionsForProductShowAll,
  setShipmentDimensionsForProduct,
} from '../../../globals/helpers/farmapi-validations';
import { hasGlobalColliError } from '../../../globals/helpers/shipment-validations';
import { CONST, config } from '../../data-config';
import { addAccountNumberToParties, formAccountNumber } from '../Form-helpers/Party-helpers';
import { calculateInputs } from './ShipmentDetailsRow/ShipmentDetailsRow-helpers';
import { Validation, shipmentDetailsTotalsValidations } from './Validations/ShipmentDetails-validation';
import { validateLoadingMeter } from '../../../globals/helpers/shipment';
import { prefilterShipmentProducts } from '../../../globals/helpers/product';
import { caseInsensitiveEquals } from 'globals/utils/strings';

export const getStatelessObj = () => {
  return {
    data: {},
    matchedProducts: [],
    payload: {},
    payerCodeError: false,
    goNextError: false,
    noContent: false,
    nextBtnLoader: false,
    dataFilledFromTemplate: true,
  };
};

export const setPricesToProduct = statelessObj => {
  statelessObj.matchedProducts.forEach(product => {
    const freightCost =
      statelessObj.productPrices &&
      statelessObj.productPrices[product.code] &&
      !statelessObj.productPrices[product.code].error &&
      Array.isArray(statelessObj.productPrices[product.code]) &&
      statelessObj.productPrices[product.code].find(item => item.id === 'FreightCost');

    // DHL PALL product price special case
    if (product.code === config.dhlPALL) {
      const priceMap = {};
      const prices = statelessObj.productPrices[product.code];

      prices &&
        !prices['error'] &&
        prices.forEach(price => {
          priceMap[price.id] = price;
        });

      if (Object.keys(priceMap).length !== 0) {
        product.price = patchPALLPriceToFreightCost(priceMap);
        product.priceUnit = '';
      } else {
        product.price = '';
        product.priceUnit = '';
      }
    } else {
      product.price = freightCost ? freightCost.value : '';
      product.priceUnit = freightCost ? freightCost.unit : '';
    }
  });
};

export const formPayloadForMatchedProducts = state => {
  const statelessObj = {};

  statelessObj.data = formShipmentDetailsData(state);
  statelessObj.shipmentData = prepareUserData(statelessObj.data, state);
  statelessObj.payload = JSON.parse(JSON.stringify(statelessObj.shipmentData.shipmentDetails));

  statelessObj.data.totals.shipmentDetailsRows.numberOfFullPallets = {
    value: statelessObj.payload.numberOfFullPallets,
  };
  statelessObj.data.totals.shipmentDetailsRows.numberOfHalfPallets = {
    value: statelessObj.payload.numberOfHalfPallets,
  };

  return statelessObj;
};

export const resetShipmentType = (selectedProduct, accountCountryCode, favouriteColliTypes) => {
  let shipmentType = {};

  if (selectedProduct && selectedProduct.code === '210') {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['full pallet']));
  } else if (accountCountryCode?.toLowerCase() === 'se') {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['unspecified']));
  } else if (Array.isArray(favouriteColliTypes) && favouriteColliTypes.length) {
    const favouriteShipmentName = config.shipmentTypes.find(colli => colli.dataRel === favouriteColliTypes[0])?.value;
    shipmentType = JSON.parse(
      JSON.stringify(config.shipmentTypesFields[favouriteShipmentName] || config.shipmentTypesFields['carton/package']),
    );
  } else {
    shipmentType = JSON.parse(JSON.stringify(config.shipmentTypesFields['carton/package']));
  }
  shipmentType.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));

  return shipmentType;
};

const filterAllowedForProductsDangerousGoods = prod => {
  return config.allowedProductsForDangerousGoods.indexOf(prod.code) > -1;
};

const formMatchedProducts = (state, previousStatelessObj, result) => {
  const statelessObj = JSON.parse(JSON.stringify(previousStatelessObj));
  const hasDangerousGoodsRow = state.shipmentDetailsRows.some(detailsRow => detailsRow.dangerousGoods.value);

  statelessObj.matchedProducts = getMatchedProducts(result.data, statelessObj.shipmentData.hideDHLPallForHalfPallet);

  // If one shipment row contains Dangerous Goods, only Euroconnect is a match
  if (hasDangerousGoodsRow) {
    statelessObj.matchedProducts = statelessObj.matchedProducts.filter(filterAllowedForProductsDangerousGoods);
  }

  if (statelessObj.matchedProducts.length === 0) {
    statelessObj.noContent = true;
  } else {
    statelessObj.matchedProducts = filterProductsBasedonPayerCode(statelessObj.matchedProducts, state.payerCode);
    statelessObj.matchedProducts = filterProductsBasedonNumberOfColli(
      state.shipmentDetailsRows,
      state.products,
      statelessObj.matchedProducts,
    );
    statelessObj.payerCodeError = statelessObj.matchedProducts.length === 0;
  }

  return statelessObj;
};

export const postMatchedProductsCall = async (state, statelessObj) => {
  const params = {
    body: statelessObj.payload,
    headers: {
      Referer: getBaseUrl(null, 1),
    },
  };
  params.body.transactionId = state.transactionId;

  return await postMatchedProducts(params);
};

export const postQuoteToolProductMatchesCall = async (state, statelessObj) => {
  const params = {
    body: statelessObj.payload,
    headers: {
      Referer: getBaseUrl(null, 1),
    },
  };
  params.body.transactionId = state.transactionId;

  return await postQuoteToolProductMatches(params);
};

export const postQuoteToolProductMatches = async params => {
  let resultMap = {};

  await apiRequest(apiLinks.postQuoteToolProductMatches, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        if (!result.data || (result.data && result.data.length === 0)) {
          result.status = CONST.STATUS_NO_CONTENT;
        }
        resultMap = result;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const addDeliveryDateToMatchedProducts = async (
  state,
  productMatchedResult,
  statelessObj,
  _isMounted,
  productMatchesWithPricesAndDates,
) => {
  if (productMatchedResult.status === CONST.STATUS_OK && _isMounted) {
    // make call to matched product api
    // TODO - remove this useless call and reuse BE productmatches call
    Object.assign(statelessObj, formMatchedProducts(state, statelessObj, productMatchedResult));

    if (!statelessObj.payerCodeError) {
      // assign deliveryDates to particullar product code from statelessObj.matchedProducts
      statelessObj.matchedProducts = statelessObj.matchedProducts.map(matchedProduct => {
        const eurapidProduct = config.ltcEurapidProductCodes.includes(matchedProduct.code.toString());
        let productDisabled = eurapidProduct;
        let productDeliveryDate = null;
        let productLTCDates = [];
        if (
          productMatchesWithPricesAndDates.deliveryDates &&
          productMatchesWithPricesAndDates.deliveryDates.deliveryDates &&
          productMatchesWithPricesAndDates.deliveryDates.deliveryDates[matchedProduct.code]
        ) {
          const deliveryDates = productMatchesWithPricesAndDates.deliveryDates.deliveryDates[matchedProduct.code];
          productDeliveryDate = deliveryDates?.deliveryDate;
          productLTCDates = deliveryDates?.fixedDeliveryDates || [];
          // product is disabled when it is Eurapid type and delivery date is unavailable
          productDisabled = eurapidProduct && deliveryDates.deliveryDate === null;
        }
        const finalProduct = {
          ...JSON.parse(JSON.stringify(matchedProduct)),
          deliveryDate: eurapidProduct ? 'ltc_error' : productDeliveryDate,
          LTCDates: productLTCDates,
          // there is not available delivery date here => eurapid products should be disabled
          disabled: productDisabled,
        };
        return finalProduct;
      });
    }
  } else if (productMatchedResult.status === CONST.STATUS_NO_CONTENT) {
    statelessObj.noContent = true;
  } else {
    statelessObj.goNextError = true;
  }
};

export const checkSubmit = (context, userAccountCountryCode) => {
  let dimensions = context.state.dimensions;
  let shipmentDetailsRows = JSON.parse(JSON.stringify(context.state.shipmentDetailsRows));
  const isRelatedToHungary =
    context.state.pickupCountry.value.toLowerCase() === HUNGARY_CODE ||
    context.state.deliveryCountry.value.toLowerCase() === HUNGARY_CODE;
  let ekaerFreeError = isRelatedToHungary && context.state.ekaerFree.value === null;
  let ekaerFree = JSON.parse(JSON.stringify(context.state.ekaerFree));
  let ekaerNumber = JSON.parse(JSON.stringify(context.state.ekaerNumber));
  let ekaerNumberError = isRelatedToHungary && !context.state.ekaerFree.value && context.state.ekaerNumber.value === '';

  const isRelatedToRomania =
    caseInsensitiveEquals(context.state.pickupCountry.value, ROMANIA_CODE) ||
    caseInsensitiveEquals(context.state.deliveryCountry.value, ROMANIA_CODE);

  const uitFree = JSON.parse(JSON.stringify(context.state.uitFree));
  uitFree.error = config.etransportEnabled && isRelatedToRomania && uitFree.value === null;

  const uitNumber = JSON.parse(JSON.stringify(context.state.uitNumber));
  const uitNumberValidation = checkFieldAndReturnState({
    name: 'uitNumber',
    value: uitNumber.value,
    regEx: config.regEx.uitNumber,
    lengthCheck: [RegExp(`^.{0,${config.shipmentInputs.uitNumberLength}}$`)],
    isRequired: uitNumber.required,
  });
  uitNumber.error = config.etransportEnabled && isRelatedToRomania && uitNumberValidation.error;

  // UIT free and number
  context.extendedSetState({ uitFree, uitNumber });

  const { hasTotalError } = shipmentDetailsTotalsValidations(context);

  const globalColliError = hasGlobalColliError(shipmentDetailsRows, userAccountCountryCode);

  shipmentDetailsRows.forEach((shipmentRow, index) => {
    if (shipmentRow.quantity && !shipmentRow.quantity.hidden && !shipmentRow.quantity.disabled) {
      shipmentRow.quantity.error = Validation(
        shipmentRow.quantity,
        dimensions.min_item_quantity,
        dimensions.max_item_quantity,
        config.regEx.float,
      );
    }

    if (shipmentRow.weight && !shipmentRow.weight.hidden && !shipmentRow.weight.disabled) {
      const weight = fixDecimalPointAndConvertToNumber(shipmentRow.weight);

      shipmentRow.weight.error = Validation(
        weight,
        dimensions.min_item_weight,
        dimensions.max_item_weight,
        config.regEx.float,
      );
    }

    if (shipmentRow.volume && !shipmentRow.volume.hidden && !shipmentRow.volume.disabled) {
      const volume = toFloatOrEmptyString(shipmentRow.volume);

      shipmentRow.volume.error = Validation(
        volume,
        dimensions.min_item_volume,
        dimensions.max_item_volume,
        config.regEx.float,
      );
    }

    if (
      (shipmentRow.loadingMeter && !shipmentRow.loadingMeter.hidden && !shipmentRow.loadingMeter.disabled) ||
      shipmentRow.loadingMeter.required
    ) {
      const { error } = validateLoadingMeter({
        loadingMeter: shipmentRow.loadingMeter.value,
        dimensions,
        required: shipmentRow.loadingMeter.required,
        nonStackable: shipmentRow.nonStackable.value,
        width: shipmentRow.width.value,
        length: shipmentRow.length.value,
        height: shipmentRow.height.value,
      });
      shipmentRow.loadingMeter.error = error;
    }

    if (shipmentRow.length && !shipmentRow.length.hidden && !shipmentRow.length.disabled) {
      shipmentRow.length.error = Validation(
        shipmentRow.length,
        dimensions.min_item_length,
        dimensions.max_item_length,
        config.regEx.float,
      );
    }

    if (shipmentRow.width && !shipmentRow.width.hidden && !shipmentRow.width.disabled) {
      shipmentRow.width.error = Validation(
        shipmentRow.width,
        dimensions.min_item_width,
        dimensions.max_item_width,
        config.regEx.float,
      );
    }

    if (shipmentRow.height && !shipmentRow.height.hidden && !shipmentRow.height.disabled) {
      shipmentRow.height.error = Validation(
        shipmentRow.height,
        dimensions.min_item_height,
        dimensions.max_item_height,
        config.regEx.float,
      );
    }

    if (shipmentRow.nonStackable && !shipmentRow.nonStackable.hidden && !shipmentRow.nonStackable.disabled) {
      shipmentRow.nonStackable.error =
        shipmentRow.nonStackable.required && !shipmentRow.nonStackable.value ? true : false;
    }

    if (shipmentRow.longShipment && !shipmentRow.longShipment.hidden && !shipmentRow.longShipment.disabled) {
      shipmentRow.longShipment.error =
        shipmentRow.longShipment.required && !shipmentRow.longShipment.value ? true : false;
    }

    if (
      shipmentRow.goodsDescription &&
      !shipmentRow.goodsDescription.hidden &&
      !shipmentRow.goodsDescription.disabled
    ) {
      let descriptionError = checkFieldAndReturnState({
        name: 'goodsDescription',
        value: shipmentRow.goodsDescription.value,
        regEx: config.regEx.everything,
        lengthCheck: [RegExp(`[\\r\\n]{0,${config.maxDescriptionLength}}$`)],
        isRequired: shipmentRow.goodsDescription.required,
      });
      shipmentRow.goodsDescription.error = descriptionError.error;
    }

    // Check validation only if International
    if (context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
      if (shipmentRow.shippingMark && !shipmentRow.shippingMark.hidden && !shipmentRow.shippingMark.disabled) {
        let shippingMarkValidation = checkFieldAndReturnState({
          name: 'shippingMark',
          value: shipmentRow.shippingMark.value,
          regEx: config.regEx.everything,
          lengthCheck: [RegExp(`^.{0,${config.maxShippingMarkLength}}$`)],
          isRequired: shipmentRow.shippingMark.required,
        });
        shipmentRow.shippingMark.error = shippingMarkValidation.error;
      }
    }

    // dangerous goods are validated within the form itself but until whole shipment details page
    // is rewritten we keep this as well to prevent some unexpected situations (if user doesnt interact with
    // the react hook form at all, the validation is never triggered because we cannot tie it to the submit button)
    if (shipmentRow.dangerousGoods && !shipmentRow.dangerousGoods.hidden && !shipmentRow.dangerousGoods.disabled) {
      let hasDangerousGoodsError = false;
      if (shipmentRow.dangerousGoods.required && !shipmentRow.dangerousGoods.value) {
        hasDangerousGoodsError = true;
      }
      if (
        shipmentRow.dangerousGoods.value &&
        shipmentRow.dangerousGoodGroup.some(dgItem => {
          return !dgItem.dgmId?.value;
        })
      ) {
        // If Dangerous Goods checkbox is checked but no DG item is selected from the lookup,
        // we need to mark this as en error.
        hasDangerousGoodsError = true;
      }
      shipmentRow.dangerousGoods.error = hasDangerousGoodsError;
      shipmentRow.dangerousGoodGroup = shipmentRow.dangerousGoodGroup.map(dgItem => {
        return {
          ...dgItem,
          netQuantity: { ...dgItem.netQuantity, value: dgItem.netQuantity.value?.replace(',', '.') },
          grossWeight: { ...dgItem.grossWeight, value: dgItem.grossWeight?.value?.replace(',', '.') },
          flamePoint: { ...dgItem.flamePoint, value: dgItem.flamePoint?.value?.replace(',', '.') },
        };
      });
    }
  });

  context.extendedSetState({ shipmentDetailsRows });

  // Ekaer free
  ekaerFree.error = context.state.ekaerFree.value === null;
  context.extendedSetState({ ekaerFree });

  // Ekaer Number
  if (context.state.ekaerFree && !context.state.ekaerFree.value) {
    ekaerNumber.error = ekaerNumberError;
    context.extendedSetState({ ekaerNumber });
  }
  return {
    hasError:
      context.state.dgWeightError ||
      checkError(shipmentDetailsRows) ||
      hasTotalError ||
      globalColliError ||
      ekaerFreeError ||
      ekaerNumberError ||
      uitFree.error ||
      uitNumber.error,
    isGlobalColliError: globalColliError,
  };
};

const checkError = shipmentDetailsRows => {
  let error = false;

  shipmentDetailsRows.forEach(shipment => {
    const keys = Object.keys(shipment);
    keys.forEach(key => {
      if (shipment[key].error) {
        error = true;
      }
    });

    if (shipment.dangerousGoods.value) {
      shipment.dangerousGoodGroup.forEach(dangerousGoodGroupItem => {
        let dangerousGoodsKeys = Object.keys(dangerousGoodGroupItem);
        dangerousGoodsKeys.forEach(dangerousGoodsKey => {
          if (dangerousGoodGroupItem[dangerousGoodsKey].error) {
            error = true;
          }
        });
      });
    }
  });

  return error;
};

export const prepareUserData = (data, state) => {
  let pieces = [];
  let numberOfFullPallets = 0;
  let numberOfHalfPallets = 0;
  let marketAvailability = '';
  let isHalfPalletStyke = false;
  let isHalfPalletAlone = true;

  data.shipmentDetailsRows.forEach(row => {
    const obj = {
      numberOfPieces: row.quantity.value || null,
      weight: returnProperValueOrNull(row.weight.value),
      length: row.length.value || null,
      width: row.width.value || null,
      height: row.height.value || null,
      volume: returnProperValueOrNull(row.volume.value),
      loadingMeters: returnProperValueOrNull(row.loadingMeter.value),
      loadingMetres: returnProperValueOrNull(row.loadingMeter.value),
      stackable: !row.nonStackable.value,
      packageType: config.packageType[row.shipmentType.value] || null,
    };

    switch (row.shipmentType.value) {
      case 'full pallet':
        numberOfFullPallets += row.quantity.value * 1;
        isHalfPalletAlone = false;
        break;
      case 'half pallet':
        numberOfHalfPallets += row.quantity.value * 1;
        if (row.height.value && isHalfPalletAlone && !isHalfPalletStyke) {
          isHalfPalletStyke = parseInt(row.height.value) > 150;
        }
        break;
      default:
        isHalfPalletAlone = false;
    }

    pieces.push(obj);
  });

  marketAvailability =
    (data.pickupAddressResidential.value ? 'C' : 'B') + '2' + (data.deliveryAddressResidential.value ? 'C' : 'B');

  const requestData = {
    pieces: pieces,
    totalNumberOfPieces: data.totals.shipmentDetailsRows.quantity.value || null,
    totalWeight: data.totals.shipmentDetailsRows.weight.value || '0.0',
    totalVolume: data.totals.shipmentDetailsRows.volume.value || '0.000',
    totalLoadingMetres: data.totals.shipmentDetailsRows.loadingMeter.value || '0.00',
    numberOfFullPallets: numberOfFullPallets || null,
    numberOfHalfPallets: numberOfHalfPallets || null,
    marketAvailability: marketAvailability,
    payerCode: state.payerCode,
  };

  const parties = [
    {
      type: CONSIGNOR_CUSTOMER_TYPE,
      address: {
        postalCode: data.pickupPostalCode.value || null,
        countryCode: data.pickupCountry.value || null,
      },
    },
    {
      type: CONSIGNEE_CUSTOMER_TYPE,
      address: {
        postalCode: data.deliveryPostalCode.value || null,
        countryCode: data.deliveryCountry.value || null,
      },
    },
  ];

  requestData.parties = addAccountNumberToParties(state, parties);

  return { shipmentDetails: requestData, hideDHLPallForHalfPallet: isHalfPalletAlone && isHalfPalletStyke };
};

export const filterProductsBasedonNumberOfColli = (shipmentDetailsRows, availableProducts, products) => {
  return (
    products &&
    products.filter(prod =>
      availableProducts.find(
        avProd => avProd.code === prod.code && shipmentDetailsRows.length <= avProd.shipmentRowsNumberMax,
      ),
    )
  );
};

export const formShipmentDetailsData = ctxState => {
  const data = {};
  const state = getPropperAPIData(ctxState);

  data.shipmentDetailsRows = ctxState.shipmentDetailsRows;
  data.totals = ctxState.totals;
  data.pickupCountry = state.pickupCountry;
  data.pickupPostalCode = state.pickupPostalCode;
  data.pickupAddressResidential = state.pickupAddressResidential;
  data.deliveryCountry = state.deliveryCountry;
  data.deliveryPostalCode = state.deliveryPostalCode;
  data.deliveryAddressResidential = state.deliveryAddressResidential;

  return JSON.parse(JSON.stringify(data));
};

export const removeShipmentDetailsRow = (index, context) => {
  const params = {};
  if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length > 1) {
    params.groupName = 'shipmentDetailsRows';
    params.index = index;
    params.afterUpdate = calculateInputs;
    context.removeGroupField(params);
  }
};

export const updateDimensions = props => {
  const stateObject = {};
  const context = props.context;

  stateObject.dimensions = JSON.parse(JSON.stringify(config.dimensions)); //structure def

  const prefilteredProducts = prefilterShipmentProducts({
    productList: context.state.products,
    shipmentData: {
      deliveryResidential: context.state.deliverToDifferentAddress.value
        ? context.state.differentDeliveryAddressResidential.value
        : context.state.deliveryAddressResidential.value,
      pickupResidential: context.state.pickupFromDifferentAddress.value
        ? context.state.differentPickupAddressResidential.value
        : context.state.pickupAddressResidential.value,
      shipmentRange: context.state.shipmentRange.value,
    },
  });

  if (context.state.productRange === PRODUCTS_RANGE.ACCOUNT) {
    const accountNumber = context.state?.receiverNumber?.isSelect
      ? context.state?.receiverNumber?.value
      : context.state?.accountNumber?.value;
    const accountProducts =
      context.state?.accounts
        ?.find(account => account.code === accountNumber)
        ?.products?.map(product => product.productCode) ?? [];
    [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProductShowAll(
      context.state,
      config,
      prefilteredProducts?.filter(product => accountProducts.includes(product.code)),
    );
  } else if (context.state.productRange === PRODUCTS_RANGE.COUNTRY) {
    [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProductShowAll(
      context.state,
      config,
      prefilteredProducts,
    );
  } else {
    [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProduct(
      context.state,
      config,
      context.state.productRange,
    );
  }

  return stateObject;
};

export const addShipmentDetailsRow = (context, favouriteColliTypes) => {
  const params = {};
  if (
    context.state.shipmentDetailsRows &&
    context.state.shipmentDetailsRows.length < config.maxAllowedShipmentDetailsRows
  ) {
    params.groupName = 'shipmentDetailsRows';
    params.item = resetShipmentType(
      context.state.selectedProduct,
      context.state?.user?.accountCountryCode,
      favouriteColliTypes,
    );
    params.afterUpdate = calculateInputs;

    context.addAnotherGroupField(params);
  }
};

export const filterProductsBasedonPayerCode = (products, payerCode) => {
  return products && products.filter(prod => prod.payerCodes.find(code => code === payerCode));
};

export const checkIfProductsNotAvailable = matchedProducts => {
  let notAvailable = false;

  matchedProducts &&
    matchedProducts.forEach(product => {
      if (config.serviceDescriptions[product.code] === undefined && !notAvailable) notAvailable = true;
    });

  return notAvailable;
};

const filterPayerCode = payerCodes => {
  let payerCodeArr = [];
  payerCodes.forEach(PC => {
    payerCodeArr.push(PC.code);
  });

  return payerCodeArr;
};

export const getErrorMessage = (t, state) => {
  let message = '';
  const caseNoProductError = state.noContent ? 'noProductError' : false;
  const caseNextError = state.goNextError ? 'goNextError' : false;
  const caseGeneralError = state.generalError ? 'generalError' : false;
  const casePayerCodeError = state.payerCodeError ? 'payerCodeError' : false;
  const caseSaveForFutureError = state.saveShipmentsError || state.saveForFutureError ? 'saveForFutureError' : false;
  const globalColliError = state.globalColliError ? 'globalColliError' : false;

  const error =
    globalColliError ||
    caseNoProductError ||
    caseNextError ||
    caseGeneralError ||
    casePayerCodeError ||
    caseSaveForFutureError;

  switch (error) {
    case 'globalColliError':
      message = t('general|errors|globalColliError');
      break;
    case 'noProductError':
      message =
        t('general|errors|No product matches were found') +
        ' <br> ' +
        t('general|errors|Please change criteria') +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'goNextError':
      message =
        t('general|errors|General error') +
        '. <br> ' +
        t('general|Try again or call technical support.') +
        ' ' +
        t('general|or') +
        ' ' +
        <ChatWithUs /> +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'generalError':
      message = t('general|errors|Please fill properly all necessary fields');
      break;
    case 'payerCodeError':
      message =
        t('general|errors|No product matches were found for selected payer code') +
        ' <br> ' +
        t('general|errors|Please change criteria') +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId;
      break;
    case 'saveForFutureError':
      message = t(`portalOrder|Shipment could not be saved`);
      break;

    default:
      return;
  }

  return <Error name="multicaseErrorMessage" message={message} />;
};

export const getMatchedProducts = (products, hideDHLPallForHalfPallet) => {
  const matchedProducts = [];
  const isSwedenCountry = localStorage.getItem('country')?.toUpperCase() === 'SE';

  if (products && products.length > 0) {
    products.forEach(prod => {
      let product;
      product = config.allowedProducts.find(p => p.code === prod.product.code);

      if (product && product.code) {
        if (!hideDHLPallForHalfPallet || (hideDHLPallForHalfPallet && product.code !== '210')) {
          matchedProducts.push({
            code: product.code,
            value: false,
            name: product.mapName,
            payerCodes: filterPayerCode(prod.product.payerCodes),
            isDomestic: prod.product.isDomestic,
            product: product.product,
            product_code: (prod.product.isDomestic && isSwedenCountry ? product.product : product.alias) || null,
          });
        }
      }
    });
  }
  return matchedProducts;
};

export const postMatchedProducts = async params => {
  let resultMap = {};

  await apiRequest(apiLinks.postPublicOrderProductMatches, 'POST', params)
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        if (!result.data || (result.data && result.data.length === 0)) {
          result.status = CONST.STATUS_NO_CONTENT;
        }
        resultMap = result;
      } else {
        resultMap.error = true;
      }
    })
    .catch(() => {
      resultMap.error = true;
    });

  return resultMap;
};

export const filterProductsByEnabledForPayingAccount = (productsFromApi, state) => {
  // const payingAccountNumber = getPayingAccountNumber(state);
  const payingAccountNumber = formAccountNumber(state);
  const payingAccount = state.accounts.find(acct => String(acct.code) === payingAccountNumber);

  if (payingAccount) {
    productsFromApi.data = productsFromApi.data.filter(el =>
      payingAccount.products.some(prodInAcct => prodInAcct.productCode === el.product.code),
    );

    productsFromApi.payingAccountNumber = payingAccountNumber;
  }

  return productsFromApi;
};

export const getPayingAccountNumber = state => {
  let payingAccount;

  if (state.shipmentPayer.value === SENDER_PAYER) payingAccount = state.accountNumber.value;
  if (state.shipmentPayer.value === RECEIVER_PAYER) payingAccount = state.receiverNumber.value;
  if (state.shipmentPayer.value === THIRD_PARTY_PAYER) payingAccount = state.thirdPartyNumber.value;

  return payingAccount;
};

export const addPricesToMatchedProducts = (prices, context) => {
  context.updateState(prices);
};

export const patchPALLPriceToFreightCost = price => {
  let pallTotalPrice = 0;

  if (price.PALL) {
    pallTotalPrice += parseFloat(price.PALL.value.replace(',', '.'));
    delete price.PALL;
  }

  if (price.HALV) {
    pallTotalPrice += parseFloat(price.HALV.value.replace(',', '.'));
    delete price.HALV;
  }

  return pallTotalPrice.toFixed(config.price.maxDecimals).toString().replace('.', ',');
};
