import React, { useEffect } from 'react';
import { globalConfig } from '../data-config-global';
import { useTranslation } from 'react-i18next';

import { Error, Input, Radio } from '../../components';

/**
 * Romania specific.
 **/
export const UitInformation = props => {
  const { context, componentConfig, componentConstants } = props;
  const { t } = useTranslation();

  const isWeightOverLimit =
    parseFloat(context.state.totals.shipmentDetailsRows.weight.value) > globalConfig.shipmentInputs.uitFreeWeightLimit;

  useEffect(() => {
    // total weight is over limit so user must fill in the uit number
    if (isWeightOverLimit && !context.state.uitFree.disabled) {
      const uitFree = { value: false, error: false, disabled: true };
      const uitNumber = { value: context.state.uitNumber.value, error: false, required: true, disabled: false };
      context.updateState({
        uitFree,
        uitNumber,
      });
    }

    // total weight dropped under the limit so we reset the uit fields
    if (!isWeightOverLimit && context.state.uitFree.disabled) {
      const uitFree = { value: null, error: false, disabled: false };
      const uitNumber = { value: '', error: false, required: false, disabled: true };
      context.updateState({
        uitFree,
        uitNumber,
      });
    }
  }, [context, context.state.uitFree.value, isWeightOverLimit]);

  return (
    <div className="frc__generic--field-wrapper">
      <h4 className="margin-bottom-1 frc__red-lined-title ">
        {t('general|uitInformation')}
        <span className="has-tooltip-label">
          <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
            <div className="frc__tooltip-container-flyout">{t(`general|uitInformationTooltip`)}</div>
          </div>
        </span>
      </h4>
      <div className="l-grid l-grid--w-100pc-s">
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <div>
            {t('general|labels|inputs|uitFree')}*
            <div className="l-grid l-grid--w-100pc-s">
              <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
                <Radio
                  id="uitFree-true"
                  name="uitFree"
                  isBooleanValueRequired
                  config={componentConfig}
                  CONST={componentConstants}
                  context={context}
                  label={t(`general|Yes`)}
                  isRequired={false}
                  value={true}
                  checked={props.context.state.uitFree.value !== null && props.context.state.uitFree.value}
                  disabled={props.context.state.uitFree.disabled}
                  afterUpdate={context => {
                    if (context.state.uitFree.value) {
                      const uitNumber = { value: '', error: false, required: false, disabled: true };
                      context.updateState({
                        uitNumber,
                      });
                    }
                  }}
                />
              </div>
              <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
                <Radio
                  id="uitFree-false"
                  name="uitFree"
                  isBooleanValueRequired
                  config={componentConfig}
                  CONST={componentConstants}
                  context={context}
                  label={t(`general|No`)}
                  isRequired={false}
                  value={false}
                  checked={props.context.state.uitFree.value !== null && !props.context.state.uitFree.value}
                  disabled={props.context.state.uitFree.disabled}
                  afterUpdate={context => {
                    const uitNumber = { value: '', error: false, required: true, disabled: false };
                    context.updateState({
                      uitNumber,
                    });
                  }}
                />
              </div>
              {props.context.state.uitFree.error && (
                <Error
                  name={'uitFree' + props.index}
                  className="frc__input--error"
                  message={t(`quoteTool|Select one of the options above`)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <Input
            id={'uitNumber' + props.index}
            className=" frc__generic--field-wrapper--no-top "
            label={t('general|labels|inputs|uitNumber')}
            index={props.index}
            name="uitNumber"
            isRequired={props.context.state.uitNumber.required}
            lengthCheck={[RegExp(`^.{${1},${globalConfig.shipmentInputs.uitNumberLength}}$`)]}
            cutTextLimit={globalConfig.shipmentInputs.uitNumberLength}
            value={props.context.state.uitNumber.value}
            disabled={props.context.state.uitNumber.disabled}
            context={props.context}
            config={componentConfig}
            CONST={componentConstants}
            regEx={/^[a-zA-Z0-9-]*$/}
          />
          {props.context.state.uitNumber.error && (
            <Error
              name={'uitNumber' + props.index}
              className="frc__input--error"
              message={t('general|errors|uitNumberError')}
            />
          )}
        </div>
      </div>
    </div>
  );
};
