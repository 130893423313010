import { Box, Typography } from '@mui/material';

export const TextWithBoldPart = ({ normalText, boldText, variant = 'body2', id = '' }) => {
  return (
    <Typography variant={variant}>
      {normalText}
      <Box component="span" fontWeight="bold" data-testid={id || undefined}>
        {boldText}
      </Box>
    </Typography>
  );
};
