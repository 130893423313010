import { createSelector } from '@reduxjs/toolkit';
import { AUTHORIZATION } from '../../Login/constants';
import { apiLinks } from '../../config/api-config';
import { rootApi, TagTypes } from './apiSlice';
import {
  AccountWithProductsAndSpecs,
  GetUserAccountsAndProductsResponse,
  GetUserDataResponse,
  UserTmsAccountDataUpdate,
  TmsAccountResponse,
} from './fcpUser.types';
import { caseInsensitiveEquals } from 'globals/utils/strings';

const transformAccountsAndProducts = (response: GetUserAccountsAndProductsResponse) => {
  const productSpecifications = response.productSpecifications.map(product => {
    const productCode = Object.keys(product)[0];
    const productSpecification = productCode ? product[productCode] : undefined;
    return {
      ...productSpecification,
    };
  });
  const accountsWithProductsAndSpecs = response.accounts.map(account => {
    const products = account.products.map(product => {
      const productSpecification = productSpecifications.find(productSpec => productSpec.code === product.productCode);
      return {
        ...product,
        productSpecification,
      };
    });
    return {
      ...account,
      products,
      atLeastOneProductB2C: products.some(product =>
        // todo: should be removed after we have correct API types
        product.productSpecification?.marketAvailability?.some((market: any) => market?.name?.toUpperCase() === 'B2C'),
      ),
    };
  });
  return {
    ...response,
    accountsWithProductsAndSpecs,
  };
};

export const userApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getUserAccountsAndProducts: builder.query<
      GetUserAccountsAndProductsResponse & { accountsWithProductsAndSpecs: AccountWithProductsAndSpecs[] },
      void
    >({
      providesTags: [TagTypes.USER_API_TAG],
      query: () => ({
        url: apiLinks.getUsersAccountsAndProductsData,
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      transformResponse: transformAccountsAndProducts,
      keepUnusedDataFor: 60 * 30,
    }),
    getUserData: builder.query<GetUserDataResponse, void>({
      providesTags: [TagTypes.USER_API_TAG],
      query: () => ({
        url: apiLinks.getUserData,
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    removeTmsAccount: builder.mutation<TmsAccountResponse, UserTmsAccountDataUpdate>({
      query: ({ tmsAccounts }) => ({
        url: apiLinks.deleteTMSAccount,
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: { tmsAccounts },
      }),
      invalidatesTags: [TagTypes.USER_API_TAG],
      // we use custom error message in component
      transformErrorResponse: error => ({ ...error, skipToast: true }),
    }),
    addTmsAccount: builder.mutation<TmsAccountResponse, UserTmsAccountDataUpdate>({
      query: ({ tmsAccounts }) => ({
        url: apiLinks.deleteTMSAccount,
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: { tmsAccounts },
      }),
      invalidatesTags: [TagTypes.USER_API_TAG],
      // we use custom error message in component
      transformErrorResponse: error => ({ ...error, skipToast: true }),
    }),
    updateLanguage: builder.mutation<void, { language: string }>({
      query: ({ language }) => ({
        url: apiLinks.updateUserLanguage,
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
        body: { language },
      }),
      invalidatesTags: [TagTypes.USER_API_TAG],
    }),
  }),
});

export const {
  useGetUserAccountsAndProductsQuery,
  useLazyGetUserAccountsAndProductsQuery,
  useGetUserDataQuery,
  useAddTmsAccountMutation,
  useRemoveTmsAccountMutation,
  useUpdateLanguageMutation,
} = userApi;

// ------------------ SELECTORS -------------------------------
const selectUserResult = userApi.endpoints.getUserData.select();
// const selectUserAccountsAndProductsResult = userApi.endpoints.getUserAccountsAndProducts.select();

//  USER SELECTORS
export const selectUserAccountCountryCode = createSelector(selectUserResult, result =>
  result.data?.user.accountCountryCode.toUpperCase(),
);

export const selectIsSwedishUser = createSelector(selectUserAccountCountryCode, accountCountryCode =>
  caseInsensitiveEquals(accountCountryCode, 'SE'),
);

// TMS ACCOUNT SELECTORS
export const selectTmsAccounts = createSelector(selectUserResult, result => result.data?.tmsAccounts ?? []);
export const selectApprovedTmsAccounts = createSelector(selectTmsAccounts, tmsAccounts =>
  tmsAccounts.filter(account => account.status === 'APPROVED'),
);
