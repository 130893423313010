import { strEnum } from 'utils/objects';

export const PRODUCT_CODES = strEnum([
  '102',
  '103',
  '104',
  '107',
  '109',
  '112',
  '118',
  '202',
  '205',
  '210',
  '211',
  '212', // parti
  '232',
  '233',
  '401',
  '402',
  '501',
  '502',
  '601',
  'ECD',
  'ECI',
  'ELD',
  'ELI',
  'ERI',
  'HDI',
  'HDL',
  'PPD',
  'PPI',
  'SPD',
  'SPI',
]);
export type ProductCode = keyof typeof PRODUCT_CODES;

export const EVO_PRODUCT_CODES = strEnum([
  PRODUCT_CODES['202'],
  PRODUCT_CODES['205'],
  PRODUCT_CODES['233'],
  PRODUCT_CODES['601'],
  PRODUCT_CODES.ECD,
  PRODUCT_CODES.ECI,
  PRODUCT_CODES.ELD,
  PRODUCT_CODES.ELI,
  PRODUCT_CODES.ERI,
  PRODUCT_CODES.HDI,
  PRODUCT_CODES.HDL,
  PRODUCT_CODES.PPD,
  PRODUCT_CODES.PPI,
  PRODUCT_CODES.SPD,
  PRODUCT_CODES.SPI,
]);
export type EvoProductCode = keyof typeof EVO_PRODUCT_CODES;

export type DaxProductCode = Exclude<ProductCode, EvoProductCode>;
export const DAX_PRODUCT_CODES = Object.fromEntries(
  Object.keys(PRODUCT_CODES)
    .filter(key => !Object.keys(EVO_PRODUCT_CODES).includes(key))
    .map(key => [key, key]),
) as { [K in DaxProductCode]: K };
