import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography,
  FormControl,
  Tooltip,
  Box,
  BoxProps,
  InputAdornment,
  FormHelperText,
  FormControlLabelProps,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useEffect } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import type { Control, Path, RegisterOptions } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Loader } from '../Loader';
import { useTranslation } from 'react-i18next';

export type SelectOption = {
  value: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
};

interface BaseControlledFieldProps<TField extends FieldValues> {
  control: Control<TField>;
  fieldName: Path<TField>;
  rules?:
    | Omit<RegisterOptions<TField, Path<TField>>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    | undefined;
  autoDeselect?: boolean;
  label?: string;
  testId: string;
}

interface ControlledSelectProps<TField extends FieldValues> extends BaseControlledFieldProps<TField> {
  TextFieldProps?: TextFieldProps;
  onChangeCb?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  options: SelectOption[];
  disabled?: boolean;
  placeholder?: string;
  BoxProps?: BoxProps;
}

export const ControlledSelect = <TField extends FieldValues>({
  control,
  label,
  fieldName,
  rules,
  TextFieldProps,
  onChangeCb,
  required,
  options,
  disabled,
  placeholder,
  BoxProps,
  testId,
  autoDeselect = true,
}: ControlledSelectProps<TField>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  useEffect(() => {
    if (!autoDeselect) return;

    const option = options.find(option => option.value === value);
    if (!option || option.disabled) {
      onChange('');
    }
  }, [options, value, onChange, autoDeselect]);

  return (
    <Box display="flex" flexDirection="column" {...BoxProps}>
      <InputLabel required={required} sx={{ fontSize: '15px' }} error={invalid} htmlFor={testId}>
        {label}
      </InputLabel>
      <TextField
        value={value}
        onChange={e => {
          onChange(e);
          onChangeCb?.(e);
        }}
        onBlur={onBlur}
        helperText={invalid && (error?.message || 'Invalid value')}
        select
        disabled={disabled}
        id={testId}
        error={invalid}
        {...TextFieldProps}
        inputProps={{
          ...TextFieldProps?.inputProps,
        }}
        SelectProps={{
          IconComponent: props => (
            <ExpandMoreIcon
              style={{ width: '30px', height: '20px', transform: 'scale(1.6, 1)', top: 'calc(50% - .6em)' }}
              {...props}
            />
          ),
          error: invalid,
          sx: {
            '& .MuiSvgIcon-root': {
              color: disabled ? 'grey' : 'text.secondary',
            },
          },
          displayEmpty: true,
          renderValue: (value: any) => {
            if (!value) {
              return <Typography color="gray">{placeholder}</Typography>;
            }
            return options.find(option => option.value === value)?.label ?? value;
          },
        }}
      >
        {options.map(({ value, label, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

interface ControlledTextFieldProps<TField extends FieldValues> extends BaseControlledFieldProps<TField> {
  TextFieldProps?: Omit<TextFieldProps, 'select'>;
  onChangeCb?: (value: string) => void;
  inputRegex?: RegExp;
  required?: boolean;
  hideErrors?: boolean;
  disabled?: boolean;
  loading?: boolean;
  transformValue?: (value: string) => string;
  BoxProps?: BoxProps;
}

export const ControlledTextField = <TField extends FieldValues>({
  control,
  label,
  fieldName,
  rules,
  TextFieldProps,
  onChangeCb,
  inputRegex,
  required,
  hideErrors = false,
  disabled,
  loading,
  testId,
  transformValue = value => value,
  BoxProps,
}: ControlledTextFieldProps<TField>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error, isTouched },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  const { t } = useTranslation();

  const getErrorMessage = (): string => {
    if (!error) return '';

    if (typeof error.message === 'string') {
      return error.message;
    }

    if (typeof error.message === 'object') {
      // @ts-expect-error
      return t(error.message.tKey, {
        // @ts-expect-error
        ...error.message.params,
        // @ts-expect-error
        ...(error.message.params?.name && { name: t(error.message.params.name) }),
      });
    }

    return 'Invalid value';
  };

  const errorMessage = getErrorMessage();

  return (
    <Box data-testid={testId} display="flex" flexDirection="column" {...BoxProps}>
      <InputLabel
        required={required}
        sx={{ fontSize: '15px' }}
        error={invalid && (!hideErrors || isTouched)}
        disabled={disabled}
      >
        {label}
      </InputLabel>
      <TextField
        value={value}
        disabled={disabled}
        onChange={e => {
          const value = e.target.value;
          if (inputRegex?.test(value) || !inputRegex) {
            const transformedValue = transformValue(value);
            onChange(transformedValue);
            onChangeCb?.(transformedValue);
          }
        }}
        onBlur={onBlur}
        helperText={invalid && (!hideErrors || isTouched) && errorMessage}
        {...TextFieldProps}
        inputProps={{
          ...TextFieldProps?.inputProps,
        }}
        InputProps={{
          startAdornment: loading && (
            <InputAdornment position="start" sx={{ position: 'absolute' }}>
              <Loader sx={{}} />
            </InputAdornment>
          ),
        }}
        error={errorMessage ? true : false}
      />
    </Box>
  );
};

interface ControlledCheckboxProps<TField extends FieldValues> extends BaseControlledFieldProps<TField> {
  CheckboxProps?: Omit<CheckboxProps, 'disabled' | 'checked'>;
  disabled?: boolean;
  tooltip?: string;
  FormControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  onChangeCb?: (value: boolean) => void;
}

export const ControlledCheckbox = <TField extends FieldValues>({
  control,
  fieldName,
  label,
  disabled = false,
  CheckboxProps,
  tooltip,
  testId,
  autoDeselect = true,
  rules,
  FormControlLabelProps,
  onChangeCb,
}: ControlledCheckboxProps<TField>) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  useEffect(() => {
    if (!autoDeselect) return;
    if (disabled && value) {
      onChange(false);
    }
  }, [value, onChange, autoDeselect, disabled]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!!value}
          onChange={e => {
            onChange(e.target.checked);
            onChangeCb?.(e.target.checked);
          }}
          onBlur={onBlur}
          disabled={disabled}
          data-testid={testId}
          {...CheckboxProps}
        />
      }
      label={label && <LabelWithTooltip label={label} tooltip={tooltip} disabled={disabled} />}
      {...FormControlLabelProps}
    />
  );
};

interface ControlledRadioGroupProps<TField extends FieldValues>
  extends Omit<BaseControlledFieldProps<TField>, 'testId'> {
  options: SelectOption[];
  onChangeCb?: (value: string) => void;
}

export const ControlledRadioGroup = <TField extends FieldValues>({
  control,
  fieldName,
  options,
  onChangeCb,
  rules,
  autoDeselect = true,
}: ControlledRadioGroupProps<TField>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name: fieldName,
    control,
    rules,
  });

  useEffect(() => {
    if (!autoDeselect) return;

    const option = options.find(option => option.value === value);
    if (!option || option.disabled) {
      onChange('');
    }
  }, [options, value, onChange, autoDeselect]);

  return (
    <FormControl error={invalid} sx={{ maxWidth: 'fit-content' }}>
      <RadioGroup
        value={value}
        onChange={(e, value) => {
          onChange(value);
          onChangeCb?.(value);
        }}
        onBlur={onBlur}
      >
        {options.map(({ value, label, disabled, tooltip }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio disabled={disabled} data-testid={value} />}
            label={<LabelWithTooltip label={label} tooltip={tooltip} disabled={disabled} />}
          />
        ))}
      </RadioGroup>
      {invalid && <FormHelperText>{error?.message ?? 'Error'}</FormHelperText>}
    </FormControl>
  );
};

const LabelWithTooltip = ({ label, tooltip, disabled }: { label: string; tooltip?: string; disabled?: boolean }) => (
  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
    <Typography variant="body2" fontSize="15px" color={disabled ? 'text.disabled' : 'inherit'}>
      {label}
    </Typography>
    {tooltip && (
      <Tooltip title={tooltip} placement="top">
        <Info color="primary" />
      </Tooltip>
    )}
  </Box>
);
