import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCTS_RANGE } from '../../../../globals/constants';
import { config } from '../../../data-config';
import { MenuItem, InputLabel, Stack, TextField, Skeleton, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountsWithProducts } from '../../../../store/portalOrderSlice';
import { useGetUserAccountsAndProductsQuery } from '../../../../store/api/fcpUser';
import { useGetProductsQuery } from '../../../../store/api/fcpProduct';
import { toast } from 'react-toastify';

export const PRODUCT_RANGE_DEFAULT_VALUE = PRODUCTS_RANGE.ACCOUNT;

export const ProductRange = ({
  initialValue,
  disabled,
  updateContext,
  shipmentFlags,
  accountInputFlags,
  products,
  isResidentialTo,
  isResidentialFrom,
  userAccountCountryCode,
}) => {
  const { t } = useTranslation();

  const { isInit, options } = useProductRangeOptions({
    shipmentFlags,
    accountInputFlags,
    products,
    isResidentialTo,
    isResidentialFrom,
    userAccountCountryCode,
  });

  const [value, setValue] = useState(initialValue ?? PRODUCT_RANGE_DEFAULT_VALUE);

  useEffect(() => {
    if (isInit) return;
    updateContext({ productRange: value });

    if (value !== PRODUCTS_RANGE.COUNTRY && value !== PRODUCTS_RANGE.ACCOUNT) {
      updateContext({ selectedProduct: { code: value, product: t(`products|${value}|name`) } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isInit]);

  // validate current value if options change
  useEffect(() => {
    if (isInit) return;
    if (options.some(option => option.value === value && !option.disabled)) return;
    setValue(PRODUCT_RANGE_DEFAULT_VALUE);
    if (value && value !== PRODUCTS_RANGE.COUNTRY && value !== PRODUCTS_RANGE.ACCOUNT) {
      toast(
        <Alert severity="error" variant="standard">
          {t('general|errors|serviceNotAvailable', {
            service: t(`products|${value}|name`),
          })}
        </Alert>,
      );
      updateContext({ selectedProduct: {}, additionalServices: [] });
    }
  }, [options, value, initialValue, isInit, t, updateContext]);

  const className =
    Number(accountInputFlags.thirdPartyShown) +
      Number(accountInputFlags.receiverShown) +
      Number(accountInputFlags.senderShown) ===
    1
      ? 'l-grid--w-100pc-s l-grid--w-48pc-m-m'
      : '';

  if (isInit) {
    return <Skeleton variant="rectangular" height={48} />;
  }

  return (
    <Stack pt="14px" px="4px" className={className} flexGrow={1}>
      <InputLabel
        disabled={disabled || options.every(option => option.disabled)}
        sx={{ fontSize: '15px', color: '#191919' }}
      >
        {t('general|labels|inputs|productRange')}
      </InputLabel>
      <TextField
        value={value}
        disabled={disabled || options.every(option => option.disabled)}
        size="medium"
        select
        onChange={e => setValue(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
        }}
        SelectProps={{
          IconComponent: props => (
            <ExpandMoreIcon style={{ width: '30px', height: '20px', transform: 'scale(1.6, 1)' }} {...props} />
          ),
          style: {
            height: 48,
          },
          sx: {
            '& .MuiSvgIcon-root': {
              color: disabled ? 'grey' : 'text.secondary',
            },
          },
        }}
        data-testid="product-range-input"
      >
        {options?.map(option => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled} data-testid={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

const EMPTY_ARR = [];

const useProductRangeOptions = ({
  shipmentFlags,
  accountInputFlags,
  isResidentialTo,
  isResidentialFrom,
  userAccountCountryCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInit, setIsInit] = useState(true);
  const accountNumber = useSelector(state => state.portalOrder.accountNumber);
  const { accountsWithProducts } = useSelector(state => state.portalOrder);
  const { isDomestic, isSenderPayer, isInternational, isReceiverPayer, isImport, isThirdPartyPayer, isExport } =
    shipmentFlags;
  const { senderSelect, receiverSelect } = accountInputFlags;

  const optionAllAccountDefault = useMemo(() => {
    return {
      value: PRODUCTS_RANGE.ACCOUNT,
      label: t('general|labels|productRangeAccountProducts'),
    };
  }, [t]);
  const { data: { accountsWithProductsAndSpecs = EMPTY_ARR } = {} } = useGetUserAccountsAndProductsQuery() || {};

  const { data: products = EMPTY_ARR } =
    useGetProductsQuery({
      countryCode: userAccountCountryCode?.toLowerCase(),
    }) || {};

  useEffect(() => {
    const accountsWithProducts = accountsWithProductsAndSpecs.map(account => {
      const accountProducts = account.products?.map(product => product.productCode) ?? [];

      const countryProductOptions = products
        .filter(product => !config.productsFilteredAtProductSelection.includes(product.code))
        .map(product => {
          return {
            value: product.code,
            label: t(`products|${product.code}|name`),
            disabled: evaluateDisabled({
              product,
              isDomestic,
              isResidentialTo,
              isResidentialFrom,
              isReceiverPayer,
              isSenderPayer,
            }),
            isDomestic: product.isDomestic,
          };
        });

      const optionAllCountry = {
        value: PRODUCTS_RANGE.COUNTRY,
        label: t('general|labels|productRangeAllProducts'),
        disabled: countryProductOptions.every(product => product.disabled),
      };

      const accountProductOptions = countryProductOptions.filter(option => accountProducts?.includes(option.value));

      const optionAllAccount = {
        ...optionAllAccountDefault,
        disabled: accountProductOptions.every(product => product.disabled),
      };

      // TODO: consolidate switch cases once the requirements are stable
      let options = [];
      switch (true) {
        case isDomestic && isSenderPayer:
        case isDomestic && !isSenderPayer && receiverSelect:
        case isInternational && isReceiverPayer && isImport:
        case isInternational && isExport && isSenderPayer:
        case isInternational && isExport && isReceiverPayer:
          options.push(optionAllAccount);
          options = options.concat(accountProductOptions);
          break;
        case isDomestic && !isSenderPayer && senderSelect:
        case isInternational && isThirdPartyPayer && isImport:
        case isInternational && isExport && isThirdPartyPayer:
          options.push(optionAllAccount);
          options.push(optionAllCountry);
          options = options.concat(countryProductOptions);
          break;
        default:
          options.push(optionAllAccount);
          console.alert('Undefined state of ProductRange');
          break;
      }

      return {
        ...account,
        productRangeOptions: options,
      };
    });

    dispatch(setAccountsWithProducts(accountsWithProducts));
    setIsInit(false);
  }, [
    accountsWithProductsAndSpecs,
    products,
    dispatch,
    t,
    receiverSelect,
    senderSelect,
    isDomestic,
    isSenderPayer,
    isInternational,
    isReceiverPayer,
    isImport,
    isThirdPartyPayer,
    isExport,
    isResidentialTo,
    isResidentialFrom,
    optionAllAccountDefault,
  ]);

  const productOptions = accountsWithProducts.find(
    account => account.accountNumber === accountNumber,
  )?.productRangeOptions;

  if (!productOptions) {
    const optionAllAccount = {
      ...optionAllAccountDefault,
      disabled: true,
    };
    return { isInit, options: [optionAllAccount] };
  }

  return { isInit, options: productOptions };
};

const evaluateDisabled = ({
  product,
  isDomestic,
  isResidentialTo,
  isResidentialFrom,
  isReceiverPayer,
  isSenderPayer,
}) => {
  const disabledBecauseDomestic = product.isDomestic !== isDomestic;
  const disabledBecauseResidentialTo =
    isResidentialTo && !product.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C');
  const disabledBecauseResidentialFrom =
    isResidentialFrom && !product.marketAvailability?.some(market => market.name?.toUpperCase() === 'C2B');
  const disabledBecauseReceiverB2C =
    isReceiverPayer &&
    product.isDomestic === isDomestic &&
    product.marketAvailability?.length === 1 &&
    product.marketAvailability[0].name?.toUpperCase() === 'B2C';
  const disabledBecauseReceiverC2B =
    isSenderPayer &&
    product.isDomestic === isDomestic &&
    product.marketAvailability?.length === 1 &&
    product.marketAvailability[0].name?.toUpperCase() === 'C2B';
  if (disabledBecauseDomestic || disabledBecauseReceiverB2C || disabledBecauseReceiverC2B) {
    return true;
  }
  return disabledBecauseResidentialTo || disabledBecauseResidentialFrom;
};
