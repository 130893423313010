import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  LinearProgress,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BulkBooking } from 'store/api/fcpBooking.types';
import { formatDate } from '../../../globals';

type Props = {
  open: boolean;
  onClose: () => void;
  shipmentProcessedCount?: BulkBooking['shipmentProcessedCount'];
  shipmentTotalCount?: BulkBooking['shipmentTotalCount'];
  elapsedTimeInMillis?: BulkBooking['elapsedTimeInMillis'];
  createdAt?: BulkBooking['createdAt'];
};

export const formatElapsedTime = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const BookingProgress: React.FC<Props> = ({
  open,
  shipmentProcessedCount,
  shipmentTotalCount,
  elapsedTimeInMillis,
  createdAt,
  onClose,
}) => {
  const { t } = useTranslation();
  const progressValue =
    shipmentTotalCount && shipmentProcessedCount ? (shipmentProcessedCount / shipmentTotalCount) * 100 : 0;

  const elapsedTime = elapsedTimeInMillis ? formatElapsedTime(elapsedTimeInMillis) : '00:00:00';

  return (
    <>
      <Dialog open={open} onClose={onClose} data-testid="booking-progress-dialog" fullWidth maxWidth="md">
        <DialogTitle data-testid="dialog-title">{t('general|bookingResumed')}</DialogTitle>

        <DialogContent>
          <DialogContentText data-testid="dialog-content-text">
            {t('general|bookingInterrupted', { shipmentCount: shipmentTotalCount, bookingDate: formatDate(createdAt) })}
          </DialogContentText>

          <Stack sx={{ mt: 3, mb: 2 }} direction="row" alignItems="center" gap="16px">
            <LinearProgress
              variant="determinate"
              color="success"
              value={progressValue}
              sx={{ height: '6px', flex: 1, borderRadius: '3px' }}
              data-testid="progress-bar"
            />
            <Typography variant="body1" data-testid="progress-status">
              {t('general|bookedProgress', { current: shipmentProcessedCount, total: shipmentTotalCount })}
            </Typography>
          </Stack>
          <Typography variant="body2" color="textSecondary" data-testid="elapsed-time">
            {t('general|elapsedTime', { elapsedTime })}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
