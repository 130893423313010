import { Typography, Stack, FormGroup, FormControlLabel, Checkbox, Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetOnHoldCountQuery } from 'store/api/fcpBooking';
import React, { useCallback, useEffect } from 'react';
import { selectApprovedTmsAccounts, selectUserAccountCountryCode } from 'store/api/fcpUser';
import { useAppSelector } from 'store/hooks';
import { useOnholdOrders } from './useOnholdOrders';

export const AccountFilter = () => {
  const { t } = useTranslation();
  const accounts = useAppSelector(selectApprovedTmsAccounts);
  const accountCountryCode = useAppSelector(selectUserAccountCountryCode)!;

  const { accountsFilter, setAccountsFilter } = useOnholdOrders();
  const { data: onHoldCount, isFetching: onHoldCountLoading } = useGetOnHoldCountQuery({ accountCountryCode });

  // Remove filtered account that have no on hold orders
  useEffect(() => {
    if (onHoldCountLoading) {
      return;
    }
    if (accountsFilter.some(accountNumber => !onHoldCount?.countByAccount[accountNumber])) {
      setAccountsFilter(prevAccounts =>
        prevAccounts.filter(accountNumber => onHoldCount?.countByAccount[accountNumber]),
      );
    }
  }, [accountsFilter, onHoldCount?.countByAccount, onHoldCountLoading, setAccountsFilter]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;

      if (checked) {
        setAccountsFilter(prevAccounts => [...prevAccounts, value]);
      } else {
        setAccountsFilter(prevAccounts => prevAccounts.filter(account => account !== value));
      }
    },
    [setAccountsFilter],
  );

  return (
    <Stack sx={{ borderRight: '1px solid #e8e8e8', minWidth: '100px', flexGrow: 2, maxWidth: '180px' }} spacing={1}>
      <Typography variant="h4" data-testid="filter-accounttitle">
        {t('portalSavedShipments|Account')}
      </Typography>
      <FormGroup sx={{ borderBottom: '1px solid #e8e8e8' }}>
        {accounts.map(({ accountNumber, accountReference }) => (
          <FormControlLabel
            key={accountNumber}
            disabled={!onHoldCount?.countByAccount[accountNumber]}
            sx={{ mr: '2px' }}
            control={
              <Checkbox
                value={accountNumber}
                onChange={handleChange}
                checked={accountsFilter.includes(accountNumber)}
                sx={{ p: '6px' }}
                data-testid="filter-accountNumberCheckbox"
              />
            }
            label={
              <AccountFilterLabel
                accountNumber={accountNumber}
                accountReference={accountReference}
                loading={onHoldCountLoading}
                count={onHoldCount?.countByAccount[accountNumber] ?? 0}
              />
            }
          />
        ))}
      </FormGroup>
    </Stack>
  );
};

const AccountFilterLabel = ({
  accountNumber,
  accountReference,
  count,
  loading,
}: {
  accountNumber: string;
  accountReference?: string;
  count: number;
  loading: boolean;
}) => {
  return (
    <Tooltip title={accountReference}>
      <Typography variant="body1" fontSize="15px" sx={{ wordBreak: 'break-word' }} data-testid="filter-accountLabel">
        {accountNumber} ({loading ? <Skeleton variant="text" width={14} sx={{ display: 'inline-block' }} /> : count})
      </Typography>
    </Tooltip>
  );
};
