import { Checkbox, Error, Input, Select } from 'components';
import { sortCountries } from 'globals/data-sorted-countries';
import { analytics } from 'globals/utils/analytics';
import { getPropperAPIData } from 'globals/utils/requests';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import PhoneNumberV2 from '../../../components/PhoneNumberV2/PhoneNumberV2';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../components/fcp-components/PostalCodeInput';
import {
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../globals/constants';
import { canFetchCityForPostalcode } from '../../../globals/helpers/postalcode';
import { getPayerCode } from '../../../portal-order/components/EnterPickupLocation/EnterPickupLocation-helpers';
import { CONST, config } from '../../data-config';
import { saveShipmentCall } from '../../helpers/savedShipment';
import { postalCodeAndCityValidation } from './EnterPickupLocation-API-validation';
import {
  afterCountry,
  checkContactEmail,
  checkSubmit,
  fillReceiverData,
  getCityName,
  getStateToSet,
  productAPICall,
  renderDifferentDeliveryAddress,
  renderDifferentPickUpAddress,
  setGeneralError,
} from './EnterPickupLocation-helpers';
import './EnterPickupLocation.css';
import ImportExportSection from './ImportExportSection/ImportExportSection';
import { changeCountryCodePhonePrefix } from '../../../globals/helpers/country-helpers';

export default class EnterPickupLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationError: false,
      apiError: false,
      generalError: false,
      nextBtnLoader: false,
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      sourceCityError: false,
      destinationCityError: false,
      productAPIError: false,
      cityValidationError: {},
      allCountries: sortCountries(),
    };

    this.loaderRef = React.createRef();
  }

  resetPostalCodeState = () => {
    return {
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      sourceCityError: false,
      destinationCityError: false,
    };
  };

  resetPostalCityError = () => {
    this.setState(this.resetPostalCodeState());

    checkContactEmail(this.props.context);
  };

  resetCityValidationError = () => {
    const stateToSet = this.resetPostalCodeState();
    stateToSet.cityValidationError = {};

    this.setState(stateToSet);
  };

  setAppropriateCity = (suggestedCity = false, cityName, cityError) => {
    const city = JSON.parse(JSON.stringify(this.props.context.state[cityName]));
    const cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));

    city.value = suggestedCity || this.state.cityValidationError[cityName].value;
    city.error = false;
    delete cityValidationError[cityName];

    this.setState(
      {
        cityValidationError,
        [cityError]: false,
      },
      () => {
        this.props.context.updateState({ [cityName]: city });
      },
    );

    if (suggestedCity) this.loaderRef.current.classList.remove('is-loading');
  };

  checkAppropriateCity = async (coutryName, postalCodeName, cityName, postalCodeError, cityError) => {
    this.loaderRef.current.classList.add('is-loading');
    const { state } = this.props.context;
    const accountCountryCode = 'SE';
    const countryCode = state[coutryName].value;
    const postalCode = state[postalCodeName].value;

    const shouldValidateCity = canFetchCityForPostalcode({ accountCountryCode, countryCode, postalCode });

    if (shouldValidateCity) {
      const localStateToSet = {};

      const fetchCityCall = await getCityName(countryCode, postalCode, state[cityName].value);
      localStateToSet.loader = false;
      localStateToSet.cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));
      localStateToSet.cityValidationError[cityName] = {};

      // postal code correct
      if (!fetchCityCall.error && fetchCityCall.data && fetchCityCall.data.city) {
        // city name correct
        if (state[cityName].value) {
          localStateToSet.cityValidationError[cityName] = {
            value: fetchCityCall.data.city,
            mismatchError: !fetchCityCall.data.validated,
          };

          if (fetchCityCall.data.validated) {
            delete localStateToSet.cityValidationError[cityName];
          }
        } else if (!state[cityName].value) {
          // city name not entered
          this.setAppropriateCity(fetchCityCall.data.city, cityName, cityError);

          return;
        }
      } else if (!fetchCityCall.error && fetchCityCall.data && !fetchCityCall.data.validated) {
        localStateToSet.cityValidationError[cityName] = { postalCodeError: true };
      }

      localStateToSet[postalCodeError] = false;
      localStateToSet[cityError] = false;

      if (
        localStateToSet.cityValidationError[cityName] &&
        Object.keys(localStateToSet.cityValidationError[cityName]).length === 0
      ) {
        delete localStateToSet.cityValidationError[cityName];
      }

      localStateToSet[postalCodeError] = false;
      localStateToSet[cityError] = false;

      this.setState(localStateToSet);
    }
    this.loaderRef.current.classList.remove('is-loading');
  };

  cityValidation = () => {
    return Object.keys(this.state.cityValidationError).length > 0;
  };

  checkIfEnteredAndSuggestedCityIsValid = cityName => {
    const { cityValidationError } = this.state;

    if (
      cityValidationError[cityName] &&
      cityValidationError[cityName].mismatchError &&
      cityValidationError[cityName].value
    ) {
      const modifiedCityValidationError = JSON.parse(JSON.stringify(cityValidationError));

      modifiedCityValidationError[cityName].mismatchError =
        this.props.context.state[cityName].value.toLowerCase() !==
        modifiedCityValidationError[cityName].value.toLowerCase();

      if (!modifiedCityValidationError[cityName].mismatchError) {
        delete modifiedCityValidationError[cityName];
      }

      this.setState({
        cityValidationError: modifiedCityValidationError,
      });
    }
  };

  resetPostalCodeSuggestionError = (context, cityName, differentCityName) => {
    afterCountry(context);

    const cityValidationError = JSON.parse(JSON.stringify(this.state.cityValidationError));
    delete cityValidationError[cityName];
    delete cityValidationError[differentCityName];

    this.setState({ cityValidationError });
  };

  onGoClick = async context => {
    const state = getPropperAPIData(context.state);
    const stateToSet = getStateToSet();

    // Initial validation check
    stateToSet.validationError = checkSubmit(context);
    const cityNotValid = this.cityValidation();

    if (cityNotValid) {
      stateToSet.validationError = true;
    }

    const newStateToSet = setGeneralError(stateToSet);
    this.setState(newStateToSet);

    if (!stateToSet.validationError) {
      this.setState({ nextBtnLoader: true }, async () => {
        stateToSet.goNextError = false;
        await postalCodeAndCityValidation(state, stateToSet);

        context.updateState({
          payerCode: getPayerCode(this.props.context.state),
        });

        this.updateStateAndGoToNextTab(stateToSet);
      });
    }
  };

  updateStateAndGoToNextTab = async stateToSet => {
    const newStateToSet = setGeneralError(stateToSet);
    let canProceedToNextStep = !newStateToSet.goNextError && !newStateToSet.generalError && !newStateToSet.apiError;

    if (canProceedToNextStep) {
      const savedShipmentResult = await saveShipmentCall(this.props.context);
      if (!savedShipmentResult.error) {
        analytics('step 1', CONST.ANALYTICS);
        this.props.nextTab(config.tabs.tab2);
      } else {
        // state update
        newStateToSet.saveShipmentsError = savedShipmentResult.error || false;
        canProceedToNextStep = false;
      }
    }

    // in case, error occured
    if (!canProceedToNextStep) {
      newStateToSet.nextBtnLoader = false;
      this.setState(newStateToSet);
    }
  };

  getFCPAPIProducts = async () => {
    const stateToSet = await productAPICall(this.props.context.state);

    if (stateToSet.productAPIError) {
      this.setState({
        productAPIError: true,
      });
    } else {
      this.props.context.updateState(stateToSet);
    }
  };

  componentDidMount = async () => {
    // set cookie data only once
    if (this.props.context.state.products.length === 0) {
      fillReceiverData(this.props.context.state.shipmentTypesImpOrExp.value, this.props.context);
      await this.getFCPAPIProducts();
    }
  };

  render() {
    const { context } = this.props;

    return (
      <>
        <div className="frc__generic-section--wrapper">
          <Translation>
            {t => (
              <>
                <ImportExportSection
                  context={context}
                  fillReceiverData={fillReceiverData}
                  resetCityValidationError={this.resetCityValidationError}
                />

                <div className="l-grid l-grid--between-s frc__push-bottom  frc__generic--field-wrapper ">
                  <div className="l-grid--w-100pc-s l-grid--w-48pc-m margin-bottom-1">
                    <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|From')}</h4>
                    <div className="l-grid c-form-step--item c-freightcalc--item-checkbox">
                      <Checkbox
                        label={t('general|labels|inputs|Pick up from a different address')}
                        labelAfter={
                          '&nbsp;<span class="has-tooltip"> ' +
                          '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                          t('general|pickupFromDifferentAddressTooltip') +
                          '</div></div>' +
                          '</span>'
                        }
                        name="pickupFromDifferentAddress"
                        isRequired={false}
                        checked={context.state.pickupFromDifferentAddress.value}
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={context.state.pickupAddressResidential.value}
                        afterUpdate={this.resetPostalCityError}
                      />
                    </div>
                    <div className="frc__generic--background-white frc__generic-section--wrapper">
                      <Checkbox
                        labelAfter={
                          '&nbsp;<span class="has-tooltip"> ' +
                          '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                          t('general|Pickup residential address tooltip') +
                          '</div></div>' +
                          '</span>'
                        }
                        label={t('general|This is a residential address')}
                        name="pickupAddressResidential"
                        isRequired={false}
                        checked={context.state.pickupAddressResidential.value}
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={
                          context.state.pickupFromDifferentAddress.value ||
                          context.state.shipmentPayer.value === SENDER_PAYER
                        }
                        afterUpdate={checkContactEmail}
                      />
                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|Sender')}
                          name="pickupSender"
                          dataTestId="pickupSenderInp"
                          isRequired={context.state.pickupSender.isRequired}
                          value={context.state.pickupSender.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupSender.error ? (
                          <Error
                            name="pickupSender"
                            className="frc__input--error"
                            message={
                              context.state.pickupAddressResidential.value
                                ? t('general|errors|Invalid Length Error', {
                                    name: 'name',
                                    max: config.maxDefaultInputLength,
                                  })
                                : t('general|errors|Company Error', {
                                    max: config.maxDefaultInputLength,
                                  })
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                        <div className=" l-grid--w-100pc-s">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|inputs|Street')}
                            name="pickupStreet"
                            isRequired={context.state.pickupStreet.isRequired}
                            value={context.state.pickupStreet.value}
                            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                          />
                          {context.state.pickupStreet.error ? (
                            <Error
                              name="pickupStreet"
                              className="frc__input--error"
                              message={t('general|errors|Street Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                        <div
                          className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                          onBlur={
                            context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                              ? () =>
                                  this.checkAppropriateCity(
                                    'pickupCountry',
                                    'pickupPostalCode',
                                    'pickupCity',
                                    'sourcePostalCodeError',
                                    'sourceCityError',
                                  )
                              : null
                          }
                        >
                          <PostalCodeInput
                            value={context.state.pickupPostalCode.value}
                            name="pickupPostalCode"
                            isRequired={context.state.pickupPostalCode.isRequired}
                            countryCode={context.state.pickupCountry.value}
                            error={
                              context.state.pickupPostalCode.error
                                ? POSTALCODE_ERRORS.validationError
                                : this.state.cityValidationError?.pickupCity?.postalCodeError
                                ? POSTALCODE_ERRORS.cityPostalCodeError
                                : !context.state.pickupFromDifferentAddress?.value && this.state?.sourcePostalCodeError
                                ? POSTALCODE_ERRORS.apiError
                                : undefined
                            }
                            context={context}
                            config={config}
                            CONST={CONST}
                          />
                        </div>
                        <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|inputs|City')}
                            name="pickupCity"
                            isRequired={context.state.pickupCity.isRequired}
                            value={context.state.pickupCity.value}
                            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                            afterUpdate={
                              this.state.cityValidationError.pickupCity
                                ? () => this.checkIfEnteredAndSuggestedCityIsValid('pickupCity')
                                : null
                            }
                            afterChangeOnBlur={
                              !(
                                this.state.cityValidationError.pickupCity &&
                                this.state.cityValidationError.pickupCity.mismatchError
                              ) &&
                              context.state.pickupPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                              context.state.pickupCountry.value === config.defaultCountry
                                ? () =>
                                    this.checkAppropriateCity(
                                      'pickupCountry',
                                      'pickupPostalCode',
                                      'pickupCity',
                                      'sourcePostalCodeError',
                                      'sourceCityError',
                                    )
                                : null
                            }
                          />
                          {context.state.pickupCity.error ? (
                            <Error
                              name="pickupCity"
                              className="frc__input--error"
                              message={t('general|errors|City Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          ) : this.state.cityValidationError.pickupCity &&
                            this.state.cityValidationError.pickupCity.mismatchError ? (
                            <div className="l-grid">
                              <Error
                                name="cityMismtachError"
                                className="frc__input--error"
                                message={t('general|errors|invalidCity')}
                              />
                              <span className="frc__city-suggestion">
                                {'"'}
                                <span
                                  className="frc__city-underline"
                                  onClick={() => this.setAppropriateCity(false, 'pickupCity', 'sourceCityError')}
                                >
                                  {this.state.cityValidationError.pickupCity.value}
                                </span>
                                {'" ?'}
                              </span>
                            </div>
                          ) : !context.state.pickupFromDifferentAddress.value ? (
                            this.state.sourceCityError && (
                              <Error
                                name="pickupFromDifferentAddresssourceCityError"
                                className="frc__input--error"
                                message={t('general|errors|City API Error', {
                                  max: config.maxDefaultInputLength,
                                })}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className=" l-grid--w-100pc-s frc__generic-row--wrapper">
                        <Select
                          selectClass="l-grid--w-100pc-s"
                          label={t(`general|Country`)}
                          name="pickupCountry"
                          isRequired={context.state.pickupCountry.isRequired}
                          values={
                            context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                              ? this.state.allCountries.filter(
                                  item => item.code !== context.state.deliveryCountry.value,
                                )
                              : config.domesticCountries
                          }
                          disabled={
                            context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE
                          }
                          value={context.state.pickupCountry.value}
                          camparisonValue={{
                            value: context.state.deliveryCountry.value,
                            field: 'deliveryCountry',
                          }}
                          compare={context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? false : true}
                          context={context}
                          config={config}
                          CONST={{
                            API_DOMAIN: 'countries',
                          }}
                          afterUpdate={(ctx, params) => {
                            this.resetPostalCodeSuggestionError(ctx, 'pickupCity', 'differentPickupCity');
                            changeCountryCodePhonePrefix(ctx, params.value, 'pickupPhoneNumbers');
                          }}
                        />
                        {context.state.pickupCountry.error ? (
                          <Error
                            name="pickupCountry"
                            className="frc__input--error"
                            message={t(`general|Country Error`)}
                          />
                        ) : (
                          ''
                        )}
                        {context.state.pickupCountry.comparisonError ? (
                          <Error
                            name="pickupCountrycomparisonError"
                            className="frc__input--error"
                            message={t(`general|To From Country Error`)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <p className="frc__contact-details-label" />

                    <div className="frc__generic--background-white frc__generic-section--wrapper">
                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t(`general|Contact Name`)}
                          name="pickupName"
                          isRequired={context.state.pickupName.isRequired}
                          value={context.state.pickupName.value}
                          lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.pickupName.error ? (
                          <Error
                            name="pickupName"
                            className="frc__input--error"
                            message={t('general|errors|Contact Name Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          label={t(`general|Contact E-mail`)}
                          name="pickupEmail"
                          isRequired={context.state.pickupEmail.isRequired}
                          value={context.state.pickupEmail.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                          cutTextLimit={config.maxEmailLength}
                        />
                        {context.state.pickupEmail.error ? (
                          <Error
                            name="pickupEmail"
                            className="frc__input--error"
                            message={t('general|errors|E-mail Error', {
                              chars: config.maxEmailLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                        {context.state.pickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                          <PhoneNumberV2
                            key={ind}
                            context={context}
                            config={config}
                            CONST={CONST}
                            index={ind}
                            number={phoneNumber.number}
                            code={phoneNumber.code}
                            error={phoneNumber.error}
                            groupName="pickupPhoneNumbers"
                            isRequired={phoneNumber.isRequired}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|Sender Reference')}
                        name="pickupSenderReference"
                        isRequired={false}
                        value={context.state.pickupSenderReference.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.pickupSenderReference.error ? (
                        <Error
                          name="pickupSenderReference"
                          className="frc__input--error"
                          message={t('general|errors|Invalid maxchar error', {
                            name: t('general|labels|inputs|Sender Reference'),
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    {context.state.pickupFromDifferentAddress.value &&
                      !context.state.pickupAddressResidential.value &&
                      renderDifferentPickUpAddress(
                        context,
                        t,
                        this.state,
                        this.checkAppropriateCity,
                        this.setAppropriateCity,
                        this.checkIfEnteredAndSuggestedCityIsValid,
                      )}
                  </div>

                  <div className="l-grid--w-100pc-s l-grid--w-48pc-m">
                    <h4 className="margin-bottom-1 frc__red-lined-title">{t('general|To')}</h4>
                    <div className="l-grid l-grid--w-100pc-s">
                      <div className="l-grid--w-100pc-s l-grid--hidden-m">
                        <p>{t(`general|Address Details`)}</p>
                      </div>
                      <div className="l-grid--w-100pc-s">
                        <Checkbox
                          label={t(`general|Deliver to a different address`)}
                          labelAfter={
                            '&nbsp;<span class="has-tooltip"> ' +
                            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                            t('general|deliverToDifferentAddressTooltip') +
                            '</div></div>' +
                            '</span>'
                          }
                          name="deliverToDifferentAddress"
                          isRequired={false}
                          checked={context.state.deliverToDifferentAddress.value}
                          context={context}
                          config={config}
                          CONST={CONST}
                          disabled={context.state.deliveryAddressResidential.value}
                          afterUpdate={this.resetPostalCityError}
                        />
                      </div>
                    </div>

                    <div className="frc__generic--background-white frc__generic-section--wrapper">
                      <Checkbox
                        labelAfter={
                          '&nbsp;<span class="has-tooltip"> ' +
                          '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
                          t(`general|Delivery residential address tooltip`) +
                          '</div></div>' +
                          '</span>'
                        }
                        label={t('general|This is a residential address')}
                        name="deliveryAddressResidential"
                        isRequired={false}
                        checked={context.state.deliveryAddressResidential.value}
                        context={context}
                        config={config}
                        CONST={CONST}
                        disabled={
                          context.state.deliverToDifferentAddress.value ||
                          context.state.shipmentPayer.value === RECEIVER_PAYER
                        }
                        afterUpdate={checkContactEmail}
                      />
                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t('general|Receiver')}
                          name="deliveryReceiver"
                          isRequired={context.state.deliveryReceiver.isRequired}
                          value={context.state.deliveryReceiver.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryReceiver.error ? (
                          <Error
                            name="deliveryReceiver"
                            className="frc__input--error"
                            message={
                              context.state.deliveryAddressResidential.value
                                ? t('general|errors|Invalid Length Error', {
                                    name: 'name',
                                    max: config.maxDefaultInputLength,
                                  })
                                : t('general|errors|Company Error', {
                                    max: config.maxDefaultInputLength,
                                  })
                            }
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                        <div className=" l-grid--w-100pc-s">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|inputs|Street')}
                            name="deliveryStreet"
                            isRequired={context.state.deliveryStreet.isRequired}
                            value={context.state.deliveryStreet.value}
                            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                          />
                          {context.state.deliveryStreet.error ? (
                            <Error
                              name="deliveryStreet"
                              className="frc__input--error"
                              message={t('general|errors|Street Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
                        <div
                          className=" l-grid--w-100pc-s l-grid--w-50pc-m"
                          onBlur={
                            context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch
                              ? () =>
                                  this.checkAppropriateCity(
                                    'deliveryCountry',
                                    'deliveryPostalCode',
                                    'deliveryCity',
                                    'destinationPostalCodeError',
                                    'destinationCityError',
                                  )
                              : null
                          }
                        >
                          <PostalCodeInput
                            value={context.state.deliveryPostalCode.value}
                            name="deliveryPostalCode"
                            isRequired={context.state.deliveryPostalCode.isRequired}
                            countryCode={context.state.deliveryCountry.value}
                            error={
                              context.state.deliveryPostalCode.error
                                ? POSTALCODE_ERRORS.validationError
                                : this.state.cityValidationError?.deliveryCity?.postalCodeError
                                ? POSTALCODE_ERRORS.cityPostalCodeError
                                : !context.state.deliverToDifferentAddress?.value &&
                                  this.state?.destinationPostalCodeError
                                ? POSTALCODE_ERRORS.apiError
                                : undefined
                            }
                            context={context}
                            config={config}
                            CONST={CONST}
                          />
                        </div>
                        <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
                          <Input
                            context={context}
                            config={config}
                            CONST={CONST}
                            regEx={config.regEx.everything}
                            label={t('general|labels|inputs|City')}
                            name="deliveryCity"
                            isRequired={context.state.deliveryCity.isRequired}
                            value={context.state.deliveryCity.value}
                            lengthCheck={[RegExp(`^.{1,${config.maxDefaultInputLength}}$`)]}
                            cutTextLimit={config.maxDefaultInputLength}
                            afterUpdate={
                              this.state.cityValidationError.deliveryCity
                                ? () => this.checkIfEnteredAndSuggestedCityIsValid('deliveryCity')
                                : null
                            }
                            afterChangeOnBlur={
                              !(
                                this.state.cityValidationError.deliveryCity &&
                                this.state.cityValidationError.deliveryCity.mismatchError
                              ) &&
                              context.state.deliveryPostalCode.value.length >= config.minPostalCodeToPerfomCitySearch &&
                              context.state.deliveryCountry.value === config.defaultCountry
                                ? () =>
                                    this.checkAppropriateCity(
                                      'deliveryCountry',
                                      'deliveryPostalCode',
                                      'deliveryCity',
                                      'destinationPostalCodeError',
                                      'destinationCityError',
                                    )
                                : null
                            }
                          />
                          {context.state.deliveryCity.error ? (
                            <Error
                              name="deliveryCity"
                              className="frc__input--error"
                              message={t('general|errors|City Error', {
                                max: config.maxDefaultInputLength,
                              })}
                            />
                          ) : this.state.cityValidationError.deliveryCity &&
                            this.state.cityValidationError.deliveryCity.mismatchError ? (
                            <div className="l-grid">
                              <Error
                                name="cityMismtachError"
                                className="frc__input--error"
                                message={t('general|errors|invalidCity')}
                              />
                              <span className="frc__city-suggestion">
                                {'"'}
                                <span
                                  className="frc__city-underline"
                                  onClick={() => this.setAppropriateCity(false, 'deliveryCity', 'destinationCityError')}
                                >
                                  {this.state.cityValidationError.deliveryCity.value}
                                </span>
                                {'" ?'}
                              </span>
                            </div>
                          ) : !context.state.deliverToDifferentAddress.value ? (
                            this.state.destinationCityError && (
                              <Error
                                name="deliverToDifferentAddressdestinationCityError"
                                className="frc__input--error"
                                message={t('general|errors|City API Error', {
                                  max: config.maxDefaultInputLength,
                                })}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
                        <Select
                          selectClass="l-grid--w-100pc-s"
                          label={t(`general|Country`)}
                          name="deliveryCountry"
                          isRequired={context.state.deliveryCountry.isRequired}
                          values={
                            context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                              ? this.state.allCountries.filter(item => item.code !== context.state.pickupCountry.value)
                              : config.domesticCountries
                          }
                          disabled={
                            context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
                            context.state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE
                          }
                          value={context.state.deliveryCountry.value}
                          camparisonValue={{
                            value: context.state.pickupCountry.value,
                            field: 'pickupCountry',
                          }}
                          compare={context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE ? false : true}
                          context={context}
                          config={config}
                          CONST={{
                            API_DOMAIN: 'countries',
                          }}
                          afterUpdate={(ctx, params) => {
                            this.resetPostalCodeSuggestionError(ctx, 'deliveryCity', 'differentDeliveryCity');
                            changeCountryCodePhonePrefix(ctx, params.value, 'deliveryPhoneNumbers');
                          }}
                        />
                        {context.state.deliveryCountry.error ? (
                          <Error
                            name="deliveryCountryError"
                            className="frc__input--error"
                            message={t(`general|Country Error`)}
                          />
                        ) : (
                          ''
                        )}
                        {context.state.deliveryCountry.comparisonError ? (
                          <Error
                            name="deliveryCountrycomparisonError"
                            className="frc__input--error"
                            message={t(`general|To From Country Error`)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <p className="frc__contact-details-label" />

                    <div className="frc__generic--background-white frc__generic-section--wrapper">
                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          regEx={config.regEx.everything}
                          label={t(`general|Contact Name`)}
                          name="deliveryName"
                          isRequired={context.state.deliveryName.isRequired}
                          value={context.state.deliveryName.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                          cutTextLimit={config.maxDefaultInputLength}
                        />
                        {context.state.deliveryName.error ? (
                          <Error
                            name="deliveryName"
                            className="frc__input--error"
                            message={t('general|errors|Contact Name Error', {
                              max: config.maxDefaultInputLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                        <Input
                          context={context}
                          config={config}
                          CONST={CONST}
                          label={t(`general|Contact E-mail`)}
                          name="deliveryEmail"
                          isRequired={context.state.deliveryEmail.isRequired}
                          value={context.state.deliveryEmail.value}
                          lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
                          cutTextLimit={config.maxEmailLength}
                        />
                        {context.state.deliveryEmail.error ? (
                          <Error
                            name="deliveryEmail"
                            className="frc__input--error"
                            message={t('general|errors|E-mail Error', {
                              chars: config.maxEmailLength,
                            })}
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
                        {context.state.deliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
                          <PhoneNumberV2
                            key={ind}
                            context={context}
                            config={config}
                            CONST={CONST}
                            index={ind}
                            number={phoneNumber.number}
                            code={phoneNumber.code}
                            error={phoneNumber.error}
                            groupName="deliveryPhoneNumbers"
                            isRequired={phoneNumber.isRequired}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
                      <Input
                        context={context}
                        config={config}
                        CONST={CONST}
                        label={t('general|labels|inputs|Receiver Reference')}
                        name="deliveryReceiverReference"
                        isRequired={false}
                        value={context.state.deliveryReceiverReference.value}
                        lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
                        cutTextLimit={config.maxDefaultInputLength}
                      />
                      {context.state.deliveryReceiverReference.error ? (
                        <Error
                          name="deliveryReceiverReference"
                          className="frc__input--error"
                          message={t('general|errors|Invalid maxchar error', {
                            name: t('general|labels|inputs|Receiver Reference'),
                            max: config.maxDefaultInputLength,
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>

                    {context.state.deliverToDifferentAddress.value &&
                      !context.state.deliveryAddressResidential.value &&
                      renderDifferentDeliveryAddress(
                        context,
                        t,
                        this.state,
                        this.checkAppropriateCity,
                        this.setAppropriateCity,
                        this.checkIfEnteredAndSuggestedCityIsValid,
                      )}
                  </div>

                  {!this.state.productAPIError && (
                    <div className="l-grid--w-100pc-s">
                      <button
                        id="goToShipmentDetailsBtn"
                        data-testid="next-button"
                        className={
                          'base-button base-button--wide base-button--pull-right l-grid--w-25pc-w' +
                          (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                        }
                        onClick={() => this.onGoClick(context)}
                        disabled={this.state.nextBtnLoader}
                      >
                        <span>{t('general|labels|buttons|Next Step')}</span>
                      </button>
                    </div>
                  )}
                  <div className="l-grid--w-100pc-s l-grid--right-s">
                    {this.state.apiError ||
                    this.state.generalError ||
                    this.state.productAPIError ||
                    this.state.saveShipmentsError ? (
                      <Error
                        name="apiGeneralproductAPIerror"
                        message={
                          this.state.generalError
                            ? t('general|errors|Please fill properly all necessary fields')
                            : this.state.apiError || this.state.productAPIError
                            ? t(`general|Booking failed. Try again or call technical support.`) +
                              ' ' +
                              t('general|or') +
                              ' ' +
                              `<strong><a href="${t('pageLinks|chat')}"
                                                class ="frc__chat-with-us"
                                                target="_blank" rel="noopener noreferrer">${t(
                                                  `general|Chat with us`,
                                                )}</a></strong>`
                            : this.state.saveShipmentsError
                            ? t(`publicOrder|Shipment could not be saved`)
                            : ''
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </>
            )}
          </Translation>
          <div ref={this.loaderRef} className="frc__generic--loader-overlay" />
        </div>
      </>
    );
  }
}
