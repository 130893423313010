import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import { chargeableWeight } from '../DeliveryOptions/Chargeable-weight';
import { homeDeliveryValidations } from './ServiceDetails-helpers/ServiceDetails-Home-Delivery-helpers';
import getLanguage from 'globals/utils/getLanguage';
import { disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound } from '../DeliveryOptions/DeliveryOptions-helpers';
import {
  checkAndTranslateOptionSchemas,
  applyOptionSchemaResultToVAS,
  validateReturnProducts,
  checkCustomsVASandEleminateOptionsSchema,
  isDomesticReturnProduct,
  servicePointUseResidentialAddress,
  filterServicesForGivenProduct,
  filterGServices,
  applyValidationRulesToASlist,
  checkIfUseResidentialAddressCanBeDisabled,
} from './ServiceDetails-helpers/ServiceDetails-helpers';
import {
  arrangeVASAlphabetically,
  setLoadersState,
  getTranslatedVASName,
  arrangeVASBasedOnSelectedLanguage,
} from './ServiceDetails-helpers/ServiceDetails-General-helpers';
import { loadCollectionAtTerminal } from './ServiceDetails-helpers/ServiceDetails-Collection-At-Terminal';
import {
  payerCodeBasedFiltering,
  tailLiftServiceFilter,
} from '../../../portal-order/components/ServiceDetails/ServiceDetails-helpers/ServiceDetails-Filter-VAS';
import { setDisabledAndResetVas } from '../../../portal-order/components/DeliveryOptions/DeliveryOptions-helpers';
import _ from 'lodash';
import { HEMLEVERANS_PRODUCT_CODE, RECEIVER_PAYER } from '../../../globals/constants';
import { getDoorStepDeliveryVasDefaultData } from '../../../globals/helpers/additionalServices';

export const beforeCheck = context => {
  return setLoadersState(context.state, true);
};

/**
 * afterCheck
 * performs additional logic after ticking a checkbox
 */
export const afterCheck = async (context, servicePointCall = true, product = {}, countryConfig = {}) => {
  const stateToSet = await getAdditionalServicesForOneSelectedProduct(
    context.state,
    servicePointCall,
    product,
    countryConfig,
  );

  stateToSet.productSelectionError = false;

  context.updateState(stateToSet);
};

const formVasNameMap = (additionalServices, languageCode) => {
  const vasNameMap = {};

  additionalServices.forEach(vas => {
    if (vas.translations) {
      const translationObj = vas.translations.find(obj => obj.languageCode === languageCode);

      //safe fix if en translation is missing
      if (typeof translationObj === 'object') {
        vasNameMap[vas.code] = translationObj.name;
      } else {
        vasNameMap[vas.code] = vas['name'];
      }
    }
  });

  return vasNameMap;
};

export const formMissingVasString = missingVASArrNames => {
  return missingVASArrNames.length > 0 ? missingVASArrNames.map(vasName => " '" + vasName + "'").toString() : false;
};

/**
 * getAdditionalServicesForOneSelectedProduct
 * main filtering functionality
 */
export const getAdditionalServicesForOneSelectedProduct = async (
  state,
  servicePointCall = true,
  product = {},
  countryConfig = {},
) => {
  const stateObject = {};
  // to prevent empty product in quotecall and after terms of delivery change
  product = _.isEmpty(product) ? state.selectedProduct : product;
  const language = getLanguage(config.regEx.urlValidation);
  const productCode = product?.code?.toString();
  const totalShipmentWeight = state.totals.shipmentDetailsRows.weight.value * 1;
  const isHomeDelivery = config.homeDeliveryProductCodes.indexOf(productCode) !== -1;
  const disabledVas = countryConfig?.disabledVass ?? {};

  stateObject.error = false;
  stateObject.isb2cProduct = config.b2cProducts.indexOf(productCode) !== -1;

  // call additional service and validations
  await additionalServiceAndValidationCall(product, state, stateObject, disabledVas);

  // Reset Dangerous Goods error flag
  stateObject.dangerousGoodsLinkedIfNeeded = true;

  // !!!! important
  // is it Hemleverans product (118) => ADD doorStepDelivery VAS => it is done by HARDCODED VAS, because Farm API does not want to include it for 118
  // !!!! important
  if (productCode === HEMLEVERANS_PRODUCT_CODE) {
    stateObject.additionalServices.push(getDoorStepDeliveryVasDefaultData());
  }

  // get options schema
  if (!stateObject.error) {
    await getOptionSchema(stateObject, product, state.pickupDate.value);
  }

  // Home Delivery case
  if (isHomeDelivery && !stateObject.error) {
    await homeDeliveryValidations(product, state, stateObject);
  }

  if (!stateObject.error) {
    checkCustomsVASandEleminateOptionsSchema(
      stateObject,
      state.countryBasedCustoms,
      state.shipmentRange.value,
      product,
      state,
    );
  }

  // Check for Chargeable weight and Collection at terminal
  if (!stateObject.error) await chargeableWeightAndCollectionAtTerminal(stateObject, state, product);

  if (!stateObject.error) {
    stateObject.additionalServicesGeneralError = false;
    stateObject.returnProdStatus = validateReturnProducts(productCode);

    stateObject.additionalServices = arrangeVASAlphabetically(stateObject.additionalServices, language);
    stateObject.additionalServices = arrangeVASBasedOnSelectedLanguage(
      stateObject.additionalServices,
      'translatedName',
    );
    stateObject.additionalServices = getTranslatedVASName(stateObject.additionalServices, state.languageCode);

    if (product && product?.hasOwnProperty('priorityServices')) {
      stateObject.additionalServices = filterGServices(product, stateObject.additionalServices);
    }

    stateObject.additionalServices = payerCodeBasedFiltering(stateObject, {
      payerCode: state.payerCode,
      productCode: productCode,
      shipmentRange: state.shipmentRange.value,
      shipmentTypesImpOrExp: state.shipmentTypesImpOrExp.value,
    });

    // FCPG-44 tailLift service filtering based on weight
    stateObject.additionalServices = tailLiftServiceFilter({
      additionalServices: structuredClone(stateObject.additionalServices),
      totalShipmentWeight,
      shipmentRows: state.shipmentDetailsRows,
      tailLiftConfig: countryConfig?.vasFilters?.tailLiftLoading,
      serviceName: 'tailLiftLoading',
    });

    stateObject.additionalServices = tailLiftServiceFilter({
      additionalServices: structuredClone(stateObject.additionalServices),
      totalShipmentWeight,
      shipmentRows: state.shipmentDetailsRows,
      tailLiftConfig: countryConfig?.vasFilters?.tailLiftUnloading,
      serviceName: 'tailLiftUnloading',
    });

    if (servicePointCall) {
      if (stateObject.isb2cProduct) {
        servicePointHelpers(stateObject, productCode, state);
      } else {
        stateObject.servicePointUseResidentialAddress = {
          value: false,
          disabled: false,
          isProductServicePointDelivery: false,
        };
      }
    }
  } else {
    stateObject.additionalServices = [];
  }

  // CR - 54
  if (
    config.schedulePickup.indexOf(product?.code) > -1 &&
    stateObject.additionalServices &&
    stateObject.additionalServices.some(VAS => config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) &&
    euroconnectSelectedWithNoDeliveryDate(state)
  ) {
    stateObject.additionalServices.forEach(VAS => {
      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.calculatedResult = null;
        VAS.value = false;
        VAS.disabled = true;
      }
    });
  }

  if (
    stateObject.additionalServices &&
    stateObject.additionalServices.length > 0 &&
    stateObject.selectedServicePoint &&
    stateObject.isb2cProduct
  ) {
    let modifiedVas = disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound(
      stateObject.selectedServicePoint,
      stateObject.additionalServices,
    );

    if (stateObject?.selectedServicePoint?.locationType === 'locker') {
      modifiedVas = setDisabledAndResetVas(modifiedVas, config.servicePointLockerDisabledVas);
    }

    if (modifiedVas) {
      stateObject.additionalServices = modifiedVas;
    }
  }

  stateObject.selectProductError = false;
  stateObject.selectedProduct = state.matchedProducts.find(productP => productP.code === productCode);
  stateObject.additionalServicesError = stateObject.error;

  if (!stateObject.error) {
    stateObject.vasNameMap = formVasNameMap(JSON.parse(JSON.stringify(stateObject.additionalServices)), language);
  }

  if (!stateObject.error) {
    checkAndEleminateMissingVasArr(state, stateObject);
  }

  // FCP-3342
  if (config.excludeDangerousGoodsVasProductConfig.includes(productCode)) {
    stateObject.additionalServices = excludeDangerousGoodsVas(stateObject.additionalServices);
  }

  return Object.assign(stateObject, setLoadersState(state, false));
};

const checkAndEleminateMissingVasArr = (state, stateObject) => {
  if (state.missingVASArrNames && state.missingVASArrNames.length > 0) {
    const vasArrayNamesList = Object.values(stateObject.vasNameMap);

    stateObject.missingVASArrNames = state.missingVASArrNames.filter(
      missingVAS => vasArrayNamesList.indexOf(missingVAS) === -1,
    );

    stateObject.preSelectedVasNotAvailable = formMissingVasString(stateObject.missingVASArrNames);
  }
};

const additionalServiceAndValidationCall = async (product, state, stateObject, disabledVas) => {
  const productCode = product?.code?.toString();
  const pickupCode = state.pickupCountry.value;
  const deliveryCode = state.deliveryCountry.value;
  const payerCode = state.payerCode;
  const urlAdditionalServices = apiLinks.getAdditionalServicesExtended
    .replace('{productCode}', productCode)
    .replace('{pickupCode}', pickupCode)
    .replace('{deliveryCode}', deliveryCode)
    .replace('{payerCode}', payerCode);

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(resultData => {
          resultData.value = false;
          resultData.productCode = product?.code;
        });
        stateObject.additionalServices = filterServicesForGivenProduct(productCode, result.data, disabledVas);

        await getValidationResults(JSON.parse(JSON.stringify(stateObject.additionalServices)), productCode).then(
          validationResult => {
            if (validationResult.data) {
              stateObject.additionalServices = validationResult.data;
            } else if (validationResult.error) {
              stateObject.error = true;
            }
          },
        );
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

export const getOptionSchema = async (stateObject, { code, LTCDates = false }, pickupDate) => {
  const urlOptions = apiLinks.getPublicOrderVAS.replace('{productCode}', code.toString());

  // API Call for optional schema
  await apiRequest(urlOptions, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices = applyOptionSchemaResultToVAS(stateObject.additionalServices, result.data);

        if (stateObject.additionalServices) {
          stateObject.additionalServices = checkAndTranslateOptionSchemas(
            stateObject.additionalServices,
            LTCDates,
            pickupDate,
          );
        }
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

const excludeDangerousGoodsVas = additionalServices => {
  return additionalServices.filter(vas => vas.code !== 'dangerousGoods');
};

export const euroconnectSelectedWithNoDeliveryDate = state => {
  return (
    state.selectedProduct &&
    state.selectedProduct?.code === '202' &&
    (!state.selectedProduct?.deliveryDate || state.selectedProduct?.deliveryDate === 'ltc_error')
  );
};

// Parallel Call for chargeable weight and collection terminal
const chargeableWeightAndCollectionAtTerminal = async (stateObject, state, product) => {
  let collectionAtTerminalIndex = -1;
  let chargeableWeightMap = {};
  const chargeableWeightCall = chargeableWeight(state, product, true);

  stateObject.additionalServices.forEach(function (vas, i) {
    if (vas.code === config.OptionFieldTypes.collectionAtTerminal) {
      collectionAtTerminalIndex = i;
      return true;
    }
  });

  if (collectionAtTerminalIndex !== -1) {
    await loadCollectionAtTerminal(stateObject, collectionAtTerminalIndex, state);
  }
  await chargeableWeightCall.then(result => (chargeableWeightMap = result));
  stateObject.chargeableWeight = chargeableWeightMap.value !== undefined ? chargeableWeightMap.value : '';

  stateObject.error = stateObject.error || chargeableWeightMap.error;
};

const servicePointHelpers = (stateObject, productCode, ctxState) => {
  const state = getPropperAPIData(ctxState);
  stateObject.servicePointUseResidentialAddress = {};

  stateObject.servicePointUseResidentialAddress.disabled = checkIfUseResidentialAddressCanBeDisabled(
    productCode,
    state,
  );
  stateObject.servicePointUseResidentialAddress.value = !stateObject.servicePointUseResidentialAddress.disabled
    ? servicePointUseResidentialAddress(productCode, state)
    : false;
  stateObject.servicePointUseResidentialAddress.isProductServicePointDelivery = isDomesticReturnProduct(productCode)
    ? true
    : false;

  if (stateObject.servicePointUseResidentialAddress.value) {
    stateObject.selectedServicePoint = {
      id: RECEIVER_PAYER,
      street: state.deliveryStreet.value,
      name: state.deliveryReceiver.value,
      postalCode: state.deliveryPostalCode.value,
      cityName: state.deliveryCity.value,
      countryCode: state.deliveryCountry.value,
    };

    stateObject.servicePointUseResidentialAddress.disabled = true;
  } else if (state.servicePoints.length > 0) {
    stateObject.selectedServicePoint = state.servicePoints.length > 0 ? state.servicePoints[0] : {};
  }
};

/**
 * getValidationResults
 * secondary filtering from validations recieved by API call
 */
export const getValidationResults = async (additionalServices, productCode) => {
  const resultObj = {};
  const urlValudationResults = apiLinks.getValidationResults.replace('{productCode}', productCode);
  const payloadDataForValidationResults = {};

  // Prepare payload for the API call
  additionalServices.forEach(service => {
    payloadDataForValidationResults[service.code] = true;
  });

  // API Call for validations
  await apiRequest(urlValudationResults, 'POST', {
    body: payloadDataForValidationResults,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyValidationRulesToASlist(additionalServices, result.data);
        resultObj.data = JSON.parse(JSON.stringify(additionalServices));
      } else {
        resultObj.error = true;
      }
    })
    .catch(() => {
      resultObj.error = true;
    });

  return resultObj;
};
