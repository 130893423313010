import React from 'react';
import { CONST } from '../../../data-config';
import { CompletedIcon } from 'components';
import { IncompleteIcon, InTransitIcon } from 'components';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';

import './Custom.css';

export default function StatusCustomCell(props) {
  const { cellValue, t } = props;
  const { status } = cellValue;

  const getStatus = action => {
    switch (action) {
      case CONST.ORDER_SENT:
        return (
          <>
            <InTransitIcon /> {t('general|Order Sent')}
          </>
        );
      case CONST.BOOKED:
        return (
          <>
            <InTransitIcon /> {t(`general|Booked`)}
          </>
        );
      case CONST.IN_TRANSIT:
        return (
          <>
            <InTransitIcon /> {t('general|InTransit')}
          </>
        );

      case CONST.DELIVERED:
        return (
          <>
            <CompletedIcon /> {t(`general|Delivered`)}
          </>
        );

      case CONST.IN_COMPLETE:
        return (
          <>
            <IncompleteIcon /> {t('general|Incomplete')}
          </>
        );

      case CONST.MOVED:
        return (
          <>
            <CircleOutlinedIcon sx={{ color: 'rgb(204, 204, 204)' }} /> {t('general|Moved')}
          </>
        );

      case CONST.ERROR:
        return (
          <>
            <CircleIcon color="error" /> {t('general|Error')}
          </>
        );

      default:
        return;
    }
  };

  return <div className="frc__status-txt">{getStatus(status)}</div>;
}
