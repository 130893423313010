import { CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest } from 'globals/utils/requests';
import {
  EXPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../globals/constants';

export const formatPieceForDisplay = pieces => {
  let piecesList = [];

  pieces.forEach(piece => {
    if (piece.quantity) {
      const perPieceList = Array(piece.quantity)
        .fill()
        .map((_, index) => {
          const piecePackage = piece.piecePackages?.[index];

          return {
            ...(piecePackage || {}),
            length: piece.length || '',
            width: piece.width || '',
            height: piece.height || '',
            weight: piece.weight || '',
            volume: piece.volume || '',
            loadMeter: piece.loadMeter || '',
            goodsDescription: piece.goodsDescription || '',
            shipmentMark: piece.shipmentMark || '',
            dangerousGoods: piece.dangerousGoods,
            nonStackable: piece.nonStackable,
          };
        });

      piecesList = piecesList.concat(perPieceList);
    }
  });

  return piecesList;
};

export const fetchCollectionAtTerminal = async ({ countryCode, postalCode }, terminalId) => {
  const collectionAtTerminalUrl =
    apiLinks.collectionAtTerminal + '?countryCode=' + countryCode + '&postalCode=' + postalCode;
  let selectedTerminalName = '';

  await apiRequest(collectionAtTerminalUrl, 'GET').then(result => {
    if (result.status === CONST.STATUS_OK) {
      let selectedTerminal = result.data.terminals.find(terminal => terminal.id === terminalId);

      if (!selectedTerminal) {
        selectedTerminal = result.data.terminals.find(terminal => terminal.id === result.data.defaultTeminalID);
      }

      if (selectedTerminal) {
        selectedTerminalName =
          selectedTerminal.address && selectedTerminal.name
            ? selectedTerminal.name +
              '/' +
              selectedTerminal.address.street +
              '/' +
              selectedTerminal.address.postalCode +
              ' ' +
              selectedTerminal.address.cityName
            : '';
      }
    }
  });

  return selectedTerminalName;
};

export const formatVasForDisplay = async (vass, languageCode, deliveryAddress) => {
  const vasToDisplay = [];

  const uniqueVasArr = vass
    .map(eVas => eVas.vas)
    .filter((eachVasCode, index, vasCodeList) => vasCodeList.indexOf(eachVasCode) === index)
    .sort();

  uniqueVasArr.forEach(vasCode => {
    const groups = [];
    const selectedVas = vass.filter(sVas => sVas.vas === vasCode);

    const optionSchemaGroups = selectedVas.reduce((grpArr, vas) => {
      const grpKey = vas.groupIndex || 0;

      if (vas.vasField) {
        grpArr[grpKey] = [
          ...(grpArr[grpKey] || []),
          {
            ...vas,
          },
        ];
      }

      return grpArr;
    }, {});

    Object.keys(optionSchemaGroups).forEach(group => {
      const optionObj = optionSchemaGroups[group].reduce((optArr, vas) => {
        const optnKey = vas.optionIndex || 0;

        optArr[optnKey] = [
          ...(optArr[optnKey] || []),
          {
            ...vas,
          },
        ];

        return optArr;
      }, {});

      groups[group] = Object.values(optionObj);
    });

    vasToDisplay.push({ vasCode, groups });
  });

  const COT = vasToDisplay.find(vas => vas.vasCode === 'collectionAtTerminal');

  if (COT) {
    if (COT.groups && COT.groups[0] && COT.groups[0][0] && COT.groups[0][0][0]) {
      const terminal = await fetchCollectionAtTerminal(deliveryAddress, COT.groups[0][0][0].vasFieldValue);
      COT.groups[0][0][0].vasFieldValue = terminal;
    }
  }

  return vasToDisplay;
};

export const routeDetails = savedShipment => {
  let pickupAddress, deliveryAddress, differentPickupAddress, differentDeliveryAddress, servicePoint;

  pickupAddress = savedShipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'sender');
  deliveryAddress = savedShipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'receiver');

  differentPickupAddress = savedShipment.savedShipmentParties.find(
    party => party.type.toLowerCase() === 'differentsender',
  );
  servicePoint = savedShipment.savedShipmentParties.find(party => party.type.toLowerCase() === 'access point');

  if (servicePoint) {
    servicePoint.country = servicePoint.countryCode || '';
  } else {
    differentDeliveryAddress = savedShipment.savedShipmentParties.find(
      party => party.type.toLowerCase() === 'differentreceiver',
    );
  }

  if (pickupAddress) {
    pickupAddress.country = pickupAddress.countryCode || '';
    pickupAddress.reference = savedShipment.sreference || '';
    pickupAddress.isResidential = savedShipment.sresidential && !savedShipment.sdifferent;
  }

  if (deliveryAddress) {
    deliveryAddress.country = deliveryAddress.countryCode || '';
    deliveryAddress.reference = savedShipment.rreference || '';
    deliveryAddress.isResidential = savedShipment.rresidential && !savedShipment.rdifferent;
  }

  if (differentPickupAddress) {
    differentPickupAddress.country = differentPickupAddress.countryCode || '';
    differentPickupAddress.isResidential = savedShipment.sresidential && savedShipment.sdifferent;
  }

  if (differentDeliveryAddress) {
    differentDeliveryAddress.country = differentDeliveryAddress.countryCode || '';
    differentDeliveryAddress.isResidential = savedShipment.rresidential && savedShipment.rdifferent;
  }

  return { pickupAddress, deliveryAddress, differentPickupAddress, differentDeliveryAddress, servicePoint };
};

export const getAccountInfo = savedShipment => {
  const isInternational = savedShipment.route === INTERNATIONAL_SHIPMENT_RANGE;
  const isExport = savedShipment.importExport === EXPORT_SHIPMENT_TYPE;
  const isSenderPayer = savedShipment.whoPay === SENDER_PAYER;
  const isReceiverPayer = savedShipment.whoPay === RECEIVER_PAYER;
  const isThirdPartyPayer = savedShipment.whoPay === THIRD_PARTY_PAYER;
  const senderAccount = savedShipment.senderAccount;
  const otherAccount = savedShipment.otherAccount;

  const getCaseCode = () => {
    if (isInternational) {
      if (isExport && isReceiverPayer) return 'case1';
      if (isExport && isSenderPayer) return 'case2';
      if (isExport && isThirdPartyPayer) return 'case3';
      if (!isExport && isReceiverPayer) return 'case4';
      if (!isExport && isThirdPartyPayer) return 'case5';
      if (!isExport && isSenderPayer) return 'case9';
    } else {
      if (isSenderPayer) return 'case6';
      if (isReceiverPayer) return 'case7';
      if (isThirdPartyPayer) return 'case8';
    }
  };

  const fetchDisplayAccount = () => {
    const caseText = getCaseCode();

    switch (caseText) {
      case 'case1':
      case 'case2':
      case 'case6':
        return {
          'Sender Account': senderAccount,
        };

      case 'case3':
      case 'case8':
        return {
          'Sender Account': senderAccount,
          'Third Party Account': otherAccount,
        };

      case 'case4':
        return {
          'Receiver Account': senderAccount,
        };

      case 'case5':
        return {
          'Receiver Account': senderAccount,
          'Third Party Account': otherAccount,
        };

      case 'case7':
        return {
          'Sender Account': senderAccount,
          'Receiver Account': otherAccount,
        };

      default:
        return;
    }
  };

  return fetchDisplayAccount();
};
