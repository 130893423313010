import { apiLinks } from 'config/api-config';
import { checkFieldAndReturnState, checkGroupOfFieldsAndReturnState } from 'globals/utils/fieldValidations';
import { apiRequest } from 'globals/utils/requests';
import { CONSIGNOR_CUSTOMER_TYPE } from '../../../globals/constants';
import { CONST, config } from '../../data-config';

export const checkPickupDetails = context => {
  let hasErrors = false;
  let getState = {};
  let stateToSet = {};

  // TMS account check
  getState = checkFieldAndReturnState({
    name: 'tmsAccountNumber',
    value: context.state['tmsAccountNumber'].value,
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Pallet account check
  getState = checkFieldAndReturnState({
    name: 'palletAccountNumber',
    value: context.state['palletAccountNumber'].value,
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Number of pallets check
  getState = checkFieldAndReturnState({
    name: 'numberOfPallets',
    value: context.state['numberOfPallets'].value,
    isRequired: true,
    min: config.minNumberOfPallets,
    max: config.maxNumberOfPallets,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Country check
  getState = checkFieldAndReturnState({
    name: 'country',
    value: context.state['country'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{${config.minCountryLength},${config.maxCountryLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Company check
  getState = checkFieldAndReturnState({
    name: 'company',
    value: context.state['company'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Street check
  getState = checkFieldAndReturnState({
    name: 'street',
    value: context.state['street'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Postalcode check
  getState = checkFieldAndReturnState({
    name: 'postalCode',
    value: context.state['postalCode'].value,
    regEx: config.regEx.number,
    lengthCheck: [RegExp(`^.{1,${config.maxPostalCode}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // City check
  getState = checkFieldAndReturnState({
    name: 'city',
    value: context.state['city'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Name check
  getState = checkFieldAndReturnState({
    name: 'name',
    value: context.state['name'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp(`^.{1,${config.maxDefaultInputLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // E-mail check
  getState = checkFieldAndReturnState({
    name: 'email',
    value: context.state['email'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{0,${config.maxEmailLength}}$`)],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Confirmation E-mail check
  getState = checkFieldAndReturnState({
    name: 'confirmationEmail',
    value: context.state['confirmationEmail'].value,
    regEx: config.regEx.email,
    lengthCheck: [RegExp(`^.{1,${config.maxEmailLength}}$`)],
    isRequired: true,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  // Phone Numbers check
  getState = checkGroupOfFieldsAndReturnState({
    groupName: 'phoneNumbers',
    values: context.state['phoneNumbers'],
    fields: [
      {
        name: 'code',
        isRequired: true,
      },
      {
        name: 'number',
        regEx: config.regEx.number,
        lengthCheck: [/^.{1,21}$/],
        isRequired: true,
      },
    ],
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.groupName] = [...getState.values];

  // Instructions check
  getState = checkFieldAndReturnState({
    name: 'instructions',
    value: context.state['instructions'].value,
    regEx: config.regEx.everything,
    lengthCheck: [RegExp('[\\r\\n]{0,' + config.maxInstructionsLength + '}$')],
    isRequired: false,
  });
  hasErrors = getState.error || hasErrors;
  stateToSet[getState.name] = { value: getState.value, error: getState.error };

  return {
    hasErrors: hasErrors,
    stateToSet: stateToSet,
  };
};

export const prepareUserData = data => {
  const requestData = {};
  let party = {};

  requestData.productCode = config.palletPickupProductCode;
  requestData.pickupInstruction = data.instructions.value;
  requestData.totalNumberOfPieces = data.numberOfPallets.value;
  requestData.transactionId = CONST.PALLET_PICKUP_PREFIX + new Date().getTime();
  requestData.parties = [];
  party = {};
  party.id = data.tmsAccountNumber.value;
  party.type = '';
  party.name = data.name.value;
  party.companyName = data.company.value;
  party.accountNumber = data.palletAccountNumber.value;
  party.address = {};
  party.address.street = data.street.value;
  party.address.cityName = data.city.value;
  party.address.postalCode = data.postalCode.value;
  party.address.countryCode = data.country.value;
  party.phone = '';
  if (data.phoneNumbers && data.phoneNumbers.length > 0) {
    party.phone = `${data.phoneNumbers[0].code}-${data.phoneNumbers[0].number}`;
  }
  party.email = data.email.value;
  party.confirmationEmail = data.confirmationEmail.value;
  party.references = [''];
  party.type = CONSIGNOR_CUSTOMER_TYPE;
  requestData.parties.push(party);

  return requestData;
};

/**
 * To Valiate Post code based on country for Source and Destination.
 *
 * @param {*} countryCode Pickup Country code
 * @param {*} postalCode  Pickup Postal code
 */
export const postalCodeValidation = async (countryCode, postalCode) => {
  const apiPath = apiLinks.fetchCityName.replace('{countryCode}', countryCode).replace('{postalCode}', postalCode);

  const resultMap = {
    sourceBookable: false,
    data: {
      source: null,
    },
    status: null,
  };

  const doApiCall = url =>
    apiRequest(url)
      .then(result => {
        resultMap.status = result.status;
        if (result.status === CONST.STATUS_OK) {
          const data = result.data;
          if (data) {
            // prevent fail if null
            if (data.postalCode === null && data.bookable === null) {
              data.bookable = true;
            }
            resultMap.sourceBookable = data.bookable;
            resultMap.data.source = data;
          }
        } else {
          resultMap.error = true;
        }
        return result;
      })
      .catch(() => {
        resultMap.error = true;
      });

  await Promise.all([doApiCall(apiPath)]);

  return resultMap;
};
