import { Alert, Box, Collapse, Typography, Paper, Stack, Container } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails } from './ContactDetails';
import { ContactDetailsForm } from './ContactDetailsForm';
import { EmailPassword } from './EmailPassword';
import { PrintSettings } from './PrintSettings';
import { EmailPasswordForm } from './EmailPasswordForm';
import { PrintSettingsForm } from './PrintSettingsForm';
import { ShipmentTypes } from './ShipmentTypes';
import { ShipmentTypesForm } from './ShipmentTypesForm';
import { PalletAccounts } from './PalletAccounts';
import { UserStatictics } from './UserStatistics';
import type { ReadOnlySectionType, SetReadOnlySectionFunc } from './EditProfile.types';
import { useAppSelector } from 'store/hooks';
import { selectIsSwedishUser, useGetUserDataQuery } from 'store/api/fcpUser';
import { GlobalLoader } from '../GlobalLoader';
import { ApprovedAccounts } from './ApprovedAccounts';
import { AddWaitingAccounts } from './AddWaitingAccounts';

export const PROFILE_SECTIONS = {
  contactDetails: 'contactDetails',
  emailPassword: 'emailPassword',
  printSettings: 'printSettings',
  shipmentTypes: 'shipmentTypes',
};

export const EditProfile = () => {
  const { t } = useTranslation();
  const { isLoading: userLoading, data: userData, isError: userError } = useGetUserDataQuery();
  const isSwedishUser = useAppSelector(selectIsSwedishUser);

  const [readOnlySections, setReadOnlySections] = useState<Record<ReadOnlySectionType, boolean>>({
    contactDetails: true,
    emailPassword: true,
    printSettings: true,
    shipmentTypes: true,
  });

  const setReadOnly: SetReadOnlySectionFunc = useCallback(({ section, readOnly }) => {
    setReadOnlySections(prevState => ({
      ...prevState,
      [section]: readOnly,
    }));
  }, []);

  useEffect(() => {
    // scroll to # in Link if provided
    const anchor = window.location.hash.split('#')[1];
    if (anchor) {
      setTimeout(() => {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView();
        }
      }, 600);
    }
  }, []);

  return (
    <Box>
      <Typography variant="h1" my="32px">
        {t('general|pageTitles|userProfile')}
      </Typography>

      {userLoading && <GlobalLoader sx={{ background: 'rgba(0, 0, 0, 0.5)' }} />}

      {!userData && !userLoading && !userError && (
        <Container maxWidth="sm" sx={{ py: 4 }}>
          <Alert variant="standard" severity="info">
          {t('general|errors|noData')}
          </Alert>
        </Container>
      )}

      {userError && (
        <Container maxWidth="sm" sx={{ py: 4 }}>
          <Alert severity="error" variant="outlined">
            {t('general|errors|loadUserError')}
          </Alert>
        </Container>
      )}

      {userData && (
        <Stack spacing={4}>
          <ReadOnlySection
            title={t('registration|enterContactDetails')}
            readOnly={readOnlySections.contactDetails}
            readOnlyForm={<ContactDetails setReadOnly={setReadOnly} userData={userData} />}
            editableForm={<ContactDetailsForm setReadOnly={setReadOnly} userData={userData} />}
          />
          <ReadOnlySection
            title={t('editProfile|emailAndPassword')}
            readOnly={readOnlySections.emailPassword}
            readOnlyForm={<EmailPassword setReadOnly={setReadOnly} userData={userData} />}
            editableForm={<EmailPasswordForm setReadOnly={setReadOnly} userData={userData} />}
          />
          <ReadOnlySection
            title={t('editProfile|Print Settings')}
            readOnly={readOnlySections.printSettings}
            readOnlyForm={<PrintSettings setReadOnly={setReadOnly} userData={userData} />}
            editableForm={<PrintSettingsForm setReadOnly={setReadOnly} userData={userData} />}
          />

          {isSwedishUser && (
            <ProfileSection title={t('dashboard|Pallet Accounts')}>
              <SectionContent>
                <PalletAccounts />
              </SectionContent>
            </ProfileSection>
          )}

          {!isSwedishUser && (
            <ReadOnlySection
              title={t('editProfile|shipmentTypesFavourites')}
              subtitle={
                <Typography variant="body1" fontSize="14px" sx={{ mb: '16px', mt: '-8px' }}>
                  <em>{t('editProfile|shipmentTypesDescription')}</em>
                </Typography>
              }
              readOnly={readOnlySections.shipmentTypes}
              readOnlyForm={<ShipmentTypes setReadOnly={setReadOnly} userData={userData} />}
              editableForm={<ShipmentTypesForm setReadOnly={setReadOnly} userData={userData} />}
            />
          )}

          <ProfileSection title={t('editProfile|accountsTitle')}>
            <SectionContent>
              <ApprovedAccounts />
              <AddWaitingAccounts />
            </SectionContent>
          </ProfileSection>

          <ProfileSection title={t('editProfile|User Statistics')}>
            <SectionContent>
              <UserStatictics userData={userData} />
            </SectionContent>
          </ProfileSection>
        </Stack>
      )}
    </Box>
  );
};

const ProfileSection = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Box sx={{ bgcolor: 'background.section', p: '32px' }}>
      <Typography variant="h2" fontSize="16px">
        {title}
      </Typography>
      {subtitle}
      <Box sx={{ bgcolor: '#fff', p: '16px' }}>{children}</Box>
    </Box>
  );
};

const SectionContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Paper elevation={0} sx={{ py: '16px', m: 0 }}>
      {children}
    </Paper>
  );
};

const ReadOnlySection = ({
  title,
  subtitle,
  readOnly,
  readOnlyForm,
  editableForm,
}: {
  title: string;
  subtitle?: React.ReactNode;
  readOnly?: boolean;
  readOnlyForm: React.ReactNode;
  editableForm: React.ReactNode;
}) => {
  return (
    <ProfileSection title={title} subtitle={subtitle}>
      <Collapse mountOnEnter unmountOnExit in={readOnly}>
        <SectionContent>{readOnlyForm}</SectionContent>
      </Collapse>
      <Collapse mountOnEnter unmountOnExit in={!readOnly}>
        <SectionContent>{editableForm}</SectionContent>
      </Collapse>
    </ProfileSection>
  );
};
