import { Box, Typography, Portal, TextField, MenuItem, InputLabel, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGridApiContext } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import { BookingProgress } from '../bulk-booking-popups/BookingProgress';
import { BulkBookingSetup } from '../bulk-booking-popups/BulkBookingSetup';
import { BulkBookingComplete } from '../bulk-booking-popups/BulkBookingComplete';
import { useDismissCompletedBulkBookMutation, useGetBulkBookStatusQuery } from 'store/api/fcpBooking';
import { BulkBookStatusResponse, DocumentType, OnHoldShipmentListItem } from 'store/api/fcpBooking.types';
import { DAX_PRODUCT_CODES } from 'types/products';
import { GlobalLoader } from '../GlobalLoader';
import { useOnholdOrders } from './useOnholdOrders';

enum OnHoldBulkActionType {
  BOOK = 'book',
  MOVE = 'move',
}

type DocumentCount = Record<DocumentType, number | undefined>;

export const BulkActionsWrapper = ({ onBulkMove }: { onBulkMove: (requestIds: string[]) => void }) => {
  return (
    <Portal container={() => document.getElementById('actions-panel')!}>
      {/* <GridToolbarQuickFilter /> */}
      <OnHoldBulkActions onBulkMove={onBulkMove} />
    </Portal>
  );
};

const OnHoldBulkActions = ({ onBulkMove }: { onBulkMove: (requestIds: string[]) => void }) => {
  const [openBulkBookingSetup, setOpenBulkBookingSetup] = useState(false);
  const [openBulkBookingProgress, setOpenBulkBookingProgress] = useState(false);
  const [openBulkBookingComplete, setOpenBulkBookingComplete] = useState(false);
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const selectedRows = apiRef.current.getSelectedRows();
  const { isBulkBookingDisabled, isBulkMoveDisabled } = useOnholdOrders();

  const prepareDocumentList = (bulkBookStatus?: BulkBookStatusResponse) => {
    if (!bulkBookStatus?.bulkBooking?.result?.documentCount) {
      return [];
    }

    const documentCount: DocumentCount = bulkBookStatus.bulkBooking.result.documentCount as DocumentCount;

    const documentList = [
      {
        label: t('general|Label'),
        count: documentCount[DocumentType.LABEL] ?? 0,
        documentType: DocumentType.LABEL,
      },
      {
        label: t('general|Waybill'),
        count: documentCount[DocumentType.WAYBILL] ?? 0,
        documentType: DocumentType.WAYBILL,
      },
      {
        label: t('general|Shipment List'),
        count: documentCount[DocumentType.SHIPMENT_LIST] ?? 0,
        documentType: DocumentType.SHIPMENT_LIST,
      },
      {
        label: t('general|returnLabel'),
        count: documentCount[DocumentType.RETURN_LABEL] ?? 0,
        documentType: DocumentType.RETURN_LABEL,
      },
      {
        label: t('general|documentType|pre7'),
        count: documentCount[DocumentType.PRE_7] ?? 0,
        documentType: DocumentType.PRE_7,
      },
      {
        label: t('general|documentType|pre10'),
        count: documentCount[DocumentType.PRE_10] ?? 0,
        documentType: DocumentType.PRE_10,
      },
      {
        label: t('general|documentType|pre12'),
        count: documentCount[DocumentType.PRE_12] ?? 0,
        documentType: DocumentType.PRE_12,
      },
    ];

    return documentList.filter(doc => doc.count > 0);
  };

  const selectedShipments: OnHoldShipmentListItem[] = useMemo(() => {
    return Array.from(selectedRows.values()) as OnHoldShipmentListItem[];
  }, [selectedRows]);

  const atLeastOneProductNotDax = useMemo(() => {
    return selectedShipments.some(item => {
      const productCode = item.productCode;
      return productCode !== undefined && !(productCode in DAX_PRODUCT_CODES);
    });
  }, [selectedShipments]);

  const daxProductsAndAtLeastOnePartiSelected = useMemo(() => {
    const allAreDaxProducts = selectedShipments.every(item => {
      const productCode = item.productCode;
      return productCode !== undefined && productCode in DAX_PRODUCT_CODES;
    });

    const atLeastOnePartiSelected = selectedShipments.some(item => {
      const productCode = item.productCode;
      return productCode !== undefined && productCode === DAX_PRODUCT_CODES['212'];
    });

    return allAreDaxProducts && atLeastOnePartiSelected;
  }, [selectedShipments]);

  const requestIds = useMemo(() => {
    return selectedShipments.map(row => row.requestId);
  }, [selectedShipments]);

  const resolveBulkBookStatus = (bulkBookStatus?: BulkBookStatusResponse) => {
    const bulkBooking = bulkBookStatus?.bulkBooking;
    if (!bulkBooking) {
      return;
    }
    if (bulkBooking.result) {
      setOpenBulkBookingComplete(true);
      setOpenBulkBookingProgress(false);
    } else {
      setOpenBulkBookingComplete(false);
      setOpenBulkBookingProgress(true);
    }
  };

  const {
    data: bulkBookStatusResponse,
    isLoading: bulkBookingIsLoading, // true only when the query is called for the first time
  } = useGetBulkBookStatusQuery(undefined, {
    pollingInterval: openBulkBookingProgress ? 1000 : 0,
  });

  const [bulkBookStatus, setBulkBookStatus] = useState<BulkBookStatusResponse>();
  const [dismissCompletedBulkBookResult, { isLoading: dismissCompletdeBulkBookInProgress }] =
    useDismissCompletedBulkBookMutation();

  useEffect(() => {
    setBulkBookStatus(bulkBookStatusResponse);
  }, [bulkBookStatusResponse]);

  useEffect(() => {
    resolveBulkBookStatus(bulkBookStatus);
  }, [bulkBookStatus]);

  return (
    <>
      {bulkBookingIsLoading ? <GlobalLoader /> : null}
      <BulkBookingSetup
        open={openBulkBookingSetup}
        requestIds={requestIds}
        selectedShipments={selectedShipments}
        onClose={() => {
          setOpenBulkBookingSetup(false);
        }}
        onSubmit={bulkBookStatus => {
          setOpenBulkBookingSetup(false);
          setBulkBookStatus(bulkBookStatus);
        }}
        atLeastOneProductNotDax={atLeastOneProductNotDax}
        daxProductsAndAtLeastOnePartiSelected={daxProductsAndAtLeastOnePartiSelected}
      />
      <BookingProgress
        open={openBulkBookingProgress}
        shipmentProcessedCount={bulkBookStatus?.bulkBooking?.shipmentProcessedCount}
        shipmentTotalCount={bulkBookStatus?.bulkBooking?.shipmentTotalCount}
        elapsedTimeInMillis={bulkBookStatus?.bulkBooking?.elapsedTimeInMillis}
        createdAt={bulkBookStatus?.bulkBooking?.createdAt}
        onClose={() => {
          setOpenBulkBookingProgress(false);
        }}
      />
      {bulkBookStatus?.bulkBooking ? (
        <BulkBookingComplete
          bulkBooking={bulkBookStatus?.bulkBooking}
          bulkBookingId={bulkBookStatus?.bulkBooking?.bulkBookingId}
          pickupDateMoved={bulkBookStatus?.bulkBooking?.result?.pickupDateMoved ?? false}
          open={openBulkBookingComplete}
          bookedCount={bulkBookStatus?.bulkBooking?.result?.successCount}
          shipmentCount={bulkBookStatus?.bulkBooking?.shipmentTotalCount}
          documentList={prepareDocumentList(bulkBookStatus)}
          onClose={async () => {
            await dismissCompletedBulkBookResult();
            setOpenBulkBookingComplete(false);
          }}
          dismissCompletdeBulkBookInProgress={dismissCompletdeBulkBookInProgress}
        />
      ) : null}

      <Box display="flex" gap={2} alignItems="center">
        <InputLabel htmlFor="bulk-actions-select" disabled={!selectedRows.size}>{`${t(
          'general|onholdOrders|bulkActions',
        )}:`}</InputLabel>
        <TextField
          id="bulk-actions-select"
          value=""
          select
          disabled={!selectedRows.size}
          variant="outlined"
          size="small"
          sx={{ minWidth: 150 }}
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: any) => {
              if (!value) {
                return (
                  <Typography color="gray" data-testid="bulkaction-onholdOrders-selectedAction">
                    {t('general|labels|inputs|Select')}
                  </Typography>
                );
              }
              // Careful here! Dynamic translation
              return (
                <span data-testid="bulkaction-onholdOrders-selectedAction">{t(`general|onholdOrders|${value}`)}</span>
              );
            },
          }}
        >
          <MenuItem
            value={OnHoldBulkActionType.MOVE}
            onClick={() => onBulkMove(requestIds)}
            disabled={isBulkMoveDisabled}
            data-testid="bulkaction-onholdOrders-move"
          >
            <ListItemIcon>
              <CachedIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('general|onholdOrders|move')}</ListItemText>
          </MenuItem>
          <MenuItem
            value={OnHoldBulkActionType.BOOK}
            onClick={() => setOpenBulkBookingSetup(true)}
            disabled={isBulkBookingDisabled}
            data-testid="bulkaction-onholdOrders-book"
          >
            <ListItemIcon>
              <SaveIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText>{t('general|onholdOrders|book')}</ListItemText>
          </MenuItem>
        </TextField>
      </Box>
    </>
  );
};
