import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { PickupDate } from '../PickupDate';
import { Address } from '../Address';
import { AddressSearchDialog } from '../AddressSearchDialog';
import { AddressType } from 'store/api/fcpAddressBook';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';
import { COUNTRY_SELECT_OPTIONS } from 'locale/config';
import { useBookOnHoldShipmentsMutation } from 'store/api/fcpBooking';
import { BulkBookStatusResponse, OnHoldShipmentListItem } from 'store/api/fcpBooking.types';
import { toast } from 'react-toastify';
import { PickupDatesPayloadItem, useGetPickupDatesMergedQuery } from '../../../store/api/fcpQuote';
import { ControlledTextField } from '../form-inputs';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { globalConfig } from 'globals/data-config-global';

type PickupFormProps = {
  open: boolean;
  requestIds: string[];
  onClose: () => void;
  onSubmit: (bulkBookStatus: BulkBookStatusResponse) => void;
  atLeastOneProductNotDax?: boolean;
  daxProductsAndAtLeastOnePartiSelected?: boolean; // parti product code is 212
  selectedShipments: OnHoldShipmentListItem[];
};

export enum BOOKING_SETUP_MODES {
  INDIVIDUAL_PICKUP_DATES = 'INDIVIDUAL_PICKUP_DATES',
  DO_NOT_SCHEDULE_PICKUP = 'DO_NOT_SCHEDULE_PICKUP',
  ONE_PICKUP_FOR_ALL_BOOKINGS = 'ONE_PICKUP_FOR_ALL_BOOKINGS',
}
export type BookingSetupMode = keyof typeof BOOKING_SETUP_MODES;

// Example:
// {
//   "code": "AE",
//   "phone": "+971",
//   "label": "+971"
// }
type PhoneCodeOption = {
  code: string;
  phone: string;
  label: string;
};

export type PickupFormValues = {
  // pickup instructions radio
  pickupOption: BookingSetupMode;
  keepExistingPickupDate: boolean;

  // pickup address fields
  senderField: string;
  streetField: string;
  postalCodeField: string;
  cityField: string;
  countryField: string;
  nameField: string;
  emailField: string;
  phoneCountryField: PhoneCodeOption | undefined;
  phoneNumberField: string;

  // shipping documents checkbox
  sendDocuments: boolean;
  pickupInstructionsField: string;
};

const BulkBookingSetupContent: React.FC<PickupFormProps> = ({
  requestIds,
  onClose,
  onSubmit,
  atLeastOneProductNotDax,
  daxProductsAndAtLeastOnePartiSelected,
  selectedShipments,
}) => {
  const { country: accountCountryCode, language } = useCountryLanguage();

  const getPickupDatesPayloadForIndividual = useCallback((): PickupDatesPayloadItem[] => {
    return selectedShipments.map((shipment: OnHoldShipmentListItem) => {
      return {
        accountNumber: shipment.accountNumber,
        productCode: shipment.productCode,
        countryFrom: shipment.senderCountryCode,
        zipcodeFrom: shipment.senderPostalCode,
        countryTo: shipment.receiverCountryCode,
        zipcodeTo: shipment.receiverPostalCode,
      };
    });
  }, [selectedShipments]);

  const [pickupDatesPayload, setPickupDatesPayload] = useState<PickupDatesPayloadItem[]>(
    getPickupDatesPayloadForIndividual(),
  );

  const { data: pickupDatesResponse } = useGetPickupDatesMergedQuery({
    pickupDatesPayload,
    countryCode: accountCountryCode ?? '',
  });

  const { t } = useTranslation();

  const [pickupDates, setPickupDates] = useState<string[]>([]);
  const [pickupDateOnePickupForAllBookingsDisabled, setPickupDateOnePickupForAllBookingsDisabled] =
    useState<boolean>(true);
  const [selectedPickupDate, setSelectedPickupDate] = useState<string | undefined>(
    pickupDates ? pickupDates[0] : undefined,
  );
  const formContext = useForm<PickupFormValues>({
    defaultValues: {
      pickupOption: BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES,
      sendDocuments: false,
      keepExistingPickupDate: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { control, handleSubmit, setValue } = formContext;

  const pickupOption = useWatch({
    control,
    name: 'pickupOption',
  });

  const postalCodeField = useWatch({
    control,
    name: 'postalCodeField',
  });

  const countryField = useWatch({
    control,
    name: 'countryField',
  });

  const [submitBulkBooking, { isLoading: bulkBookingInProgress }] = useBookOnHoldShipmentsMutation();

  const handleFormSubmit = async (data: PickupFormValues) => {
    if (accountCountryCode) {
      const response = await submitBulkBooking({
        accountCountryCode,
        language,
        requestIds,
        pickupOption: data.pickupOption,
        pickupDate:
          pickupOption === BOOKING_SETUP_MODES.DO_NOT_SCHEDULE_PICKUP || !selectedPickupDate
            ? null
            : selectedPickupDate,
        pickupInstructions: data.pickupInstructionsField,
        keepExistingPickupDate:
          pickupOption === BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES ? data.keepExistingPickupDate : null,
        address:
          data.pickupOption === 'ONE_PICKUP_FOR_ALL_BOOKINGS'
            ? {
                sender: data.senderField,
                street: data.streetField,
                postalCode: data.postalCodeField,
                city: data.cityField,
                country: data.countryField,
                name: data.nameField,
                email: data.emailField,
                phoneCountryPrefix: data.phoneCountryField?.phone ?? '',
                phoneNumber: data.phoneNumberField,
              }
            : null,
        sendDocuments: data.sendDocuments,
      });
      if (response.error) {
        console.error('Unable to submit bulk booking', response.error);
        if ('status' in response.error && response.error.status === 409) {
          toast(
            <Alert severity="error" variant="standard">
              {t('general|onholdOrders|bulkBookingConflict')}
            </Alert>,
          );
        }
      } else if (response.data) {
        onSubmit(response.data);
      }
    }
  };
  const [openAddressSearch, setOpenAddressSearch] = useState(false);

  const handleOpenAddressSearchDialog = () => setOpenAddressSearch(true);
  const handleCloseAddressSearchDialog = () => setOpenAddressSearch(false);

  const handleTileClick = (address: AddressType) => {
    const phoneNumber = preparePhoneNumber(address.phone);

    const phoneCountryOption = COUNTRY_SELECT_OPTIONS.find(option => `+${option.phone}` === phoneNumber.code);

    handleCloseAddressSearchDialog();
    setValue('senderField', address.companyName);
    setValue('streetField', address.street);
    setValue('postalCodeField', address.postalCode);
    setValue('cityField', address.city);
    setValue('countryField', address.countryCode);
    setValue('nameField', address.name);
    setValue('emailField', address.email);
    setValue(
      'phoneCountryField',
      phoneCountryOption
        ? {
            code: `${phoneCountryOption.code}`,
            phone: `+${phoneCountryOption.phone}`,
            label: `+${phoneCountryOption.phone}`,
          }
        : undefined,
    );
    setValue('phoneNumberField', phoneNumber.number);
  };

  useEffect(() => {
    if (pickupDatesResponse && pickupDatesResponse.pickupDates) {
      setPickupDates(pickupDatesResponse.pickupDates);
      setSelectedPickupDate(pickupDatesResponse.pickupDates[0]);
    }
  }, [pickupDatesResponse]);

  useEffect(() => {
    if (pickupOption === BOOKING_SETUP_MODES.ONE_PICKUP_FOR_ALL_BOOKINGS) {
      setPickupDateOnePickupForAllBookingsDisabled(postalCodeField === undefined || postalCodeField === '');
    }
    if (pickupOption === BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES) {
      setPickupDatesPayload(getPickupDatesPayloadForIndividual());
    }
  }, [pickupOption, postalCodeField, getPickupDatesPayloadForIndividual]);

  return (
    <>
      <AddressSearchDialog
        open={openAddressSearch}
        onClose={handleCloseAddressSearchDialog}
        onTileClick={handleTileClick}
      />

      <DialogTitle
        sx={{
          textAlign: 'left',
          pb: '4px',
        }}
      >
        {t('general|dialogTitle', { shipmentCount: requestIds.length })}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 26, top: 26 }}
          data-testid="bulkBookingSetup-closeicon"
        >
          <CloseIcon
            sx={{
              fontSize: '25px',
            }}
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          minHeight: '440px',
        }}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Typography data-testid="bulkBookingSetup-selectPickupOption">{t('general|selectPickupOption')}</Typography>

          <Typography variant="h4" sx={{ mt: '32px' }}>
            {t('general|setPickupDate')}
          </Typography>
          <Controller
            name="pickupOption"
            control={control}
            defaultValue={BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES}
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value={BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES}
                  control={<Radio data-testid="bulkBookingSetup-INDIVIDUAL_PICKUP_DATES" />}
                  label={t('general|defaultPickup')}
                />
                {pickupOption === BOOKING_SETUP_MODES.INDIVIDUAL_PICKUP_DATES ? (
                  <Stack
                    direction="column"
                    gap="16px"
                    sx={{
                      ml: '48px',
                    }}
                  >
                    <Box
                      sx={{
                        mt: '8px',
                      }}
                    >
                      <Typography variant="h4" mb="8px">
                        {t('general|Pickup Date')}
                      </Typography>
                      <PickupDate
                        pickupDates={pickupDates ? pickupDates : []}
                        selectedPickupDate={selectedPickupDate}
                        setSelectedPickupDate={setSelectedPickupDate}
                        id="bulkBookingSetup-pickupDate"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Controller
                          name="keepExistingPickupDate"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={field.value}
                              data-testid="bulkBookingSetup-keepExistingPickupDate"
                            />
                          )}
                        />
                      }
                      label={t('general|onholdOrders|keepExistingPickupDate')}
                    />
                  </Stack>
                ) : null}
                <FormControlLabel
                  value={BOOKING_SETUP_MODES.DO_NOT_SCHEDULE_PICKUP}
                  control={<Radio data-testid="bulkBookingSetup-DO_NOT_SCHEDULE_PICKUP" />}
                  sx={{ alignItems: 'flex-start' }}
                  label={
                    <Stack component="span" direction="column" sx={{ marginTop: '8px' }}>
                      {t('general|noPickup')}
                      {atLeastOneProductNotDax ? (
                        <Typography
                          variant="body2"
                          color="error"
                          component="span"
                          data-testid="bulkBookingSetup-shipmentWithMandatoryPickupSelected"
                        >
                          {t('general|shipmentWithMandatoryPickupSelected')}
                        </Typography>
                      ) : null}
                    </Stack>
                  }
                  disabled={atLeastOneProductNotDax}
                />
                <FormControlLabel
                  value={BOOKING_SETUP_MODES.ONE_PICKUP_FOR_ALL_BOOKINGS}
                  control={<Radio data-testid="bulkBookingSetup-ONE_PICKUP_FOR_ALL_BOOKINGS" />}
                  sx={{ alignItems: 'flex-start' }}
                  label={
                    <Stack component="span" direction="column" sx={{ marginTop: '8px' }}>
                      {t('general|schedulePickup')}
                      {pickupOption === BOOKING_SETUP_MODES.ONE_PICKUP_FOR_ALL_BOOKINGS ? (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="span"
                          data-testid="bulkBookingSetup-onePickupLocation"
                        >
                          {t('general|onePickupLocation')}
                        </Typography>
                      ) : null}
                      {atLeastOneProductNotDax ? (
                        <Typography
                          variant="body2"
                          color="error"
                          component="span"
                          data-testid="bulkBookingSetup-shipmentWithoutChangePickupInstructionsSelected"
                        >
                          {t('general|shipmentWithoutChangePickupInstructionsSelected')}
                        </Typography>
                      ) : null}
                      {daxProductsAndAtLeastOnePartiSelected ? (
                        <Typography
                          variant="body2"
                          color="error"
                          component="span"
                          data-testid="bulkBookingSetup-moreThanOnePickupWarning"
                        >
                          {t('general|moreThanOnePickupWarning')}
                        </Typography>
                      ) : null}
                    </Stack>
                  }
                  disabled={atLeastOneProductNotDax}
                />
              </RadioGroup>
            )}
          />
          {pickupOption === BOOKING_SETUP_MODES.ONE_PICKUP_FOR_ALL_BOOKINGS ? (
            <Box
              sx={{
                mt: '32px',
              }}
            >
              <Box
                sx={{
                  mt: '32px',
                  width: '100%',
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h4">{t('general|Pickup Address')}</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ContactMailIcon />}
                    onClick={handleOpenAddressSearchDialog}
                    data-testid="bulkBookingSetup-adressBookButton"
                  >
                    {t(`general|Address Book`)}
                  </Button>
                </Stack>
                <Address
                  formContext={formContext}
                  senderField="senderField"
                  streetField="streetField"
                  postalCodeField="postalCodeField"
                  cityField="cityField"
                  countryField="countryField"
                  nameField="nameField"
                  emailField="emailField"
                  phoneCountryField="phoneCountryField"
                  phoneNumberField="phoneNumberField"
                  onPostalCodeValidated={({ deviating, city }) => {
                    // TODO: jm - maybe improve validation?
                    // dispatch(postalCodeValidated({ direction: 'from', deviating, city }))
                    // when postal code is valid -> call get pickup dates to update datepicker calendar

                    setPickupDatesPayload(
                      selectedShipments.map((shipment: OnHoldShipmentListItem) => {
                        return {
                          accountNumber: shipment.accountNumber,
                          productCode: shipment.productCode,
                          countryFrom: countryField,
                          zipcodeFrom: postalCodeField,
                          countryTo: shipment.receiverCountryCode,
                          zipcodeTo: shipment.receiverPostalCode,
                        };
                      }),
                    );

                    setPickupDateOnePickupForAllBookingsDisabled(false);
                  }}
                  countryDisabled={true} // disabled due to 3rd option (schedule 1 pickup for all) is possible only for SE
                  testIdPrefix="pickup"
                />
              </Box>
              <Box
                sx={{
                  mt: '32px',
                }}
              >
                <Typography variant="h4" mb="8px">
                  {t('general|Pickup Date')}
                </Typography>
                <PickupDate
                  pickupDates={pickupDates ? pickupDates : []}
                  selectedPickupDate={selectedPickupDate}
                  setSelectedPickupDate={setSelectedPickupDate}
                  disabled={pickupDateOnePickupForAllBookingsDisabled}
                />
              </Box>
              <Box
                sx={{
                  mt: '32px',
                }}
              >
                <ControlledTextField
                  control={formContext.control}
                  label={t('general|Add Pickup Instructions (Optional)')}
                  fieldName={'pickupInstructionsField'}
                  testId={'pickupInstructions'}
                  TextFieldProps={{
                    inputProps: { maxLength: globalConfig.maxInstructionsLength },
                    multiline: true,
                    maxRows: 5,
                  }}
                />
              </Box>
            </Box>
          ) : null}

          <Typography variant="h4" sx={{ mt: '32px' }}>
            {t('general|shippingDocuments')}
          </Typography>
          <Controller
            name="sendDocuments"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel control={<Checkbox {...field} />} label={t('general|sendDocuments')} />
            )}
          />
        </form>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'space-between',
          borderTop: '1px solid #E0E0E0',
          pb: 0,
          pt: '16px',
          mx: '-16px',
          px: '36px',
        }}
      >
        <Button onClick={onClose} color="primary" variant="outlined">
          {t('general|labels|buttons|Cancel')}
        </Button>
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          color="primary"
          variant="contained"
          disabled={bulkBookingInProgress}
          endIcon={bulkBookingInProgress ? <CircularProgress color="inherit" size="20px" /> : undefined}
        >
          {t('general|bookShipments', { shipmentCount: requestIds.length })}
        </Button>
      </DialogActions>
    </>
  );
};

export const BulkBookingSetup: React.FC<PickupFormProps> = props => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <BulkBookingSetupContent {...props} />
    </Dialog>
  );
};
