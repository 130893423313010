import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation';
import { TermsAndConditions } from '../TermsAndSubmit/TermsAndConditions';
import ReturnShipment from '../ReturnShipment/ReturnShipment';
import './ReviewAndBook.css';
import UploadDocuments from './UploadDocuments/UploadDocuments';
import PriceBreakdown from './PriceBreakdown/PriceBreakdown';
import { apiRequest, getCookie } from 'globals/utils/requests';
import { formatDate } from 'globals/utils/formatting';
import { objectIsEmpty } from 'globals/utils/fieldValidations';
import { hidePricesDueToPayerType } from 'globals/utils/filtering';
import { formShipmentQuoteData } from './FormSubmitData/FormSubmitData';
import { Error } from 'components';
import { analytics } from 'globals/utils/analytics';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import { onSaveForFutureClick, prepareSavedShipmentData } from './../../helpers/savedShipments';
import {
  getThankYouStatus,
  resetTermsAndConditions,
  setAddressCookie,
  setReturnShipmentCookie,
  setSendNotificationsCookie,
  validateAndSubmit,
} from './ReviewAndBook-helpers';
import { formQuoteSummaryPrice } from './PriceBreakdown/PriceBreakdown-helpers';
import {
  fromDateComponent,
  reviewAndBookDatePresenter,
  toDateComponent,
} from '../../../globals/helpers/side-summary-helpers';
import { quoteSummaryToPriceBreakdown } from '../../helpers/priceBreakdown';

const ENV_DEV = process.env.NODE_ENV === 'development';

class ReviewAndBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookShipment: false,
      generalError: false,
      fixedDate: false,
      quoteSummary: {},
      timeDefiniteUnloading: false,
      uploadError: false,
      saveForFutureError: false,
      saveShipmentsError: false,
      saveForFutureLoader: false,
    };

    this.UploadDocumentsRef = React.createRef();
    this._isMounted = false;
  }

  setInitialState = () => {
    resetTermsAndConditions(this.props.context);
    this.setFDDOrTDDDate();
  };

  setFDDOrTDDDate = () => {
    // set fixed delivery date if vas present
    const fixedDateVas = this.props.context.state.additionalServices.find(
      vas => vas.code === 'fixedDeliveryDate' && vas.value,
    );

    if (fixedDateVas && fixedDateVas.groups && fixedDateVas.groups.length > 0) {
      fixedDateVas.groups.forEach(group => {
        group.options.forEach(option => {
          const FDDOption = option.find(optionSchema => optionSchema.type === 'LTCdate');

          if (FDDOption && FDDOption.value) {
            this.setState({ fixedDate: formatDate(FDDOption.value) });
          }
        });
      });
    }

    // set time definite unloading
    const timeDefiniteUnloading = this.props.context.state.additionalServices.find(
      vas => vas.code === 'timeDefiniteUnloading' && vas.value,
    );

    if (timeDefiniteUnloading && timeDefiniteUnloading.groups && timeDefiniteUnloading.groups.length > 0) {
      timeDefiniteUnloading.groups.forEach(group => {
        group.options.forEach(option => {
          const TDDOption = option.find(optionSchema => optionSchema.type === 'DateTime');

          if (TDDOption && TDDOption.value) {
            this.setState({ timeDefiniteUnloading: formatDate(TDDOption.value) });
          }
        });
      });
    }
  };

  componentDidMount = () => {
    this._isMounted = true;
    this.setInitialState();
    // set tab 4 touched
    this.props.context.updateState({ tab4OrTab5Touched: true });
    if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);

    // Formats quote summary
    const context = this.props.context;
    const quoteSummary =
      context.state.quotePriceMap &&
      !objectIsEmpty(context.state.quotePriceMap) &&
      formQuoteSummaryPrice(
        JSON.parse(JSON.stringify(context.state.quotePriceMap)),
        context.state.additionalServices,
        context.state.selectedProduct,
      );

    this.setState({
      quoteSummary,
    });
    context.state.returnShipmentRequested.value = getCookie('returnShipmentRequested');
  };

  submitOrder = async uploadDocResult => {
    let submitShipment = {};
    const shipmentData = formShipmentQuoteData(this.props.context.state);
    const shouldPickupRequestCondition = shipmentData.shipment.isPickupRequired;
    const headerParams = {
      transactionId: this.props.context.state.transactionId,
    };

    // Set UniqueCode
    if (uploadDocResult && uploadDocResult.result && uploadDocResult.uploadId) {
      headerParams.UniqueCode = uploadDocResult.uploadId;
    }

    setAddressCookie(shipmentData);
    setReturnShipmentCookie(shipmentData);
    setSendNotificationsCookie(this.props.context);

    shipmentData.savedShipmentData = prepareSavedShipmentData(this.props.context.state, 0);
    shipmentData.savedShipmentData.savedShipmentUploadedFiles = uploadDocResult.savedShipmentUploadedFiles;
    shipmentData.savedShipmentData.savedShipmentPriceBreakdown = this.state.quoteSummary
        ? quoteSummaryToPriceBreakdown(this.state.quoteSummary)
        : null;
    const shipmentRequest = apiRequest(apiLinks.postPortalOrder, 'POST', {
      body: shipmentData,
      headers: headerParams,
    });

    await shipmentRequest.then(result => {
      if (this._isMounted) {
        submitShipment = result;
      }
    });
    if (this._isMounted) {
      if (submitShipment.status === CONST.STATUS_OK) {
        ENV_DEV && console.success('API Request successful ...');
        analytics('order', CONST.ANALYTICS);

        this.setState(
          {
            bookShipment: false,
          },
          () => {
            getThankYouStatus(
              submitShipment.data,
              shouldPickupRequestCondition,
              shipmentData.shipment.shippingDate,
              this.props,
              this.state,
            );
          },
        );
      } else if (submitShipment.status === 403) {
        this.captchaReload();
      } else {
        this.setShipmentAndGeneralError(false, false);
        this.props.context.updateState({ bookShipmentAPIError: submitShipment.data });
      }
    }
  };

  handleSaveForFutureClick = async t => {
    this.setState({ saveForFutureLoader: true }, async () => {
      const result = await onSaveForFutureClick(this.props.context, t);

      if (result.error) {
        this.setState({ saveForFutureError: true, saveForFutureLoader: false });
      }
    });
  };

  onGoClick = async () => {
    this.setState({
      bookShipment: true,
      generalError: false,
    });
    this.props.context.updateState({ bookShipmentAPIError: false });

    if (!validateAndSubmit(this.props)) {
      this.setState({
        generalError: false,
      });

      let uploadDocresult = {};

      disableOtherTabs(this.props, true, [5], config.tabs.tab5);

      uploadDocresult = await this.UploadDocumentsRef.current.uploadToServer({
        answer: this.props.context.state.captcha.value,
        token: this.props.context.state.captcha.token,
      });

      if (uploadDocresult && uploadDocresult.result) {
        await this.submitOrder(uploadDocresult);
      } else if (uploadDocresult && uploadDocresult.captchaError) {
        this.captchaReload();
      } else if (uploadDocresult && !uploadDocresult.result) {
        this.setState({ bookShipment: false, uploadError: true });
      } else {
        this.setShipmentAndGeneralError(false, false);
        this.props.context.updateState({ bookShipmentAPIError: true });
      }
    } else {
      this.setShipmentAndGeneralError(false, true);
    }

    if (this._isMounted) {
      disableOtherTabs(this.props, false, [5], config.tabs.tab5);
    }
  };

  setShipmentAndGeneralError = (bookShipment, generalError) => {
    this.setState({
      bookShipment: bookShipment,
      generalError: generalError,
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  chargeableWeightFromQuoteOrCalculation = (state, weight, t) => {
    const { quoteSummary } = this.state;
    const { selectedProduct } = state;

    const chargedWeightFromQuote =
      quoteSummary && quoteSummary.ChargedWeight && selectedProduct.code !== config.dhlPALL
        ? quoteSummary.ChargedWeight.calculatedResult
        : null;

    const calculatedChargeableWeight = state.chargeableWeight
      ? state.chargeableWeight.toFixed(config.chargeableWeight.maxDecimals) + ' ' + t('general|kg')
      : weight + ' ' + t('general|kg');

    return chargedWeightFromQuote ? chargedWeightFromQuote : calculatedChargeableWeight;
  };

  renderErrorMessage = (state, t) => {
    if (this.state.generalError) {
      return t('general|errors|Please fill properly all necessary fields');
    }
    if (state.bookShipmentAPIError) {
      const message = state.bookShipmentAPIError.translatedMessage
        ? t(
            state.bookShipmentAPIError.translatedMessage.phraseCode,
            state.bookShipmentAPIError.translatedMessage.parameters,
          )
        : t('general|errors|General error');
      return (
        message +
        '. <br> ' +
        t('general|Try again or call technical support.') +
        ' ' +
        t('general|or') +
        ' ' +
        `<strong><a href="${t('pageLinks|chat')}"
                                              class ="frc__chat-with-us"
                                              target="_blank" rel="noopener noreferrer">${t(
                                                `general|Chat with us`,
                                              )}</a></strong>` +
        ' <br> ' +
        t('general|ID') +
        ': ' +
        state.transactionId
      );
    }
    if (this.state.saveShipmentsError || this.state.saveForFutureError) {
      return t(`portalOrder|Shipment could not be saved`);
    }
    if (this.state.uploadError) {
      return (
        t('general|Unsupported file format. Please use one of following') +
        ': ' +
        t('portalOrder|pdf, doc, xls, docx, xlsx, csv')
      );
    }
    return '';
  };

  render() {
    let weight = null;
    const { state } = this.props.context;

    if (state.totals.shipmentDetailsRows && state.totals.shipmentDetailsRows.weight.value)
      weight = state.totals.shipmentDetailsRows.weight.value;

    const { selectedProduct } = state;
    const disableBtn = this.state.bookShipment || this.state.saveForFutureLoader;
    const showPrices = !hidePricesDueToPayerType(state);
    const returnShipmentEnabled = config.productsReturnShipmentAvailable.includes(selectedProduct.code);

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <section className="frc__review-summary">
                <h4 className="frc__review-summary__title">{selectedProduct.product}</h4>

                <div className="frc__review-summary__box" id="summaryBox1">
                  <span>{t(`general|Chargeable weight`)}:</span>
                  <strong>{this.chargeableWeightFromQuoteOrCalculation(state, weight, t)}</strong>
                </div>

                <div className="frc__review-summary__box" id="summaryBox2">
                  {fromDateComponent(state, reviewAndBookDatePresenter)}
                </div>

                <div className="frc__review-summary__box" id="summaryBox3">
                  {toDateComponent(state, reviewAndBookDatePresenter)}
                </div>
              </section>

              {state.quotePriceMap && Object.keys(state.quotePriceMap).length > 0 && showPrices && (
                <PriceBreakdown quoteSummary={this.state.quoteSummary} />
              )}

              <UploadDocuments context={this.props.context} ref={this.UploadDocumentsRef} />

              <BookingConfirmation context={this.props.context} />
              {returnShipmentEnabled && <ReturnShipment context={this.props.context} />}
              <TermsAndConditions />

              <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper frc__spacebetween-firstleft">
                <button
                  id="backToSchedulePickup"
                  className={`base-button base-button--white l-grid--w-25pc-w  ${disableBtn ? 'disabled' : ''} `}
                  onClick={() =>
                    disableBtn
                      ? null
                      : state.returnProdStatus
                      ? this.props.previousTab(config.tabs.tab3, 3)
                      : this.props.previousTab(config.tabs.tab4, 4)
                  }
                  disabled={disableBtn}
                >
                  {state.returnProdStatus ? (
                    <span>{t(`general|Back to Delivery Options`)}</span>
                  ) : (
                    <span>{t(`general|Back to Schedule Pickup`)}</span>
                  )}
                </button>

                <button
                  id="saveForFutureBtn"
                  className={
                    'base-button base-button--white l-grid--w-25pc-w frc__saveforfuturebtn ' +
                    (disableBtn ? ' disabled' : '') +
                    (this.state.saveForFutureLoader ? ' is-loading--right ' : '')
                  }
                  disabled={disableBtn}
                  onClick={disableBtn ? null : () => this.handleSaveForFutureClick(t)}
                >
                  <span>{t(`portalOrder|Save for future`)}</span>
                </button>
                <button
                  id="bookShipmentBtn"
                  className={
                    'base-button base-button--wide l-grid--w-25pc-w ' +
                    (disableBtn ? ' disabled' : '') +
                    (this.state.bookShipment ? ' is-loading--right ' : '')
                  }
                  onClick={disableBtn ? null : () => this.onGoClick()}
                  disabled={disableBtn}
                >
                  <span>{t(`general|Book Shipment`)}</span>
                </button>
              </div>
              <div className="l-grid--right-s">
                {<Error name="multipleUploadFileError" message={this.renderErrorMessage(state, t)} />}
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

export default ReviewAndBook;
