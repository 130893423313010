import { config, CONST } from '../../data-config';
import { apiLinks } from 'config/api-config';
import { apiRequest, getPropperAPIData } from 'globals/utils/requests';
import { chargeableWeight } from '../DeliveryOptions/Chargeable-weight';
import {
  getProductBasedPrice,
  disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound,
  setDisabledAndResetVas,
  isFreeVas,
  getTimeTable,
} from '../DeliveryOptions/DeliveryOptions-helpers';
import { homeDeliveryValidations } from './ServiceDetails-helpers/ServiceDetails-Home-Delivery-helpers';
import getLanguage from 'globals/utils/getLanguage';
import { hidePricesDueToPayerType } from 'globals/utils/filtering';
import {
  checkAndTranslateOptionSchemas,
  applyOptionSchemaResultToVAS,
  validateReturnProducts,
  checkCustomsVASandEleminateOptionsSchema,
  isDomesticReturnProduct,
  servicePointUseResidentialAddressCheckBox,
  filterServicesForGivenProduct,
  filterGServices,
  applyValidationRulesToASlist,
  checkIfUseResidentialAddressCanBeDisabled,
} from './ServiceDetails-helpers/ServiceDetails-helpers';
import {
  arrangeVASAlphabetically,
  setLoadersState,
  getTranslatedVASName,
  arrangeVASBasedOnSelectedLanguage,
} from './ServiceDetails-helpers/ServiceDetails-General-helpers';
import { loadCollectionAtTerminal } from './ServiceDetails-helpers/ServiceDetails-Collection-At-Terminal';
import { payerCodeBasedFiltering, tailLiftServiceFilter } from './ServiceDetails-helpers/ServiceDetails-Filter-VAS';
import _ from 'lodash';
import { HEMLEVERANS_PRODUCT_CODE, RECEIVER_PAYER } from '../../../globals/constants';
import { formatDate } from '../../../globals';
import { setSelectedPickupDate } from '../../../store/portalOrderSlice';
import { getDoorStepDeliveryVasDefaultData } from '../../../globals/helpers/additionalServices';
import { GLOBAL_CONST } from '../../../globals/data-config-global';

export const beforeCheck = context => {
  return setLoadersState(context.state, true);
};

/**
 * afterCheck
 * performs additional logic after ticking a checkbox
 */
export const afterCheck = async (
  context,
  servicePointCall = true,
  product = {},
  countryConfig = {},
  desiredPickupDates = null,
) => {
  const stateToSet = await getAdditionalServicesForOneSelectedProduct(
    context.state,
    true,
    servicePointCall,
    product,
    countryConfig,
  );

  stateToSet.productSelectionError = false;

  context.updateState(stateToSet, newContext => {
    // when product is changed and changed pickup default date due to cutoff etc, check if is same, if not, call LTC to commited delivery
    if (desiredPickupDates) {
      if (desiredPickupDates instanceof Promise) {
        desiredPickupDates.then(date => {
          const pickupMinDateAfterSelectProduct = date.pickupMinDate ? formatDate(date.pickupMinDate) : null;
          const pickupDateAfterSelectProduct = date.pickupDate ? formatDate(date.pickupDate.value) : null;
          const ltcPickupDate =
            new Date(pickupMinDateAfterSelectProduct) >= new Date(pickupDateAfterSelectProduct)
              ? pickupMinDateAfterSelectProduct
              : pickupDateAfterSelectProduct;
          getTimeTable(ltcPickupDate, newContext, false, true, true, setSelectedPickupDate);
        });
      } else {
        const pickupMinDateAfterSelectProduct = desiredPickupDates.pickupMinDate
          ? formatDate(desiredPickupDates.pickupMinDate)
          : null;
        const pickupDateAfterSelectProduct = desiredPickupDates.pickupDate
          ? formatDate(desiredPickupDates.pickupDate.value)
          : null;
        const ltcPickupDate =
          new Date(pickupMinDateAfterSelectProduct) >= new Date(pickupDateAfterSelectProduct)
            ? pickupMinDateAfterSelectProduct
            : pickupDateAfterSelectProduct;
        getTimeTable(ltcPickupDate, newContext, false, true, true, setSelectedPickupDate);
      }
    }
  });
};

const additionalServiceAndValidationCall = async (product, state, stateObject, disabledVas) => {
  const productCode = product?.code?.toString();
  const pickupCode = state.pickupCountry.value;
  const deliveryCode = state.deliveryCountry.value;
  const payerCode = state.payerCode;
  const urlAdditionalServices = apiLinks.getAdditionalServicesExtended
    .replace('{productCode}', productCode)
    .replace('{pickupCode}', pickupCode)
    .replace('{deliveryCode}', deliveryCode)
    .replace('{payerCode}', payerCode);

  await apiRequest(urlAdditionalServices, 'GET')
    .then(async result => {
      if (result.status === CONST.STATUS_OK) {
        result.data.forEach(resultR => {
          resultR.value = false;
          resultR.productCode = product.code;
        });
        stateObject.additionalServices = filterServicesForGivenProduct(productCode, result.data, disabledVas);

        // Filter out some VASs when Dangerous Goods is selected in tab 2 for any shipment row
        if (state.shipmentDetailsRows.some(row => row.dangerousGoods?.value)) {
          stateObject.additionalServices = stateObject.additionalServices.filter(
            AS => !config.ASToFilterOutIfDangerousGoods.some(ASToFilterOut => ASToFilterOut === AS.code),
          );
        }

        await getValidationResults(JSON.parse(JSON.stringify(stateObject.additionalServices)), productCode).then(
          validationResult => {
            if (validationResult.data) {
              stateObject.additionalServices = validationResult.data;
              stateObject.error = false;
            } else if (validationResult.error) {
              stateObject.error = true;
            }
          },
        );
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

export const getOptionSchema = async (stateObject, { code, LTCDates = false }, pickupDate, state) => {
  const url = window.location.pathname;
  const isPublicOrder = url.indexOf('public-order') !== -1;
  const vasUrl = isPublicOrder ? apiLinks.getPublicOrderVAS : apiLinks.getPortalOrderVAS;
  const urlOptions = vasUrl.replace('{productCode}', code.toString());

  // API Call for optional schema
  await apiRequest(urlOptions, 'GET')
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        stateObject.additionalServices = applyOptionSchemaResultToVAS(stateObject.additionalServices, result.data);

        if (stateObject.additionalServices) {
          stateObject.additionalServices = checkAndTranslateOptionSchemas(
            stateObject.additionalServices,
            LTCDates,
            pickupDate,
            state,
          );
        }
      } else {
        stateObject.error = true;
      }
    })
    .catch(() => {
      stateObject.error = true;
    });
};

const formVasNameMap = (additionalServices, languageCode) => {
  const vasNameMap = {};

  additionalServices.forEach(vas => {
    if (vas.translations) {
      const translationObj = vas.translations.find(obj => obj.languageCode === languageCode);

      //safe fix if en translation is missing
      if (typeof translationObj === 'object') {
        vasNameMap[vas.code] = translationObj.name;
      } else {
        vasNameMap[vas.code] = vas['name'];
      }
    }
  });

  return vasNameMap;
};

/**
 * getAdditionalServicesForOneSelectedProduct
 * main filtering functionality
 */
export const getAdditionalServicesForOneSelectedProduct = async (
  state,
  quotePriceCall = true,
  servicePointCall = true,
  product = {},
  countryConfig = {},
) => {
  const stateObject = {};
  // to prevent empty product in quotecall and after terms of delivery change
  product = _.isEmpty(product) ? state.selectedProduct : product;
  const language = getLanguage(config.regEx.urlValidation);
  const productCode = product?.code?.toString();
  const totalShipmentWeight = state.totals.shipmentDetailsRows.weight.value * 1;
  const isHomeDelivery = config.homeDeliveryProductCodes.indexOf(productCode) !== -1;
  const disabledVas = countryConfig?.disabledVass ?? {};

  stateObject.error = false;
  stateObject.isb2cProduct = config.b2cProducts.indexOf(productCode) !== -1;

  // call additional service and validations
  await additionalServiceAndValidationCall(product, state, stateObject, disabledVas);

  // Reset Dangerous Goods error flag
  stateObject.dangerousGoodsLinkedIfNeeded = true;

  // !!!! important
  // is it Hemleverans product (118) => ADD doorStepDelivery VAS => it is done by HARDCODED VAS, because Farm API does not want to include it for 118
  // !!!! important
  if (productCode === HEMLEVERANS_PRODUCT_CODE) {
    stateObject.additionalServices.push(getDoorStepDeliveryVasDefaultData());
  }

  // get options schema
  if (!stateObject.error) {
    await getOptionSchema(stateObject, product, state.pickupDate.value, state);
  }

  stateObject.additionalServices = stateObject.additionalServices.map(newVas => {
    const vasInPreviousState = state?.additionalServices?.find(item => item?.code === newVas.code);
    if (vasInPreviousState) {
      return {
        ...newVas,
        ...vasInPreviousState,
      };
    }
    return newVas;
  });

  // Home Delivery case
  if (isHomeDelivery && !stateObject.error) {
    await homeDeliveryValidations(product, state, stateObject);
  }

  if (!stateObject.error) {
    checkCustomsVASandEleminateOptionsSchema(
      stateObject,
      state.countryBasedCustoms,
      state.shipmentRange.value,
      product,
      state,
    );
  }

  // Check for Chargeable weight and Collection at terminal
  if (!stateObject.error) await chargeableWeightAndCollectionAtTerminal(stateObject, state, product);

  if (!stateObject.error) {
    stateObject.additionalServicesGeneralError = false;
    stateObject.returnProdStatus = validateReturnProducts(productCode);

    stateObject.additionalServices = arrangeVASAlphabetically(stateObject.additionalServices, language);
    stateObject.additionalServices = arrangeVASBasedOnSelectedLanguage(
      stateObject.additionalServices,
      'translatedName',
    );
    stateObject.additionalServices = getTranslatedVASName(stateObject.additionalServices, state.languageCode);

    if (product && product.hasOwnProperty('priorityServices')) {
      stateObject.additionalServices = filterGServices(product, stateObject.additionalServices);
    }

    stateObject.additionalServices = payerCodeBasedFiltering(stateObject, {
      payerCode: state.payerCode,
      productCode: productCode,
      shipmentRange: state.shipmentRange.value,
      shipmentTypesImpOrExp: state.shipmentTypesImpOrExp.value,
    });

    // FCPG-44 tailLift service filtering based on weight
    stateObject.additionalServices = tailLiftServiceFilter({
      additionalServices: structuredClone(stateObject.additionalServices),
      totalShipmentWeight,
      shipmentRows: state.shipmentDetailsRows,
      tailLiftConfig: countryConfig?.vasFilters?.tailLiftLoading,
      serviceName: 'tailLiftLoading',
    });

    stateObject.additionalServices = tailLiftServiceFilter({
      additionalServices: structuredClone(stateObject.additionalServices),
      totalShipmentWeight,
      shipmentRows: state.shipmentDetailsRows,
      tailLiftConfig: countryConfig?.vasFilters?.tailLiftUnloading,
      serviceName: 'tailLiftUnloading',
    });

    stateObject.additionalServices = checkAddQuotePriceFlagForVas(stateObject.additionalServices);

    if (servicePointCall) {
      if (stateObject.isb2cProduct) {
        servicePointHelpers(stateObject, productCode, state);
      } else {
        stateObject.servicePointUseResidentialAddress = {
          value: false,
          disabled: false,
          isProductServicePointDelivery: false,
        };
      }
    }
  } else {
    stateObject.additionalServices = [];
  }

  // CR - 54
  if (
    config.schedulePickup.indexOf(product?.code) > -1 &&
    stateObject.additionalServices &&
    stateObject.additionalServices.some(VAS => config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) &&
    euroconnectSelectedWithNoDeliveryDate(state)
  ) {
    stateObject.additionalServices.forEach(VAS => {
      if (config.vasExcludedForFailedLTC.indexOf(VAS.code) !== -1) {
        VAS.calculatedResult = null;
        VAS.value = false;
        VAS.disabled = true;
      }
    });
  }

  if (!stateObject.error && quotePriceCall && !hidePricesDueToPayerType(state)) {
    const proxyState = {
      ...JSON.parse(JSON.stringify(state)),
      ...JSON.parse(JSON.stringify(stateObject)),
    };
    proxyState.selectedProduct.code = product.code;
    const { quotePriceError, quotePriceMap } = await getProductBasedPrice(proxyState);

    if (!quotePriceError) {
      stateObject.quotePriceMap = quotePriceMap;
    } else {
      stateObject.quotePriceError = true;
      stateObject.quotePriceMap = {};
    }
  }

  if (
    stateObject.additionalServices &&
    stateObject.additionalServices.length > 0 &&
    stateObject.selectedServicePoint &&
    stateObject.isb2cProduct
  ) {
    let modifiedVas = disableServicePointRelatedVasWhenSpecificFeatureCodeNotFound(
      stateObject.selectedServicePoint,
      stateObject.additionalServices,
    );

    if (stateObject?.selectedServicePoint?.locationType === 'locker') {
      modifiedVas = setDisabledAndResetVas(modifiedVas, config.servicePointLockerDisabledVas);
    }

    if (modifiedVas) {
      stateObject.additionalServices = modifiedVas;
    }
  }

  if (!stateObject.quotePriceError && !hidePricesDueToPayerType(state)) {
    stateObject.additionalServices = setNewPriceForPreviouslySelectedVas(
      stateObject.quotePriceMap,
      stateObject.additionalServices,
      countryConfig,
      productCode,
    );
  }

  stateObject.selectProductError = false;
  stateObject.selectedProduct = state.matchedProducts.find(productP => productP.code === productCode) ?? { code: '' };
  stateObject.additionalServicesError = stateObject.error;

  if (!stateObject.error) {
    stateObject.vasNameMap = formVasNameMap(JSON.parse(JSON.stringify(stateObject.additionalServices)), language);
  }

  if (!stateObject.error && quotePriceCall) {
    checkAndEleminateMissingVasArr(state, stateObject);
  }

  return Object.assign(stateObject, setLoadersState(state, false));
};

export const euroconnectSelectedWithNoDeliveryDate = state => {
  return (
    state.selectedProduct &&
    state.selectedProduct.code.includes(config.ltcNonEurapidProductCodes.toString()) &&
    (!state.selectedProduct.deliveryDate || state.selectedProduct.deliveryDate === 'ltc_error')
  );
};

const checkAndEleminateMissingVasArr = (state, stateObject) => {
  if (state.missingVASArrNames && state.missingVASArrNames.length > 0) {
    const vasArrayNamesList = Object.values(stateObject.vasNameMap);

    stateObject.missingVASArrNames = state.missingVASArrNames.filter(
      missingVAS => vasArrayNamesList.indexOf(missingVAS) === -1,
    );

    stateObject.preSelectedVasNotAvailable = formMissingVasString(stateObject.missingVASArrNames);
  }
};

export const formMissingVasString = missingVASArrNames => {
  return missingVASArrNames.length > 0 ? missingVASArrNames.map(vasName => " '" + vasName + "'").toString() : false;
};

const setNewPriceForPreviouslySelectedVas = (quotePriceMap, additionalServices, countryConfig, selectedProductCode) => {
  const newAdditionalServices = additionalServices.map(AD => {
    if (AD.value) {
      if (quotePriceMap && quotePriceMap[AD.code]) {
        AD.calculatedResult = quotePriceMap[AD.code].calculatedResult;
      } else {
        const freeVas = isFreeVas({
          freeVas: countryConfig?.freeVass,
          vasCode: AD.code,
          selectedProductCode,
        });
        if (freeVas) {
          AD.calculatedResult = GLOBAL_CONST.PRICE_FREE_OF_CHARGE;
        } else {
          AD.calculatedResult = GLOBAL_CONST.PRICE_NOT_AVAILABLE;
        }

        AD.translationWhenPriceNotAvailable = true;
      }
    }

    return AD;
  });

  return newAdditionalServices;
};

const checkAddQuotePriceFlagForVas = additionalServices => {
  const newAdditionalServices = additionalServices.map(additionalService => {
    // flag for vas containing options schema not required for price quote call
    additionalService.canMakeQuotePriceCall = config.priceQuoteOptionsSchema.indexOf(additionalService.code) === -1;

    return additionalService;
  });

  return newAdditionalServices;
};

// Parallel Call for chargeable weight and collection at terminal
const chargeableWeightAndCollectionAtTerminal = async (stateObject, state, product) => {
  let collectionAtTerminalIndex = -1;
  let chargeableWeightMap = {};

  stateObject.additionalServices.forEach(function (vas, i) {
    if (vas.code === config.OptionFieldTypes.collectionAtTerminal) {
      collectionAtTerminalIndex = i;
      return true;
    }
  });

  // Collection at Terminal API Call
  if (collectionAtTerminalIndex !== -1) {
    await loadCollectionAtTerminal(stateObject, collectionAtTerminalIndex, state);
  }

  // Chargeable Weight API Call
  await chargeableWeight(state, product, true).then(result => (chargeableWeightMap = result));

  if (chargeableWeightMap) {
    stateObject.chargeableWeight = chargeableWeightMap.value !== undefined ? chargeableWeightMap.value : '';
    stateObject.error = stateObject.error || chargeableWeightMap.error;
  }
};

const servicePointHelpers = (stateObject, productCode, state) => {
  const properState = getPropperAPIData(state);
  stateObject.servicePointUseResidentialAddress = {};

  stateObject.servicePointUseResidentialAddress.disabled = checkIfUseResidentialAddressCanBeDisabled(
    productCode,
    state,
  );
  stateObject.servicePointUseResidentialAddress.value = !stateObject.servicePointUseResidentialAddress.disabled
    ? servicePointUseResidentialAddressCheckBox(productCode, state)
    : false;
  stateObject.servicePointUseResidentialAddress.isProductServicePointDelivery = isDomesticReturnProduct(productCode);

  if (stateObject.servicePointUseResidentialAddress.value) {
    stateObject.selectedServicePoint = {
      id: RECEIVER_PAYER,
      street: properState.deliveryStreet.value,
      name: properState.deliveryReceiver.value,
      postalCode: properState.deliveryPostalCode.value,
      cityName: properState.deliveryCity.value,
      countryCode: properState.deliveryCountry.value,
    };
    stateObject.servicePointUseResidentialAddress.disabled = true;
  } else if (state.servicePoints.length > 0) {
    stateObject.selectedServicePoint = state.servicePoints.length > 0 ? state.servicePoints[0] : {};
  }
};

/**
 * getValidationResults
 * secondary filtering from validations recieved by API call
 */
export const getValidationResults = async (additionalServices, productCode) => {
  const resultObj = {};
  const urlValudationResults = apiLinks.getValidationResults.replace('{productCode}', productCode);
  const payloadDataForValidationResults = {};

  // Prepare payload for the API call
  additionalServices.forEach(service => {
    payloadDataForValidationResults[service.code] = true;
  });

  // API Call for validations
  await apiRequest(urlValudationResults, 'POST', {
    body: payloadDataForValidationResults,
  })
    .then(result => {
      if (result.status === CONST.STATUS_OK) {
        additionalServices = applyValidationRulesToASlist(additionalServices, result.data);
        resultObj.data = JSON.parse(JSON.stringify(additionalServices));
      } else {
        resultObj.error = true;
      }
    })
    .catch(() => {
      resultObj.error = true;
    });

  return resultObj;
};
