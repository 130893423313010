import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { toCamelCase } from '../../globals/utils/strings';

export const ErrorMessage = ({ message, id: propInput }: { message: ReactNode; id?: string }) => {
  const id = `${toCamelCase(propInput || 'generic')}ErrId`;
  return (
    <Typography variant="body1" id={id} data-testid={id} color="error">
      {message}
    </Typography>
  );
};
