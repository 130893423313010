import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  title: ReactNode;
  description: ReactNode;
  onClose: () => void;
};

export const BulkLimitWarningDialog: React.FC<Props> = ({ open, title, description, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} maxWidth="md" fullWidth data-testid="bulk-limit-warning-dialog">
      <DialogTitle data-testid="dialog-title" sx={{ textAlign: 'left' }}>
        {title}
      </DialogTitle>

      <DialogContent dividers>
        <Typography variant="body1" data-testid="bulk-limit-warning-dialog-description">
          {description}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          pt: '24px',
        }}
      >
        <Button
          onClick={() => {
            onClose();
          }}
          color="primary"
          variant="contained"
          data-testid="close-button"
        >
          {t('general|close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
